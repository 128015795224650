import { EkomiRatingResponse } from 'ppz-otr-common';

import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

import { getHttp } from '.';

export const EKOMI_API = {
  GET_EKOMI_RATING: `${ENDPOINTS.ekomi}/ratings`,
};

export const getEkomiRating = async (
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await getHttp<EkomiRatingResponse>({
    url: EKOMI_API.GET_EKOMI_RATING,
    errorThrown,
  });
};
