import { useState, createContext, useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '../components/Common';
import { SUBMIT_CHECK_TIMEOUT } from '../config/environment';
import commonLiterals from '../language/src/common';
import { getSubmissionStatus, LocalKeys } from '../services';
import storage from '../services/storage';
import { VIEWS } from '../types';

import { useInsuranceUpdate } from './InsuranceCtx';
export interface LoadingContext {
  loading: boolean;
  processing: boolean;
  isAppInitialized: boolean;
}

export interface LoadingUpdateContext {
  setLoading: React.Dispatch<LoadingContext['loading']>;
  setProcessing: React.Dispatch<LoadingContext['processing']>;
  setIsAppInitialized: React.Dispatch<LoadingContext['isAppInitialized']>;
}

const defaultCtx: LoadingContext = {
  loading: true,
  processing: storage.get(LocalKeys.processing) === 'true',
  isAppInitialized: false,
};

export const loadingCtx = createContext<LoadingContext>(defaultCtx);
export const loadingCtxUpdate = createContext<LoadingUpdateContext>(
  {} as LoadingUpdateContext,
);

export const useLoading = () => {
  const loading = useContext(loadingCtx);
  if (loading === undefined)
    throw new Error('useLoading must be used within a LoadingContext');
  return loading;
};

export const useLoadingUpdate = () => {
  const setLoading = useContext(loadingCtxUpdate);
  if (setLoading === undefined)
    throw new Error(
      'useLoadingUpdate must be used within a LoadingUpdateContext',
    );
  return setLoading;
};

interface Props extends Partial<LoadingContext> {
  children?: React.ReactNode;
}

export const LoadingProvider = ({
  children,
  loading: defaultLoading,
  processing: defaultProcessing,
  isAppInitialized: defaultIsAppInitialized,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { setFormSubmitted } = useInsuranceUpdate();

  const [isAppInitialized, setIsAppInitialized] = useState<
    LoadingContext['isAppInitialized']
  >(defaultIsAppInitialized ?? defaultCtx.isAppInitialized);
  const [loading, setLoading] = useState<LoadingContext['loading']>(
    defaultLoading ?? defaultCtx.loading,
  );
  const [processing, setProcessing] = useState<LoadingContext['processing']>(
    defaultProcessing ?? defaultCtx.processing,
  );
  let offerStatusTimeout = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    const clearIntervalStatus = () => {
      !!offerStatusTimeout.current && clearInterval(offerStatusTimeout.current);
    };
    const setIntervalStatus = () => {
      const onError = () => {
        clearIntervalStatus();
        setProcessing(false);
        navigate(VIEWS.error);
      };
      offerStatusTimeout.current = setInterval(async () => {
        if (processing) {
          const response = await getSubmissionStatus(
            storage.get(LocalKeys.businessId)!,
            onError,
          );
          if (!response || !response.data) onError();

          const { submitted } = response.data;
          if (submitted) {
            setFormSubmitted(true);
            setProcessing(false);
            clearIntervalStatus();
            navigate(VIEWS.feedback);
          }
        } else {
          clearIntervalStatus();
        }
      }, SUBMIT_CHECK_TIMEOUT);
    };
    processing && setIntervalStatus();

    return () => {
      clearIntervalStatus();
    };
  }, []);

  return (
    <loadingCtxUpdate.Provider
      value={{
        setLoading,
        setIsAppInitialized,
        setProcessing: (newValue: boolean) => {
          if (!newValue) {
            storage.remove(LocalKeys.processing);
          } else {
            storage.set(LocalKeys.processing, String(newValue));
          }
          setProcessing(newValue);
        },
      }}
    >
      <loadingCtx.Provider
        value={{
          loading,
          isAppInitialized,
          processing,
        }}
      >
        {!processing && loading && (
          <Spinner
            messageToAnnounce={intl.formatMessage(
              commonLiterals.loadingContent,
            )}
            loadingMessage={''}
            show={loading}
          />
        )}
        {processing && (
          <Spinner
            loadingMessage={intl.formatMessage(commonLiterals.processingOffer)}
            show={processing}
          />
        )}
        {children}
      </loadingCtx.Provider>
    </loadingCtxUpdate.Provider>
  );
};

export default LoadingProvider;
