import { ClickableElements } from '../../../types';

export const eventDetails = new Map<ClickableElements, boolean>();

eventDetails.set(ClickableElements.ButtonNext, false);
eventDetails.set(ClickableElements.ButtonBack, false);
eventDetails.set(ClickableElements.ButtonAddPerson, true);
eventDetails.set(ClickableElements.ButtonDeletePerson, true);
eventDetails.set(ClickableElements.ButtonRequestOffer, false);
eventDetails.set(ClickableElements.ButtonOnlineAllServices, false);
eventDetails.set(ClickableElements.ButtonRequestOfferEmail, false);
eventDetails.set(ClickableElements.ButtonRequestOfferPost, false);
eventDetails.set(ClickableElements.ButtonRequestOfferPDF, false);
eventDetails.set(ClickableElements.ButtonFinalizeDownload, true);
eventDetails.set(ClickableElements.ButtonFeedbackDownload, true);
eventDetails.set(ClickableElements.SidebarOpen, true);
eventDetails.set(ClickableElements.SidebarClose, true);
eventDetails.set(ClickableElements.RadioButtonMySelf, true);
eventDetails.set(ClickableElements.RadioButtonMySelfAndOthers, true);
eventDetails.set(ClickableElements.RadioButtonOnlyOthers, true);
eventDetails.set(ClickableElements.RadioButtonBeginningDate_1, true);
eventDetails.set(ClickableElements.RadioButtonBeginningDate_2, true);
eventDetails.set(ClickableElements.RadioButtonBeginningDate_3, true);
eventDetails.set(ClickableElements.RadioButtonBasicTariff, true);
eventDetails.set(ClickableElements.RadioButtonPremiumTariff, true);
eventDetails.set(ClickableElements.CheckBoxActive, true);
eventDetails.set(ClickableElements.CheckBoxInactive, true);
eventDetails.set(ClickableElements.CheckBoxDiffAddressActive, true);
eventDetails.set(ClickableElements.CheckBoxFinalizeActive, true);
eventDetails.set(ClickableElements.CheckBoxFinalizeInactive, true);
eventDetails.set(ClickableElements.CheckBoxDiffAddressInactive, true);
eventDetails.set(ClickableElements.ModalInsuranceOpen, true);
eventDetails.set(ClickableElements.ModalInsuranceClose, true);
eventDetails.set(ClickableElements.TooltipBirthdates, true);
eventDetails.set(ClickableElements.TooltipContributionUSP1, true);
eventDetails.set(ClickableElements.TooltipContributionUSP2, true);
eventDetails.set(ClickableElements.TooltipContributionUSP3, true);
eventDetails.set(ClickableElements.TooltipContributionUSP4, true);
eventDetails.set(ClickableElements.TooltipContributionUSP5, true);
eventDetails.set(ClickableElements.TooltipContributionUSP6, true);
eventDetails.set(ClickableElements.TooltipPrivacyInfo, true);
eventDetails.set(ClickableElements.TooltipBankDetails, true);
eventDetails.set(ClickableElements.TooltipSEPA, true);
eventDetails.set(ClickableElements.TooltipEmail, true);
eventDetails.set(ClickableElements.LinkDownloadPDF, true);
eventDetails.set(ClickableElements.LinkAllServices, true);
eventDetails.set(ClickableElements.LinkToEditContribution, false);
eventDetails.set(ClickableElements.LinkToEditBeginningDate, false);
eventDetails.set(ClickableElements.LinkToEditInsuredOwner, false);
eventDetails.set(ClickableElements.LinkToEditInsuredPerson1, false);
eventDetails.set(ClickableElements.LinkToEditInsuredPerson2, false);
eventDetails.set(ClickableElements.LinkToEditInsuredPerson3, false);
eventDetails.set(ClickableElements.LinkToEditInsuredPerson4, false);
eventDetails.set(ClickableElements.AccordionContribution, true);
eventDetails.set(ClickableElements.AccordionBeginningDate, true);
eventDetails.set(ClickableElements.AccordionInsuredOwner, true);
eventDetails.set(ClickableElements.AccordionInsuredPerson1, true);
eventDetails.set(ClickableElements.AccordionInsuredPerson2, true);
eventDetails.set(ClickableElements.AccordionInsuredPerson3, true);
eventDetails.set(ClickableElements.AccordionInsuredPerson4, true);
eventDetails.set(ClickableElements.EventIBANValidationStart, true);
eventDetails.set(ClickableElements.EventIBANValidationSuccess, true);
eventDetails.set(ClickableElements.EventAddressValidationSuccess, true);
eventDetails.set(ClickableElements.EventAddressValidationError, true);
eventDetails.set(ClickableElements.EventAddressValidationCorrected, true);
