export interface IddRequest {
  checked: boolean;
}

export interface SubmitRequest {
  submitType: ShippingWay;
  online: boolean;
}

export interface SubmitResponse {
  success: boolean;
  documentId?: string;
}

export interface SubmitStatusResponse {
  submitted: boolean;
}

export interface GeneratePdfRequest {
  documentId: string | null;
}

export enum ShippingWay {
  POST = 'POST',
  EMAIL = 'EMAIL',
  SOFORT_DOWNLOAD = 'PDF_DRUCK',
  ONLINE = 'PDF_DRUCK',
}

export const FEEDBACK_DOWNLOAD = 'download';
