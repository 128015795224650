import { ProductIds } from 'ppz-otr-common';

import { getTextInputValue } from '../../utils';

const formatSelectedTariff = (tariff: string): ProductIds[] => {
  if (tariff === '' || tariff === 'IZS') return [];
  const products = tariff.split('+');
  return products as ProductIds[]; //IZS
};

const useGetInitialProduct = () => {
  const _tariffSelected = getTextInputValue('tariffSelected');
  return formatSelectedTariff(_tariffSelected);
};

export default useGetInitialProduct;
