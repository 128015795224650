import {
  Person,
  OFFER_ROLES,
  SpcsSalutation,
  SpcsContactType,
  SpcsTelephoneType,
  ConsentContact,
  INSURANCE_TYPE,
} from 'ppz-otr-common';

import {
  PersonInfo,
  ValidatePersonalDataResponse,
  SpcsRolleDto,
} from '../../../services/personalData';

import { hasValue } from './index';

export const isNameEmpty = (person: Person) => {
  return !hasValue(person.name);
};

export const isLastNameEmpty = (person: Person) => {
  return !hasValue(person.lastname);
};

export const hasNameFormatError = (str: string) => {
  return (
    !!str.match(/[0-9]/) ||
    /dr(\.)?\s/i.test(str) ||
    /ing(\.)?\s/i.test(str) ||
    /prof(\.)?\s/i.test(str) ||
    str.length === 1
  );
};

export const checkContactElementValidationError = (
  response: ValidatePersonalDataResponse[],
  input: string,
  isConsentShow: boolean,
  isOfferEmailShow: boolean,
  insuranceOwner: Person,
) => {
  if (!isConsentShow && !isOfferEmailShow) return;

  if (insuranceOwner === undefined) return;

  const insuranceOwnerResponse = response.find(
    (elem) => elem.personId === insuranceOwner.id,
  );
  return (
    insuranceOwnerResponse !== undefined &&
    insuranceOwnerResponse.dataErrorInformationUpdated?.data[input]
  );
};

export const getValidateParams = (
  persons: Map<string | number | null, Person>,
  consentContact: ConsentContact,
  insuranceOwnerId: string,
): PersonInfo[] => {
  const insuranceOwner = persons.get(insuranceOwnerId);
  return Array.from(persons.values()).map((person) => {
    const isInsuranceOwner = person.role === OFFER_ROLES.insuranceOwner;
    const inheritAddress = !isInsuranceOwner && !person.diffAddress;

    if (inheritAddress) {
      person.address = { ...insuranceOwner!.address };
    }

    const { id, gender, name, lastname, ...rest } = person;
    const { year, month, day, address } = rest;

    let personInfo: PersonInfo = {
      id: id as string,
      salutation: gender as SpcsSalutation,
      firstName: name as string,
      familyName: lastname as string,
      dateOfBirth: `${year}-${month}-${day}`,
      addresses: [
        {
          id: null,
          street: address?.street as string,
          streetNumber: address?.streetNumber as string,
          postalCode: address?.postalCode as string,
          city: address?.city as string,
        },
      ],
    };

    // About consents
    const { email, phone } = consentContact;

    const consentEmailValue = email && email.value;
    const phoneValue = phone && phone.value;

    //aboutOfferByEmail
    const { emailOffer } = person;

    if (isInsuranceOwner) {
      // Checking Consent email
      if (hasValue(consentEmailValue)) {
        personInfo = {
          ...personInfo,
          consentEmail: true,
          emailAddresses: [
            {
              id: null,
              contactType: SpcsContactType.PERSONAL,
              email: consentEmailValue as string,
            },
          ],
        };
      }

      //Checking OfferByEmail email
      else if (hasValue(emailOffer)) {
        personInfo = {
          ...personInfo,
          consentEmail: false,
          emailAddresses: [
            {
              id: null,
              contactType: SpcsContactType.PERSONAL,
              email: emailOffer as string,
            },
          ],
        };
      }

      // Checking telephone
      if (hasValue(phoneValue)) {
        personInfo = {
          ...personInfo,
          consentTelephony: true,
          telephoneNumbers: [
            {
              id: null,
              contactType: SpcsContactType.PERSONAL,
              telephoneType: SpcsTelephoneType.FIXED_LINE,
              number: phoneValue as string,
            },
          ],
        };
      }
    }

    return personInfo;
  });
};

export const getSaveParams = (
  persons: Map<string | number | null, Person>,
  situation: INSURANCE_TYPE,
  addressResponse: ValidatePersonalDataResponse[],
): SpcsRolleDto[] => {
  const updateStructure: SpcsRolleDto[] = addressResponse.map(
    (singleAddress) => {
      const currentPerson = persons.get(singleAddress.personId);
      const { personId, addresses, ...rest } = singleAddress;

      let personRolle: SpcsRolleDto = {
        reference: {
          personId,
          addressId: addresses[0].id as string,
        },
        rollentyp:
          currentPerson && currentPerson.role
            ? currentPerson.role
            : OFFER_ROLES.insuredPerson,
      };

      const { emailAddresses, telephoneNumbers } = rest;

      if (emailAddresses && emailAddresses.length) {
        personRolle = {
          ...personRolle,
          reference: {
            ...personRolle.reference,
            emailId: emailAddresses[0].id,
          },
        };
      }

      if (telephoneNumbers && telephoneNumbers.length) {
        personRolle = {
          ...personRolle,
          reference: {
            ...personRolle.reference,
            telephoneId: telephoneNumbers[0].id,
          },
        };
      }

      return personRolle;
    },
  );
  if (situation !== INSURANCE_TYPE.others) {
    const insuranceOwner: SpcsRolleDto = updateStructure.find(
      ({ rollentyp }) => rollentyp === OFFER_ROLES.insuranceOwner,
    )!;
    updateStructure.push({
      ...insuranceOwner,
      rollentyp: OFFER_ROLES.insuredPerson,
    });
  }
  return updateStructure;
};
