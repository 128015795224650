import { UseValidateParams } from '../../../hooks/useValidateInput';

const validatePhoneNumber: UseValidateParams['validateValueFn'] | undefined = (
  value: string,
) => {
  if (!value) return false;

  const internationalAreaCodeMaxLength = 3;
  const maxDigits = 20;
  const firstElement = value.slice(0, 1);
  const isPlusBegin = firstElement === '+';

  const onlyNumber = new RegExp('^[0-9]*$');
  const val = isPlusBegin ? value.slice(1) : value;
  const phoneNumber = `${val}`.replaceAll(' ', '');

  let validNumber = false;
  let validFirstElement = false;

  validNumber = phoneNumber !== '' ? onlyNumber.test(phoneNumber) : true;
  validFirstElement = onlyNumber.test(firstElement) || isPlusBegin;

  const validLen =
    phoneNumber.length > internationalAreaCodeMaxLength &&
    phoneNumber.length <= maxDigits;

  return validNumber && validFirstElement && validLen;
};

export { validatePhoneNumber };
