import { rest } from 'msw';

import { INSURANCE_BEGINNING_API } from '../../services';
import { getUrl, resDefault } from '../utils';

import { response } from './responses/updateDate';

const updateDateUrl = getUrl(INSURANCE_BEGINNING_API.UPDATE_BEGINNING_DATE);
const updateDateHandler = rest.post(updateDateUrl, (req, res, ctx) => {
  return resDefault(ctx.status(201), ctx.json(response));
});

export const handlers = [updateDateHandler];
export default handlers;
