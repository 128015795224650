import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import {
  UpdateIzsBirthdatesResponse,
  Person,
  INSURANCE_TYPE,
} from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/insuranceSituation';
import {
  ActionButtons,
  PageContainer,
  RadioButtonCard,
} from '../../components/Common';
import { useInsurance, useInsuranceUpdate } from '../../context/InsuranceCtx';
import {
  useIzsIntegration,
  useIzsIntegrationUpdate,
} from '../../context/IzsIntegrationCtx';
import { useLegal, useLegalUpdate } from '../../context/LegalCtx';
import { useLoadingUpdate } from '../../context/LoadingCtx';
import { usePerson, usePersonUpdate } from '../../context/PersonCtx';
import { useTariff } from '../../context/TariffCtx';
import { useTracking } from '../../hooks';
import commonLiterals from '../../language/src/common';
import literals from '../../language/src/insurance-situation';
import { MainWrapper, PageLayout, Section } from '../../layouts';
import { updateIzsBirthdates, updatePreallocationRoles } from '../../services';
import storage, { LocalKeys } from '../../services/storage';
import {
  SingleButton,
  VIEWS,
  ClickableElements,
  GeneralClickProps,
} from '../../types';
import { getOwnerId, personsFromPersonData } from '../../utils';

import Disclaimer from './Disclaimer';
import { getInsuranceTypeOptions } from './utils';

const InsuranceSituation = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { spcsPersons, insuranceOwnerId } = usePerson();
  const { situation, businessId, isPreallocation } = useInsurance();
  const { products, premiumProducts: premiumProductsCtx } = useTariff();

  const { setSituation } = useInsuranceUpdate();
  const { setPersons, setSpcsPersons, setInsuranceOwnerId } = usePersonUpdate();
  const { disclaimerCheckbox } = useLegal();
  const { setDisclaimerCheckbox } = useLegalUpdate();
  const { setLoading } = useLoadingUpdate();
  const { setTrackingClick } = useTracking();

  const { isIzs, isIzsBusinessId, isIzsDataSynced, izsFirstNavigated } =
    useIzsIntegration();
  const { setIsIzsDataSynced, setIzsFirstNavigated, setIsIzsVnSynced } =
    useIzsIntegrationUpdate();

  const [oldSituation, setOldSituation] = useState<string | null>();

  const _trackingRadioButtons = (value: string) => {
    const trackingProps: GeneralClickProps = {
      type: 'INSURANCE_TYPE',
      value: value as INSURANCE_TYPE,
    };
    const _value = value as INSURANCE_TYPE;
    switch (_value) {
      case INSURANCE_TYPE.myself:
        setTrackingClick(ClickableElements.RadioButtonMySelf, trackingProps);
        break;
      case INSURANCE_TYPE.myselfAndMore:
        setTrackingClick(
          ClickableElements.RadioButtonMySelfAndOthers,
          trackingProps,
        );
        break;
      case INSURANCE_TYPE.others:
        setTrackingClick(
          ClickableElements.RadioButtonOnlyOthers,
          trackingProps,
        );
        break;
      default:
        setTrackingClick(ClickableElements.RadioButtonMySelf, trackingProps);
    }
  };

  const _trackingCheckBox = (value: boolean) => {
    const trackingElement = value
      ? ClickableElements.CheckBoxActive
      : ClickableElements.CheckBoxInactive;
    setTrackingClick(trackingElement);
  };

  const _trackingModal = (value: boolean) => {
    const trackingElement = value
      ? ClickableElements.ModalInsuranceOpen
      : ClickableElements.ModalInsuranceClose;
    setTrackingClick(trackingElement);
  };

  const onError = () => {
    navigate(VIEWS.error);
    setLoading(false);
  };

  const updatedSelectedOption = (value: string) => {
    _trackingRadioButtons(value);
    const valueFormatted = value as INSURANCE_TYPE;
    setDisclaimerCheckbox(false);
    setSituation(valueFormatted);
  };

  const onChangeAcceptHandler = (isAccepted: boolean) => {
    _trackingCheckBox(isAccepted);
    setDisclaimerCheckbox(isAccepted);
  };

  const updatePersonContext = (persons: Person[]) => {
    const personsCtx = personsFromPersonData(persons);

    setLoading(false);
    setPersons(personsCtx);
    setSpcsPersons(personsCtx);

    const ownerId = getOwnerId(insuranceOwnerId, personsCtx, true);
    setInsuranceOwnerId(ownerId);
    setIsIzsVnSynced(!!ownerId);
    setIsIzsDataSynced(true);
  };

  const izsBirthdateUpdate = async () => {
    setLoading(true);
    const initProducts = { basic: products, premium: premiumProductsCtx };
    const response: AxiosResponse<UpdateIzsBirthdatesResponse> =
      await updateIzsBirthdates(businessId, situation, initProducts, onError);
    if (!response || !response.data || !response.data.persons) return onError();

    const { data } = response;
    const { persons } = data;
    updatePersonContext(persons);
  };

  const _checkDifferentSituationWithPersons = async (): Promise<boolean> => {
    const isNewSituationWithAlreadyExistingPersons =
      !!oldSituation && oldSituation !== situation && spcsPersons?.size !== 0;

    if (!isNewSituationWithAlreadyExistingPersons) return false;

    const oldIzsProducts = storage.get(LocalKeys.izsProducts)?.split(',');
    storage.clear();
    !!oldIzsProducts &&
      storage.set(LocalKeys.izsProducts, oldIzsProducts.join(','));

    setSituation(situation);
    return true;
  };

  const navigateToBirthDateView = async () => {
    setTrackingClick(ClickableElements.ButtonNext);
    const shouldWeResetApp = await _checkDifferentSituationWithPersons();

    const shouldIzsBirthdatesBeUpdated =
      isIzsBusinessId && isIzs && !isIzsDataSynced;

    if (!isIzsBusinessId && isIzs) setIsIzsDataSynced(true);
    if (shouldIzsBirthdatesBeUpdated) await izsBirthdateUpdate();

    if (isPreallocation && situation === INSURANCE_TYPE.others) {
      try {
        setLoading(true);
        const response = await updatePreallocationRoles(businessId, onError);
        if (!response || !response.data || !response.data.success)
          return onError();
      } catch (e) {
        onError();
        return;
      } finally {
        setLoading(false);
      }
    }
    navigate(VIEWS.myself);
    if (shouldWeResetApp) navigate(0);
  };

  const isOnlyForMe = !(
    situation === INSURANCE_TYPE.myselfAndMore ||
    situation === INSURANCE_TYPE.others
  );

  const initialOptions = getInsuranceTypeOptions(products);

  const disabledButton =
    situation === null || (!isOnlyForMe && !disclaimerCheckbox);

  const buttonsList: SingleButton[] = [
    {
      label: intl.formatMessage(commonLiterals.nextButton),
      type: 'primary',
      callbackOnClick: navigateToBirthDateView,
      disabled: disabledButton,
      id: ABTestingIds.nextButton,
    },
  ];

  useEffect(() => {
    setOldSituation(storage.get(LocalKeys.insuranceSituation));
  }, []);

  useEffect(() => {
    if (isIzsDataSynced && !izsFirstNavigated) {
      navigate(VIEWS.myself);
      setIzsFirstNavigated(true);
    }
  }, [isIzsDataSynced, izsFirstNavigated]);

  return (
    <PageLayout
      title={intl.formatMessage(literals.title)}
      id={ABTestingIds.title}
      subtitle={intl.formatMessage(literals.subtitle)}
      textAlign="left"
    >
      <PageContainer>
        <MainWrapper>
          <Section className="abTesting" id={ABTestingIds.radiosContainer}>
            {initialOptions.map((option, index) => {
              return (
                <RadioButtonCard
                  key={index}
                  id={option.id}
                  name={option.name}
                  value={option.value}
                  label={option.label}
                  selected={situation === option.value}
                  onSelectedCard={updatedSelectedOption}
                />
              );
            })}
          </Section>
          {!isOnlyForMe && (
            <Disclaimer
              onChangeAccept={onChangeAcceptHandler}
              checked={disclaimerCheckbox}
              id={ABTestingIds.disclaimer}
              idModal={ABTestingIds.modal}
              onClosed={() => _trackingModal(false)}
              onOpened={() => _trackingModal(true)}
            />
          )}
          <ActionButtons
            buttonsList={buttonsList}
            id={ABTestingIds.buttonContainer}
          />
        </MainWrapper>
      </PageContainer>
    </PageLayout>
  );
};

export default InsuranceSituation;
