const literals = {
  usp: {
    prevention: {
      basic: [
        'contribution.prevention.limited.usp1',
        'contribution.prevention.usp2',
        'contribution.prevention.usp3',
      ],
      premium: [
        'contribution.prevention.unlimited.usp1',
        'contribution.prevention.usp2',
        'contribution.prevention.usp3',
      ],
    },
    protection: {
      _75: [
        'contribution.protection.75.usp1',
        'contribution.protection.75.usp2',
        'contribution.protection.75.usp3',
      ],
      _90: [
        'contribution.protection.90.usp1',
        'contribution.protection.90.usp2',
        'contribution.protection.90.usp3',
      ],
      _100: [
        'contribution.protection.100.usp1',
        'contribution.protection.100.usp2',
        'contribution.protection.100.usp3',
      ],
    },
    old: {
      zef: [
        'contribution.old.zef.usp1',
        'contribution.old.zef.usp2',
        'contribution.old.zef.usp3',
      ],
      zzp: [
        'contribution.old.zzp.usp1',
        'contribution.old.zzp.usp2',
        'contribution.old.zzp.usp3',
      ],
      zez: [
        'contribution.old.zez.usp1',
        'contribution.old.zez.usp2',
        'contribution.old.zez.usp3',
      ],
    },
  },
  tooltips: {
    prevention: [
      'contribution.tooltip.1',
      'contribution.tooltip.2',
      'contribution.tooltip.3',
    ],
    protection: [
      'contribution.tooltip.4',
      'contribution.tooltip.5',
      'contribution.tooltip.6',
    ],
    old: {
      zez: ['contribution.zez.tooltip.1', 'contribution.zez.tooltip.2'],
      zef: ['contribution.zef.tooltip.1', 'contribution.zef.tooltip.2'],
      zzp: ['contribution.zzp.tooltip.1', 'contribution.zzp.tooltip.2'],
    },
  },
};

export { literals };
