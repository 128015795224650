import { rest } from 'msw';

import { SaveBankDetailsDto, BANK_DETAILS_API } from '../../services';
import { getUrl, resDefault } from '../utils';

import { getBankDataResponse, saveResponse } from './responses/';

const bankDataUrl = getUrl(
  'bank-details/bank-data/:businessId/person/:personId',
);
const bankDataHandler = rest.post(bankDataUrl, async (req, res, ctx) => {
  const request = await req.json<SaveBankDetailsDto>();
  return resDefault(ctx.status(201), ctx.json(getBankDataResponse(request)));
});

const saveBankDetailsUrl = getUrl(BANK_DETAILS_API.SAVE);
const saveBankDetailsHandler = rest.post(
  saveBankDetailsUrl,
  (req, res, ctx) => {
    return resDefault(ctx.status(201), ctx.json(saveResponse));
  },
);

export const handlers = [bankDataHandler, saveBankDetailsHandler];
export default handlers;
