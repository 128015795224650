import { ReactNode, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import ABTestingIds from '../ABTesting';
import { Version } from '../components/Common';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import TrustBanner from '../components/TrustElements/TrustBanner';
import { useDates } from '../context/DatesCtx';
import { useInsurance } from '../context/InsuranceCtx';
import { useLoading } from '../context/LoadingCtx';
import { usePerson } from '../context/PersonCtx';
import { useTariff } from '../context/TariffCtx';
import { useTracking } from '../hooks';
import commonLiterals from '../language/src/common';
import storage, { LocalKeys } from '../services/storage';
import {
  ClickableElements,
  ErrorProperties,
  ErrorTypes,
  HeaderDetails,
  SidebarDetails,
  VIEWS,
} from '../types';
import { getProductName, getSidebarDetails } from '../utils';

import Main from './Main';
import PageContent from './PageContent';
interface Props {
  children: ReactNode;
}

const AppLayout = ({ children }: Props) => {
  const intl = useIntl();
  const location = useLocation();

  const { setTrackingLoadedPage, setTrackingClick, setTrackingError } =
    useTracking();
  const { situation, formSubmitted } = useInsurance();
  const { temporalProducts, tariffPriceSelected } = useTariff();

  const { beginningDateSelected } = useDates();
  const { persons } = usePerson();

  const { isAppInitialized } = useLoading();

  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [headerDetails, setHeaderDetails] = useState<HeaderDetails>({});
  const [sidebarDetails, setSidebarDetails] = useState<SidebarDetails>({
    personTariffs: [],
  });
  const title = intl.formatMessage(commonLiterals.headerTitle);
  const showHeader =
    !location.pathname.includes(VIEWS.feedback) && !formSubmitted;
  useEffect(() => {
    const { pathname } = location;

    const isPageTrackable = (): boolean => {
      const isFirstPageSubmitted = storage.get(LocalKeys.insuranceSituation);
      const isFormSubmitted = storage.get(LocalKeys.formSubmitted) === 'true';
      const isTrackingDone = storage.get(LocalKeys.tracking) === 'true';
      const isKnownView = Object.values(VIEWS).includes(pathname as VIEWS);

      const isInitialPage = pathname === VIEWS.initialSelection;
      const isFeedbackPage = pathname === VIEWS.feedback;

      if (!isInitialPage && !isFirstPageSubmitted) return false;
      if (isFeedbackPage && !isFormSubmitted) return false;

      return isAppInitialized && isKnownView && !isTrackingDone;
    };

    if (isPageTrackable()) {
      const isErrorPage = pathname === VIEWS.error;

      if (isErrorPage) {
        const errorProperties: ErrorProperties = {
          url: window.location.href,
          code: 500,
          message: 'An unexpected error occurred',
        };

        setTrackingError(ErrorTypes.GENERIC, errorProperties);
        return;
      }

      setTrackingLoadedPage(pathname as VIEWS);
      pathname === VIEWS.feedback && storage.set(LocalKeys.tracking, 'true');
      //Clear the storage on feedback page load
      pathname === VIEWS.feedback && storage.clear();
      //Set formSubmitted as true on feedback page load after clearing the existing storage because based on this value restartApp() is called on hitting back button from feedback page
      pathname === VIEWS.feedback &&
        storage.set(LocalKeys.formSubmitted, 'true');
    }
  }, [location.pathname, isAppInitialized]);

  useEffect(() => {
    if (tariffPriceSelected !== undefined) {
      const headerProps = {
        startTariff: tariffPriceSelected.initial,
        finalTariff: tariffPriceSelected.total,
      };
      const sidebarProps = getSidebarDetails(
        tariffPriceSelected,
        beginningDateSelected,
        persons,
      );
      setHeaderDetails(headerProps);
      setSidebarDetails(sidebarProps);
    }
  }, [tariffPriceSelected, beginningDateSelected, persons]);

  const productName = useMemo(() => {
    return getProductName(temporalProducts);
  }, [temporalProducts]);
  return !isAppInitialized ? (
    <></>
  ) : (
    <Main className="abTesting abTestingBottom" id={ABTestingIds.appId}>
      {showHeader && (
        <Header
          title={title}
          products={productName}
          {...headerDetails}
          onShowSidebar={() => {
            setTrackingClick(ClickableElements.SidebarOpen);
            setShowSidebar(!showSidebar);
          }}
        />
      )}
      <Sidebar
        title={title}
        shown={showSidebar}
        products={productName}
        {...sidebarDetails}
        insuranceSituation={situation}
        onClose={() => {
          setTrackingClick(ClickableElements.SidebarClose);
          setShowSidebar(false);
        }}
      />
      <PageContent>
        {children}
        <TrustBanner />
      </PageContent>
      <Footer />
      <Version />
    </Main>
  );
};

export default AppLayout;
