import { INSURANCE_TYPE } from 'ppz-otr-common';

import literals from '../../../language/src/personal-data';
import {
  getLiteral,
  getLiteralWithFormattedPerson,
} from '../../../utils/literals';

export const getInsuranceOwnerTitle = (): string => {
  const { ownerTitle } = literals;

  return getLiteral(ownerTitle);
};

export const getInsuredPersonTitle = (
  situation: INSURANCE_TYPE,
  order?: number,
): string => {
  const { beneficiaryTitle, uniqueBeneficiaryTitle } = literals;

  if (!order) {
    return getLiteral(uniqueBeneficiaryTitle);
  }

  const isMyselfAndMore = situation === INSURANCE_TYPE.myselfAndMore;
  const index = isMyselfAndMore ? order + 1 : order;

  return getLiteralWithFormattedPerson(beneficiaryTitle, index);
};
