import { ValidateBankDetailsResponseDto } from 'ppz-otr-common';

import { postHttp } from './config';
import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

export interface PostBankDetailsDto {
  iban: string;
  mrn?: string | undefined;
}

export const BANK_DETAILS_API = {
  POST: `${ENDPOINTS.bankDetails}/bank-data/:businessId/person/:personId`,
  SAVE: `${ENDPOINTS.bankDetails}/bank-data/:businessId/save`,
};

export const postBankDetails = async (
  businessId: string,
  personId: string,
  bankDetails: PostBankDetailsDto,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<PostBankDetailsDto, ValidateBankDetailsResponseDto>(
    {
      url: BANK_DETAILS_API.POST,
      path: { businessId, personId },
      errorThrown,
    },
    bankDetails,
  );
};

export interface SaveBankDetailsDto {
  bankname?: string;
  bic?: string;
  iban?: string;
  accountHolder?: string;
  paymentId: string;
}

export const saveBankDetails = async (
  businessId: string,
  bankDetails: SaveBankDetailsDto,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<SaveBankDetailsDto, boolean>(
    {
      url: BANK_DETAILS_API.SAVE,
      path: { businessId },
      errorThrown,
    },
    bankDetails,
  );
};
