import styled from 'styled-components';

import { breakpoints, colors, fonts, sizes } from '../../styles/tokens';
import { Icon } from '../../types';

import TooltipInfoModal from './TooltipInfoModal';

interface Props {
  title: string;
  subtitle?: string;
  infoIcon?: Icon;
  id?: string;
  reducedMarginBottom?: boolean;
}

interface TitleContainerProps {
  reducedMarginBottom?: boolean;
}

const TitleContainer = styled.div<TitleContainerProps>`
  text-align: center;
  margin: ${({ reducedMarginBottom }) =>
    reducedMarginBottom
      ? `${sizes.space.PX_32} 0 ${sizes.space.PX_16}`
      : `${sizes.space.PX_32} 0 ${sizes.space.PX_32}`};

  @media (min-width: ${breakpoints.M}) {
    margin: ${({ reducedMarginBottom }) =>
      reducedMarginBottom
        ? `${sizes.space.PX_64} 0 ${sizes.space.PX_32}`
        : `${sizes.space.PX_64} 0 ${sizes.space.PX_48}`};
  }
`;

const MainTitle = styled.h2`
  color: ${colors.BRAND_COLOR};
  font-family: ${fonts.HEADINGS};
  font-size: ${sizes.text.LG};
  font-weight: bold;
  letter-spacing: 0;
  line-height: ${sizes.space.PX_28};

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.XXL};
    line-height: ${sizes.text.H3};
  }
`;

const SubTitle = styled.p`
  color: ${colors.DARKEST_GRAY};
  font-family: ${fonts.REGULARS};
  font-size: ${sizes.text.SM};
  letter-spacing: 0;
  line-height: ${sizes.space.PX_20};

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.M};
    line-height: ${sizes.space.PX_24};
  }
`;

const InfoIconWrapper = styled.div`
  margin: ${sizes.space.PX_12} 0;

  @media (min-width: ${breakpoints.M}) {
    margin: ${sizes.space.PX_24} 0;
  }
`;

const Title = ({
  title,
  subtitle,
  infoIcon,
  id,
  reducedMarginBottom,
}: Props) => {
  return (
    <>
      <TitleContainer
        className="title abTesting"
        id={id}
        reducedMarginBottom={reducedMarginBottom}
      >
        <MainTitle data-testid="title__main-title">{title}</MainTitle>
        {subtitle && (
          <SubTitle data-testid="title__subtitle">{subtitle}</SubTitle>
        )}
        {infoIcon && infoIcon.show && (
          <InfoIconWrapper data-testid="title__info-icon">
            <TooltipInfoModal {...infoIcon} />
          </InfoIconWrapper>
        )}
      </TitleContainer>
    </>
  );
};

export default Title;
