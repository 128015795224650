import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import ControlWithHint from '@eg/elements/ControlWithHint';
import Input from '@eg/elements/Input';

import literals from '../../../language/src/bank-details';
import { FormRowCustom } from '../layouts';
import { IbanBeautified } from '../types';

interface Props {
  IBAN: IbanBeautified;
  loading: boolean;
  error?: string;
  onFocus?: () => void;
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  onIBANUpdated: (iban: string, input: EventTarget & HTMLInputElement) => void;
  id: string;
}

const maxIbanLength = 31;
const maxSpacesAdded = Math.floor(maxIbanLength / 4);

const MAX_LENGTH = maxIbanLength + maxSpacesAdded;

const IbanForm = ({
  IBAN,
  loading,
  error,
  onFocus,
  onChangeHandler,
  onIBANUpdated,
  id,
}: Props) => {
  const intl = useIntl();

  const formIBANLabel = intl.formatMessage(literals.formIBAN);
  const placeholder = intl.formatMessage(literals.formIBANPlaceholder);

  const onKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { currentTarget: input } = event;
    const IBAN = input.value;

    if (event.key === 'Enter') {
      onIBANUpdated(IBAN, input);
    }
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const { currentTarget: input } = event;
    const IBAN = input.value;
    onIBANUpdated(IBAN, input);
  };

  return (
    <FormRowCustom label={formIBANLabel}>
      <ControlWithHint error={error}>
        <Input
          data-testid="bank-details.iban-input"
          id={id}
          onFocus={() => onFocus && onFocus()}
          onChange={onChangeHandler}
          placeholder={placeholder}
          maxLength={MAX_LENGTH}
          value={IBAN?.iban}
          style={{ textTransform: 'uppercase' }}
          disabled={loading}
          onBlur={onBlurHandler}
          onKeyPress={onKeyPressHandler}
        />
      </ControlWithHint>
    </FormRowCustom>
  );
};

export { IbanForm };
