import styled from 'styled-components';

import { Container } from '../../../layouts';
import { sizes } from '../../../styles/tokens';

const ErrorContainer = styled(Container)`
  margin-bottom: ${sizes.space.PX_150};
`;

export default ErrorContainer;
