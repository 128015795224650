const enum SummaryABTestingIds {
  title = 'ppz601',
  tariffDetails = 'ppz602',
  tariffDetailsTop = 'ppz603',
  tariffDetailsButton = 'ppz604',
  insuranceBeginningDetails = 'ppz605',
  insuranceBeginningDetailsTop = 'ppz606',
  insuranceBeginningDetailsButton = 'ppz607',
  buttonContainer = 'ppz608',
  nextButton = 'ppz609',
  backButton = 'ppz610',
  prefixPersonDetails = 'ppz65',
  prefixPersonDetailsTop = 'ppz66',
  prefixPersonDetailsButton = 'ppz67',
}

export default SummaryABTestingIds;
