import React from 'react';

interface IProps {
  children?: React.ReactNode;
}

const PageContainer = ({ children }: IProps) => {
  return (
    <section className="esc_container esc_container--l">{children}</section>
  );
};

export default PageContainer;
