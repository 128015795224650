import { defineMessages } from 'react-intl';

export default defineMessages({
  headline: {
    id: 'finalize.headline',
    defaultMessage: 'Schließen Sie Ihren Vertrag jetzt online ab.',
  },
  subtitle: {
    id: 'finalize.subtitle',
    defaultMessage:
      'Mit dem Klick auf "Online abschließen" werden Ihre Vertragsdaten verbindlich an die ERGO Krankenversicherung AG übertragen.',
  },
  adviceTitle: {
    id: 'finalize.advice.title',
    defaultMessage: '<bold>EU-Vorgabe: Beratungsverzicht</bold>',
  },
  adviceText: {
    id: 'finalize.advice.text',
    defaultMessage:
      'Seit Februar 2018 gilt auch für Vertragsabschlüsse per Internet:',
  },
  adviceSubtext: {
    id: 'finalize.advice.subtext',
    defaultMessage:
      'Wir müssen Sie vorher beraten und dies dokumentieren. Sie können auch schnell und dafür ohne Beratung abschließen. Dann brauchen wir vor dem Abschluss Ihren Beratungsverzicht.',
  },
  checkbox: {
    id: 'finalize.checkbox',
    defaultMessage:
      'Ich verzichte für diesen Vertragsabschluss auf eine persönliche Beratung sowie deren Dokumentation. Dies kann sich evtl. auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken.',
  },
  errorUnchecked: {
    id: 'finalize.error.unchecked',
    defaultMessage:
      'Bitte bestätigen Sie, dass Sie für diesen Vertragsabschluss auf eine persönliche Beratung verzichten.',
  },
  contactSubLine: {
    id: 'finalize.contact.subline',
    defaultMessage: '<bold>Vertragsunterlagen</bold>',
  },
  contactText: {
    id: 'finalize.contact.text',
    defaultMessage:
      'Bitte speichern Sie vor dem Online-Abschluss die Vertragsunterlagen und Informationen als PDF und schließen Sie dann online ab.',
  },
  contractSubLine: {
    id: 'finalize.contract.subline',
    defaultMessage: '<bold>Zahlungspflichtiger Vertrag</bold>',
  },
  contractText: {
    id: 'finalize.contract.text',
    defaultMessage:
      'Bei Vertragsabschluss fallen für Sie keine weiteren Nebenkosten an.',
  },
  finalizeFirstStepButton: {
    id: 'finalize.button.firstStep',
    defaultMessage: 'Herunterladen',
  },
  finalizeSecondStepButton: {
    id: 'finalize.button.secondStep',
    defaultMessage: 'Online abschließen',
  },
  finalizeSecondButtonSubText: {
    id: 'finalize.button.secondStep.subText',
    defaultMessage: 'zahlungspflichtiger Vertrag',
  },
});
