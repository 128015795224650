import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';

const TrustBannerWrapper = styled.div`
  margin-top: ${sizes.space.PX_60};
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: col;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
  gap: ${sizes.space.PX_24};

  //Desktop
  @media (min-width: ${breakpoints.M}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface TrustBannerItemProps {
  show: boolean;
}

const TrustBannerItem = styled.div<TrustBannerItemProps>`
  display: ${({ show }) => (!show ? 'none' : 'flex')};
  height: auto;
  flex: 1 0 calc(100% - 2 * ${sizes.space.PX_24});
  padding: ${sizes.space.PX_20} ${sizes.space.PX_24};
  justify-content: center;

  //Desktop
  @media (min-width: ${breakpoints.M}) {
    padding: ${sizes.space.PX_20} 0;
    flex: 1 0 20%;
  }

  @media (min-width: ${breakpoints.L}) {
    justify-content: center;
  }
`;

export { TrustBannerWrapper, TrustBannerItem };
