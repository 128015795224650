import styled from 'styled-components';

import { breakpoints, colors, fonts, sizes } from '../../../styles/tokens';

const Title = styled.h2`
  color: ${colors.DARKEST_GRAY};
  margin: ${sizes.space.PX_64} 0 ${sizes.space.PX_24} 0 !important;
  font-family: ${fonts.REGULARS};
  font-size: ${sizes.text.XXL};
  line-height: ${sizes.text.H3};
  text-align: center;
  font-weight: 700;

  @media (max-width: ${breakpoints.M}) {
    font-size: ${sizes.text.XL};
    line-height: ${sizes.text.H4};
  }
`;

export default Title;
