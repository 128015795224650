import { InitOfferRequest, InitOfferResponse } from 'ppz-otr-common';

import { postHttp } from './config';
import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

export const INIT_OFFER_API = {
  POST: `${ENDPOINTS.initOffer}`,
};

export const postInitOffer = async (
  params: InitOfferRequest,
  errorThrown: HttpOptions['errorThrown'],
) => {
  const properties = { url: INIT_OFFER_API.POST, errorThrown };
  return await postHttp<InitOfferRequest, InitOfferResponse>(
    properties,
    params,
  );
};
