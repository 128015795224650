import {
  IddRequest,
  GeneratePdfRequest,
  SubmitRequest,
  SubmitResponse,
  SubmitStatusResponse,
} from 'ppz-otr-common';

import { SUBMIT_TIMEOUT, PDF_TIMEOUT } from '../config/environment';

import { getHttp } from './config';
import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

import { postHttp } from '.';

export enum OfferShippingWay {
  POST = 'POST',
  EMAIL = 'EMAIL',
  DIGITAL_CUSTOMER_FILES = 'DIGITALE_KUNDENAKTE',
  FAX = 'FAX',
  CLERK = 'SACHBEARBEITER',
  SIMULATION = 'SIMULATION',
  SOFORT_DOWNLOAD = 'PDF_DRUCK',
}

export interface UpdateOfferResponse {
  success: boolean;
}

export const FINALIZE_API = {
  POST_WAIVER_ADVICE_CHECK: `${ENDPOINTS.finalize}/:businessId/idd`,
  POST_GENERATE_DOCUMENT: `${ENDPOINTS.finalize}/:businessId/document`,
  SUBMIT_FINAL_OFFER: `${ENDPOINTS.finalize}/:businessId/submit`,
  GET_SUBMISSION_STATUS: `${ENDPOINTS.finalize}/:businessId/submitted`,
};

export const postWaiverAdviceCheck = async (
  businessId: string,
  checkStatus: IddRequest,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<IddRequest, UpdateOfferResponse>(
    {
      url: FINALIZE_API.POST_WAIVER_ADVICE_CHECK,
      path: { businessId },
      errorThrown,
    },
    checkStatus,
  );
};

export const postGenerateDocument = async (
  businessId: string,
  errorThrown: HttpOptions['errorThrown'],
  documentId: string | null = null,
) => {
  return await postHttp<GeneratePdfRequest, Blob | MediaSource>(
    {
      url: FINALIZE_API.POST_GENERATE_DOCUMENT,
      path: { businessId },
      errorThrown,
      timeout: PDF_TIMEOUT,
      responseType: 'blob',
    },
    { documentId },
  );
};

export const submitFinalOffer = async (
  businessId: string,
  submitDetails: SubmitRequest,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<SubmitRequest, SubmitResponse>(
    {
      url: FINALIZE_API.SUBMIT_FINAL_OFFER,
      path: { businessId },
      errorThrown,
      timeout: SUBMIT_TIMEOUT,
    },
    submitDetails,
  );
};

export const getSubmissionStatus = async (
  businessId: string,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await getHttp<SubmitStatusResponse>({
    url: FINALIZE_API.GET_SUBMISSION_STATUS,
    path: { businessId },
    errorThrown,
  });
};
