import { defineMessages } from 'react-intl';

export default defineMessages({
  nextButton: {
    id: 'common.button.next',
    defaultMessage: 'weiter',
  },
  nextButtonOnlineContract: {
    id: 'common.button.next.online.contract',
    defaultMessage: 'Weiter zum Online-Abschluss',
  },
  backButton: {
    id: 'common.button.back',
    defaultMessage: 'zurück',
  },
  birthday: {
    id: 'common.birthday',
    defaultMessage: 'Geburtsdatum',
  },
  policyholder: {
    id: 'common.policyholder',
    defaultMessage: 'Versicherungsnehmer',
  },
  insuredPerson: {
    id: 'common.insured-person',
    defaultMessage: 'Versicherte Person',
  },
  loadingContent: {
    id: 'common.loading-content',
    defaultMessage: 'Inhalte werden geladen', // TODO: with this changes now this message will be shown for whatever loading is done, check if this literal is needed
  },
  processingOffer: {
    id: 'common.processing-offer',
    defaultMessage:
      'Moment bitte - die Verarbeitung Ihrer Daten kann bis zu 20 Sekunden dauern.',
  },
  monthly: {
    id: 'common.monthly',
    defaultMessage: 'monatlich*',
  },
  smallPrintPriceAsterisk: {
    id: 'common.small-print-price-asterisk',
    defaultMessage:
      'Es handelt sich um eine vereinfachte Darstellung. Altersbedingte Beitragserhöhungen können darin nicht berücksichtigt werden. Weitere Informationen zur Beitragsentwicklung finden Sie in den Vertragsunterlagen.',
  },
  headerTitle: {
    id: 'header.title',
    defaultMessage: 'ERGO Zahnzusatzversicherung',
  },
  headerFinalPrice: {
    id: 'header.tariff.final-price',
    defaultMessage: 'Ab dem 7. Monat je',
  },
  generalErrorTitle: {
    id: 'general-error.title',
    defaultMessage: 'Entschuldigung',
  },
  generalErrorParagraph: {
    id: 'general-error.paragraph',
    defaultMessage:
      'Ein Online-Abschluss ist zurzeit leider nicht möglich.<br></br> Bitte versuchen Sie es später wieder. Oder Sie rufen an und lassen sich telefonisch beraten.',
  },
  mr: {
    id: 'common.mr',
    defaultMessage: 'Herr',
  },
  ms: {
    id: 'common.ms',
    defaultMessage: 'Frau',
  },
  emailErrorMessage: {
    id: 'common.email.format-error-message',
    defaultMessage: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
  },
  emailEmptyMessage: {
    id: 'common.email.format-empty-message',
    defaultMessage: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
  },
  emailPlaceholder: {
    id: 'common.email-placeholder',
    defaultMessage: 'beispiel@beispiel.de',
  },
  emailLabel: {
    id: 'common.email-label',
    defaultMessage: 'E-Mail Adresse',
  },
  disclaimerLabelCardDefault: {
    id: 'common.disclaimer-label-card-default',
    defaultMessage: 'Hinweis',
  },
});
