import React from 'react';

import Card from '@eg/elements/Card';

import { useTariff } from '../../../context/TariffCtx';
import { getProductName } from '../../../utils';
import { CustomProductName } from '../layouts';

import { CoverageList } from './CoverageList';
interface Props {
  gotPremium: boolean;
}
export const CustomCard = ({ gotPremium }: Props) => {
  const { products } = useTariff();

  const title = getProductName(products);

  return (
    <Card>
      <CustomProductName>{title}</CustomProductName>
      <CoverageList products={products} gotPremium={gotPremium} />
    </Card>
  );
};
