import {
  TariffPrices,
  InitProducts,
  ContributionDetails,
} from 'ppz-otr-common';

import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

import { postHttp } from '.';

export interface UpdateBeginningDateDto {
  beginningDate: string;
  currentPrice: TariffPrices;
  initialProducts: InitProducts;
}

export interface UpdateResponseDto {
  success: boolean;
  priceUpdated: boolean;
  contributionDetails?: ContributionDetails;
}

export const INSURANCE_BEGINNING_API = {
  UPDATE_BEGINNING_DATE: `${ENDPOINTS.insuranceBeginning}/update-date/:businessId`,
};

export const updateBeginningDate = async (
  businessId: string,
  updateDetails: UpdateBeginningDateDto,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<UpdateBeginningDateDto, UpdateResponseDto>(
    {
      url: INSURANCE_BEGINNING_API.UPDATE_BEGINNING_DATE,
      path: {
        businessId,
      },
      errorThrown,
    },
    updateDetails,
  );
};
