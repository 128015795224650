import styled from 'styled-components';

import { breakpoints, colors, fonts, sizes } from '../../../styles/tokens';

const Paragraph = styled.p`
  margin: 0;
  color: ${colors.DARKEST_GRAY};
  font-family: ${fonts.REGULARS};
  font-size: ${sizes.space.PX_12};
  letter-spacing: 0;
  line-height: ${sizes.text.L};

  @media (min-width: ${breakpoints.L}) {
    font-size: ${sizes.text.SM};
  }
`;

export default Paragraph;
