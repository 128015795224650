import { useCallback } from 'react';

import { InitOfferResponse, gotTariffPrices } from 'ppz-otr-common';

import { useTariff, useTariffUpdate } from '../../context/TariffCtx';

const useInitContribution = () => {
  const { contributionSelected } = useTariff();
  const {
    setBasicTariffPrices,
    setPremiumTariffPrices,
    setProducts,
    setPremiumProducts,
    setTariffPriceSelected,
    setTemporalProducts,
  } = useTariffUpdate();

  const initContribution = useCallback(
    (contributionDetails: InitOfferResponse['contributionDetails']) => {
      const { basicPrices, premiumPrices, basicProducts, premiumProducts } =
        contributionDetails;

      const _gotPrices = gotTariffPrices(basicPrices, premiumPrices);
      const isBasic = contributionSelected !== 'premium';
      const selectedProducts = isBasic ? basicProducts : premiumProducts;
      const hasBasicProducts = !!basicProducts.length;

      if (hasBasicProducts) {
        setProducts(basicProducts);
        setTemporalProducts(selectedProducts);
        setPremiumProducts(premiumProducts);
      }

      if (_gotPrices) {
        setBasicTariffPrices(basicPrices);

        if (premiumPrices && Object.keys(premiumPrices).length > 0) {
          setPremiumTariffPrices(premiumPrices);
        }

        const selectedPrices = isBasic ? basicPrices : premiumPrices;
        setTariffPriceSelected(selectedPrices);
      }
    },
    [
      contributionSelected,
      setBasicTariffPrices,
      setPremiumProducts,
      setPremiumTariffPrices,
      setProducts,
      setTariffPriceSelected,
      setTemporalProducts,
    ],
  );

  return initContribution;
};

export default useInitContribution;
