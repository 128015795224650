import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Details from '@eg/elements/Details';
import EditIcon from '@eg/elements/Icons/EditIcon';

import { DetailTitle } from '../../../components/SummaryGrid/layout';
import SummaryGrid from '../../../components/SummaryGrid/SummaryGrid';
import SummaryGridItem from '../../../components/SummaryGrid/SummaryGridItem';
import literals from '../../../language/src/summary';
import { VIEWS } from '../../../types';
import { ButtonLink } from '../layouts';

export interface DetailsPersonProps {
  title: string;
  birthDate: string;
  gender: string;
  fullName: string;
  address: JSX.Element | string;
  email?: string;
  phone?: string;
  id: string;
  idTop: string;
  idButton: string;
  onClick?: () => void;
  onToggle?: () => void;
}

const DetailsPerson = (props: DetailsPersonProps) => {
  const { onClick, onToggle } = props;
  const navigate = useNavigate();

  return (
    <div className="abTesting" id={props.id}>
      <Details
        summary={<DetailTitle>{props.title}</DetailTitle>}
        onToggledOpen={(isOpen) => isOpen && onToggle && onToggle()}
      >
        <SummaryGrid id={props.idTop}>
          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsPersonSalutation} />
          </SummaryGridItem>
          <SummaryGridItem type="right">{props.gender}</SummaryGridItem>

          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsPersonTitleName} />
          </SummaryGridItem>
          <SummaryGridItem type="right">{props.fullName}</SummaryGridItem>

          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsPersonTitleAddress} />
          </SummaryGridItem>
          <SummaryGridItem type="right">{props.address}</SummaryGridItem>

          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsPersonTitleBirthdate} />
          </SummaryGridItem>
          <SummaryGridItem type="right">{props.birthDate}</SummaryGridItem>

          {props.email && (
            <>
              <SummaryGridItem type="left">
                <FormattedMessage {...literals.detailsPersonTitleEmail} />
              </SummaryGridItem>
              <SummaryGridItem type="right">{props.email}</SummaryGridItem>
            </>
          )}

          {props.phone && (
            <>
              <SummaryGridItem type="left">
                <FormattedMessage {...literals.detailsPersonTitlePhone} />
              </SummaryGridItem>
              <SummaryGridItem type="right">{props.phone}</SummaryGridItem>
            </>
          )}
        </SummaryGrid>

        <SummaryGrid id={props.idButton}>
          <SummaryGridItem type="offsetRight">
            <ButtonLink
              variant="text-link"
              size={'large'}
              iconLeft={EditIcon}
              onClick={() => {
                onClick && onClick();
                navigate(VIEWS.personalData);
              }}
            >
              <FormattedMessage {...literals.editButton} />
            </ButtonLink>
          </SummaryGridItem>
        </SummaryGrid>
      </Details>
    </div>
  );
};

export default DetailsPerson;
