import { useState, createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import storage, { LocalKeys } from '../services/storage';
import { VIEWS } from '../types';

export interface LegalContext {
  disclaimerCheckbox: boolean;
  showConsent: boolean;
}

export interface LegalUpdateContext {
  setDisclaimerCheckbox: React.Dispatch<LegalContext['disclaimerCheckbox']>;
  setShowConsent: React.Dispatch<LegalContext['showConsent']>;
}

const defaultCtx: LegalContext = {
  disclaimerCheckbox:
    storage.get(LocalKeys.checkedInsuranceSituation) === 'true',
  showConsent: storage.get(LocalKeys.consentIsShown) === 'true',
};

export const legalCtx = createContext<LegalContext>(defaultCtx);
export const legalCtxUpdate = createContext<LegalUpdateContext>(
  {} as LegalUpdateContext,
);

export const useLegal = () => {
  const legal = useContext(legalCtx);
  if (legal === undefined)
    throw new Error('useLegal must be used within a LegalContext');
  return legal;
};

export const useLegalUpdate = () => {
  const setLegal = useContext(legalCtxUpdate);
  if (setLegal === undefined)
    throw new Error('useLegalUpdate must be used within a LegalUpdateContext');
  return setLegal;
};

interface Props extends Partial<LegalContext> {
  children?: React.ReactNode;
}

export const LegalProvider = ({
  children,
  disclaimerCheckbox: defaultDisclaimerCheckbox,
  showConsent: defaultShowConsent,
}: Props) => {
  const location = useLocation();
  const [disclaimerCheckbox, setDisclaimerCheckbox] = useState<
    LegalContext['disclaimerCheckbox']
  >(defaultDisclaimerCheckbox ?? defaultCtx.disclaimerCheckbox);
  const [showConsent, setShowConsent] = useState<LegalContext['showConsent']>(
    defaultShowConsent ?? defaultCtx.showConsent,
  );

  useEffect(() => {
    /**
     * Legal checkbox needs to be unchecked if page reloaded just in the view
     * itself, if it's not /insurance-selection it should keep it checked behind
     *  the scene.
     */
    if (location.pathname?.includes(VIEWS.initialSelection)) {
      setDisclaimerCheckbox(false);
    }
  }, []);

  return (
    <legalCtxUpdate.Provider
      value={{
        setDisclaimerCheckbox: (newDisclaimerCheckbox: boolean) => {
          if (!newDisclaimerCheckbox) {
            storage.remove(LocalKeys.checkedInsuranceSituation);
          } else {
            storage.set(
              LocalKeys.checkedInsuranceSituation,
              String(newDisclaimerCheckbox),
            );
          }
          setDisclaimerCheckbox(newDisclaimerCheckbox);
        },

        setShowConsent: (consentIsShown: boolean) => {
          storage.set(LocalKeys.consentIsShown, String(consentIsShown));
          setShowConsent(consentIsShown);
        },
      }}
    >
      <legalCtx.Provider
        value={{
          disclaimerCheckbox,
          showConsent,
        }}
      >
        {children}
      </legalCtx.Provider>
    </legalCtxUpdate.Provider>
  );
};

export default LegalProvider;
