import { ErrorProperties, ErrorTypes } from '../../../types';

export const getErrorType = (
  type: ErrorTypes,
  properties: ErrorProperties,
): ErrorTypes | string | number => {
  if (type === ErrorTypes.FORMFIELD) {
    return ErrorTypes.FORMFIELD;
  }

  const { code } = properties;
  return code || 400;
};

export const getErrorMessage = (properties: ErrorProperties): string => {
  const { message } = properties;

  return message || 'An unexpected error occurred';
};

export const getErrorUrl = (properties: ErrorProperties): string => {
  const { url } = properties;

  return url || window.location.href;
};
