const getBlobFromBuffer = async (source, option) => {
  const Blob = await import('buffer').then(({ Blob }) => Blob);
  return new Blob(source, option);
};

const Blob = async (source, option) =>
  typeof window !== 'undefined'
    ? new window.Blob(source, option)
    : await getBlobFromBuffer(source, option);

export const downloadPdfResponse: () => Promise<any> = () =>
  Blob(['testing'], {
    type: 'application/pdf',
  });
