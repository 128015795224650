import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Details from '@eg/elements/Details';
import EditIcon from '@eg/elements/Icons/EditIcon';

import ABTestingIds from '../../../ABTesting/summary';
import { DetailTitle } from '../../../components/SummaryGrid/layout';
import SummaryGrid from '../../../components/SummaryGrid/SummaryGrid';
import SummaryGridItem from '../../../components/SummaryGrid/SummaryGridItem';
import { useTariff } from '../../../context/TariffCtx';
import { useTracking } from '../../../hooks';
import literals from '../../../language/src/summary';
import { ClickableElements, VIEWS } from '../../../types';
import { getProductName } from '../../../utils';
import { CoverageProductsList } from '../../Contribution/components';
import { ButtonLink } from '../layouts';

import DetailsTariffPrices from './DetailsTariffPrices';

const DetailsTariff = () => {
  const navigate = useNavigate();

  const { temporalProducts, tariffPriceSelected } = useTariff();
  const { setTrackingClick } = useTracking();

  const product = useMemo(() => {
    return getProductName(temporalProducts);
  }, [temporalProducts]);

  return (
    <div className="abTesting" id={ABTestingIds.tariffDetails}>
      <Details
        summary={
          <DetailTitle>
            <FormattedMessage {...literals.detailsTariffTitle} />
          </DetailTitle>
        }
        isInitiallyOpen
        onToggledOpen={(isOpen) => {
          isOpen && setTrackingClick(ClickableElements.AccordionContribution);
        }}
      >
        <SummaryGrid id={ABTestingIds.tariffDetailsTop}>
          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsTariffDes} />
          </SummaryGridItem>
          <SummaryGridItem type="right">{product}</SummaryGridItem>

          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsTariffUspList} />
          </SummaryGridItem>
          <SummaryGridItem type="right">
            <CoverageProductsList
              products={temporalProducts}
              showTooltips={false}
              bordered={false}
              marginX={false}
            />
          </SummaryGridItem>

          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsTariffPrices} />
          </SummaryGridItem>
          <SummaryGridItem type="right">
            <DetailsTariffPrices prices={tariffPriceSelected} />
          </SummaryGridItem>
        </SummaryGrid>

        <SummaryGrid id={ABTestingIds.tariffDetailsButton}>
          <SummaryGridItem type="offsetRight">
            <ButtonLink
              variant="text-link"
              size={'large'}
              iconLeft={EditIcon}
              onClick={() => {
                setTrackingClick(ClickableElements.LinkToEditContribution);
                navigate(VIEWS.contribution);
              }}
            >
              <FormattedMessage {...literals.editButton} />
            </ButtonLink>
          </SummaryGridItem>
        </SummaryGrid>
      </Details>
    </div>
  );
};

export default DetailsTariff;
