import {
  RefObject,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import ControlWithHint from '@eg/elements/ControlWithHint';
import Input from '@eg/elements/Input';

import { Address, Person, ValidationErrorPlaces } from 'ppz-otr-common';

import DisclaimerCard from '../../../components/DisclaimerCard/DisclaimerCard';
import { usePerson } from '../../../context/PersonCtx';
import { useValidateInput, useTracking } from '../../../hooks';
import literals from '../../../language/src/personal-data';
import { ErrorTypes, PERSON_INPUTS } from '../../../types';
import { scrollToError } from '../../../utils';
import { getLiteral } from '../../../utils/literals';
import { FormRowCustom, FormSectionCustom } from '../layouts';
import { FormType, OnChangeZipCode } from '../types';
import {
  addressReducer,
  errorReducer,
  initialError,
  setAgentRequiredError,
  setEmptyPostalCode,
  setEmptyStreetError,
  setEmptyStreetNumber,
  setInvalidPostalCode,
  setInvalidStreetError,
  updateCity,
  updatePostalCode,
  updateStreet,
  updateStreetNumber,
} from '../utils';

import AlertAddressUpdated from './AlertAddressUpdated';
import ZipCode from './ZipCode';

interface Props {
  id: number;
  person: Person;
  forwardStreetNameRef?: RefObject<ControlWithHint>;
  forwardStreetNumberRef?: RefObject<ControlWithHint>;
  forwardZipCodeRef?: RefObject<ControlWithHint>;
  onChange: (id: Person['id'], address: Address, type: FormType) => void;
}

const AddressInputs = ({
  id,
  person,
  forwardStreetNameRef,
  forwardStreetNumberRef,
  forwardZipCodeRef,
  onChange,
}: Props) => {
  const { address } = person; // initial Address value
  const {
    errorOnPersonalAddress,
    showErrorOnEmptyPersonalFields,
    autoCorrectedAddresses,
  } = usePerson();

  const { setTrackingError } = useTracking();

  const forcedErrorZipCode = useRef<boolean>(false);

  const [addressState, dispatchAddress] = useReducer(addressReducer, address);
  const [errorState, dispatchError] = useReducer(errorReducer, initialError);
  const [errorZipCode, setErrorZipCode] = useState('');
  const [showAlertWarning, setShowAlertWarning] = useState(false);

  const streetNameInput = useValidateInput({
    required: true,
    defaultValue: addressState.street ?? '',
  });

  const showAgentRequiredCard = errorState.agentRequired;

  const getErrorStreetName = (onBlur?: boolean) => {
    const { forced, value: hasEmptyError } = errorState.emptyStreet;
    const isTouched = onBlur ?? streetNameInput.inputTouched;
    const errorFromHook = !streetNameInput.isValidInput && isTouched;

    if (forced || errorFromHook || errorState.invalidStreet !== '') {
      if (hasEmptyError) {
        return getLiteral(literals.streetEmptyError);
      }

      return errorState.invalidStreet;
    }
    return '';
  };

  const streetNumberInput = useValidateInput({
    required: true,
    defaultValue: addressState.streetNumber ?? '',
  });

  const getErrorStreetNumber = (onBlur?: boolean) => {
    const { forced, value: hasEmptyError } = errorState.emptyStreetNumber;
    const { isValidInput, inputTouched } = streetNumberInput;
    const isTouched = onBlur ?? inputTouched;
    const errorFromHook = !isValidInput && isTouched;

    if ((forced || errorFromHook) && hasEmptyError) {
      return getLiteral(literals.streetNumberEmptyError);
    }
    return '';
  };

  const getErrorZipCode = (valid: boolean, touched: boolean) => {
    const { forced, value: hasError } = errorState.emptyPostalCode;
    const errorFromHook = !valid && (touched || forcedErrorZipCode.current);

    if ((forced && hasError) || errorFromHook) {
      return getLiteral(literals.zipCodeError);
    }
    return '';
  };

  const onChangeZipCode: OnChangeZipCode = useCallback(({ zipCode, city }) => {
    dispatchAddress(updatePostalCode(zipCode.value));
    dispatchAddress(updateCity(city));

    if (zipCode.valid && city !== '') {
      dispatchError(setEmptyPostalCode({ value: false, forced: false }));
      dispatchError(setInvalidPostalCode(false));
      setErrorZipCode('');
      return;
    }

    if (zipCode.value === '') {
      dispatchError(setEmptyPostalCode({ value: true, forced: false }));
    } else {
      dispatchError(setInvalidPostalCode(true));
    }

    const error = getErrorZipCode(false, zipCode.touched);
    setErrorZipCode(error);
  }, []);

  // Street name
  useEffect(() => {
    const { inputValue, isValidInput } = streetNameInput;
    dispatchAddress(updateStreet(inputValue));
    dispatchError(setInvalidStreetError(''));
    dispatchError(setEmptyStreetError({ value: !isValidInput, forced: false }));
  }, [streetNameInput.inputValue, streetNameInput.isValidInput]);

  // Street number
  useEffect(() => {
    const { inputValue, isValidInput } = streetNumberInput;
    dispatchAddress(updateStreetNumber(inputValue));
    dispatchError(
      setEmptyStreetNumber({ value: !isValidInput, forced: false }),
    );
  }, [streetNumberInput.inputValue, streetNumberInput.isValidInput]);

  useEffect(() => {
    if (errorOnPersonalAddress.length > 0) {
      const errorByPerson = errorOnPersonalAddress[id];
      if (errorByPerson) {
        const { where, message, scroll, needAgent } = errorByPerson;
        dispatchError(setAgentRequiredError(needAgent));
        if (
          [
            ValidationErrorPlaces.streetName,
            ValidationErrorPlaces.city,
            ValidationErrorPlaces.generic,
          ].includes(where) &&
          !needAgent
        ) {
          dispatchError(setInvalidStreetError(message));
        }
        if (scroll) {
          scrollToError(PERSON_INPUTS.street, id);
        }
      }
    } else {
      /**
       * When backend returns an unknown validation error in address, we set the
       * error by default to street name. Sometimes this error is fixed updating
       * the zip code, for example, and therefore we need to clean the error
       * from the field.
       */
      dispatchError(setInvalidStreetError(''));
      dispatchError(setAgentRequiredError(false));
    }
  }, [errorOnPersonalAddress, id]);

  useEffect(() => {
    if (showErrorOnEmptyPersonalFields) {
      if (!addressState.street) {
        dispatchError(setEmptyStreetError({ value: true, forced: true }));
      }
      if (!addressState.streetNumber) {
        dispatchError(setEmptyStreetNumber({ value: true, forced: true }));
      }
      if (!addressState.postalCode) {
        dispatchError(setEmptyPostalCode({ value: true, forced: true }));
      }
    }
  }, [
    addressState.postalCode,
    addressState.street,
    addressState.streetNumber,
    showErrorOnEmptyPersonalFields,
  ]);

  useEffect(() => {
    onChange(person.id, addressState, 'address');
  }, [addressState]);

  useEffect(() => {
    const { forced, value: hasError } = errorState.emptyPostalCode;
    if (forced && hasError) {
      forcedErrorZipCode.current = true;
      setErrorZipCode(getLiteral(literals.zipCodeError));
    }
  }, [errorState.emptyPostalCode]);

  /**
   * Triggered when backend returns autocorrected addresses. Sometimes when
   * an address is autocorrected one of the fields is set to null, so we leave
   * the field empty in that case.
   */
  useEffect(() => {
    const { street, streetNumber, postalCode } = person.address;
    streetNameInput.setInputValue(street ?? '');
    streetNumberInput.setInputValue(streetNumber ?? '');
    postalCode && dispatchAddress(updatePostalCode(postalCode));
  }, [person.address]);

  useEffect(() => {
    if (autoCorrectedAddresses.length > 0) {
      const { scroll, showAlert } = autoCorrectedAddresses[id];
      setShowAlertWarning(showAlert);

      if (scroll) {
        scrollToError(PERSON_INPUTS.addressAlert, id);
      }
    } else {
      setShowAlertWarning(false);
    }
  }, [autoCorrectedAddresses, id]);

  return (
    <FormSectionCustom className="personal-data__form-section">
      <span id={`${PERSON_INPUTS.addressAlert}-${id}`}>
        {showAlertWarning && <AlertAddressUpdated />}
      </span>
      <FormRowCustom
        label={getLiteral(literals.addressGroupLabel)}
        colspans={[3, 1]}
      >
        <ControlWithHint
          ref={forwardStreetNameRef}
          data-testid="address-inputs__street-error"
          error={getErrorStreetName()}
          onBlur={() => {
            const isTouched = true;
            const message = getErrorStreetName(isTouched);
            message && setTrackingError(ErrorTypes.FORMFIELD, { message });
          }}
        >
          <Input
            id={`${PERSON_INPUTS.street}-${id}`}
            data-testid="address-inputs-element"
            placeholder={getLiteral(literals.streetLabel)}
            aria-label={getLiteral(literals.streetLabel)}
            value={streetNameInput.inputValue}
            onChange={streetNameInput.onChangeHandler}
            onBlur={streetNameInput.onBlurHandler}
          />
        </ControlWithHint>
        <ControlWithHint
          ref={forwardStreetNumberRef}
          data-testid="address-inputs__number-error"
          error={getErrorStreetNumber()}
          onBlur={() => {
            const isTouched = true;
            const message = getErrorStreetNumber(isTouched);
            message && setTrackingError(ErrorTypes.FORMFIELD, { message });
          }}
        >
          <Input
            id={`${PERSON_INPUTS.streetNumber}-${id}`}
            data-testid="address-inputs-element"
            placeholder={getLiteral(literals.streetNumberLabel)}
            aria-label={getLiteral(literals.streetNumberLabel)}
            value={streetNumberInput.inputValue}
            onChange={streetNumberInput.onChangeHandler}
            onBlur={streetNumberInput.onBlurHandler}
          />
        </ControlWithHint>
      </FormRowCustom>
      <ZipCode
        error={errorZipCode}
        id={id}
        zipCode={addressState.postalCode ?? ''}
        city={addressState.city ?? ''}
        forwardZipCodeRef={forwardZipCodeRef}
        onChange={onChangeZipCode}
        onBlur={(event) => {
          const { target } = event;
          let message = !target.value ? getLiteral(literals.zipCodeError) : '';
          message = errorZipCode || message;
          message && setTrackingError(ErrorTypes.FORMFIELD, { message });
        }}
      />

      {showAgentRequiredCard && (
        <DisclaimerCard>
          {getLiteral(literals.addressAgentError)}
        </DisclaimerCard>
      )}
    </FormSectionCustom>
  );
};
export default AddressInputs;
