import FormRow from '@eg/elements/FormRow';

import styled from 'styled-components';

import { sizes } from '../../../styles/tokens';

const FormRowCustom = styled(FormRow)`
  margin-bottom: ${sizes.space.PX_12};
`;

export { FormRowCustom };
