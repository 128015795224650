import { getCheckboxValue } from '../../utils';

const useGetOfferStatus = () => {
  const fullOfferDisabled = getCheckboxValue('fullOfferDisabled') ?? false;
  const upSellingDisabled = getCheckboxValue('upsellingDisabled') ?? false;

  return {
    fullOfferDisabled,
    upSellingDisabled,
  };
};

export default useGetOfferStatus;
