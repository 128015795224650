import { useState, createContext, useContext } from 'react';

import { QueryParams } from '../hooks/useQueryParams';
import storage, { LocalKeys } from '../services/storage';

export interface IzsIntegrationContext {
  isIzs: boolean;
  isIzsDataSynced: boolean;
  izsFirstNavigated: boolean;
  isIzsVnSynced: boolean;
  isIzsBusinessId: boolean;
}

export type IzsQueryParams = Pick<
  QueryParams,
  'izsBusinessId' | 'prevention' | 'protection'
>;

export interface IzsIntegrationUpdateContext {
  setIsIzs: React.Dispatch<IzsIntegrationContext['isIzs']>;
  setIsIzsDataSynced: React.Dispatch<IzsIntegrationContext['isIzsDataSynced']>;
  setIzsFirstNavigated: React.Dispatch<
    IzsIntegrationContext['izsFirstNavigated']
  >;
  setIsIzsVnSynced: React.Dispatch<IzsIntegrationContext['isIzsVnSynced']>;
  setIsIzsBusinessId: React.Dispatch<IzsIntegrationContext['isIzsBusinessId']>;
}

const defaultCtx: IzsIntegrationContext = {
  isIzs: !!storage.get(LocalKeys.izsProducts),
  isIzsDataSynced: storage.get(LocalKeys.izsDataSynced) === 'true',
  izsFirstNavigated: storage.get(LocalKeys.izsFirstNavigated) === 'true',
  isIzsVnSynced: storage.get(LocalKeys.izsVnSynced) === 'true',
  isIzsBusinessId: storage.get(LocalKeys.izsBusinessId) === 'true',
};

export const izsIntegrationCtx =
  createContext<IzsIntegrationContext>(defaultCtx);
export const izsIntegrationCtxUpdate =
  createContext<IzsIntegrationUpdateContext>({} as IzsIntegrationUpdateContext);

export const useIzsIntegration = () => {
  const ctx = useContext(izsIntegrationCtx);
  if (ctx === undefined)
    throw new Error('izsIntegrationCtx must be used within a LegalContext');
  return ctx;
};

export const useIzsIntegrationUpdate = () => {
  const updateCtx = useContext(izsIntegrationCtxUpdate);
  if (updateCtx === undefined)
    throw new Error(
      'izsIntegrationCtxUpdate must be used within a LegalUpdateContext',
    );
  return updateCtx;
};

interface Props extends Partial<IzsIntegrationContext> {
  children?: React.ReactNode;
}

type Handler = React.Dispatch<React.SetStateAction<boolean>>;

export const IzsIntegrationProvider = ({
  children,
  isIzs: defaultIsIzs,
  isIzsDataSynced: defaultIsIzsDataSynced,
  izsFirstNavigated: defaultIzsFirstNavigated,
  isIzsVnSynced: defaultIsIzsVnSynced,
  isIzsBusinessId: defaultIsIzsId,
}: Props) => {
  const [isIzs, setIsIzs] = useState<boolean>(defaultIsIzs ?? defaultCtx.isIzs);
  const [isIzsDataSynced, setIsIzsDataSynced] = useState<boolean>(
    defaultIsIzsDataSynced ?? defaultCtx.isIzsDataSynced,
  );
  const [izsFirstNavigated, setIzsFirstNavigated] = useState<boolean>(
    defaultIzsFirstNavigated ?? defaultCtx.izsFirstNavigated,
  );
  const [isIzsVnSynced, setIsIzsVnSynced] = useState<boolean>(
    defaultIsIzsVnSynced ?? defaultCtx.isIzsVnSynced,
  );
  const [isIzsBusinessId, setIsIzsBusinessId] = useState<boolean>(
    defaultIsIzsId ?? defaultCtx.isIzsBusinessId,
  );

  const createBooleanHandler = (key: LocalKeys, handler: Handler) => {
    return (newValue: boolean) => {
      if (newValue) {
        storage.set(key, 'true');
      } else {
        storage.remove(key);
      }
      handler(newValue);
    };
  };

  return (
    <izsIntegrationCtxUpdate.Provider
      value={{
        setIsIzs: createBooleanHandler(LocalKeys.izs, setIsIzs),
        setIsIzsVnSynced: createBooleanHandler(
          LocalKeys.izsVnSynced,
          setIsIzsVnSynced,
        ),
        setIsIzsBusinessId: createBooleanHandler(
          LocalKeys.izsBusinessId,
          setIsIzsBusinessId,
        ),
        setIsIzsDataSynced: createBooleanHandler(
          LocalKeys.izsDataSynced,
          setIsIzsDataSynced,
        ),
        setIzsFirstNavigated: createBooleanHandler(
          LocalKeys.izsFirstNavigated,
          setIzsFirstNavigated,
        ),
      }}
    >
      <izsIntegrationCtx.Provider
        value={{
          isIzs,
          isIzsDataSynced,
          izsFirstNavigated,
          isIzsVnSynced,
          isIzsBusinessId,
        }}
      >
        {children}
      </izsIntegrationCtx.Provider>
    </izsIntegrationCtxUpdate.Provider>
  );
};

export default IzsIntegrationProvider;
