import React from 'react';

import GlobalStyled from './GlobalStyledComponent';

const Fonts = React.lazy(() => import('./Fonts'));

export interface Environment {
  dev: boolean;
  aem: boolean;
}

interface GlobalProps {
  environment: Environment;
}

const GlobalStyle = ({ environment }: GlobalProps) => {
  const { aem, dev } = environment;
  return (
    <>
      {/* Fonts are loaded only outside AEM environment */}
      <React.Suspense fallback={null}>{!aem && <Fonts />}</React.Suspense>
      <GlobalStyled dev={dev} debugAB={false} />
    </>
  );
};

export default GlobalStyle;
