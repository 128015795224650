import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  FocusEvent,
  RefObject,
} from 'react';

import ControlWithHint from '@eg/elements/ControlWithHint';
import Input from '@eg/elements/Input';
import Select from '@eg/elements/Select';

import { useLoadingUpdate } from '../../../context/LoadingCtx';
import { useValidateInput } from '../../../hooks';
import literals from '../../../language/src/personal-data';
import { getCitiesByZipCode } from '../../../services/personalData';
import { PERSON_INPUTS } from '../../../types';
import { getLiteral } from '../../../utils/literals';
import { FormRowCustom } from '../layouts';
import { OnChangeZipCode } from '../types';
import { validatePostalCode } from '../utils';

interface ZipCodeProps {
  id: number;
  error: string;
  zipCode: string;
  city: string;
  forwardZipCodeRef?: RefObject<ControlWithHint>;
  onChange: OnChangeZipCode;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const ZipCode = ({
  id,
  error,
  zipCode,
  city,
  forwardZipCodeRef,
  onChange,
  onBlur,
}: ZipCodeProps) => {
  const { setLoading } = useLoadingUpdate();
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState(city);

  const initialLoaded = useRef(false);
  const initialCity = useRef(city);

  const {
    inputValue,
    onChangeHandler,
    onBlurHandler,
    isValidInput,
    inputTouched,
  } = useValidateInput({
    validateValueFn: validatePostalCode,
    required: true,
    defaultValue: zipCode,
    touched: zipCode !== '',
  });

  const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const eventCity = event.target.value;
    onChange({
      zipCode: {
        value: inputValue,
        valid: isValidInput,
        touched: inputTouched,
      },
      city: eventCity,
    });
    setSelectedCity(eventCity);
  };

  useEffect(() => {
    if (isValidInput) {
      searchCities(inputValue);
    } else {
      setCities([]);
    }

    async function searchCities(zipCode: string) {
      setLoading(true);
      const response = await getCitiesByZipCode(zipCode, errorGetCities);
      if (response) {
        const cities = response.data.result.map((element) => element.city);
        setCities(cities);
      }
      setLoading(false);
    }

    function errorGetCities() {
      setCities([]);
      setLoading(false);
    }
  }, [inputValue, isValidInput, inputTouched, setLoading]);

  useEffect(() => {
    const initCity = initialCity.current;
    const defaultCity = cities[0] ?? '';

    if (initialLoaded.current) {
      setSelectedCity(defaultCity);
    } else if (cities.length) {
      setSelectedCity(initCity !== '' ? initCity : defaultCity);
      initialLoaded.current = true;
    }
  }, [cities]);

  useEffect(() => {
    onChange({
      zipCode: {
        value: inputValue,
        valid: isValidInput,
        touched: inputTouched,
      },
      city: selectedCity,
    });
  }, [selectedCity, onChange, inputValue, isValidInput, inputTouched]);

  return (
    <FormRowCustom label="" colspans={[1, 3]} data-testid="zip-code-city">
      <ControlWithHint
        ref={forwardZipCodeRef}
        data-testid="address-inputs__zipcode-error"
        error={error}
      >
        <Input
          id={`${PERSON_INPUTS.postalCode}-${id}`}
          data-testid="address-inputs-element"
          maxLength={5}
          placeholder={getLiteral(literals.zipCodeLabel)}
          aria-label={getLiteral(literals.zipCodeLabel)}
          value={inputValue}
          onChange={onChangeHandler}
          onBlur={(event) => {
            onBlurHandler(event);
            onBlur && onBlur(event);
          }}
        />
      </ControlWithHint>
      {cities.length < 2 && (
        <Input
          value={cities[0] ?? getLiteral(literals.zipCodeEmptyPlaceholder)}
          readOnly
        />
      )}
      {cities.length > 1 && (
        <Select onChange={onChangeSelect} defaultValue={selectedCity}>
          {cities.map((cityName) => (
            <option value={cityName} key={cityName}>
              {cityName}
            </option>
          ))}
        </Select>
      )}
    </FormRowCustom>
  );
};

export default ZipCode;
