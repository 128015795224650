import React, { ReactNode } from 'react';

import { Title } from '../components/Common';
import { Icon, TypeTextAlign } from '../types';

import Content from './Content';
interface Props {
  title: string;
  subtitle?: string;
  infoIcon?: Icon;
  children: ReactNode;
  textAlign?: TypeTextAlign;
  id?: string;
  reducedMarginBottom?: boolean;
}

const PageLayout = ({ children, textAlign, ...titleProps }: Props) => {
  return (
    <>
      <Title {...titleProps} />
      <Content textAlign={textAlign}>{children}</Content>
    </>
  );
};

export default PageLayout;
