import { Environments } from 'ppz-otr-common';

/*
  Warning!
  The file build-info.json will be overwritten during the build phase.
  Do not move or rename this file without changing the build-docker-app.yaml and the dockerfile.
*/
import buildInfo from '../build-info.json';

type DefaultTariff = 'basic' | 'premium' | 'old';

declare global {
  interface Window {
    CONFIG_BACKEND_PROTOCOL?: string;
    CONFIG_BACKEND_HOST?: string;
    CONFIG_BACKEND_BASEPATH?: string;
    NODE_ENV?: string;
    DEVELOPMENT_TIMEOUT?: string;
    DEFAULT_TIMEOUT?: string;
    SUBMIT_TIMEOUT?: string;
    PDF_TIMEOUT?: string;
    BUILD_DATE?: string;
    VERSION?: string;
    SUBMIT_CHECK_TIMEOUT: string;
    DEFAULT_TARIFF: DefaultTariff;
  }
}

const windowObj = typeof window !== 'undefined' ? window : {};
const {
  CONFIG_BACKEND_PROTOCOL,
  CONFIG_BACKEND_HOST,
  CONFIG_BACKEND_BASEPATH,
  NODE_ENV,
  VERSION: WINDOW_VERSION,
  DEVELOPMENT_TIMEOUT: WINDOW_DEVELOPMENT_TIMEOUT,
  DEFAULT_TIMEOUT: WINDOW_DEFAULT_TIMEOUT,
  SUBMIT_TIMEOUT: WINDOW_SUBMIT_TIMEOUT,
  PDF_TIMEOUT: WINDOW_PDF_TIMEOUT,
  SUBMIT_CHECK_TIMEOUT: WINDOW_SUBMIT_CHECK_TIMEOUT,
  DEFAULT_TARIFF: WINDOW_DEFAULT_TARIFF,
} = windowObj as Window & typeof globalThis;

const {
  REACT_APP_BACKEND_PROTOCOL,
  REACT_APP_BACKEND_HOST,
  REACT_APP_BACKEND_BASEPATH,
  REACT_APP_NODE_ENV,
  REACT_APP_VERSION,
  REACT_APP_DEVELOPMENT_TIMEOUT,
  REACT_APP_DEFAULT_TIMEOUT,
  REACT_APP_SUBMIT_TIMEOUT,
  REACT_APP_PDF_TIMEOUT,
  REACT_APP_SUBMIT_CHECK_TIMEOUT,
  REACT_APP_MOCKS_TIMEOUT,
  REACT_APP_DEFAULT_TARIFF,
} = process.env;

const PROTOCOL = CONFIG_BACKEND_PROTOCOL || REACT_APP_BACKEND_PROTOCOL;
const HOST = CONFIG_BACKEND_HOST || REACT_APP_BACKEND_HOST;
const BASE_PATH = CONFIG_BACKEND_BASEPATH || REACT_APP_BACKEND_BASEPATH;

const DEVELOPMENT_TIMEOUT =
  parseInt(
    WINDOW_DEVELOPMENT_TIMEOUT || REACT_APP_DEVELOPMENT_TIMEOUT || '20',
  ) * 1000;

const DEFAULT_TIMEOUT =
  parseInt(WINDOW_DEFAULT_TIMEOUT || REACT_APP_DEFAULT_TIMEOUT || '5') * 1000;

const SUBMIT_CHECK_TIMEOUT = parseInt(
  WINDOW_SUBMIT_CHECK_TIMEOUT || REACT_APP_SUBMIT_CHECK_TIMEOUT || '5000',
);

const SUBMIT_TIMEOUT =
  parseInt(WINDOW_SUBMIT_TIMEOUT || REACT_APP_SUBMIT_TIMEOUT || '180') * 1000;

const PDF_TIMEOUT =
  parseInt(WINDOW_PDF_TIMEOUT || REACT_APP_PDF_TIMEOUT || '60') * 1000;

const BUILD_DATE = buildInfo.buildDate;

const VERSION = REACT_APP_VERSION || WINDOW_VERSION;

const ENV: Environments = (NODE_ENV || REACT_APP_NODE_ENV) as Environments;

const URL_BASE = `${PROTOCOL}${HOST}${BASE_PATH}`;

const MOCK_SERVICES_TIMEOUT = parseInt(REACT_APP_MOCKS_TIMEOUT || '0');

const DEFAULT_TARIFF = REACT_APP_DEFAULT_TARIFF || WINDOW_DEFAULT_TARIFF;

export {
  BASE_PATH,
  URL_BASE,
  VERSION,
  DEVELOPMENT_TIMEOUT,
  DEFAULT_TIMEOUT,
  SUBMIT_TIMEOUT,
  PDF_TIMEOUT,
  BUILD_DATE,
  SUBMIT_CHECK_TIMEOUT,
  ENV,
  MOCK_SERVICES_TIMEOUT,
  DEFAULT_TARIFF,
};
