import styled from 'styled-components';

import { sizes } from '../../../styles/tokens';

const Heading = styled.h2`
  // TODO: dynamic level
  margin: 0 0 ${sizes.space.PX_24};
`;

export default Heading;
