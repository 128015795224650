export enum VIEWS {
  initialSelection = '/initial-selection',
  myself = '/myself',
  myselfAndMore = '/myself-and-more',
  others = '/other-people-only',
  insuranceBeginning = '/insurance-beginning',
  contribution = '/contribution',
  personalData = '/personal-data',
  summary = '/summary',
  bankDetails = '/bank-details',
  finalize = '/finalize',
  feedback = '/feedback',
  error = '/general-error',
}

export const enum PERSON_INPUTS {
  addressAlert = 'addressAlert',
  name = 'name',
  lastname = 'lastname',
  gender = 'gender',
  street = 'street',
  streetNumber = 'number',
  postalCode = 'zipcode',
  city = 'city',
  offerEmail = 'offerByEmail-email',
  IBAN = 'IBAN',
  consentReceiveEmail = 'consentReceiveInformation-email',
  consentReceivePhone = 'consentReceiveInformation-phone',
}

export type GenericError = () => void;
