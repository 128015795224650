import styled from 'styled-components';

import { breakpoints, colors, fonts, sizes } from '../../../styles/tokens';

const Description = styled.p`
  color: ${colors.DARKEST_GRAY};
  font-family: ${fonts.REGULARS};
  text-align: center;
  font-size: ${sizes.text.M};
  line-height: ${sizes.text.XL};

  @media (max-width: ${breakpoints.M}) {
    font-size: ${sizes.text.SM};
    line-height: ${sizes.text.LG};
  }
`;

export default Description;
