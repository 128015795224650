import * as colors from '@eg/elements/styles/ergo-one/colors';

export const BRAND_COLOR = colors.themePrimary;
export const DARKEST_BRAND_COLOR = colors.themeTertiary;
export const WHITE = colors.themeWhite;
export const BLACK = colors.themeBlack;
export const DARKEST_GRAY = colors.themeDarkestGray;
export const DARKER_GRAY = colors.themeDarkerGray;
export const DARK_GRAY = colors.themeDarkGray;
export const MEDIUM_GRAY = colors.themeMediumGray;
export const LIGHT_GRAY = colors.themeLightGray;
export const LIGHTEST_GRAY = colors.themeLightestGray;
export const ERROR = colors.themeErrorColor;
export const SUCCESS = colors.themeSuccessColor;
export const LIGHTER_CYAN = colors.themeAdditional3Lighter;
export const LIGHT_CYAN = colors.themeAdditional3Light;
export const LIGHTER_LAVENDER = colors.themeAdditional1Lighter;
export const ORANGE = colors.themeAdditional5;
export const LIGHTER_GREEN = colors.themeAdditional2Lighter;
export const LIGHT_ORAGE = colors.themeAdditional5Lighter;
