import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

import literals from '../../../language/src/personal-data';
import { colors, sizes, fonts } from '../../../styles/tokens';

const Alert = styled.div`
  background-color: ${colors.LIGHTER_CYAN};
  font-size: ${sizes.text.SM};
  font-family: ${fonts.REGULARS};
  padding: ${sizes.space.PX_12} ${sizes.space.PX_16};
  margin-top: ${sizes.space.PX_16};
  margin-bottom: ${sizes.space.PX_32};
`;

const AlertAddressUpdated = () => {
  return (
    <Alert role="alert">
      <FormattedMessage {...literals.addressAutoCorrected} />
    </Alert>
  );
};

export default AlertAddressUpdated;
