import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tariffs, InitOfferRequest, AgencyDetails } from 'ppz-otr-common';

import { useInsurance, useInsuranceUpdate } from './context/InsuranceCtx';
import { useIzsIntegrationUpdate } from './context/IzsIntegrationCtx';
import { useLoading, useLoadingUpdate } from './context/LoadingCtx';
import { useTariff } from './context/TariffCtx';
import { getProductsFromParams } from './context/utils';
import {
  useGetAEMInitialProduct,
  useGetAEMOfferStatus,
  useGetAgencyValues,
} from './hooks';
import useInitOffer from './hooks/init/useInitOffer';
import useQueryParams from './hooks/useQueryParams';
import useRouteGuard from './hooks/useRouteGuard';
import storage, { LocalKeys } from './services/storage';
import { VIEWS } from './types';

interface InitInfo {
  products: Tariffs[];
  isIZS?: boolean;
}

const AppInit = ({ children }) => {
  const isMounted = useRef<boolean>(false);
  const initOffer = useInitOffer();

  const { loading, isAppInitialized } = useLoading();
  const { setLoading, setIsAppInitialized, setProcessing } = useLoadingUpdate();
  const { setFormSubmitted } = useInsuranceUpdate();

  const location = useLocation();
  const navigate = useNavigate();
  const { guardApp, defaultView } = useRouteGuard();

  const { businessId: businessIdCtx } = useInsurance();
  const { setBusinessId, setIsPreallocation } = useInsuranceUpdate();

  const { products: productsCtx } = useTariff();

  const {
    izsBusinessId,
    prevention,
    protection,
    agentNumber: agentNumberQuery,
    preallocation,
  } = useQueryParams();
  const { setIsIzsBusinessId, setIsIzs } = useIzsIntegrationUpdate();

  const aemProducts = useGetAEMInitialProduct();
  const gotAEMProducts = !!aemProducts && aemProducts.length > 0;

  const { upSellingDisabled: upSelling } = useGetAEMOfferStatus();

  const { agentNumber, actionNumber, isMicroSite } = useGetAgencyValues();

  const [initialError, setInitialError] = useState(false);

  const _getIzsData = (): Tariffs[] => {
    // get possible IZS products
    const alreadyExistingIzsProducts: Tariffs[] =
      (storage.get(LocalKeys.izsProducts)?.split(',') as Tariffs[]) ?? []; // app with already IZS data init
    const queryIzsProducts = getProductsFromParams([prevention!, protection!]); // new query params with IZS data

    // check if IZS query params are new
    const isNewIzsFromQuery: boolean = queryIzsProducts.length > 0;

    // always take new products if the query params are new
    const products = isNewIzsFromQuery
      ? queryIzsProducts
      : alreadyExistingIzsProducts;

    // delete old data as new query appears
    if (isNewIzsFromQuery) {
      storage.clear();
      storage.set(LocalKeys.izsProducts, products.join(','));
      !!agentNumberQuery &&
        storage.set(LocalKeys.agentNumber, agentNumberQuery);
      !!izsBusinessId && setBusinessId(izsBusinessId);
      setIsIzsBusinessId(
        !!izsBusinessId || storage.get(LocalKeys.izsBusinessId) === 'true',
      );
      setIsIzs(true);
      navigate(VIEWS.initialSelection);
    }

    return products;
  };

  const _isAEMWithOldProducts = (): boolean => {
    if (!gotAEMProducts) {
      storage.remove(LocalKeys.initialAEMProducts);
      return false;
    }

    const aemProductsLocal = storage.get(LocalKeys.initialAEMProducts);

    if (aemProductsLocal === null) {
      storage.set(LocalKeys.initialAEMProducts, JSON.stringify(aemProducts));

      /**
       * If we have a businessId here, we don't know if products match,
       * therefore we reset if that is the case.
       */
      const mustReset = businessIdCtx !== null;
      return mustReset;
    }

    // Products don't match, we must reset
    if (JSON.stringify(aemProducts) !== aemProductsLocal) {
      return true;
    }

    // Products match
    return false;
  };

  const _getProducts = (): InitInfo => {
    const izsProducts = _getIzsData();

    const gotIZSProducts = izsProducts.length > 0;

    // Edge case in AEM when you were in IZS and you go to product page afterwards
    const isAemWithOldIzsData = gotIZSProducts && !prevention && gotAEMProducts;

    /**
     * Edge case in AEM when you come from a product page and there is an
     * existing businessId in sessionStorage from another product.
     */
    const isAEMWithOldProducts = _isAEMWithOldProducts();

    if (isAemWithOldIzsData || isAEMWithOldProducts) {
      restartApp();
      return { products: aemProducts };
    }
    if (gotIZSProducts) return { products: izsProducts, isIZS: gotIZSProducts };
    if (gotAEMProducts) return { products: aemProducts };

    return { products: productsCtx };
  };

  const _getBusinessId = (isIZS: boolean | undefined) => {
    if (isIZS && !!izsBusinessId) return izsBusinessId; // isIZS and businessId from query, take the query one
    if (!!prevention || !!protection) return ''; // new businessId because IZS
    if (storage.get(LocalKeys.izsBusinessId) === 'true') return businessIdCtx; // it was a businessId from IZS, we should take the context now

    return businessIdCtx;
  };

  const getCookie = (name: string): string | null => {
    const cookieName = name + '=';
    const cookieList = document.cookie.split(';');
    const cookieFound = cookieList.find((cookie) => {
      return cookie.trim().startsWith(cookieName);
    });
    if (!cookieFound) {
      return '';
    } else {
      return cookieFound.split('=')[1];
    }
  };

  const getFremdIdInCookies = () => {
    const fremdIdCookieKey = 'fid';
    const allCookies = document.cookie;
    const exists = allCookies.includes(fremdIdCookieKey);
    if (exists) {
      const cookieFremdId = getCookie(fremdIdCookieKey);
      if (!cookieFremdId) {
        return '';
      } else {
        return cookieFremdId;
      }
    } else {
      return '';
    }
  };

  const _getInitOfferParams = (
    products: Tariffs[],
    isIZS: boolean = false,
  ): InitOfferRequest => {
    const businessId = _getBusinessId(isIZS);
    const oldBusinessId = storage.get(LocalKeys.oldBusinessId);

    const agencyDetails: AgencyDetails = {
      actionNumber,
      isMicroSite,
      agentNumber: agentNumber ?? storage.get(LocalKeys.agentNumber),
    };

    return {
      products,
      agencyDetails,
      businessId: businessId ?? '',
      izs: isIZS,
      izsTariffsNotUpdated: !!izsBusinessId && izsBusinessId !== businessIdCtx,
      upSelling,
      oldBusinessId,
      fremdId: getFremdIdInCookies(),
    };
  };

  const _checkGeneralError = (): VIEWS | null => {
    const pathname = location.pathname;
    const previousPathname = storage.get(LocalKeys.history);

    if (pathname === VIEWS.error) {
      return previousPathname as VIEWS;
    } else return null;
  };

  const startApp = async () => {
    const { products, isIZS } = _getProducts();

    const initOfferParams = _getInitOfferParams(products, isIZS);
    const isOfferSubmitted = await initOffer(initOfferParams);
    if (isOfferSubmitted === null) {
      return Promise.reject(new Error('Error initializing the offer'));
    }
    if (isOfferSubmitted) {
      setFormSubmitted(true);
      setProcessing(false);
      navigate(VIEWS.feedback);
    }

    const { oldBusinessId } = initOfferParams;
    !!oldBusinessId && setIsPreallocation(!!oldBusinessId);
    !!oldBusinessId && storage.remove(LocalKeys.oldBusinessId);
  };

  useEffect(() => {
    isMounted.current = true;

    const start = async () => {
      try {
        setLoading(true);
        await startApp();
        const previousPathname = _checkGeneralError();
        previousPathname && navigate(previousPathname);
        isMounted.current && setIsAppInitialized(true);
      } catch (error) {
        setIsAppInitialized(true);
        setInitialError(true);
      } finally {
        setLoading(false);
      }
    };

    !isAppInitialized && actionNumber !== null && start();
    return () => {
      isMounted.current = false;
    };
  }, [actionNumber]);

  useEffect(() => {
    const isLoadingDone: boolean = isAppInitialized && !loading;
    let passingGuard = true;

    const isAppFullyInitialized = isLoadingDone && !!businessIdCtx;
    const isAppInitializedButWithoutOffer = isLoadingDone && !businessIdCtx;

    if (isAppFullyInitialized) {
      passingGuard = guardApp(location.pathname);
    }
    if (!passingGuard || isAppInitializedButWithoutOffer) navigate(defaultView);
  }, [businessIdCtx, isAppInitialized, loading]);

  useEffect(() => {
    const checkRoute = () => {
      const guarded = guardApp(location.pathname);
      !guarded && navigate(storage.get(LocalKeys.history) as VIEWS);
    };
    isAppInitialized && checkRoute();
  }, [isAppInitialized, location.pathname]);

  useEffect(() => {
    if (initialError) {
      navigate(VIEWS.error);
    }
  }, [initialError, navigate]);

  const restartApp = useCallback(() => {
    const oldBusinessId = preallocation
      ? storage.get(LocalKeys.businessId)
      : null;

    storage.clear();
    !!oldBusinessId && storage.set(LocalKeys.oldBusinessId, oldBusinessId);

    if (gotAEMProducts) {
      storage.set(LocalKeys.initialAEMProducts, JSON.stringify(aemProducts));
    }

    navigate(VIEWS.initialSelection);
    navigate(0);
  }, [navigate]);

  useEffect(() => {
    // if we've reached feedback page and we enter other page that is not feedback, we just reset
    if (
      storage.get(LocalKeys.formSubmitted) === 'true' &&
      location.pathname !== VIEWS.feedback
    ) {
      restartApp();
    }
  }, [location.pathname, restartApp]);

  if (!isAppInitialized) return <></>;

  return <>{children}</>;
};

export default AppInit;
