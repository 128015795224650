import { Tariffs, INSURANCE_TYPE } from 'ppz-otr-common';

import ABTestingIds from '../../../ABTesting/insuranceSituation';
import literals from '../../../language/src/insurance-situation';
import { InitialSituation } from '../../../types';
import { isZEZ } from '../../../utils';
import { getLiteral } from '../../../utils/literals';

const RADIOBUTTON_NAME = 'selection';

const MYSELF = {
  label: getLiteral(literals.option1),
  name: RADIOBUTTON_NAME,
  value: INSURANCE_TYPE.myself,
  id: ABTestingIds.myself,
};

const MYSELF_AND_MORE = {
  label: getLiteral(literals.option2),
  name: RADIOBUTTON_NAME,
  value: INSURANCE_TYPE.myselfAndMore,
  id: ABTestingIds.myselfAndMore,
};

const ONLY_OTHERS = {
  label: getLiteral(literals.option3),
  name: RADIOBUTTON_NAME,
  value: INSURANCE_TYPE.others,
  id: ABTestingIds.others,
};

const ONLY_OTHERS_ZEZ = {
  label: getLiteral(literals.option3ZEZ),
  name: RADIOBUTTON_NAME,
  value: INSURANCE_TYPE.others,
  id: ABTestingIds.others,
};

export const getInsuranceTypeOptions = (
  products: Tariffs[],
): InitialSituation[] => {
  const defaultInsuranceTypes = [MYSELF, MYSELF_AND_MORE, ONLY_OTHERS];

  if (isZEZ(products)) {
    return [MYSELF, ONLY_OTHERS_ZEZ];
  }
  return defaultInsuranceTypes;
};
