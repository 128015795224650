import { useMemo } from 'react';

import { useTariff } from '../../../context/TariffCtx';
import { AemAssets, AemContent } from '../../../types';
import { getAssetURL, getTrustElementContentPath } from '../../../utils';
import { aemInputHasValue } from '../helpers';
interface Content {
  firstLine: string;
  secondLine: string;
  tooltip: string;
  imagePath: string;
  showTrust: boolean;
}

const useGetTrustElementContent = () => {
  const { temporalProducts } = useTariff();
  const currentProductContent: Content = useMemo(() => {
    const _imagePath = getTrustElementContentPath(
      temporalProducts,
      AemContent.IMAGE,
    );

    const contents = {
      firstLine: getTrustElementContentPath(
        temporalProducts,
        AemContent.FIRST_LINE,
      ),
      secondLine: getTrustElementContentPath(
        temporalProducts,
        AemContent.SECOND_LINE,
      ),
      tooltip: getTrustElementContentPath(temporalProducts, AemContent.TOOLTIP),
      imagePath: getAssetURL(_imagePath, AemAssets.IMAGE),
    };

    const { firstLine, secondLine, tooltip, imagePath } = contents;

    const showTrust =
      aemInputHasValue(firstLine) ||
      aemInputHasValue(secondLine) ||
      aemInputHasValue(tooltip) ||
      aemInputHasValue(imagePath)
        ? true
        : false;

    return {
      ...contents,
      showTrust,
    };
  }, [temporalProducts]);

  return {
    ...currentProductContent,
  };
};
export default useGetTrustElementContent;
