import {
  UpdateContributionDto,
  UpdateContributionResponse,
} from '../../../services/contribution';
import { getContributionDetails } from '../../common/responses';

export const getUpdateContributionResponse = (
  bodyRequest: UpdateContributionDto,
): UpdateContributionResponse => {
  const { products } = bodyRequest;
  const contributionDetails = getContributionDetails(products);

  return {
    success: true,
    contributionDetails,
  };
};
