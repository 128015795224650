import {
  SubmitRequest,
  SubmitResponse,
  ShippingWay,
  Tariffs,
} from 'ppz-otr-common';

import { postGenerateDocument, submitFinalOffer } from '../services/finalize';
import storage from '../services/storage';
import { CHECKOUT_TYPE } from '../types';

import { errorHandler } from './handlers';

import { downloadPdf } from '.';

export interface SubmitOffer extends SubmitRequest {
  businessId: string;
}

export const submit = async (
  onError: () => void,
  businessId: string,
  submitType: ShippingWay,
  online: boolean = false,
): Promise<SubmitResponse> => {
  try {
    return await submitOffer({ businessId, submitType, online });
  } catch (e) {
    errorHandler(onError, e);
    return Promise.reject(new Error('generic error'));
  }
};

const submitOffer = async (params: SubmitOffer): Promise<SubmitResponse> => {
  const { submitType, businessId, online } = params;

  const submitDetails: SubmitRequest = {
    submitType,
    online: online ?? false,
  };

  const response = await submitFinalOffer(businessId, submitDetails, () =>
    Promise.reject(new Error('generic error')),
  );

  if (!response || !response.data || !response.data.success) {
    return Promise.reject(new Error('generic error'));
  }

  return Promise.resolve(response.data);
};

export interface GeneratePDF {
  businessId: string;
  documentId: string | null;
  checkoutType: CHECKOUT_TYPE;
  products: Tariffs[];
}

const generatePDF = async (
  businessId: GeneratePDF['businessId'],
  documentId: GeneratePDF['documentId'] = null,
  checkoutType?: GeneratePDF['checkoutType'],
  currentProducts?: GeneratePDF['products'],
): Promise<void> => {
  const response = await postGenerateDocument(
    businessId,
    () => Promise.reject(new Error('generic error')),
    documentId,
  );

  if (!response) return Promise.reject(new Error('generic error'));

  const { data } = response;
  if (!data) return Promise.reject(new Error('generic error'));

  downloadPdf(data, checkoutType, currentProducts);
  return Promise.resolve();
};

export { submitOffer, generatePDF };
