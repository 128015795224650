import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import { Environments } from 'ppz-otr-common';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/index.scss';

const isDevelopment = process.env.REACT_APP_NODE_ENV === Environments.local;
const enableMock = isDevelopment && process.env.REACT_APP_MOCKS === 'true';

if (enableMock) {
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
