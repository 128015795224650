export enum OfferActionNumber {
  DENTAL_PRODUCTS = '8605', // ZAHN
  HOSPITAL_PRODUCTS = '8630', // SZSL
  DENTAL_DEFAULT = '7602',
}

export enum AgencyRoles {
  MAIN_INTERMEDIARY = 'HAUPTVERMITTLER',
  ADVISORY_AGENT = 'BERATUNGSVERMITTLER',
  REAL_STATE_AGENT = 'BESTANDSVERMITTLER',
}

export interface AgencyDetails {
  actionNumber: OfferActionNumber | string;
  agentNumber: string | null;
  isMicroSite: boolean;
}
