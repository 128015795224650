import { useEffect } from 'react';

import { InitProducts, ProductsType } from 'ppz-otr-common';

import { useInsurance } from '../context/InsuranceCtx';
import { useLoadingUpdate } from '../context/LoadingCtx';
import { useTariff, useTariffUpdate } from '../context/TariffCtx';
import { LocalKeys, putSelectedTariff } from '../services';
import storage from '../services/storage';
import { GenericError, Selection } from '../types';
import { getProductName } from '../utils';

const useResetContributionTariff = (genericError: GenericError) => {
  const { businessId } = useInsurance();
  const { products, premiumProducts } = useTariff();

  const {
    setProducts,
    setBasicTariffPrices,
    setPremiumTariffPrices,
    setPremiumProducts,
  } = useTariffUpdate();

  const { setLoading } = useLoadingUpdate();

  useEffect(() => {
    const basicProductName = getProductName(products);
    const premiumProductName = getProductName(premiumProducts);
    const hasPremium = premiumProductName !== basicProductName;

    const checkoutType = storage.get(LocalKeys.checkoutType);
    const selection = storage.get(LocalKeys.contribution) as Selection;
    const offerToPremium = checkoutType && selection === 'basic';

    const resetPremiumProducts = async () => {
      setLoading(true);
      const initProducts: InitProducts = {
        basic: products,
        premium: premiumProducts,
      };

      const response = await putSelectedTariff(
        businessId!,
        ProductsType.premium,
        initProducts,
        genericError,
      );

      if (!response || !response.data.success) {
        return genericError();
      }
      storage.remove(LocalKeys.checkoutType);

      const { contributionDetails } = response.data;

      // Update products and prices with information returned by the backend
      if (contributionDetails) {
        const { basicProducts, basicPrices, premiumPrices, premiumProducts } =
          contributionDetails;

        setProducts(basicProducts);
        setBasicTariffPrices(basicPrices);
        setPremiumTariffPrices(premiumPrices);
        setPremiumProducts(premiumProducts);
      }
      setLoading(false);
    };

    offerToPremium && hasPremium && resetPremiumProducts();
  }, []);
};

export default useResetContributionTariff;
