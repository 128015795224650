import bankDetailsHandlers from './bankDetails/index.handlers';
import birthdatesHandlers from './birthdates/index.handlers';
import contributionHandlers from './contribution/index.handlers';
import finalizeHandlers from './finalize/index.handlers';
import initOfferHandlers from './initOffer/index.handlers';
import insuranceBeginningHandlers from './insuranceBeginning/index.handlers';
import personalDataHandlers from './personalData/index.handlers';
import shippingAddressHandlers from './shippingAddress/index.handlers';

export const handlers = [
  ...initOfferHandlers,
  ...birthdatesHandlers,
  ...insuranceBeginningHandlers,
  ...contributionHandlers,
  ...personalDataHandlers,
  ...bankDetailsHandlers,
  ...shippingAddressHandlers,
  ...finalizeHandlers,
];
