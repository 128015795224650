import { useState, createContext, useContext, useCallback } from 'react';

import { Person, OFFER_ROLES, ConsentContact } from 'ppz-otr-common';

import { DetailsValidation } from '../types';
import { getOwnerId } from '../utils';
import { initialConsentContact } from '../views/PersonalData/utils/reducers';

export interface AutocorrectedAddress {
  scroll: boolean;
  showAlert: boolean;
}

export interface PersonContext {
  insuranceOwnerId: Person['id'];
  persons: Map<Person['id'], Person>;
  spcsPersons: Map<Person['id'], Person>;
  getConsentInfo: () => ConsentContact;
  showErrorOnEmptyPersonalFields: boolean;
  errorOnPersonalAddress: DetailsValidation[];
  autoCorrectedAddresses: AutocorrectedAddress[];
  minInsuredPersons: number;
  maxInsuredPersons: number;
}

export interface PersonUpdateContext {
  setInsuranceOwnerId: React.Dispatch<PersonContext['insuranceOwnerId']>;
  setPersons: React.Dispatch<PersonContext['persons']>;
  setSpcsPersons: React.Dispatch<PersonContext['spcsPersons']>;

  setPersonById: (id: Person['id'], personData: Partial<Person>) => void;
  setSpcsPersonById: (id: Person['id'], personData: Partial<Person>) => void;

  setShowErrorOnEmptyPersonalFields: React.Dispatch<
    PersonContext['showErrorOnEmptyPersonalFields']
  >;
  setErrorOnPersonalAddress: React.Dispatch<
    PersonContext['errorOnPersonalAddress']
  >;
  setAutoCorrectedAddresses: React.Dispatch<
    PersonContext['autoCorrectedAddresses']
  >;
  setMinInsuredPersons: React.Dispatch<PersonContext['minInsuredPersons']>;
  setMaxInsuredPersons: React.Dispatch<PersonContext['maxInsuredPersons']>;
}

export const defaultPerson: Person = {
  id: 0,
  role: OFFER_ROLES.insuranceOwner,
  day: '',
  month: '',
  year: '',
  gender: null,
  lastname: null,
  name: null,
  address: {
    city: null,
    postalCode: null,
    street: null,
    streetNumber: null,
  },
  diffAddress: false,
  tariffDetails: null,
};

const defaultPersonsCtx = new Map();

const defaultCtx: PersonContext = {
  insuranceOwnerId: null,
  persons: defaultPersonsCtx,
  spcsPersons: new Map(defaultPersonsCtx),
  getConsentInfo: () => {
    return { ...initialConsentContact };
  },
  errorOnPersonalAddress: [],
  showErrorOnEmptyPersonalFields: false,
  autoCorrectedAddresses: [],
  minInsuredPersons: 1,
  maxInsuredPersons: 4,
};

export const personCtx = createContext<PersonContext>(defaultCtx);
export const personCtxUpdate = createContext<PersonUpdateContext>(
  {} as PersonUpdateContext,
);

export const usePerson = () => {
  const person = useContext(personCtx);
  if (person === undefined)
    throw new Error('usePerson must be used within a PersonContext');
  return person;
};

export const usePersonUpdate = () => {
  const setPerson = useContext(personCtxUpdate);
  if (setPerson === undefined)
    throw new Error(
      'usePersonUpdate must be used within a PersonUpdateContext',
    );
  return setPerson;
};

interface Props extends Partial<PersonContext> {
  children?: React.ReactNode;
}

export const PersonProvider = ({
  children,
  insuranceOwnerId: defaultInsuranceOwnerId,
  persons: defaultPersons,
  spcsPersons: defaultSpcsPersons,
  showErrorOnEmptyPersonalFields: defaultShowError,
  minInsuredPersons: defaultMinInsuredPersons,
  maxInsuredPersons: defaultMaxInsuredPersons,
}: Props) => {
  const [persons, setPersons] = useState<PersonContext['persons']>(
    defaultPersons ?? defaultCtx.persons,
  );
  const [spcsPersons, setSpcsPersons] = useState<PersonContext['spcsPersons']>(
    defaultSpcsPersons ?? defaultCtx.spcsPersons,
  );
  const [insuranceOwnerId, setInsuranceOwnerId] = useState<Person['id']>(
    defaultInsuranceOwnerId ?? null,
  );

  const setPersonById = (id: Person['id'], personData: Partial<Person>) => {
    const newPersons: PersonContext['persons'] = new Map(persons);
    const oldPerson: Person = {
      ...newPersons.get(id)!,
    };
    const optionals = ['email', 'phone', 'emailOffer'];
    optionals.forEach((optional) => {
      if (personData[optional] === '') {
        delete oldPerson[optional];
        delete personData[optional];
      }
    });
    newPersons.set(id, {
      ...oldPerson,
      ...personData,
    });
    setPersons(newPersons);
  };
  const setSpcsPersonById = (
    id: Person['id'],
    spcsPersonData: Partial<Person>,
  ) => {
    const newPersons: PersonContext['spcsPersons'] = new Map(spcsPersons);
    const oldPerson: Person = {
      ...newPersons.get(id)!,
    };
    const optionals = ['email', 'phone', 'emailOffer'];
    optionals.forEach((optional) => {
      if (spcsPersonData[optional] === '') {
        delete oldPerson[optional];
        delete spcsPersonData[optional];
      }
    });
    newPersons.set(id, {
      ...newPersons.get(id)!,
      ...spcsPersonData,
    });
    setSpcsPersons(newPersons);
  };
  const getInsuranceOwnerId = useCallback((): Person['id'] => {
    let ownerId: Person['id'] = getOwnerId(insuranceOwnerId, persons, false);
    if (!insuranceOwnerId && !!ownerId) {
      setInsuranceOwnerId(ownerId);
    }
    return ownerId;
  }, [persons, insuranceOwnerId]);

  const [showErrorOnEmptyPersonalFields, setShowErrorOnEmptyPersonalFields] =
    useState<PersonContext['showErrorOnEmptyPersonalFields']>(
      defaultShowError ?? defaultCtx.showErrorOnEmptyPersonalFields,
    );
  const [errorOnPersonalAddress, setErrorOnPersonalAddress] = useState<
    PersonContext['errorOnPersonalAddress']
  >(defaultCtx.errorOnPersonalAddress);
  const [autoCorrectedAddresses, setAutoCorrectedAddresses] = useState<
    PersonContext['autoCorrectedAddresses']
  >(defaultCtx.autoCorrectedAddresses);

  const [minInsuredPersons, setMinInsuredPersons] = useState(
    defaultMinInsuredPersons ?? defaultCtx.minInsuredPersons,
  );
  const [maxInsuredPersons, setMaxInsuredPersons] = useState(
    defaultMaxInsuredPersons ?? defaultCtx.maxInsuredPersons,
  );

  const getConsentInfo = useCallback((): ConsentContact => {
    const insuranceOwner = persons.get(insuranceOwnerId);

    if (!insuranceOwner) {
      return { ...initialConsentContact };
    }

    const email = insuranceOwner?.email;
    const phone = insuranceOwner?.phone;

    return {
      email: {
        valid: email !== undefined,
        value: email ?? '',
      },
      phone: {
        valid: phone !== undefined,
        value: phone ?? '',
      },
    };
  }, [insuranceOwnerId, persons]);

  return (
    <personCtxUpdate.Provider
      value={{
        setInsuranceOwnerId,
        setPersons,
        setSpcsPersons,
        setPersonById,
        setSpcsPersonById,
        setShowErrorOnEmptyPersonalFields,
        setErrorOnPersonalAddress,
        setAutoCorrectedAddresses,
        setMinInsuredPersons,
        setMaxInsuredPersons,
      }}
    >
      <personCtx.Provider
        value={{
          insuranceOwnerId: getInsuranceOwnerId(),
          persons,
          spcsPersons,
          getConsentInfo,
          showErrorOnEmptyPersonalFields,
          errorOnPersonalAddress,
          autoCorrectedAddresses,
          minInsuredPersons,
          maxInsuredPersons,
        }}
      >
        {children}
      </personCtx.Provider>
    </personCtxUpdate.Provider>
  );
};

export default PersonProvider;
