import { useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';

import { useGetAEMProductsTable, useTracking } from '../../../hooks';
import literals from '../../../language/src/contribution';
import { ClickableElements } from '../../../types';
import { CustomLinkButton } from '../layouts';

interface Props {
  gotPremium: boolean;
}

export const ComparisonTables = ({ gotPremium }: Props) => {
  const intl = useIntl();
  const { setTrackingClick } = useTracking();
  const { currentTablePath } = useGetAEMProductsTable();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalId = 'comparisonTableModal';

  const onClickHandler = () => {
    setTrackingClick(ClickableElements.LinkAllServices);
    setIsModalOpen(true);
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
  };

  const modalLinkText = gotPremium
    ? intl.formatMessage(literals.premiumTariffModalLink)
    : intl.formatMessage(literals.singleTariffModalLink);

  if (currentTablePath === '') return <></>;

  return (
    <>
      <CustomLinkButton>
        <Button variant="secondary" onClick={onClickHandler}>
          {modalLinkText}
        </Button>
      </CustomLinkButton>
      <Modal
        open={isModalOpen}
        id={modalId}
        onDismiss={closeModalHandler}
        backdropDismissesModal={true}
        dismissible
        extendedWidth
      >
        <iframe src={currentTablePath} title="productTables" />
      </Modal>
    </>
  );
};
