import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@eg/elements/components/Button';
import DateInput, { DateInputValue } from '@eg/elements/components/DateInput';
import ControlWithHint from '@eg/elements/ControlWithHint';
import TrashbinIcon from '@eg/elements/Icons/TrashbinIcon';
import { DateErrors } from '@eg/elements/utils/validation/date';

import { BirthdateData, OFFER_ROLES } from 'ppz-otr-common';

import { usePerson } from '../../../context/PersonCtx';
import { useTracking } from '../../../hooks';
import { ErrorTypes } from '../../../types';
import { ButtonWrapper, InputWrapper } from '../layouts';
import { OnDateChange, OnDecrease, OnBlur, DeleteIcon } from '../types';
import { getValidateError, formatDateBackend } from '../utils';

interface Props {
  value: BirthdateData;
  icon?: DeleteIcon;
  description: string;
  onDateChange: OnDateChange;
  onDecrease?: OnDecrease;
  onBlur: OnBlur;
  minDate: Date;
  maxDate: Date;
  yesterdayDate: Date;
  id: string;
}

const BirthDatesInput = ({
  value: {
    id,
    role,
    day,
    month,
    year,
    errors: { valid, badInput, rangeOverflow, rangeUnderflow, valueMissing },
  },
  description,
  icon: { show: showIcon = false, disabled: disabledIcon = false } = {
    show: false,
    disabled: true,
  },
  onDateChange,
  onDecrease,
  onBlur,
  minDate,
  maxDate,
  yesterdayDate,
  id: idDOM,
}: Props) => {
  const { persons } = usePerson();
  const intl = useIntl();

  const { setTrackingError } = useTracking();

  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(valid);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [inputDate, setInputDate] = useState<DateInputValue | undefined>();
  const [errors, setErrors] = useState<DateErrors | undefined>();
  const isInsuranceOwner = role === OFFER_ROLES.insuranceOwner;

  const checkChanges = (): boolean => {
    const ctxDate = persons.get(id);
    return (
      touched ||
      !ctxDate ||
      ctxDate.year !== year ||
      ctxDate.month !== month ||
      ctxDate.day !== day
    );
  };

  const onBlurInput = () => {
    let errorMessage =
      errors &&
      inputDate &&
      getValidateError(
        formatDateBackend(day, month, year),
        rangeUnderflow!,
        rangeOverflow!,
        yesterdayDate,
        isInsuranceOwner,
        badInput!,
        valueMissing!,
        intl,
      );
    setErrorMessage(errorMessage);
    errorMessage &&
      setTrackingError(ErrorTypes.FORMFIELD, { message: errorMessage });
    onBlur();
  };

  const onEnterKeydown = (event: KeyboardEvent<HTMLSpanElement>): void => {
    if (event.key === 'Enter') {
      onBlurInput();
    }
  };

  const onChangeInput = (value: DateInputValue, errors: DateErrors) => {
    setErrors(errors);
    setInputDate(value);
    const errorMessageStr = getInputError(value, errors);
    !errors.valid && !!errorMessageStr && setErrorMessage(errorMessageStr);
    setIsValid(errors.valid);
    onDateChange(value, errors, id);
    if (checkChanges()) {
      setTouched(true);
    }
  };

  const getInputError = (
    value: DateInputValue,
    errors: DateErrors,
  ): string | undefined => {
    let errorMessage: string | undefined;
    if (
      value.day !== '' &&
      value.month !== '' &&
      value.year !== '' &&
      value.year?.length === 4
    ) {
      errorMessage = getValidateError(
        formatDateBackend(day, month, year),
        errors.rangeUnderflow!,
        errors.rangeOverflow!,
        yesterdayDate,
        isInsuranceOwner,
        errors.badInput!,
        errors.valueMissing!,
        intl,
      );
    }
    return errorMessage;
  };

  return (
    <InputWrapper
      data-testid="birthdate__single-input"
      className="abTesting abTestingBottom"
      id={idDOM}
    >
      <ControlWithHint
        error={isValid ? '' : errorMessage}
        description={description}
      >
        <DateInput
          id={'dates-' + id}
          value={{ day, month, year }}
          minDate={minDate}
          maxDate={maxDate}
          autoTab={true}
          onKeyDown={onEnterKeydown}
          onChange={onChangeInput}
          onBlur={onBlurInput}
        />
      </ControlWithHint>
      {showIcon && (
        <ButtonWrapper>
          <Button
            data-testid="birthdates-buttons__trash-bin-icon"
            variant="text-link"
            icon={TrashbinIcon}
            size="large"
            disabled={disabledIcon}
            onClick={() => onDecrease && onDecrease(id)}
            type="button"
            id={`${idDOM}-bin`}
            className="abTesting abTestingBottom abTestingOut"
          >
            Delete
          </Button>
        </ButtonWrapper>
      )}
    </InputWrapper>
  );
};

export default BirthDatesInput;
