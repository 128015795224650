export interface TrackingObject {
  event: TrackingEvent;
  eventdetails?: EventDetails;
  page?: Page;
  product?: TrackingProduct[];
  transaction?: Transaction;
  user?: TrackingUser[];
  miscellaneous?: Miscellaneous;
}

export interface Miscellaneous {
  errors: TrackingErrors;
}

export interface TrackingErrors {
  errorFlag?: boolean;
  errorType?: ErrorTypes | string | number;
  errorMessages?: string;
  errorUrl?: string;
}
export interface Page {
  pageInfo: PageInfo;
  attributes?: PageAttrs;
  category?: PageCategory;
}

export interface EventDetails {
  clickedElement?: ClickableElements | string;
  clickType?: ClickTypes;
  timedCall?: boolean;
}

export interface PageInfo {
  pageName: string;
  sysEnv?: SYSENV;
  issueDate?: string;
}

export interface PageAttrs {
  displayedBrand: Brands;
  brokerModus: BrokerModus;
}

export interface PageCategory {
  pagePurpose: PagePurposes;
  primaryCategory: ProductCategories;
}

export interface Transaction {
  attributes: TransactionAttrs;
  transactionID: string;
  total?: TransactionTotal;
}

export interface TransactionAttrs {
  calculatedValue: number;
  calculatedValueReduced: number;
  conversionType?: ConversionTypes;
}

export interface TransactionTotal {
  transactionTotal: number;
}
export interface TrackingProduct {
  productInfo?: ProductInfo;
  attributes: ProductAttr;
  category?: ProductCategory;
}

export interface TrackingUser {
  profile: UserProfile;
}

export interface UserProfile {
  attributes: UserProfileAttr;
  address?: UserProfileAddress;
}

export interface UserProfileAttr {
  insuredIsInsuredPerson?: string;
  birthday?: string;
  gender?: Gender | null;
}

export interface UserProfileAddress {
  postalCode: string | null;
}

export interface ProductInfo {
  productName: string;
}

export interface ProductAttr {
  tariffOptions1: string;
  productCombination?: ProductCombinations;
  insuranceStart?: string;
}

export interface ProductCategory {
  primaryCategory: string;
}

export type SYSENV = 'prod' | 'develop';

export const enum TrackingEvent {
  LOAD = 'PageLoaded',
  CLICK = 'GeneralClick',
}

export const enum Brands {
  ERGO = 'ERGO',
}

export const enum BrokerModus {
  ON = 'On',
  OFF = 'Off',
}

export const enum PagePurposes {
  SALE = 'Sale',
}

export const enum ConversionTypes {
  SALE = 'Sale',
  START = 'SaleFunnelStart',
  CALCULATION = 'SaleFunnelCalculation',
  PERSONAL_DATA = 'SaleFunnelPersonalData',
  PERSONAL_DATA_SUCCESS = 'AdressVerificationSuccess', // This is not a typo, in german it's "Adresse"
  PERSONAL_DATA_FAILED = 'AdressVerificationFailed', // This is not a typo, in german it's "Adresse"
  PERSONAL_DATA_CORRECTED = 'AdressVerificationDataCorrected', // This is not a typo, in german it's "Adresse"
  FINAL_CHECK = 'SaleFunnelFinalCheck',
  BANK_DATA = 'SaleFunnelBankData',
  IBAN_START = 'IbanVerificationStart',
  IBAN_SUCCESS = 'IbanVerificationSuccess',
  POST = 'OfferPost',
  EMAIL = 'OfferEmail',
  PDF = 'OfferPdfDownload',
}

export const enum ClickableElements {
  ButtonNext = 'Button_Weiter',
  ButtonBack = 'Button_Zurück',
  ButtonAddPerson = 'Add_Person',
  ButtonDeletePerson = 'Delete_Person',
  ButtonRequestOffer = 'Button_AngebotAnfordern',
  ButtonOnlineAllServices = 'Button_WeiterAbschluss',
  ButtonRequestOfferEmail = 'Button_AngebotEmail',
  ButtonRequestOfferPost = 'Button_AngebotPost',
  ButtonRequestOfferPDF = 'Button_SofortDownload',
  ButtonFinalizeDownload = 'Button_IDDDownload',
  ButtonFeedbackDownload = 'Button_AngebotAlsPDF',
  SidebarOpen = 'SB_Open',
  SidebarClose = 'SB_Close',
  RadioButtonMySelf = 'RButton_Mich',
  RadioButtonMySelfAndOthers = 'RButton_MichAndere',
  RadioButtonOnlyOthers = 'RButton_Andere',
  RadioButtonBeginningDate_1 = 'RButton_StartDate1',
  RadioButtonBeginningDate_2 = 'RButton_StartDate2',
  RadioButtonBeginningDate_3 = 'RButton_StartDate3',
  RadioButtonBasicTariff = 'RButton_Tariff1',
  RadioButtonPremiumTariff = 'RButton_Tariff2',
  CheckBoxActive = 'Checkbox_AndereAbsichern_Active',
  CheckBoxInactive = 'Checkbox_AndereAbsichern_Inactive',
  CheckBoxDiffAddressActive = 'CheckBox_AndereAdresse_Active',
  CheckBoxDiffAddressInactive = 'CheckBox_AndereAdresse_Inactive',
  CheckBoxFinalizeActive = 'CheckBox_Beratungsverzicht_active',
  CheckBoxFinalizeInactive = 'CheckBox_Beratungsverzicht_inactive',
  ModalInsuranceOpen = 'Modal_NaheAngehoerigeOpen',
  ModalInsuranceClose = 'Modal_NaheAngehoerigeClose',
  TooltipBirthdates = 'InfoIcon_GebDatum',
  TooltipContributionUSP1 = 'InfoIcon_Prophylaxe',
  TooltipContributionUSP2 = 'InfoIcon_Füllungen',
  TooltipContributionUSP3 = 'InfoIcon_Wurzel',
  TooltipContributionUSP4 = 'InfoIcon_Implantate',
  TooltipContributionUSP5 = 'InfoIcon_Prothesen',
  TooltipContributionUSP6 = 'InfoIcon_Inlays',
  TooltipPrivacyInfo = 'InfoIcon_Datenschutz',
  TooltipBankDetails = 'InfoIcon_IBAN',
  TooltipSEPA = 'InfoIcon_SEPALastschr',
  TooltipEmail = 'InfoIcon_Email',
  LinkDownloadPDF = 'Link_DownloadPDF',
  LinkAllServices = 'Link_AlleLeistungen',
  LinkToEditContribution = 'Link_AngabenAndernBeitrag',
  LinkToEditBeginningDate = 'Link_AngabenAndernStartDate',
  LinkToEditInsuredOwner = 'Link_AngabenAndernVN',
  LinkToEditInsuredPerson1 = 'Link_AngabenAndernVP1',
  LinkToEditInsuredPerson2 = 'Link_AngabenAndernVP2',
  LinkToEditInsuredPerson3 = 'Link_AngabenAndernVP3',
  LinkToEditInsuredPerson4 = 'Link_AngabenAndernVP4',
  AccordionContribution = 'Acc_TarifBeitrag',
  AccordionBeginningDate = 'Acc_AngabenAndernStartDate',
  AccordionInsuredOwner = 'Acc_PersonendatenVN',
  AccordionInsuredPerson1 = 'Acc_PersonendatenVP1',
  AccordionInsuredPerson2 = 'Acc_PersonendatenVP2',
  AccordionInsuredPerson3 = 'Acc_PersonendatenVP3',
  AccordionInsuredPerson4 = 'Acc_PersonendatenVP4',
  EventIBANValidationStart = 'IbanVerificationStart',
  EventIBANValidationSuccess = 'IbanVerificationSuccess',
  EventAddressValidationSuccess = 'AdressVerificationSuccess', // This is not a typo, in german it's "Adresse"
  EventAddressValidationError = 'AdressVerificationFailed', // This is not a typo, in german it's "Adresse"
  EventAddressValidationCorrected = 'AdressVerificationDataCorrected', // This is not a typo, in german it's "Adresse"
}

export const enum ProductNames {
  IZS = 'izs',
}

export const enum ProductCombinations {
  DVB = 'DVB',
  DVB_DS75 = 'DVB_DS75',
  DVB_DS90 = 'DVB_DS90',
  DVB_DS100 = 'DVB_DS100',
  DVE = 'DVE',
  DVE_DS75 = 'DVE_DS75',
  DVE_DS90 = 'DVE_DS90',
  DVE_DS100 = 'DVE_DS100',
  DS75 = 'DS75',
  DS90 = 'DS90',
  DS100 = 'DS100',
  ZEZ = 'ZEZ',
  ZEF = 'ZEF',
  ZEF_DVB = 'ZEF_DVB',
  ZZP = 'ZZP',
  ZZP_DVB = 'ZZP_DVB',
}

export const enum TariffOptions {
  ZAHN = 'Rechner=zahn2022',
  MYSELF = 'Mich',
  MYSELF_AND_OTHERS = 'MichWeitere',
  ONLY_OTHERS = 'NurAndere',
  YES = 'J',
  NO = 'N',
}

export const enum ProductCategories {
  PAGE = 'zahn2022',
  PRODUCT = 'ZahnZusatz',
}

export const enum Gender {
  Mr = 'Herr',
  Ms = 'Frau',
}

export const enum ClickTypes {
  OTHER = 'other',
}

export const enum ErrorTypes {
  GENERIC = 'GenericError',
  FORMFIELD = 'FormFieldError',
}

export interface ErrorProperties {
  message: string;
  code?: string | number;
  url?: string;
}

export interface GeneralClickProps {
  type: GeneralClickTypes;
  value: any;
}

export type GeneralClickTypes =
  | 'INSURANCE_TYPE'
  | 'ProductIds'
  | 'BirthdateData'
  | 'BeginningDate';
