import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'insurance-situation.title',
    defaultMessage: 'Wer soll versichert werden?',
  },
  subtitle: {
    id: 'insurance-situation.subtitle',
    defaultMessage:
      'Bei ERGO bekommen Sie online den passenden Versicherungsschutz für ein strahlendes Lächeln.',
  },
  option1: {
    id: 'insurance-situation.option1',
    defaultMessage: 'Ich',
  },
  option2: {
    id: 'insurance-situation.option2',
    defaultMessage: 'Ich und jemand anders',
  },
  option3: {
    id: 'insurance-situation.option3',
    defaultMessage: 'Nur jemand anders',
  },
  option3ZEZ: {
    id: 'insurance-situation.option3ZEZ',
    defaultMessage: 'Jemand anders',
  },
  disclaimerLabel: {
    id: 'insurance-situation.disclaimer.label',
    defaultMessage: 'Hinweis',
  },
  disclaimerTitle: {
    id: 'insurance-situation.disclaimer.title',
    defaultMessage:
      'Sie möchten jemand anderen absichern, z. B. Ihre/n Partner/in?',
  },
  disclaimerParagraph1: {
    id: 'insurance-situation.disclaimer.paragraph1',
    defaultMessage:
      'Dies ist nur möglich, wenn es sich dabei um (nahe) Angehörige handelt, da ansonsten Versicherungssteuerpflicht eintreten kann. Wer zu den <link>(nahen) Angehörigen zählt</link>, ist gesetzlich geregelt, und zwar in § 7 des Pflegezeitgesetzes und in § 15 der Abgabenordnung.',
  },
  disclaimerParagraph2: {
    id: 'insurance-situation.disclaimer.paragraph2',
    defaultMessage: 'Bei Fragen, helfen wir Ihnen gerne telefonisch weiter.',
  },
  disclaimerCheckbox: {
    id: 'insurance-situation.disclaimer.checkbox',
    defaultMessage:
      'Ich bestätige, dass es sich bei den anderen Personen um (nahe) Angehörige im Sinne des Pflegezeitgesetzes bzw. der Abgabenordnung handelt.',
  },
  modalTitle: {
    id: 'insurance-situation.modal.title',
    defaultMessage:
      '(Nahe) Angehörige und damit versicherbarer Personenkreis sind:',
  },
  modalPoint1: {
    id: 'insurance-situation.modal.point1',
    defaultMessage: 'Großeltern, Eltern, Schwiegereltern, Stiefeltern,',
  },
  modalPoint2: {
    id: 'insurance-situation.modal.point2',
    defaultMessage:
      'Ehegatten, Lebenspartner, Partner einer eheähnlichen oder lebenspartnerschaftsähnlichen Gemeinschaft, Geschwister, Ehegatten der Geschwister und Geschwister der Ehegatten, Lebenspartner der Geschwister und Geschwister der Lebenspartner,',
  },
  modalPoint3: {
    id: 'insurance-situation.modal.point3',
    defaultMessage:
      'Kinder, Adoptiv- oder Pflegekinder, die Kinder, Adoptiv- oder Pflegekinder des Ehegatten oder Lebenspartners, Schwiegerkinder und Enkelkinder,',
  },
  modalPoint4: {
    id: 'insurance-situation.modal.point4',
    defaultMessage: 'Verlobte,',
  },
  modalPoint5: {
    id: 'insurance-situation.modal.point5',
    defaultMessage: 'Verwandte und Verschwägerte gerader Linie,',
  },
  modalPoint6: {
    id: 'insurance-situation.modal.point6',
    defaultMessage: 'Kinder der Geschwister,',
  },
  modalPoint7: {
    id: 'insurance-situation.modal.point7',
    defaultMessage: 'Geschwister der Eltern,',
  },
  modalPoint8: {
    id: 'insurance-situation.modal.point8',
    defaultMessage:
      'Personen, die durch ein auf längere Dauer angelegtes Pflegeverhältnis mit häuslicher Gemeinschaft wie Eltern und Kind miteinander verbunden sind (Pflegeeltern und Pflegekinder).',
  },
  modalParagraph1: {
    id: 'insurance-situation.modal.paragraph1',
    defaultMessage:
      'Die Auflösung einer Verlobung bzw. die Beendigung einer eheähnlichen oder lebenspartnerschaftsähnlichen Gemeinschaft nach Vertragsschluss kann dazu führen, dass Versicherungssteuerpflicht eintritt. Dies gilt ebenso bei Beendigung der häuslichen Gemeinschaft mit Pflegekindern, wenn mit diesen keine innere Verbundenheit/Beziehung mehr besteht.',
  },
  modalParagraph2: {
    id: 'insurance-situation.modal.paragraph2',
    defaultMessage:
      'Sie müssen uns in diesen Fällen umgehend informieren. Denn Versicherungen von Personen, die keine (nahen) Angehörigen sind, sind in der Regel versicherungssteuerpflichtig. Das bedeutet für Sie: Sie könnten dann zusätzlich zum vertraglich geschuldeten Beitrag zur Zahlung der Versicherungssteuer verpflichtet sein.',
  },
});
