import { ConsentContact, ContactElement, Address } from 'ppz-otr-common';

import {
  ConsentContactAction,
  CONSENT_TYPES,
  ADDRESS_ACTION_TYPE,
  ADDRESS_ACTIONS,
  ERROR_ACTION_TYPE,
  ERROR_ACTIONS,
  EMPTY_ERROR,
} from '../types';

/**
 * ContactElement useReducer
 */
const initialContactElement: ContactElement = {
  valid: false,
  value: '',
};

export const initialConsentContact: ConsentContact = {
  email: initialContactElement,
  phone: initialContactElement,
};

export const consentContactReducer = (
  state: ConsentContact,
  action: ConsentContactAction,
): ConsentContact => {
  switch (action.type) {
    case CONSENT_TYPES.UPDATE:
      return action.payload;
    default:
      return { ...state };
  }
};

/**
 * address useReducer
 */
export const addressReducer = (
  state: Address,
  action: ADDRESS_ACTION_TYPE,
): Address => {
  switch (action.type) {
    case ADDRESS_ACTIONS.UPDATE_STREET:
      return { ...state, street: action.payload };
    case ADDRESS_ACTIONS.UPDATE_STREET_NUMBER:
      return { ...state, streetNumber: action.payload };
    case ADDRESS_ACTIONS.UPDATE_POSTAL_CODE:
      return { ...state, postalCode: action.payload };
    case ADDRESS_ACTIONS.UPDATE_CITY:
      return { ...state, city: action.payload };
    default:
      return { ...state };
  }
};

/**
 * error useReducer
 */
export const initialError = {
  emptyStreet: {
    value: false,
    forced: false,
  },
  invalidStreet: '',
  emptyStreetNumber: {
    value: false,
    forced: false,
  },
  emptyPostalCode: {
    value: false,
    forced: false,
  },
  invalidPostalCode: false,
  agentRequired: false,
};

export const errorReducer = (
  state: typeof initialError,
  action: ERROR_ACTION_TYPE,
): typeof initialError => {
  switch (action.type) {
    case ERROR_ACTIONS.EMPTY_STREET:
      return { ...state, emptyStreet: action.payload as EMPTY_ERROR };
    case ERROR_ACTIONS.INVALID_STREET:
      return { ...state, invalidStreet: action.payload as string };
    case ERROR_ACTIONS.EMPTY_STREET_NUMBER:
      return { ...state, emptyStreetNumber: action.payload as EMPTY_ERROR };
    case ERROR_ACTIONS.EMPTY_POSTAL_CODE:
      return { ...state, emptyPostalCode: action.payload as EMPTY_ERROR };
    case ERROR_ACTIONS.INVALID_POSTAL_CODE:
      return { ...state, invalidPostalCode: action.payload as boolean };
    case ERROR_ACTIONS.AGENT_REQUIRED:
      return { ...state, agentRequired: action.payload as boolean };
    default:
      return { ...state };
  }
};
