/**
 * @important the order is important in backend/src/utils/tariffs.ts
 */
export enum ProductIds {
  ZAHN_DS75 = 'ZAHN_DS75',
  ZAHN_DS90 = 'ZAHN_DS90',
  ZAHN_DS100 = 'ZAHN_DS100',
  ZAHN_DVB = 'ZAHN_DVB',
  ZAHN_DVE = 'ZAHN_DVE',
}

/**
 * @important the order is important in backend/src/utils/tariffs.ts
 */
export enum OldProductIds {
  ZAHN_ZEZ = 'ZAHN_ZEZ',
  ZAHN_ZZP = 'ZAHN_ZZP',
  ZAHN_ZEF = 'ZAHN_ZEF',
}

export type Tariffs = ProductIds | OldProductIds;

export enum ProductsType {
  basic = 'basic',
  premium = 'premium',
}

export interface TariffPrices {
  initial: number;
  total: number;
  perPerson: TariffPricesDetail[];
  perContribution: TariffPricesDetail[];
}

export interface TariffPricesDetail {
  personId?: string;
  contributionSelected?: Tariffs;
  initial: number;
  final: number;
}

export interface ContributionDetails {
  basicPrices: TariffPrices;
  premiumPrices: TariffPrices;
  basicProducts: Tariffs[];
  premiumProducts: Tariffs[];
}

export interface InitProducts {
  basic: Tariffs[];
  premium: Tariffs[];
}
