import {
  BirthdateData,
  CrudBirthdatesRequest,
  CrudBirthdatesResponse,
} from 'ppz-otr-common';

import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

import { postHttp } from '.';

export const BIRTHDATES_API = {
  CRUD_BIRTHDATES: `${ENDPOINTS.birthdates}/:businessId`,
  VALIDATE_BIRTHDATE: `${ENDPOINTS.birthdates}/:businessId/validate`,
};

export const crudBirthdates = async (
  businessId: string,
  dateValues: CrudBirthdatesRequest,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<CrudBirthdatesRequest, CrudBirthdatesResponse>(
    {
      url: BIRTHDATES_API.CRUD_BIRTHDATES,
      path: {
        businessId,
      },
      errorThrown,
    },
    dateValues,
  );
};

export const validateBirthdate = async (
  businessId: string,
  dateValues: BirthdateData[],
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<BirthdateData[], BirthdateData[]>(
    {
      url: BIRTHDATES_API.VALIDATE_BIRTHDATE,
      path: {
        businessId,
      },
      errorThrown,
    },
    dateValues,
  );
};
