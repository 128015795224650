export const AddressErrorCodes = {
  knownParisStreetNameErrors: [
    '6050003',
    '6050024',
    '6050027',
    '6050028',
    '6050030',
  ],
  knownParisNameErrors: ['6020254'],
  knownParisCityErrors: ['6050004', '6050008'],
  knownPdbStreetNameErrors: ['VAUD0739'],
};
