import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';

export const ContributionContainer = styled.div`
  & .ee_radiotabs__panellist {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.16),
      0 6px 10px 0 rgba(0, 0, 0, 0.12), 0 1px 18px 0 rgba(0, 0, 0, 0.1);
  }

  & .ee_radiotabs__tablist {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & .ee_radiotabs__tab:first-child {
    border-radius: ${sizes.space.PX_04} 0 0 0 !important;
  }

  & .ee_radiotabs__tab:last-child {
    border-radius: 0 ${sizes.space.PX_04} 0 0 !important;
  }

  @media (min-width: ${breakpoints.S}) {
    & .ee_radiotabs__tablist {
      margin-left: unset !important;
      margin-right: unset !important;
    }

    & .ee_radiotabs__tab:first-child {
      border-radius: ${sizes.space.PX_04} 0 0 ${sizes.space.PX_04} !important;
    }

    & .ee_radiotabs__tab:last-child {
      border-radius: 0 ${sizes.space.PX_04} ${sizes.space.PX_04} 0 !important;
    }
  }
`;
