import styled, { css } from 'styled-components';

import { breakpoints, colors, sizes } from '../../../styles/tokens';

interface CustomCoverageListProps {
  bordered: boolean;
  marginX: boolean;
}

export const CustomCoverageList = styled.div<CustomCoverageListProps>`
  text-align: left;

  ${({ bordered }) =>
    bordered &&
    css`
      & > :not(:last-child) {
        border-bottom: ${sizes.space.PX_01} solid ${colors.LIGHTEST_GRAY};
      }
    `}

  ${({ marginX }) =>
    marginX &&
    css`
      @media (min-width: ${breakpoints.M}) {
        margin: 0 ${sizes.space.PX_64};
      }
    `}
`;
