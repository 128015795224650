import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import FormFooter from '@eg/elements/FormFooter';

import { OFFER_ROLES, INSURANCE_TYPE } from 'ppz-otr-common';
import styled from 'styled-components';

import { useDates } from '../../context/DatesCtx';
import { useInsurance } from '../../context/InsuranceCtx';
import { useIzsIntegration } from '../../context/IzsIntegrationCtx';
import { useLegal } from '../../context/LegalCtx';
import { usePerson } from '../../context/PersonCtx';
import { useTariff } from '../../context/TariffCtx';
import commonLiterals from '../../language/src/common';
import literals from '../../language/src/personal-data';
import Container from '../../layouts/Container';
import { sizes } from '../../styles/tokens';
import { VIEWS } from '../../types';
import { formatDateBackend } from '../../views/BirthDates/utils';
import SmallPrint from '../Common/SmallPrint';

const ContainerFooter = styled(Container)`
  margin-top: ${sizes.space.PX_64};
`;

const Footer = () => {
  const location = useLocation();
  const { situation } = useInsurance();
  const { spcsPersons } = usePerson();
  const { tariffPriceSelected } = useTariff();
  const { showConsent } = useLegal();
  const { validationDates } = useDates();
  const { isIzs } = useIzsIntegration();
  const intl = useIntl();

  const getShowPriceAsterisk = () => {
    const insuranceOwner = Array.from(spcsPersons.values()).find(
      ({ role }) => role === OFFER_ROLES.insuranceOwner,
    );
    const hasInsuranceOwner = !!insuranceOwner;

    const { adult } = validationDates;
    // not spreading from insuranceOwner as it can be undefined
    const insuranceOwnerDate = hasInsuranceOwner
      ? formatDateBackend(
          insuranceOwner.day,
          insuranceOwner.month,
          insuranceOwner.year,
        )
      : '';

    // time in milliseconds from 1970 should be lower or equal to the maximum adult date
    const isInsuranceOwnerAdult =
      hasInsuranceOwner &&
      new Date(insuranceOwnerDate).getTime() <= new Date(adult).getTime();

    const isOnlyOthers = situation === INSURANCE_TYPE.others;
    const isValidSituation =
      !isOnlyOthers || (isOnlyOthers && spcsPersons.size > 1);

    const { total } = tariffPriceSelected;
    const hasPrice = total !== 0;

    const hasInitialData = !!situation && hasInsuranceOwner && hasPrice;

    const gotTariffWithIzs = isIzs && isInsuranceOwnerAdult;

    return hasInitialData && isValidSituation && (!isIzs || gotTariffWithIzs);
  };

  const showPriceAsterisk = getShowPriceAsterisk();

  const showAsteriskConsent =
    location.pathname?.includes(VIEWS.personalData) && showConsent;

  const asteriskTariff = intl.formatMessage(
    commonLiterals.smallPrintPriceAsterisk,
  );

  const asteriskConsent = intl.formatMessage(literals.consentSmallPrint);

  return (
    <footer>
      <ContainerFooter>
        {showPriceAsterisk && (
          <SmallPrint noMarginTop message={asteriskTariff} />
        )}
        {showAsteriskConsent && (
          <SmallPrint message={asteriskConsent} bullet="**" />
        )}
        <FormFooter />
      </ContainerFooter>
    </footer>
  );
};

export default Footer;
