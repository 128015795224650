import { useLocation } from 'react-router-dom';

import {
  useGetAEMCancelationDetails,
  useGetAEMTrustElementContent,
  useGetAEMEkomiDetails,
  useResize,
} from '../../hooks';
import { getNumberByPixels } from '../../styles/helpers';
import { breakpoints } from '../../styles/tokens';
import {
  TrustBannerVisibility,
  TrustBannerVisibilityOptions,
  VIEWS,
} from '../../types';
import {
  getCheckboxValue,
  getKey,
  getTextInputValue,
  getTrustElementDisplayed,
} from '../../utils';

import { EkomiElement, TrustElement } from './components';
import { TrustBannerItem, TrustBannerWrapper } from './layouts';
import {
  MAX_TRUST_ITEMS,
  TrustBannerItemStructure as Structure,
} from './types';

const getTrustElementVisibility = (): TrustBannerVisibility => {
  const _key = getKey();
  if (!_key) return {};
  const visibility = getTextInputValue('trustBannerVisibility');

  switch (visibility) {
    case TrustBannerVisibilityOptions.SHOW_EVERYWHERE:
      return { showEverywhere: true };
    case TrustBannerVisibilityOptions.SHOW_DEFINED_PAGES:
      return {
        insuranceSituation: getCheckboxValue('showTrustInsuranceSituation'),
        birthdates: getCheckboxValue('showTrustBirthdates'),
        insuranceBeginning: getCheckboxValue('showTrustInsuranceBeginning'),
        contribution: getCheckboxValue('showTrustContribution'),
        personalData: getCheckboxValue('showTrustPersonalData'),
        summary: getCheckboxValue('showTrustSummary'),
        bankDetails: getCheckboxValue('showTrustBankDetails'),
        finalize: getCheckboxValue('showTrustFinalize'),
      };
    case TrustBannerVisibilityOptions.DONT_SHOW:
      return {
        hideEverywhere: true,
      };
    default:
      return { showEverywhere: true };
  }
};

const currentElementVisibility = (location: string): boolean => {
  const visibility = getTrustElementVisibility();
  if (visibility?.showEverywhere) return true;

  if (visibility?.hideEverywhere) return false;

  const visiblePerView = {
    [VIEWS.initialSelection]: visibility.insuranceSituation,
    [VIEWS.myself]: visibility.birthdates,
    [VIEWS.myselfAndMore]: visibility.birthdates,
    [VIEWS.others]: visibility.birthdates,
    [VIEWS.insuranceBeginning]: visibility.insuranceBeginning,
    [VIEWS.contribution]: visibility.contribution,
    [VIEWS.personalData]: visibility.personalData,
    [VIEWS.summary]: visibility.summary,
    [VIEWS.bankDetails]: visibility.bankDetails,
    [VIEWS.finalize]: visibility.finalize,
  };
  const currentLocation = Object.values(VIEWS).find((view) => {
    return location.includes(view);
  });
  if (!currentLocation) return false;

  return visiblePerView[currentLocation];
};

const TrustBanner = () => {
  const location = useLocation();
  const [width] = useResize();

  const showTrustBanner = currentElementVisibility(location.pathname);

  const { showEkomi: ekomi = false, ekomiText: text } = useGetAEMEkomiDetails();
  const { showCancelationElement: cancelation } = useGetAEMCancelationDetails();
  const { showTrust: trust } = useGetAEMTrustElementContent();

  const numberOfItems = getTrustElementDisplayed(ekomi, trust, cancelation);

  if (!showTrustBanner || !numberOfItems) return null;

  const breakpointL = getNumberByPixels(breakpoints.L);
  const isLarge = breakpointL <= width;
  const isHorizontal = isLarge && numberOfItems < MAX_TRUST_ITEMS;

  const structure: Structure = isHorizontal ? 'horizontal' : 'vertical';

  return (
    <TrustBannerWrapper className="trust-banner__container">
      <TrustBannerItem show={trust}>
        <TrustElement structure={structure} />
      </TrustBannerItem>
      <TrustBannerItem show={ekomi}>
        <EkomiElement structure={structure} text={text} />
      </TrustBannerItem>
      <TrustBannerItem show={cancelation}>
        <TrustElement structure={structure} isCancelation />
      </TrustBannerItem>
    </TrustBannerWrapper>
  );
};
export default TrustBanner;
