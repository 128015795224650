import React from 'react';

import TooltipIcon from '@eg/elements/TooltipIcon';

import { useTracking } from '../../../hooks';
import { ClickableElements } from '../../../types';

interface Props {
  tooltip: string | undefined;
}

const EmailTooltip = ({ tooltip }: Props) => {
  const { setTrackingClick } = useTracking();

  return (
    <TooltipIcon
      isModal
      onToggledOpen={(isOpen) =>
        isOpen && setTrackingClick(ClickableElements.TooltipEmail)
      }
    >
      {tooltip}
    </TooltipIcon>
  );
};

export default EmailTooltip;
