import { useEffect, useState } from 'react';

import { useLoading } from '../../context/LoadingCtx';
import { useTariff } from '../../context/TariffCtx';
import { AemContent } from '../../types';
import { getProductComparisonContentPath } from '../../utils';

const useGetProductsTable = () => {
  const { isAppInitialized } = useLoading();
  const { products } = useTariff();
  const [currentTablePath, setCurrentTablePath] = useState<string>('');

  useEffect(() => {
    if (isAppInitialized) {
      const _tablePath = getProductComparisonContentPath(
        products,
        AemContent.TABLE,
      );
      setCurrentTablePath(_tablePath);
    }
  }, [isAppInitialized, products]);
  return {
    currentTablePath,
  };
};
export default useGetProductsTable;
