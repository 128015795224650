import { useLocation } from 'react-router-dom';

import { VIEWS } from '../types';

export const useSteps = (): {
  numberOfSteps: number;
  currentStep: number;
  stepName: string;
} => {
  const location = useLocation();
  const viewsArray = Object.values(VIEWS);
  const checkCurrentStep =
    viewsArray.findIndex((view) => location.pathname?.includes(view)) + 1 ?? 1;
  let currentStep = 0;
  if (checkCurrentStep > 1 && checkCurrentStep < 5) {
    currentStep = 2;
  } else if (checkCurrentStep >= 5) {
    currentStep = checkCurrentStep - 2;
  } else {
    currentStep = checkCurrentStep;
  }

  const stepName =
    Object.keys(VIEWS).find((key) => location.pathname?.includes(VIEWS[key])) ??
    '';

  const numberOfSteps = viewsArray.length - 4;

  return {
    stepName,
    currentStep,
    numberOfSteps,
  };
};
