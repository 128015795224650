import styled from 'styled-components';

import { breakpoints } from '../styles/tokens';

const Container = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 87%;

  @media (min-width: ${breakpoints.M}) {
    width: 93%;
  }

  @media (min-width: ${breakpoints.L}) {
    width: 95%;
    max-width: ${`${parseInt(breakpoints.L.replace('px', '')) - 2 * 24}px`};
  }
`;

export default Container;
