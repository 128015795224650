import React from 'react';

import styled from 'styled-components';

interface IProps {
  children?: React.ReactNode;
}

const GridWrapper = styled.div`
  overflow: hidden;
`;

const Grid = ({ children }: IProps) => {
  return (
    <GridWrapper>
      <div className="esc_grid">
        <div className="esc_grid__wrapper">{children}</div>
      </div>
    </GridWrapper>
  );
};

export default Grid;
