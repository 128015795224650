import React, { ReactNode, RefObject } from 'react';

import styled, { css } from 'styled-components';

import ABTestingIds from '../../../ABTesting/sidebar';
import { colors } from '../../../styles/tokens';
import { Header } from '../components';
import { Void } from '../types';

import { SidebarHeader, SidebarBody } from '.';

interface SidebarContentProps {
  shown: boolean;
  title: string;
  forwardRef: RefObject<HTMLButtonElement>;
  onClose: Void;
  children: ReactNode;
}

const transform = (shown: boolean) => {
  if (shown)
    return css`
      transform: translateX(0);
    `;
};

const ContentWrapper = styled.div<SidebarContentProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: ${colors.WHITE};
  transform: translateX(200px);
  transition: all 0.2s ease-in-out;
  ${({ shown }) => transform(shown)}

  @media (min-width: 432px) {
    & {
      width: 432px;
    }
  }
`;

const Content = ({ children, ...props }: SidebarContentProps) => {
  return (
    <ContentWrapper
      {...props}
      className="sidebar__content abTesting abTestingBottom"
      onClick={(event) => event.stopPropagation()}
      id={ABTestingIds.sidebarContainer}
    >
      <SidebarHeader
        className="sidebar__header abTesting"
        id={ABTestingIds.headerContainer}
      >
        <Header {...props} />
      </SidebarHeader>
      <SidebarBody
        className="sidebar__body abTesting abTestingBottom"
        id={ABTestingIds.sidebarBodyContainer}
      >
        {children}
      </SidebarBody>
    </ContentWrapper>
  );
};

export default Content;
