import TooltipIcon from '@eg/elements/TooltipIcon';

import styled, { css } from 'styled-components';

import { breakpoints, fonts, sizes } from '../../../styles/tokens';
import { TrustBannerItemStructure } from '../types';

export type Visibility = 'visible' | 'hidden';

interface ContainerProps {
  structure: TrustBannerItemStructure;
}

interface SectionProps extends ContainerProps {
  show: boolean;
}

interface ItemProps {
  visibility: Visibility;
}

interface ImageProps extends ContainerProps, ItemProps {}
interface IconProps extends ContainerProps {}

const centerImage = () => css`
  display: block;
  margin: auto;
`;

const adjustIconPosition = (structure: TrustBannerItemStructure) => {
  if (structure === 'horizontal') {
    return css`
      &&& button {
        margin-left: -${sizes.space.PX_08};
      }
    `;
  }
};

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${sizes.space.PX_20};
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.M}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.L}) {
    padding: 0 ${sizes.space.PX_24};
    flex-direction: ${({ structure }) =>
      structure === 'horizontal' ? 'row' : 'column'};
  }
`;

const Section = styled.div<SectionProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  text-align: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.L}) {
    text-align: ${({ structure }) =>
      structure === 'horizontal' ? 'left' : 'center'};
  }
`;

const Image = styled.img<ImageProps>`
  ${() => centerImage()}
  visibility: ${({ visibility }) => visibility};
  max-height: ${sizes.space.PX_112};
  width: ${({ visibility }) => (visibility === 'hidden' ? 0 : 'auto')};

  @media (min-width: ${breakpoints.M}) {
    height: ${sizes.space.PX_112};
  }
`;

const SubTitle = styled.p<ItemProps>`
  margin: ${sizes.space.PX_04};
  font-size: ${sizes.text.S};
  font-family: ${fonts.REGULARS};
  visibility: ${({ visibility }) => visibility};

  @media (min-width: ${breakpoints.L}) {
    font-size: ${sizes.text.SM};
  }
`;

const Title = styled.h3<ItemProps>`
  margin: ${sizes.space.PX_04};
  font-family: ${fonts.REGULARS};
  font-weight: bold;
  font-size: ${sizes.text.L};
  visibility: ${({ visibility }) => visibility};
`;

const Icon = styled(TooltipIcon)<IconProps>`
  margin-top: -5px;

  @media (min-width: ${breakpoints.L}) {
    ${({ structure }) => adjustIconPosition(structure)}
  }
`;

export { Container, Section, Image, SubTitle, Title, Icon };
