export const enum pageNames {
  situation = 'BasisdatenVpAuswahl',
  birthdatesMySelf = 'BasisdatenGebDatumVN',
  birthdatesMySelfAndOthers = 'BasisdatenGebDatumWeitereVP',
  birthdatesOnlyOthers = 'BasisdatenGebDatumNurAndereVP',
  beginning = 'BasisdatenVersBeginn',
  contribution = 'Berechnungsergebnis',
  personalData = 'Personendaten',
  summary = 'PruefenUndSenden',
  bank = 'Bankdaten',
  finalize = 'PruefenUndSendenIDD',
  onlineFeedback = 'Abschluss Feedback',
  offerFeedback = 'Angebot Feedback',
}

export const aemInputHasValue = (str: string | undefined | null) => {
  return str !== null && str?.trim() !== '' && str !== undefined;
};
