import styled, { css } from 'styled-components';

import { colors } from '../../../styles/tokens';

interface Props {
  top: number;
}

const getTopSpace = (top: number) => {
  return css`
    top: ${top}px;
  `;
};

const HeaderContainer = styled.header<Props>`
  position: sticky;
  background-color: ${colors.LIGHTER_CYAN};
  z-index: 1;
  ${({ top }) => getTopSpace(top)};
`;

export default HeaderContainer;
