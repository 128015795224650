import axios, { AxiosResponse } from 'axios';
import { Environments } from 'ppz-otr-common';

import {
  DEFAULT_TIMEOUT,
  DEVELOPMENT_TIMEOUT,
  ENV,
  URL_BASE,
} from '../config/environment';

import { HttpOptions } from './types';

const isDevelopment =
  ENV && [Environments.local, Environments.dvit].includes(ENV);

export const httpInstance = axios.create({
  baseURL: URL_BASE,
  timeout: isDevelopment ? DEVELOPMENT_TIMEOUT : DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '-1',
  },
});

const getUrlWithParams = (
  url: string,
  params: Record<string, string | number | boolean> | undefined,
) => {
  if (!params) return url;

  Object.keys(params).forEach((key) => {
    url = url.replace(`:${key}`, String(params[key]));
  });
  return url;
};

export const getHttp = async <R>({
  url,
  path,
  query,
  errorThrown,
  timeout,
  responseType = 'json',
}: HttpOptions<R>): Promise<AxiosResponse<R>> => {
  let response: AxiosResponse<R> | null = null;
  try {
    response = await httpInstance.get(getUrlWithParams(url, path), {
      params: query,
      timeout,
      responseType,
    });
  } catch (e: any) {
    errorThrown(e);
  }

  return response as AxiosResponse<R>;
};

export const postHttp = async <Req, Res>(
  {
    url,
    path,
    query,
    errorThrown,
    timeout,
    responseType = 'json',
  }: HttpOptions<Res>,
  body: Req,
): Promise<AxiosResponse<Res>> => {
  let response: AxiosResponse<Res> | null = null;
  try {
    response = await httpInstance.post(getUrlWithParams(url, path), body, {
      params: query,
      timeout,
      responseType,
    });
  } catch (e: any) {
    errorThrown(e);
  }

  return response as AxiosResponse<Res>;
};

export const putHttp = async <Req, Res>(
  {
    url,
    path,
    query,
    errorThrown,
    timeout,
    responseType = 'json',
  }: HttpOptions<Res>,
  body: Req,
): Promise<AxiosResponse<Res>> => {
  let response: AxiosResponse<Res> | null = null;
  try {
    response = await httpInstance.put(getUrlWithParams(url, path), body, {
      params: query,
      timeout,
      responseType,
    });
  } catch (e: any) {
    errorThrown(e);
  }

  return response as AxiosResponse<Res>;
};

export const deleteHttp = async <Req, Res>(
  {
    url,
    path,
    query,
    errorThrown,
    timeout,
    responseType = 'json',
  }: HttpOptions<Res>,
  body: Req,
): Promise<AxiosResponse<Res>> => {
  let response: AxiosResponse<Res> | null = null;
  try {
    response = await httpInstance.delete(getUrlWithParams(url, path), {
      params: query,
      data: body,
      timeout,
      responseType,
    });
  } catch (e: any) {
    errorThrown(e);
  }

  return response as AxiosResponse<Res>;
};
