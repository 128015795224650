import React from 'react';

import ProgressBarContainer from './layouts/ProgressBarContainer';

interface ProgressBarProps {
  max?: number;
  value: number;
  dataTestId?: string;
}
export const ProgressBar = ({
  max = 100,
  value = 0,
  dataTestId = 'ee-progressbar',
}: ProgressBarProps) => {
  const absValue = Math.abs(value);
  const normalizedValue = absValue > max ? max : absValue;
  return (
    <ProgressBarContainer data-testid={`${dataTestId}`}>
      <progress max={max} value={normalizedValue}></progress>
    </ProgressBarContainer>
  );
};
