import styled from 'styled-components';

import { colors, sizes } from '../../../styles/tokens';

export const EmailSectionContainer = styled.div`
  padding-top: ${sizes.space.PX_48};
  border-top: ${sizes.space.PX_01} solid ${colors.LIGHT_GRAY};
`;

export const ContactSection = styled.section`
  p {
    line-height: ${sizes.space.PX_24};
  }
`;

export const ContactTitle = styled.h2`
  margin-bottom: ${sizes.space.PX_28};
`;

export const ContactDescription = styled.p`
  margin-bottom: ${sizes.space.PX_24};
`;
