import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Details from '@eg/elements/Details';
import EditIcon from '@eg/elements/Icons/EditIcon';

import ABTestingIds from '../../../ABTesting/summary';
import { DetailTitle } from '../../../components/SummaryGrid/layout';
import SummaryGrid from '../../../components/SummaryGrid/SummaryGrid';
import SummaryGridItem from '../../../components/SummaryGrid/SummaryGridItem';
import { useDates } from '../../../context/DatesCtx';
import { useTracking } from '../../../hooks';
import literals from '../../../language/src/summary';
import { ClickableElements, VIEWS } from '../../../types';
import { ButtonLink } from '../layouts';

const DetailsBeginningDate = () => {
  const navigate = useNavigate();

  const { beginningDateSelected } = useDates();
  const { setTrackingClick } = useTracking();

  return (
    <div className="abTesting" id={ABTestingIds.insuranceBeginningDetails}>
      <Details
        summary={
          <DetailTitle>
            <FormattedMessage {...literals.detailsBeginningDateTitle} />
          </DetailTitle>
        }
        onToggledOpen={(isOpen) => {
          isOpen && setTrackingClick(ClickableElements.AccordionBeginningDate);
        }}
      >
        <SummaryGrid id={ABTestingIds.insuranceBeginningDetailsTop}>
          <SummaryGridItem type="left">
            <FormattedMessage {...literals.detailsBeginningDateDes} />
          </SummaryGridItem>
          <SummaryGridItem type="right">
            {beginningDateSelected}
          </SummaryGridItem>
        </SummaryGrid>

        <SummaryGrid id={ABTestingIds.insuranceBeginningDetailsButton}>
          <SummaryGridItem type="offsetRight">
            <ButtonLink
              variant="text-link"
              size={'large'}
              iconLeft={EditIcon}
              onClick={() => {
                setTrackingClick(ClickableElements.LinkToEditBeginningDate);
                navigate(VIEWS.insuranceBeginning);
              }}
            >
              <FormattedMessage {...literals.editButton} />
            </ButtonLink>
          </SummaryGridItem>
        </SummaryGrid>
      </Details>
    </div>
  );
};

export default DetailsBeginningDate;
