import { ReactNode } from 'react';

import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import TooltipIcon from '@eg/elements/TooltipIcon';

import { CustomUSPItem } from '../layouts';

interface Props {
  tooltip: string | null;
  children: ReactNode;
  condensed?: boolean;
  onClick?: () => void;
}

export const CoverageItem = ({
  tooltip,
  children,
  condensed = false,
  onClick,
}: Props) => {
  const gotTooltip = tooltip !== null;

  const onClickTooltip = (isOpen: boolean) => {
    isOpen && onClick && onClick();
  };

  return (
    <CustomUSPItem className="contribution__usp-item" condensed={condensed}>
      <div
        className="contribution__usp-check-icon"
        data-testid="contribution__usp-check-icon"
      >
        <CheckColorIcon />
      </div>
      <div className="contribution__usp-text">{children}</div>
      {gotTooltip && (
        <div
          className="contribution__usp-tooltip"
          data-testid="contribution__usp-tooltip"
        >
          <TooltipIcon onToggledOpen={onClickTooltip} isModal>
            {tooltip}
          </TooltipIcon>
        </div>
      )}
    </CustomUSPItem>
  );
};
