import { useLocation } from 'react-router-dom';

import { agentNumberQueryKey, SiteCoreQuery } from './useGetAgencyValues';

export interface QueryParams {
  izsBusinessId: string | null;
  prevention: string | null;
  protection: string | null;
  agentNumber: string | null;
  preallocation: boolean;
}

type IzsQuery = 'businessId' | 'tariff1' | 'tariff2' | SiteCoreQuery | 'intcid';
type PossibleQueries = IzsQuery;

const preallocationKey = 'intcid';

const checkQueries = (search: string): QueryParams => {
  let result: QueryParams = {
    agentNumber: null,
    izsBusinessId: null,
    prevention: null,
    protection: null,
    preallocation: false,
  };

  if (!search) return result;

  const params = search.substring(1).split('&');

  if (!params.length) return result;

  const queryMapper = {
    businessId: 'izsBusinessId',
    tariff1: 'prevention',
    tariff2: 'protection',
    [agentNumberQueryKey]: 'agentNumber',
    [preallocationKey]: 'preallocation',
  };

  params.forEach((queryPair) => {
    const [key, value] = queryPair.split('=') as [PossibleQueries, string];
    if (Object.keys(queryMapper).includes(key)) {
      const isPreallocation = key === preallocationKey;
      result = {
        ...result,
        [queryMapper[key]]: isPreallocation ? !!value : value,
      };
    }
  });

  return result;
};

const getQueryWithValue = <T,>(valueA: T, valueB: T): T => {
  return !!valueA ? valueA : !!valueB ? valueB : (null as unknown as T);
};

const useQueryParams = () => {
  const location = useLocation();

  const { izsBusinessId, prevention, protection, agentNumber, preallocation } =
    checkQueries(location.search);
  const {
    izsBusinessId: izsBusinessIdWindow,
    prevention: preventionWindow,
    protection: protectionWindow,
    agentNumber: agentNumberWindow,
    preallocation: preallocationWindow,
  } = checkQueries(window.location.search);

  return {
    izsBusinessId: getQueryWithValue(izsBusinessId, izsBusinessIdWindow),
    prevention: getQueryWithValue(prevention, preventionWindow),
    protection: getQueryWithValue(protection, protectionWindow),
    agentNumber: getQueryWithValue(agentNumber, agentNumberWindow),
    preallocation: getQueryWithValue<boolean>(
      preallocation,
      preallocationWindow,
    ),
  };
};

export default useQueryParams;
