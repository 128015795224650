import { useIntl } from 'react-intl';

import Card from '@eg/elements/Card';

import styled from 'styled-components';

import commonLiterals from '../../language/src/common';
import { sizes, colors } from '../../styles/tokens';

interface IProps {
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  margin-top: ${sizes.space.PX_28};
`;

const DisclaimerCard = ({ children }: IProps) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Card
        label={intl.formatMessage(commonLiterals.disclaimerLabelCardDefault)}
        alignLabel="left"
        accentColor={colors.ORANGE}
      >
        {children}
      </Card>
    </Wrapper>
  );
};

export default DisclaimerCard;
