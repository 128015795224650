import Button from '@eg/elements/Button';

import styled from 'styled-components';

import { breakpoints } from '../../../styles/tokens';

const LinkButton = styled(Button)`
  &&& {
    .ee_button__wrapper {
      padding: 0;
    }

    @media (max-width: ${breakpoints.M}) {
      margin: auto;
    }
  }
`;

export default LinkButton;
