const enum FinalizeABTestingIds {
  title = 'ppz801',
  card = 'ppz802',
  cardLegalTop = 'ppz803',
  cardLegalCheckbox = 'ppz804',
  contactSection = 'ppz805',
  contactSubline = 'ppz806',
  contactText = 'ppz807',
  contractSection = 'ppz808',
  contractSubline = 'ppz809',
  contractText = 'ppz810',
  buttonContainer = 'ppz811',
  backButton = 'ppz812',
}

export default FinalizeABTestingIds;
