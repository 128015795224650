import { Tariffs, ProductIds } from 'ppz-otr-common';

import { AemWindow, AemContent, AemAssets } from '../types';

import { getAemProductComparison } from './products';

import { isZEZ } from '.';
const mainKey = 'zahn-otr';
export const { params } = window as AemWindow;
export const getKey = (): string | undefined => {
  return params && Object.keys(params).find((k) => k.includes(mainKey));
};

export const getTextInputValue = (property: string): string => {
  const _key = getKey();
  if (!_key) return '';
  return params[_key][property];
};

export const getCheckboxValue = (property: string): boolean | undefined => {
  const _key = getKey();
  if (!_key) return undefined;

  const _params = params[_key];
  if (!_params[property]) return undefined;

  return _params[property] === 'true';
};

const loadExternalJs = (content: any): string => {
  const aemWindow = window as AemWindow;
  if (aemWindow.loadFetchedAemJs) {
    return aemWindow.loadFetchedAemJs(content);
  }
  return content;
};

const getHostnameWithProtocol = () =>
  `${window.location.protocol}//${window.location.hostname}`;

const getXfUrlByAemProperties = (pathToXf: string, assetType: AemAssets) => {
  if (pathToXf) {
    let fullPathToXF = '';
    switch (assetType) {
      case AemAssets.HTML:
        fullPathToXF = pathToXf.endsWith('.html')
          ? pathToXf
          : `${pathToXf}.html`;
        break;
      case AemAssets.PDF:
        fullPathToXF = pathToXf.endsWith('.pdf') ? pathToXf : `${pathToXf}.pdf`;
        break;
      case AemAssets.IMAGE:
        fullPathToXF = pathToXf;
        break;
      default:
        fullPathToXF = pathToXf;
    }

    if (!(window as AemWindow).isEditMode === undefined) {
      return getHostnameWithProtocol() + fullPathToXF;
    }

    const newPathToFile = fullPathToXF.replace(
      'content/experience-fragments',
      'xf',
    );

    return getHostnameWithProtocol() + newPathToFile;
  }
  return '';
};

export const getProductComparisonContentPath = (
  premium: Tariffs[],
  contentType: AemContent,
): string => {
  let _propertyName = '';
  const comparisonText = getAemProductComparison(premium);
  switch (contentType) {
    case AemContent.PDF:
      _propertyName = `${_propertyName}${comparisonText}_pdf`;
      break;
    case AemContent.TABLE:
      _propertyName = `${_propertyName}${comparisonText}_table`;
      break;
  }
  return getTextInputValue(_propertyName);
};

export const getTrustElementContentPath = (
  products: Tariffs[],
  contentType: AemContent,
): string => {
  let _propertyName = 'ZAHN_';
  if (products.length === 1 && products.includes(ProductIds.ZAHN_DVB)) {
    _propertyName = `${_propertyName}DVB_`;
  } else {
    _propertyName = products.includes(ProductIds.ZAHN_DVE)
      ? `${_propertyName}DVE_`
      : products.length > 1 && products.includes(ProductIds.ZAHN_DVB)
      ? `${_propertyName}DVB_`
      : _propertyName;
  }
  if (products.includes(ProductIds.ZAHN_DS100)) {
    _propertyName = `${_propertyName}DS100_`;
  } else if (products.includes(ProductIds.ZAHN_DS90)) {
    _propertyName = `${_propertyName}DS90_`;
  } else if (products.includes(ProductIds.ZAHN_DS75)) {
    _propertyName = `${_propertyName}DS75_`;
  }

  if (isZEZ(products)) {
    _propertyName = `${_propertyName}ZEZ_`;
  }
  switch (contentType) {
    case AemContent.FIRST_LINE:
      _propertyName = `${_propertyName}firstLine`;
      break;
    case AemContent.SECOND_LINE:
      _propertyName = `${_propertyName}secondLine`;
      break;
    case AemContent.TOOLTIP:
      _propertyName = `${_propertyName}tooltip`;
      break;
    case AemContent.IMAGE:
      _propertyName = `${_propertyName}image_path`;
  }
  return getTextInputValue(_propertyName);
};

export const getAssetURL = (
  contentPath: string,
  assetType: AemAssets,
): string => {
  return (
    (window as AemWindow)?.params &&
    getXfUrlByAemProperties(contentPath, assetType)
  );
};

export const setHTMLContent = (
  contentPath: string,
  setCurrentContentHTML: (str: string) => void,
) => {
  if (contentPath) {
    const productContentUrl = getAssetURL(contentPath, AemAssets.HTML);
    fetch(productContentUrl)
      .then((res) => res.text())
      .then((res) => {
        setCurrentContentHTML(loadExternalJs(res));
      })
      .catch((_error) => {
        console.warn(_error);
        setCurrentContentHTML('');
      });
  } else {
    setCurrentContentHTML('');
  }
};

export const setPDFContent = (
  contentPath: string,
  setCurrentPDFPath: (str: string) => void,
) => {
  if (contentPath) {
    const pdfURL = getAssetURL(contentPath, AemAssets.PDF);
    setCurrentPDFPath(pdfURL);
  } else {
    return '';
  }
};

export const isAEM = () =>
  document.querySelector(`[id^='${mainKey}']`) !== null;

export const getTrustElementDisplayed = (
  ekomi: boolean = false,
  trust: boolean = false,
  cancelation: boolean = false,
): number => {
  const visibility = [ekomi, trust, cancelation];
  const result = visibility.reduce((sum, item) => sum + Number(item), 0);

  return result;
};
