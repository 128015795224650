import { ChangeEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@eg/elements/Button';
import Checkbox from '@eg/elements/Checkbox';
import ControlWithHint from '@eg/elements/ControlWithHint';
import CheckIcon from '@eg/elements/Icons/CheckIcon';

import { ShippingWay } from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/finalize';
import { ActionButtons } from '../../components/Common';
import { useInsurance, useInsuranceUpdate } from '../../context/InsuranceCtx';
import { useLoadingUpdate } from '../../context/LoadingCtx';
import { useTariff } from '../../context/TariffCtx';
import { useTracking } from '../../hooks';
import commonLiterals from '../../language/src/common';
import literals from '../../language/src/finalize';
import { PageLayout, MainWrapper } from '../../layouts';
import { postWaiverAdviceCheck } from '../../services/finalize';
import { CHECKOUT_TYPE, ClickableElements, VIEWS } from '../../types';
import { errorHandler } from '../../utils/handlers';
import { generatePDF, submit } from '../../utils/submit';
import {
  BoldTitles,
  InfoSection,
  CheckboxSection,
  CustomCard,
  CustomStepButton,
  TextWrapper,
} from '../Finalize/layouts/finalize';

const Finalize = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { businessId } = useInsurance();
  const { temporalProducts } = useTariff();
  const { setLoading, setProcessing } = useLoadingUpdate();
  const { setFormSubmitted } = useInsuranceUpdate();
  const { setTrackingClick } = useTracking();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showCheckedError, setShowCheckedError] = useState<boolean>(false);
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] =
    useState<boolean>(true);
  const [isOnlineButtonAble, setIsOnlineButtonAble] = useState<boolean>(false);
  const [pdfIsDownloaded, setPDFIsDownloaded] = useState<boolean>(false);

  const _trackingCheckBox = (isChecked: boolean) => {
    const element = isChecked
      ? ClickableElements.CheckBoxFinalizeActive
      : ClickableElements.CheckBoxFinalizeInactive;
    setTrackingClick(element);
  };

  const onGenericError = () => {
    setProcessing(false);
    setLoading(false);
    navigate(VIEWS.error);
  };

  const onGoBack = () => {
    setTrackingClick(ClickableElements.ButtonBack);
    navigate(VIEWS.bankDetails);
  };

  const setWaiverAdviceCheckbox = async (checkStatus: boolean) => {
    const response = await postWaiverAdviceCheck(
      businessId,
      {
        checked: checkStatus,
      },
      onGenericError,
    );
    setLoading(false);
    if (!response || !response.data || !response.data.success) onGenericError();
  };

  const checkboxChangeHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { target } = event;
    const { checked } = target;

    _trackingCheckBox(checked);
    setIsChecked(checked);
    setIsDownloadButtonDisabled(!checked);
    setIsOnlineButtonAble(checked && pdfIsDownloaded);
    setShowCheckedError(!checked);
    setLoading(true);
    await setWaiverAdviceCheckbox(checked);
  };
  const onDownloadButtonClicked = async () => {
    try {
      setTrackingClick(ClickableElements.ButtonFinalizeDownload);
      setProcessing(true);
      await generatePDF(
        businessId,
        null,
        CHECKOUT_TYPE.online,
        temporalProducts,
      );
      setIsOnlineButtonAble(true);
      setPDFIsDownloaded(true);
    } catch (error: unknown) {
      errorHandler(onGenericError, error);
    } finally {
      setProcessing(false);
    }
  };

  const onOnlineButtonClicked = async () => {
    try {
      setTrackingClick(ClickableElements.ButtonNext);
      setProcessing(true);
      await submit(
        onGenericError,
        businessId,
        ShippingWay.SOFORT_DOWNLOAD,
        true,
      );
      setFormSubmitted(true);
      navigate(VIEWS.feedback);
    } catch (error: unknown) {
      errorHandler(onGenericError, error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <PageLayout
      title={intl.formatMessage(literals.headline)}
      id={ABTestingIds.title}
      subtitle={intl.formatMessage(literals.subtitle)}
      textAlign="left"
    >
      <MainWrapper>
        <CustomCard className="abTesting" id={ABTestingIds.card}>
          <div className="abTesting" id={ABTestingIds.cardLegalTop}>
            <TextWrapper isCheckboxText={true}>
              <BoldTitles isMainTitle={true}>
                {intl.formatMessage(literals.adviceTitle)}
              </BoldTitles>
            </TextWrapper>
            <TextWrapper isCheckboxText={true}>
              <FormattedMessage {...literals.adviceText} />
            </TextWrapper>
            <TextWrapper isCheckboxText={true}>
              <FormattedMessage {...literals.adviceSubtext} />
            </TextWrapper>
          </div>
          <CheckboxSection
            className="abTesting"
            id={ABTestingIds.cardLegalCheckbox}
          >
            <ControlWithHint
              error={
                showCheckedError
                  ? intl.formatMessage(literals.errorUnchecked)
                  : ''
              }
            >
              <Checkbox
                data-testid="finalize.checkbox"
                checked={isChecked}
                verticalAlign="top"
                onChange={checkboxChangeHandler}
                label={intl.formatMessage(literals.checkbox)}
              />
            </ControlWithHint>
          </CheckboxSection>
        </CustomCard>

        <div className="abTesting" id={ABTestingIds.contactSection}>
          <InfoSection
            className="abTesting abTestingBottom"
            id={ABTestingIds.contactSubline}
          >
            <TextWrapper>
              <BoldTitles>
                {intl.formatMessage(literals.contactSubLine)}
              </BoldTitles>
            </TextWrapper>
            <TextWrapper>
              <FormattedMessage {...literals.contactText} />
            </TextWrapper>
          </InfoSection>

          <InfoSection
            className="abTesting abTestingBottom"
            id={ABTestingIds.contactText}
          >
            {!pdfIsDownloaded && (
              <CustomStepButton
                text={intl.formatMessage(literals.finalizeFirstStepButton)}
                step="1"
                disabled={isDownloadButtonDisabled}
                onClick={onDownloadButtonClicked}
              />
            )}
            {pdfIsDownloaded && (
              <Button
                variant="tertiary"
                onClick={onDownloadButtonClicked}
                iconRight={CheckIcon}
              >
                {intl.formatMessage(literals.finalizeFirstStepButton)}
              </Button>
            )}
          </InfoSection>
        </div>

        <div className="abTesting" id={ABTestingIds.contractSection}>
          <InfoSection
            className="abTesting abTestingBottom"
            id={ABTestingIds.contractSubline}
          >
            <TextWrapper>
              <BoldTitles>
                {intl.formatMessage(literals.contractSubLine)}
              </BoldTitles>
            </TextWrapper>
            <TextWrapper>
              <FormattedMessage {...literals.contractText} />
            </TextWrapper>
          </InfoSection>

          <InfoSection
            className="abTesting abTestingBottom"
            id={ABTestingIds.contractText}
          >
            <CustomStepButton
              text={intl.formatMessage(literals.finalizeSecondStepButton)}
              subText={intl.formatMessage(literals.finalizeSecondButtonSubText)}
              step="2"
              disabled={!isOnlineButtonAble}
              onClick={onOnlineButtonClicked}
            />
          </InfoSection>
        </div>

        <ActionButtons
          buttonsList={[
            {
              label: intl.formatMessage(commonLiterals.backButton),
              type: 'text-link',
              callbackOnClick: onGoBack,
              id: ABTestingIds.backButton,
            },
          ]}
          id={ABTestingIds.buttonContainer}
        />
      </MainWrapper>
    </PageLayout>
  );
};

export default Finalize;
