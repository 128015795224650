import { ReactNode } from 'react';

import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';

interface CustomUSPItemProps {
  children: ReactNode;
  condensed: boolean;
}

export const CustomUSPItem = styled.div<CustomUSPItemProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  gap: ${sizes.space.PX_08};
  padding: ${(props) =>
    props.condensed ? `${sizes.space.PX_08} 0` : `${sizes.space.PX_16} 0`};
  font-size: ${sizes.text.SM};

  & .contribution__usp-check-icon {
    padding-left: ${(props) =>
      props.condensed ? '0' : `${sizes.space.PX_12}`};
  }

  .contribution__usp-text {
    flex-grow: 4;
  }

  @media (min-width: ${breakpoints.M}) {
    align-items: center;
    font-size: ${sizes.text.M};
    gap: ${sizes.space.PX_12};
  }
`;
