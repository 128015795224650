import { UseValidateParams } from '../../../hooks/useValidateInput';

import { hasNameFormatError } from './person';

export const validateName: UseValidateParams['validateValueFn'] = (
  value: string,
) => {
  return !hasNameFormatError(value);
};

export const validatePostalCode: UseValidateParams['validateValueFn'] = (
  value: string,
  _event,
) => {
  const onlyNumber = new RegExp('^[0-9]*$');
  const isValid = onlyNumber.test(value) && value.length === 5;
  return isValid;
};
