import Button from '@eg/elements/Button';

import styled from 'styled-components';

import { breakpoints, sizes } from '../../styles/tokens';
import { SingleButton } from '../../types';
interface Props {
  buttonsList: SingleButton[];
  specialMargin?: boolean;
  id?: string;
}

const CommonButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > button {
    align-self: center;
    margin-top: ${sizes.space.PX_16};
  }
`;

const MarginButtonWrapper = styled.div<{ specialMargin }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > button {
    align-self: center;
    margin-top: ${sizes.space.PX_13};
    margin-bottom: ${sizes.space.PX_32};

    @media (min-width: ${breakpoints.S}) {
      margin-top: ${sizes.space.PX_05};
    }

    @media (min-width: ${breakpoints.L}) {
      margin-top: ${sizes.space.PX_17};
      margin-bottom: ${sizes.space.PX_48};
    }
  }
`;

const ActionButtons = ({ buttonsList, id, specialMargin = false }: Props) => {
  const props: Partial<Props> = {};
  if (!!id) props.id = id;

  const ButtonWrapper = specialMargin
    ? MarginButtonWrapper
    : CommonButtonWrapper;

  return (
    <ButtonWrapper className="abTesting" {...props}>
      {buttonsList
        .map(
          (
            {
              type,
              callbackOnClick,
              label,
              disabled,
              hidden,
              id: buttonId,
              ...restOfProps
            },
            idx,
          ) => {
            if (hidden) return null;
            return (
              <Button
                key={idx}
                size="auto"
                variant={type}
                disabled={disabled ?? false}
                onClick={callbackOnClick}
                data-testid="buttons-footer__button"
                id={buttonId}
                className="abTesting abTestingOut"
                {...restOfProps}
              >
                {label}
              </Button>
            );
          },
        )
        .filter((item) => item)}
    </ButtonWrapper>
  );
};
export default ActionButtons;
