import {
  Person,
  ConsentContact,
  ContactElement,
  OFFER_ROLES,
  INSURANCE_TYPE,
} from 'ppz-otr-common';

import {
  AutocorrectedAddress,
  PersonUpdateContext,
} from '../../../context/PersonCtx';
import literals from '../../../language/src/personal-data';
import {
  savePersonalData,
  validatePersonalData,
  ValidatePersonalDataResponse,
  postShippingAddress,
} from '../../../services';
import { DetailsValidation, PERSON_INPUTS } from '../../../types';
import { scrollToError } from '../../../utils';
import { getLiteral } from '../../../utils/literals';
import { PersonalDataStatus } from '../types';

import { getSaveParams, getValidateParams } from './person';

export interface ValidateParams {
  id: string;
  businessId: string;
  persons: Map<string | number | null, Person>;
  consentContact: ConsentContact;
  status: PersonalDataStatus;
  callbacks: CallbacksValidateParams;
}

interface CallbacksValidateParams {
  setShowEmailError: (value: React.SetStateAction<boolean>) => void;
  setShowPhoneError: (value: React.SetStateAction<boolean>) => void;
  setErrorOnPersonalAddress: PersonUpdateContext['setErrorOnPersonalAddress'];
  setAutoCorrectedAddresses: PersonUpdateContext['setAutoCorrectedAddresses'];
  setSpcsPersons: PersonUpdateContext['setSpcsPersons'];
  setPersons: PersonUpdateContext['setPersons'];
}

const validate = async (
  params: ValidateParams,
): Promise<ValidatePersonalDataResponse[]> => {
  const {
    id,
    businessId,
    persons,
    consentContact: consent,
    status,
    callbacks,
  } = params;
  const { showLegalConsent, showOfferByEmail } = status;
  const {
    setShowEmailError,
    setShowPhoneError,
    setErrorOnPersonalAddress,
    setSpcsPersons,
    setPersons,
    setAutoCorrectedAddresses,
  } = callbacks;

  const _persons = getValidateParams(persons, consent, id);
  const response = await validatePersonalData(_persons, businessId, () =>
    Promise.reject(new Error('generic error')),
  );

  if (!response) return Promise.reject(new Error('generic error'));

  const { data } = response;

  const gotError = data.some((elem) => elem.error === true);

  if (gotError) {
    const validateErrorParams: ValidateErrorParams = {
      data,
      showLegalConsent,
      showOfferByEmail,
      callbacks: {
        setErrorOnPersonalAddress,
        setShowEmailError,
        setShowPhoneError,
      },
    };
    validateError(validateErrorParams);
    return Promise.reject();
  }

  const gotAutocorrected = data.some((elem) => elem.autoCorrected === true);

  if (gotAutocorrected) {
    const actionsAutocorrectedParams: ActionsAutocorrectedParams = {
      data,
      persons,
      callbacks: {
        setAutoCorrectedAddresses,
        setSpcsPersons,
        setPersons,
      },
    };
    setErrorOnPersonalAddress([]);
    actionsAutocorrected(actionsAutocorrectedParams);
    return Promise.reject();
  }

  return Promise.resolve(data);
};

export interface ValidateErrorParams {
  data: ValidatePersonalDataResponse[];
  showLegalConsent: boolean;
  showOfferByEmail: boolean;
  callbacks: CallbacksValidateErrorParams;
}

type CallbacksValidateErrorParams = Pick<
  CallbacksValidateParams,
  'setShowEmailError' | 'setShowPhoneError' | 'setErrorOnPersonalAddress'
>;

const validateError = (params: ValidateErrorParams) => {
  const {
    data,
    showLegalConsent,
    showOfferByEmail,
    callbacks: {
      setShowPhoneError,
      setShowEmailError,
      setErrorOnPersonalAddress,
    },
  } = params;

  /**
   * There are 2 steps in the personal data validation.
   *
   * 1. Validation in SPCS for personal data (update person info). If an error
   * is found, address validation is not performed.
   */
  const mailAndPhoneErrors = data.filter(
    (item) => item.dataErrorInformationUpdated,
  );

  mailAndPhoneErrors.forEach((item) => {
    const { dataErrorInformationUpdated } = item;

    if (!dataErrorInformationUpdated) return;
    const { data: _data } = dataErrorInformationUpdated;

    const { emailError, telephoneError } = _data;

    /**
     * Scroll to telephone input. This error has the highest priority, once
     * phone is valid, we can find errors in email or address.
     */
    if (telephoneError) {
      setShowPhoneError(telephoneError);
      scrollToError(PERSON_INPUTS.consentReceivePhone, 0);
    }

    if (emailError) {
      const legalConsentOrOfferByEmail = showLegalConsent || showOfferByEmail;
      if (legalConsentOrOfferByEmail) {
        setShowEmailError(emailError);
        const input = showLegalConsent
          ? PERSON_INPUTS.consentReceiveEmail
          : PERSON_INPUTS.offerEmail;

        /**
         * After phone, the next one with highest priority is email. Once
         * email is valid we can find errors in addresses.
         */
        scrollToError(input, 0);
      }
    }
  });

  /**
   * 2. Validation in SPCS for addresses. This step is only executed once
   * personal data for the person has been updated successfully in SPCS.
   */
  const addressValidationResults: DetailsValidation[] = data.map((item) => {
    const { addressValidationResult } = item;
    const noneErrorResult: DetailsValidation = {
      message: '',
      code: '',
      where: 0,
      needAgent: false,
    };
    if (!addressValidationResult) return noneErrorResult;
    else
      return {
        ...addressValidationResult,
        message: getLiteral(literals.addressNotValidError),
      };
  });

  const firstIndex = addressValidationResults.findIndex((elem) => {
    return !!elem && !!elem.code;
  });

  /**
   * Scroll to address input. If we have more than one error, we must scroll
   * to the first one, therefore we set scroll to true just in the first one.
   */
  const errorInAddressInputs = firstIndex !== -1;
  if (errorInAddressInputs) {
    addressValidationResults[firstIndex].scroll = true;
  }
  setErrorOnPersonalAddress(addressValidationResults);
};

export interface ActionsAutocorrectedParams {
  data: ValidatePersonalDataResponse[];
  persons: Map<string | number | null, Person>;
  callbacks: CallbacksActionsAutocorrectedParams;
}

type CallbacksActionsAutocorrectedParams = Pick<
  CallbacksValidateParams,
  'setAutoCorrectedAddresses' | 'setSpcsPersons' | 'setPersons'
>;

const actionsAutocorrected = (params: ActionsAutocorrectedParams) => {
  const {
    data,
    persons,
    callbacks: { setAutoCorrectedAddresses, setPersons, setSpcsPersons },
  } = params;

  let correctedAddresses: AutocorrectedAddress[] = [];
  let foundFirst = false;

  // Create a copy of current persons map
  const updatedPersons = new Map(persons);

  data.forEach(({ addressesUpdated, personId, autoCorrected }) => {
    const currentPersonData = updatedPersons.get(personId);
    const notAddressUpdated = !addressesUpdated || !addressesUpdated.length;

    if (notAddressUpdated || !autoCorrected) {
      // We must keep current person information
      if (currentPersonData) {
        updatedPersons.set(personId, { ...currentPersonData });
      }

      // Prepare corrected addresses information for the person
      correctedAddresses.push({
        scroll: false,
        showAlert: false,
      });
      return;
    }

    // Prepare person information to be updated
    const { city, postalCode, street, streetNumber } = addressesUpdated[0];

    const newPersonAddress: Partial<Person> = {
      address: {
        city,
        postalCode,
        street,
        streetNumber,
      },
    };

    if (currentPersonData) {
      updatedPersons.set(personId, {
        ...currentPersonData,
        ...newPersonAddress,
      });
    }

    // Prepare corrected addresses information for the person
    const scroll = !foundFirst;
    const personInformation = persons.get(personId);
    const isInsuranceOwner =
      personInformation?.role === OFFER_ROLES.insuranceOwner;
    const inheritAddress = !isInsuranceOwner && !personInformation?.diffAddress;
    const showAlert = isInsuranceOwner || !inheritAddress;

    correctedAddresses.push({
      scroll,
      showAlert,
    });

    foundFirst = true;
  });

  // Finally, update persons with new information
  setSpcsPersons(updatedPersons);
  setPersons(updatedPersons);

  setAutoCorrectedAddresses(correctedAddresses);
};

export interface UpdateParams {
  businessId: string;
  persons: Map<string | number | null, Person>;
  situation: INSURANCE_TYPE;
  personalData: ValidatePersonalDataResponse[];
  callbacks: {
    setSpcsPersons: (value: Map<string | number | null, Person>) => void;
  };
}

const update = async (params: UpdateParams): Promise<void> => {
  const { businessId, persons, situation, personalData, callbacks } = params;
  const { setSpcsPersons } = callbacks;

  const saveParams = getSaveParams(persons, situation, personalData);

  const response = await savePersonalData(saveParams, businessId!, () =>
    Promise.reject(new Error('generic error')),
  );

  if (!response) return Promise.reject(new Error('generic error'));

  const { data } = response;
  const { success } = data;

  if (!success) return Promise.reject(new Error('generic error'));

  setSpcsPersons(new Map(persons));
  return Promise.resolve();
};

export interface SendShippingAddress {
  id: string;
  businessId: string;
  consentContact: ConsentContact;
  offerByEmail: ContactElement;
  status: PersonalDataStatus;
  callbacks: {
    setShowEmailError: (value: React.SetStateAction<boolean>) => void;
  };
}

const sendShippingAddress = async (
  params: SendShippingAddress,
): Promise<void> => {
  const { id, businessId, consentContact, offerByEmail, status, callbacks } =
    params;
  const { showLegalConsent, showOfferByEmail } = status;
  const { setShowEmailError } = callbacks;

  const { email: consentEmail } = consentContact;
  const { value: consentEmailValue } = consentEmail!;

  const { value: offerEmailValue } = offerByEmail;

  const email = consentEmailValue || offerEmailValue;
  const personId = id;

  if (!email) {
    (showLegalConsent || showOfferByEmail) && setShowEmailError(true);
    return Promise.reject();
  }

  const response = await postShippingAddress(
    { businessId, personId, email },
    () => Promise.reject(new Error('generic error')),
  );

  if (!response) return Promise.reject(new Error('generic error'));

  const { data } = response;
  if (!data) return Promise.reject(new Error('generic error'));

  return Promise.resolve();
};

export { validate, update, sendShippingAddress };
