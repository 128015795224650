import classNames from 'classnames';
import styled from 'styled-components';

import { colors, fonts, sizes } from '../../../styles/tokens';

interface PriceWrapperProps {
  noMargin?: boolean;
}

export const PriceWrapper = styled.div<PriceWrapperProps>`
  margin-bottom: ${(props) => (props.noMargin ? '0' : sizes.space.PX_16)};
`;

const Base = styled.h2`
  font-family: ${fonts.REGULARS};
  line-height: ${sizes.text.XL};
  color: ${colors.DARKEST_GRAY};
  font-weight: bold;
`;

export const DetailTitle = styled(Base)`
  font-size: ${sizes.text.L};
`;

export const SubLine = styled(Base)`
  font-size: ${sizes.text.M};
  margin-top: 0;
  margin-bottom: 0;
`;

export const subLineWrapperClasses = classNames(
  'esc_col',
  'esc_col-12',
  'esc_col-m-4',
  'esc_col-l-4',
);

export const subLineBodyWrapperClasses = classNames(
  'esc_col',
  'esc_col-12',
  'esc_col-m-8',
  'esc_col-l-8',
);

export const bodyWithoutSubLine = classNames(
  'esc_col',
  'esc_col-12',
  'esc_col-m-8',
  'esc_col--offset-0',
  'esc_col-m--offset-4',
);
