import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'feedback.title',
    defaultMessage: 'Vielen Dank, {fullname}!',
  },
  subHeading: {
    id: 'feedback.subheading',
    defaultMessage: 'Ihre Zahnversicherung',
  },
  onlineSubtitle: {
    id: 'feedback.onlineSubtitle',
    defaultMessage: 'Ihr Antrag ist angekommen. Sie müssen nichts weiter tun.',
  },
  onlineContent: {
    id: 'feedback.onlineContent',
    defaultMessage:
      '<paragraph><bold>Herzlichen Glückwunsch! Jetzt haben Sie gut lachen, denn Sie sind perfekt abgesichert.</bold></paragraph><paragraph>Sie haben bereits eine Bestätigung an die angegebene E-Mail-Adresse erhalten. <bold>Ihre Vertragsunterlagen bekommen Sie in wenigen Tagen per Post.</bold></paragraph>',
  },
  emailSubtitle: {
    id: 'feedback.emailSubtitle',
    defaultMessage: 'Ihre Angebotsanforderung wurde erfolgreich gesendet.',
  },
  emailContent: {
    id: 'feedback.emailContent',
    defaultMessage:
      '<paragraph><bold>In Kürze erhalten Sie Ihr persönliches Angebot an die angegebene E-Mail-Adresse.</bold></paragraph><paragraph>Dann können Sie alles in Ruhe prüfen und vergleichen.</paragraph>',
  },
  postSubtitle: {
    id: 'feedback.postSubtitle',
    defaultMessage: 'Ihre Angebotsanforderung wurde erfolgreich gesendet.',
  },
  postContent: {
    id: 'feedback.postContent',
    defaultMessage:
      '<paragraph><bold>In wenigen Tagen erhalten Sie Ihr persönliches Angebot per Post.</bold></paragraph><paragraph>Dann können Sie alles in Ruhe prüfen und vergleichen.</paragraph>',
  },
  pdfSubtitle: {
    id: 'feedback.pdfSubtitle',
    defaultMessage: 'Ihre Angebotsanforderung wurde erfolgreich gesendet.',
  },
  pdfContent: {
    id: 'feedback.pdfContent',
    defaultMessage:
      '<paragraph><bold>Ihr persönliches Angebot wurde Ihnen per Download bereitgestellt.</bold></paragraph><paragraph>Jetzt können Sie alles in Ruhe prüfen und vergleichen.</paragraph>',
  },
  downloadPdf: {
    id: 'feedback.downloadPdf',
    defaultMessage: 'Angebot als PDF',
  },
});
