import { useCallback } from 'react';

import { OFFER_ROLES, INSURANCE_TYPE } from 'ppz-otr-common';

import { useDates } from '../context/DatesCtx';
import { useInsurance } from '../context/InsuranceCtx';
import { usePerson } from '../context/PersonCtx';
import { useTariff } from '../context/TariffCtx';
import { CHECKOUT_TYPE, VIEWS } from '../types';

const defaultView = VIEWS.initialSelection;

const useRouteGuard = () => {
  const { businessId, situation, checkoutType, bankDetails } = useInsurance();
  const { beginningDateSelected } = useDates();
  const { contributionSelected } = useTariff();
  const { persons, insuranceOwnerId } = usePerson();

  const guardInitialSelection = useCallback(
    (path: VIEWS) => {
      const isInitialSelection = path === VIEWS.initialSelection;
      const isInit = !businessId || !situation;

      return isInit && !isInitialSelection ? false : true;
    },
    [situation, businessId],
  );

  const guardBirthdate = useCallback(
    (path: VIEWS) => {
      if (!guardInitialSelection(path)) {
        return false;
      }

      if (path === VIEWS.myself) {
        return true;
      }

      if (
        situation === INSURANCE_TYPE.myself &&
        (path === VIEWS.myselfAndMore || path === VIEWS.others)
      ) {
        return false;
      }

      if (
        persons.size === 0 ||
        !insuranceOwnerId ||
        !isNaN(insuranceOwnerId as number)
      ) {
        return false;
      }

      if (situation === INSURANCE_TYPE.myselfAndMore && path === VIEWS.others) {
        return false;
      } else if (
        situation === INSURANCE_TYPE.others &&
        path === VIEWS.myselfAndMore
      ) {
        return false;
      }
      return true;
    },
    [persons, insuranceOwnerId, situation, guardInitialSelection],
  );

  const guardInsuranceBeginning = useCallback(
    (path: VIEWS) => {
      if (!guardBirthdate(path)) {
        return false;
      }
      switch (situation) {
        case INSURANCE_TYPE.myselfAndMore:
        case INSURANCE_TYPE.others:
          if (
            !Array.from(persons.values()).find(
              ({ role }) => role === OFFER_ROLES.insuredPerson,
            )
          ) {
            return false;
          }
          break;
      }
      return true;
    },
    [situation, persons, guardBirthdate],
  );

  const guardContribution = useCallback(
    (path: VIEWS) => {
      if (!guardInsuranceBeginning(path)) {
        return false;
      }

      if (!beginningDateSelected) {
        return false;
      }
      return true;
    },
    [beginningDateSelected, guardInsuranceBeginning],
  );

  const guardPersonalData = useCallback(
    (path: VIEWS) => {
      if (!guardContribution(path)) {
        return false;
      }

      if (!contributionSelected || !checkoutType) {
        return false;
      }
      return true;
    },
    [checkoutType, contributionSelected, guardContribution],
  );

  const guardSummary = useCallback(
    (path: VIEWS) => {
      if (!guardPersonalData(path)) {
        return false;
      }

      const personWithName = Array.from(persons.values()).find(
        ({ name }) => name !== null,
      );
      if (personWithName === undefined) {
        return false;
      }
      return true;
    },
    [guardPersonalData, persons],
  );

  const guardBankDetails = useCallback(
    (path: VIEWS) => {
      if (!guardSummary(path)) {
        return false;
      }

      return true;
    },
    [guardSummary],
  );

  const guardFinalize = useCallback(
    (path: VIEWS) => {
      if (checkoutType !== CHECKOUT_TYPE.online) return false;

      if (!guardBankDetails(path)) {
        return false;
      }

      if (!bankDetails || !bankDetails.iban) {
        return false;
      }

      return true;
    },
    [checkoutType, bankDetails, guardBankDetails],
  );

  const guardFeedback = useCallback(
    (path: VIEWS) => {
      if (checkoutType === CHECKOUT_TYPE.online) {
        if (!guardFinalize(path)) {
          return false;
        }
      } else {
        if (!guardPersonalData(path)) {
          return false;
        }
      }

      return true;
    },
    [checkoutType, guardFinalize, guardPersonalData],
  );

  const guardApp = useCallback(
    (path: string): boolean => {
      if (path === VIEWS.error) {
        return true;
      }

      const similarPath = Object.values(VIEWS).find((view) =>
        path.includes(view),
      );
      if (!similarPath) {
        return false;
      }

      switch (similarPath) {
        case VIEWS.initialSelection:
          return guardInitialSelection(similarPath);
        case VIEWS.myself:
        case VIEWS.myselfAndMore:
        case VIEWS.others:
          return guardBirthdate(similarPath);
        case VIEWS.insuranceBeginning:
          return guardInsuranceBeginning(similarPath);
        case VIEWS.contribution:
          return guardContribution(similarPath);
        case VIEWS.personalData:
          return guardPersonalData(similarPath);
        case VIEWS.summary:
          return guardSummary(similarPath);
        case VIEWS.bankDetails:
          return guardBankDetails(similarPath);
        case VIEWS.finalize:
          return guardFinalize(similarPath);
        case VIEWS.feedback:
          return guardFeedback(similarPath);
        default:
          return false;
      }
    },
    [
      guardInitialSelection,
      guardBirthdate,
      guardInsuranceBeginning,
      guardContribution,
      guardPersonalData,
      guardSummary,
      guardBankDetails,
      guardFinalize,
      guardFeedback,
    ],
  );

  return { guardApp, defaultView };
};

export default useRouteGuard;
