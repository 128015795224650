import { IntlShape } from 'react-intl';

import { Person, BirthdateData } from 'ppz-otr-common';

import literals from '../../../language/src/birthdates';
import { BirthdateMap } from '../types';

export const isValid = (
  personData: Person | BirthdateData,
  maxDate: Date,
  minDate: Date,
): BirthdateData['errors'] => {
  const resultError = {
    valid: false,
  };

  const resultOk = {
    valid: true,
  };

  const { day, month, year } = personData;
  if (!!(personData as BirthdateData).errors) {
    const errorInput = (personData as BirthdateData).errors;
    if (!errorInput.valid) return resultError;
  }

  const hasValue = day !== '' && month !== '' && year !== '';
  if (!hasValue) {
    return resultError;
  }

  const currentPersonDate = new Date(`${year}-${month}-${day}`);
  const currentPersonDateMs = currentPersonDate.getTime();
  const isLessThanMin = currentPersonDateMs < minDate.getTime();
  const isFutureDate = currentPersonDateMs > maxDate.getTime();
  const isInRange = !isLessThanMin && !isFutureDate;

  if (isInRange) {
    return resultOk;
  }

  if (isLessThanMin) {
    return {
      valid: false,
      rangeUnderflow: '',
    };
  }

  return {
    valid: false,
    rangeOverflow: '',
  };
};

export const hasErrors = (dates: BirthdateMap) => {
  return Array.from(dates.values()).some((value) => !value.errors.valid);
};

export const getValidateError = (
  dateStr: string,
  rangeUnder: string,
  rangeOver: string,
  yesterdayDate: Date,
  isInsuranceOwner: boolean,
  badInputValue: string,
  missing: string,
  intl: IntlShape,
) => {
  const date = new Date(dateStr);
  let errorMsg = '';
  if (rangeUnder !== undefined) {
    errorMsg = intl.formatMessage(literals.validationNotPast);
  } else if (rangeOver !== undefined) {
    if (!isInsuranceOwner || (isInsuranceOwner && date > yesterdayDate)) {
      errorMsg = intl.formatMessage(literals.validationNotFuture);
    } else {
      errorMsg = intl.formatMessage(literals.validationVnAdult);
    }
  } else if (badInputValue !== undefined || missing !== undefined) {
    errorMsg = intl.formatMessage(literals.genericError);
  }

  return errorMsg;
};
