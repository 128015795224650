import { Person, BirthdateData } from 'ppz-otr-common';

import { PersonContext } from '../../../context/PersonCtx';
import { BirthdateMap } from '../types';

export const formatDateBackend = (
  day: string = '',
  month: string = '',
  year: string = '',
) => {
  return `${year}-${month}-${day}`;
};

export const getNewMapFromArr = (
  dates: (BirthdateData | Person)[],
): Map<(BirthdateData | Person)['id'], BirthdateData | Person> => {
  const newDates: Map<any, BirthdateData | Person> = new Map();
  dates
    .filter((date) => !!date)
    .forEach((date: BirthdateData | Person) => {
      newDates.set(date.id, date);
    });
  return newDates;
};

export const getCleanPersons = (persons: PersonContext['persons']) => {
  const newPersons: PersonContext['persons'] = new Map();
  persons.forEach((person) => {
    if (isNaN(person.id as number)) {
      newPersons.set(person.id, person);
    }
  });
  return newPersons;
};

export const sortDateValues = (dates: BirthdateMap): BirthdateMap => {
  const isNumber = (id: string | number | null) => typeof id === 'number';

  const datesArray = [...dates.entries()];
  const filledDates = datesArray.filter((date) => !isNumber(date[1].id));
  const emptyDates = datesArray.filter((date) => isNumber(date[1].id));

  return new Map([...filledDates, ...emptyDates]);
};

export * from './defaults';
export * from './events';
export * from './validation';
