import styled from 'styled-components';

import { fonts, sizes, colors, breakpoints } from '../../../styles/tokens';

export const FullWidth = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: ${colors.LIGHTER_GREEN};
  padding: ${sizes.space.PX_32} ${sizes.space.PX_24};
  text-align: center;

  @media (min-width: ${breakpoints.M}) {
    padding: ${sizes.space.PX_64} ${sizes.space.PX_24};
  }
`;

export const MainTitle = styled.h2`
  color: ${colors.BRAND_COLOR};
  font-family: ${fonts.HEADINGS};
  font-size: ${sizes.text.XL};
  line-height: ${sizes.text.H4};
  font-weight: bold;
  letter-spacing: 0;
  margin: ${sizes.space.PX_24} 0;

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.H2};
    line-height: ${sizes.text.H1};
  }
`;

export const SubTitle = styled.p`
  color: ${colors.DARKEST_GRAY};
  font-family: ${fonts.REGULARS};
  font-size: ${sizes.text.SM};
  line-height: ${sizes.text.LG};
  letter-spacing: 0;
  font-weight: bold;
  margin-bottom: 0;

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.L};
    line-height: ${sizes.space.PX_24};
  }
`;

export const SubHeading = styled.h3`
  color: ${colors.DARKEST_GRAY};
  font-family: ${fonts.REGULARS};
  font-weight: bold;
  font-size: ${sizes.text.LG};
  line-height: ${sizes.text.XXL};
  text-align: center;
  margin-bottom: ${sizes.space.PX_24};

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.XXL};
    line-height: ${sizes.text.H3};
  }
`;

export const ContentTextCompletion = styled.div`
  margin-top: ${sizes.space.PX_32};
  margin-bottom: ${sizes.space.PX_32};

  @media (min-width: ${breakpoints.M}) {
    margin-top: ${sizes.space.PX_64};
    margin-bottom: ${sizes.space.PX_48};
  }
`;

export const GridDetails = styled.div`
  margin: ${sizes.space.PX_08} 0;
`;
