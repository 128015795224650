import storage, { LocalKeys } from '../../../services/storage';

export const getYesNo = (condition: boolean) => {
  return condition ? 'J' : 'N';
};

export const round = (num: number) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

export const generatePreallocationKeyValue = () =>
  `PreA=${storage.get(LocalKeys.isPreallocation) ? 'J' : 'N'}`;
