import React from 'react';
import { FormattedMessage } from 'react-intl';

import EditIcon from '@eg/elements/Icons/EditIcon';

import { Person } from 'ppz-otr-common';

import commonLiterals from '../../../language/src/common';
import literals from '../../../language/src/personal-data';
import { getBirthdate } from '../../../utils';
import { getLiteral } from '../../../utils/literals';
import { FormRowCustom, LinkButton } from '../layouts';

interface Props {
  person: Person;
  onClick: () => void;
}

const Birthdate = ({ person, onClick }: Props) => {
  const label = getLiteral(commonLiterals.birthday);
  return (
    <FormRowCustom label={label} birthdate="true">
      <p>{getBirthdate(person)}</p>
      <LinkButton
        variant="text-link"
        size={'large'}
        iconLeft={EditIcon}
        onClick={onClick}
      >
        <FormattedMessage {...literals.editBirthdate} />
      </LinkButton>
    </FormRowCustom>
  );
};

export default Birthdate;
