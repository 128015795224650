import { GetCitiesByZipCodeResponseDto } from '../../../services/personalData';

export const getZipCodeResponse = (
  zipCode: string,
): GetCitiesByZipCodeResponseDto => {
  return {
    result: [
      {
        zipCode,
        city: `Halle (${zipCode})`,
        street: '',
        municipalityKey: '15002000',
      },
    ],
  };
};
