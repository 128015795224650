import React, { ReactNode } from 'react';

import styled from 'styled-components';

import Container from './Container';

interface Props {
  children: ReactNode;
}

const Section = styled.section`
  position: relative;
  box-sizing: border-box; // TODO: Check by default
`;

const PageContent = ({ children }: Props) => (
  <Section>
    <Container>{children}</Container>
  </Section>
);

export default PageContent;
