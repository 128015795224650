import styled from 'styled-components';

import { sizes, colors } from '../../../styles/tokens';

const ProgressBarContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > progress {
    -webkit-appearance: none;
    height: ${sizes.space.PX_04};
  }

  > progress[value] {
    width: 100%;
    border-radius: 0 ${sizes.space.PX_02} ${sizes.space.PX_02} 0;
  }

  > progress[value]::-webkit-progress-bar {
    background-color: ${colors.WHITE};
    border-top: ${sizes.space.PX_01} solid ${colors.LIGHT_GRAY};
  }

  > progress[value]::-webkit-progress-value {
    border-radius: 0 ${sizes.space.PX_02} ${sizes.space.PX_02} 0;
    background-color: ${colors.BRAND_COLOR};
  }

  > progress::-moz-progress-bar {
    border-radius: 0 ${sizes.space.PX_02} ${sizes.space.PX_02} 0;
    background-color: ${colors.BRAND_COLOR};
  }
`;

export default ProgressBarContainer;
