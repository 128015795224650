import { useEffect, useState, ChangeEvent, useRef, RefObject } from 'react';

import ControlWithHint from '@eg/elements/ControlWithHint';
import Input from '@eg/elements/Input';
import RadioGroupRow from '@eg/elements/RadioGroupRow';

import { SpcsSalutation, Identification, Person } from 'ppz-otr-common';

import { usePerson } from '../../../context/PersonCtx';
import { useValidateInput, useTracking } from '../../../hooks';
import literals from '../../../language/src/personal-data';
import { ErrorTypes, PERSON_INPUTS } from '../../../types';
import { getLiteral } from '../../../utils/literals';
import { FormRowCustom, FormSectionCustom } from '../layouts';
import { FormType, PersonalInputType } from '../types';
import { validateName } from '../utils/form';

import GenderCheckBox from './GenderCheckBox';

export interface Props {
  id: number;
  person: Person;
  forwardGenderRef?: RefObject<RadioGroupRow>;
  forwardNameRef?: RefObject<ControlWithHint>;
  forwardLastNameRef?: RefObject<ControlWithHint>;
  onChange: (id: Person['id'], data: Identification, type: FormType) => void;
}

const PersonalInputs = ({
  id,
  person,
  forwardGenderRef,
  forwardNameRef,
  forwardLastNameRef,
  onChange,
}: Props) => {
  const { name, lastname, gender } = person;

  const personId = useRef<Person['id']>(person.id);

  const { setTrackingError } = useTracking();
  const { showErrorOnEmptyPersonalFields } = usePerson();

  const nameInput = useValidateInput({
    validateValueFn: validateName,
    required: true,
    defaultValue: name ?? '',
  });

  const lastNameInput = useValidateInput({
    validateValueFn: validateName,
    required: true,
    defaultValue: lastname ?? '',
  });

  const [salutation, setSalutation] =
    useState<Identification['gender']>(gender);
  const [nameError, setNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [genderError, setGenderError] = useState<boolean>(false);

  const getErrorMessage = (
    isName: boolean,
    isTooShort: boolean,
    isEmpty: boolean,
  ) => {
    const lengthError = isName
      ? literals.lengthErrorName
      : literals.lengthErrorLastName;
    const emptyError = isName
      ? literals.emptyErrorName
      : literals.emptyErrorLastName;
    const formatError = isName
      ? literals.formatErrorName
      : literals.formatErrorLastName;

    if (isEmpty) return emptyError;

    if (isTooShort) return lengthError;

    return formatError;
  };
  const getError = (type: PersonalInputType, onBlur?: boolean) => {
    const isName = type === 'name' ? true : false;

    const input = isName ? nameInput : lastNameInput;
    const error = isName ? nameError : lastNameError;

    const { isValidInput, inputTouched, inputValue } = input;

    const isInputTouched = onBlur ?? inputTouched;

    if (error || (!isValidInput && isInputTouched)) {
      const isEmpty = inputValue === '';
      const isTooShort = inputValue.length === 1;

      const message = getErrorMessage(isName, isTooShort, isEmpty);
      return getLiteral(message);
    }

    return '';
  };

  const onChangeGender = (event: ChangeEvent<HTMLInputElement>) => {
    const salutation = event.target.value;
    setSalutation(salutation as SpcsSalutation);
    if (salutation !== null) {
      setGenderError(false);
    }
  };

  useEffect(() => {
    if (salutation !== null) setGenderError(false);

    const { inputValue: name } = nameInput;
    const { inputValue: lastname } = lastNameInput;

    const updatedData: Identification = {
      name,
      lastname,
      gender: salutation,
    };

    onChange(personId.current, updatedData, 'personal');
    // onPersonalInputsChange(personId.current, undefined, personalDataUpdated);
  }, [nameInput.inputValue, lastNameInput.inputValue, salutation]); // TODO: Refactor to avoid warning for onPersonalInputsChange callback

  useEffect(() => {
    if (nameInput.isValidInput) {
      setNameError(false);
    }
  }, [nameInput.isValidInput]);

  useEffect(() => {
    if (lastNameInput.isValidInput) {
      setLastNameError(false);
    }
  }, [lastNameInput.isValidInput]);

  useEffect(() => {
    if (showErrorOnEmptyPersonalFields) {
      if (nameInput.inputValue === '') setNameError(true);
      if (lastNameInput.inputValue === '') setLastNameError(true);
      if (salutation === null) setGenderError(true);
    }
  }, [showErrorOnEmptyPersonalFields]);

  const nameInputId = `${PERSON_INPUTS.name}-${id}`;
  const lastNameInputId = `${PERSON_INPUTS.lastname}-${id}`;

  return (
    <FormSectionCustom className="personal-data__form-section">
      <GenderCheckBox
        forwardGenderRef={forwardGenderRef}
        id={id}
        salutation={salutation}
        gotError={genderError}
        onChange={onChangeGender}
      />
      <FormRowCustom
        label={getLiteral(literals.labelLastName)}
        colspans={[1, 1]}
      >
        <ControlWithHint
          ref={forwardNameRef}
          data-testid="personal-inputs__name-error"
          error={getError('name')}
          onBlur={() => {
            const isTouched = true;
            const message = getError('name', isTouched);
            message && setTrackingError(ErrorTypes.FORMFIELD, { message });
          }}
        >
          <Input
            id={nameInputId}
            placeholder={getLiteral(literals.labelName)}
            aria-label={getLiteral(literals.labelName)}
            value={nameInput.inputValue}
            onChange={nameInput.onChangeHandler}
            onBlur={nameInput.onBlurHandler}
          />
        </ControlWithHint>
        <ControlWithHint
          ref={forwardLastNameRef}
          data-testid="personal-inputs__lastname-error"
          error={getError('lastname')}
          onBlur={() => {
            const isTouched = true;
            const message = getError('lastname', isTouched);
            message && setTrackingError(ErrorTypes.FORMFIELD, { message });
          }}
        >
          <Input
            id={lastNameInputId}
            placeholder={getLiteral(literals.labelLastName)}
            aria-label={getLiteral(literals.labelLastName)}
            value={lastNameInput.inputValue}
            onChange={lastNameInput.onChangeHandler}
            onBlur={lastNameInput.onBlurHandler}
          />
        </ControlWithHint>
      </FormRowCustom>
    </FormSectionCustom>
  );
};
export default PersonalInputs;
