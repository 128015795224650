import { useIntl } from 'react-intl';

import { Tariffs } from 'ppz-otr-common';

import { useTracking } from '../../../hooks';
import { ClickableElements } from '../../../types';
import { getProductUsps } from '../../../utils';
import { CustomCoverageList } from '../layouts';
import { literals } from '../literals';

import { CoverageItem } from '.';

interface Props {
  products: Tariffs[];
  showTooltips?: boolean;
  bordered?: boolean;
  marginX?: boolean;
}

export const CoverageProductsList = ({
  products,
  showTooltips = true,
  bordered = true,
  marginX = true,
}: Props) => {
  const intl = useIntl();
  const { setTrackingClick } = useTracking();

  const getTooltipId = (id: string, index: number): string | undefined => {
    const [, key, subKey] = id.split('.');
    const literalIndex = index > 2 ? index - 3 : index;

    // Old tariffs tooltips
    if (key === 'old') {
      return literals.tooltips[key][subKey][literalIndex];
    }

    // Rest
    return literals.tooltips[key][literalIndex];
  };

  const _tooltipContent = (
    showAllTooltips: boolean,
    id: string,
    key: number,
  ): string | null => {
    if (!showAllTooltips) return null;
    const tooltipId = getTooltipId(id, key);
    return tooltipId ? intl.formatMessage({ id: getTooltipId(id, key) }) : null;
  };

  const _trackingCoverageInfoIcons = (key: number) => {
    let clickableElement = ClickableElements.TooltipContributionUSP1;
    switch (key) {
      case 2:
        clickableElement = ClickableElements.TooltipContributionUSP2;
        break;
      case 3:
        clickableElement = ClickableElements.TooltipContributionUSP3;
        break;
      case 4:
        clickableElement = ClickableElements.TooltipContributionUSP4;
        break;
      case 5:
        clickableElement = ClickableElements.TooltipContributionUSP5;
        break;
      case 6:
        clickableElement = ClickableElements.TooltipContributionUSP6;
        break;
      default:
        clickableElement = ClickableElements.TooltipContributionUSP1;
    }

    setTrackingClick(clickableElement);
  };
  return (
    <CustomCoverageList
      className="contribution__coverage-list"
      bordered={bordered}
      marginX={marginX}
    >
      {getProductUsps(products)?.map((id, key) => (
        <div key={key} data-testid="contribution__usp-item">
          <CoverageItem
            condensed={true}
            onClick={() => _trackingCoverageInfoIcons(key + 1)}
            tooltip={_tooltipContent(showTooltips, id, key)}
          >
            {intl.formatMessage({ id })}
          </CoverageItem>
        </div>
      ))}
    </CustomCoverageList>
  );
};
