import { rest } from 'msw';

import { FINALIZE_API } from '../../services';
import { getUrl, resDefault } from '../utils';

import {
  acceptConditionsResponse,
  downloadPdfResponse,
  submitResponse,
} from './responses/';

const acceptConditionsUrl = getUrl(FINALIZE_API.POST_WAIVER_ADVICE_CHECK);
const acceptConditionsHandler = rest.post(
  acceptConditionsUrl,
  (req, res, ctx) => {
    return resDefault(ctx.status(201), ctx.json(acceptConditionsResponse));
  },
);

const downloadPdfUrl = getUrl(FINALIZE_API.POST_GENERATE_DOCUMENT);
const downloadPdfHandler = rest.post(downloadPdfUrl, async (req, res, ctx) => {
  const bodyPdf = await downloadPdfResponse();
  return resDefault(
    ctx.status(201),
    ctx.set('Content-Disposition', 'attachment'),
    ctx.set('Content-Length', '1024'),
    ctx.set('Content-Type', 'application/pdf'),
    ctx.body(bodyPdf),
  );
});

const submitUrl = getUrl(FINALIZE_API.SUBMIT_FINAL_OFFER);
const submitHandler = rest.post(submitUrl, (req, res, ctx) => {
  return resDefault(ctx.status(201), ctx.json(submitResponse));
});

export const handlers = [
  acceptConditionsHandler,
  downloadPdfHandler,
  submitHandler,
];
export default handlers;
