import EKomiRating from '@eg/elements/EKomiRating';

import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';
import { TrustBannerItemStructure } from '../types';

export interface CustomEkomiRatingProps {
  structure: TrustBannerItemStructure;
}

const CustomEkomiRating = styled(EKomiRating)<CustomEkomiRatingProps>`
  padding: 0 ${sizes.space.PX_24};
  gap: ${sizes.space.PX_20};
  flex-direction: ${({ structure }) =>
    structure === 'horizontal' ? 'row' : 'column'};

  &&& {
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  &&& > div {
    align-self: auto;
    max-width: 100%;
    margin-right: 0;
    text-align: ${({ structure }) =>
      structure === 'horizontal' ? 'left' : 'center'};
  }

  &&& > div:nth-child(1) {
    height: ${({ structure }) =>
      structure === 'horizontal' ? sizes.space.PX_112 : 'auto'};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &&& .ee_ekomi-rating__text {
    padding-left: ${({ structure }) =>
      structure === 'horizontal' ? sizes.space.PX_04 : 0};
  }
`;

export { CustomEkomiRating };
