import { useEffect, useState } from 'react';

import { AgencyDetails } from 'ppz-otr-common';

import { AgentWindow } from '../types';

import useQueryParams from './useQueryParams';

export type SiteCoreQuery = 'adh_oenr' | 'adh_pnr';

const marketingEvent = 'MarketingLogicReady';
const marketingActionNumberKey = 'actionNumber';
const marketingAgentNumberKey = 'agentNumber';
const actionNumberCookieKey = 'aktionNr';
export const agentNumberQueryKey = 'adh_oenr';
const parsedComma = '%2C';

const getCookie = (name: string): string | null => {
  const cookieName = name + '=';
  const cookieList = document.cookie.split(';');
  const cookieFound = cookieList.find((cookie) => {
    return cookie.includes(cookieName);
  });
  if (!cookieFound) return null;

  return cookieFound.split('=')[1];
};

const getActionNumberInCookies = () => {
  const allCookies = document.cookie;
  const exists = allCookies.includes(actionNumberCookieKey);
  if (exists) {
    const cookieActionNumber = getCookie(actionNumberCookieKey);
    if (!cookieActionNumber) return cookieActionNumber;

    return cookieActionNumber.split(parsedComma)[0];
  }
};

const getMarketingValues = (property: string): string | null => {
  const { appEventData } = window as AgentWindow;
  if (appEventData) {
    const idx = appEventData.findIndex((e) => e.event === marketingEvent);
    return idx === -1
      ? null
      : appEventData[idx].miscellaneous.marketing[property];
  }
  return null;
};

const getAgencyDataValues = () => {
  const { agencyData } = window as AgentWindow;
  if (agencyData) return agencyData.oenrNumber;
};

const getDefaultActionNumber = (): string => {
  const marketingActionNumber = getMarketingValues(marketingActionNumberKey);
  if (marketingActionNumber) return marketingActionNumber;

  const cookiesActionNumber = getActionNumberInCookies();
  if (cookiesActionNumber) return cookiesActionNumber;

  return '';
};

const getMicroSiteCaseValues = (): AgencyDetails | null => {
  /* Case Microsites (partners/kooperation) pages: Exists  appEventData with marketing object  */
  const marketingActionNumber = getMarketingValues(marketingActionNumberKey);
  const marketingAgentNumber = getMarketingValues(marketingAgentNumberKey);
  if (marketingActionNumber && marketingAgentNumber) {
    return {
      actionNumber: marketingActionNumber,
      agentNumber: marketingAgentNumber,
      isMicroSite: true,
    };
  } else {
    return null;
  }
};

const getAEMCaseValues = (): AgencyDetails | null => {
  /*Case Agent Page (AEM) pages: Exists agencyData  */
  const _oenrNumber = getAgencyDataValues();
  const _actionNrInCookies = getActionNumberInCookies();
  if (_actionNrInCookies && _oenrNumber) {
    return {
      actionNumber: _actionNrInCookies,
      agentNumber: _oenrNumber,
      isMicroSite: false,
    };
  } else {
    return null;
  }
};

const getSiteCoreCaseValues = (
  marketingActionNumber: string,
  agentNumberQuery: string | null,
): AgencyDetails | null => {
  /*Case Agent Pages (Sitecore) pages: adh_oenr && adh_pnr are present in the url with values   */
  if (!agentNumberQuery) return null;

  return {
    actionNumber: marketingActionNumber,
    agentNumber: agentNumberQuery,
    isMicroSite: false,
  };
};

const useGetAgencyValues = (): AgencyDetails => {
  const { agentNumber: agentNumberQuery } = useQueryParams();

  const [actionNumber, setActionNumber] = useState<
    AgencyDetails['actionNumber'] | null
  >(null);
  const [agentNumber, setAgentNumber] =
    useState<AgencyDetails['agentNumber']>(null);
  const [isMicroSite, setIsMicroSite] =
    useState<AgencyDetails['isMicroSite']>(false);

  useEffect(() => {
    const getAgentMetadata = (): AgencyDetails => {
      const actionNumber = getDefaultActionNumber();

      const siteCoreValues = getSiteCoreCaseValues(
        actionNumber,
        agentNumberQuery,
      );
      if (siteCoreValues !== null) return { ...siteCoreValues };

      const microSiteValues = getMicroSiteCaseValues();
      if (microSiteValues !== null) return { ...microSiteValues };

      const aemValues = getAEMCaseValues();
      if (aemValues !== null) return { ...aemValues };

      return {
        actionNumber,
        agentNumber: null,
        isMicroSite: false,
      };
    };

    const {
      actionNumber: _providedActionNr,
      agentNumber: _providedAgentNr,
      isMicroSite: _providedPageType,
    } = getAgentMetadata();

    setActionNumber(_providedActionNr);
    setAgentNumber(_providedAgentNr);
    setIsMicroSite(_providedPageType);
  }, []);

  return {
    actionNumber: actionNumber as string,
    agentNumber,
    isMicroSite,
  };
};
export default useGetAgencyValues;
