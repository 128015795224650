import FormSection from '@eg/elements/FormSection';

import styled from 'styled-components';

import { sizes } from '../../../styles/tokens';

const FormSectionCustom = styled(FormSection)`
  &&&& {
    border: 0;
    padding-bottom: 0;
  }

  &&& > section.ee_form-section {
    padding-top: ${sizes.space.PX_08};
  }
`;

export default FormSectionCustom;
