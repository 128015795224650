import { createResponseComposition, context } from 'msw';

import {
  URL_BASE,
  BASE_PATH,
  MOCK_SERVICES_TIMEOUT,
} from '../../config/environment';
import { personIdsMock } from '../constants';

export const corsHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Allow-Methods': '*',
};

export const getUrl = (url) => {
  const baseUrl = process.env.MOCKED_E2E === 'true' ? BASE_PATH : URL_BASE;
  return `${baseUrl}${url}`;
};

export const getPersonId = () => {
  const person = Array.from(personIdsMock).find((elem) => {
    const isUsed = elem[1];
    return isUsed === false;
  });

  if (!person) {
    return `id:${new Date().getTime()}`;
  }

  const [personId] = person;
  personIdsMock.set(personId, true);

  return personId;
};

export const resDefault = createResponseComposition(
  {
    delay: MOCK_SERVICES_TIMEOUT,
  },
  [context.set(corsHeaders)],
);

export const resWithDelay = (delay: number) => {
  return createResponseComposition({
    delay,
  });
};
