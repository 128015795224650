import { rest } from 'msw';
import { BirthdateData, CrudBirthdatesRequest } from 'ppz-otr-common';

import { BIRTHDATES_API } from '../../services';
import { getUrl, resDefault } from '../utils';

import { getCrudResponse, getValidateResponse } from './responses/';

const validateUrl = getUrl(BIRTHDATES_API.VALIDATE_BIRTHDATE);
const validateHandler = rest.post(validateUrl, async (req, res, ctx) => {
  const request = await req.json<BirthdateData[]>();
  return resDefault(ctx.status(201), ctx.json(getValidateResponse(request)));
});

const crudUrl = getUrl('birthdates/:businessId');
const crudHandler = rest.post(crudUrl, async (req, res, ctx) => {
  const request = await req.json<CrudBirthdatesRequest>();
  return resDefault(ctx.status(201), ctx.json(getCrudResponse(request)));
});

export const handlers = [validateHandler, crudHandler];
export default handlers;
