import {
  APPOINTMENT_TYPE,
  ClickableElements,
  ConversionTypes,
  VIEWS,
} from '../../../types';

import { round } from './common';

export const getCalculatedValue = (price: number) => round(price * 12) || 0; // Calculated turnover per year

export const getCalculatedValueReduced = (total: number, initial: number) =>
  round(total * 6 + initial * 6) || 0; // Calculated turnover per year, price reduction should be contained here (first 6 month reduced)

export const isConversionTypedPage = (view: VIEWS): boolean => {
  const conversionTypedPages = [
    VIEWS.initialSelection,
    VIEWS.contribution,
    VIEWS.personalData,
    VIEWS.summary,
    VIEWS.bankDetails,
    VIEWS.feedback,
  ];

  return conversionTypedPages.includes(view);
};

export const getConversionType = (
  view: VIEWS,
  appointmentType: APPOINTMENT_TYPE | null,
  element: ClickableElements | null,
): ConversionTypes => {
  const conversionTypes = {
    [VIEWS.initialSelection]: ConversionTypes.START,
    [VIEWS.myself]: ConversionTypes.SALE,
    [VIEWS.myselfAndMore]: ConversionTypes.SALE,
    [VIEWS.others]: ConversionTypes.SALE,
    [VIEWS.insuranceBeginning]: ConversionTypes.SALE,
    [VIEWS.contribution]: ConversionTypes.CALCULATION,
    [VIEWS.personalData]: getPersonalDataConversionType(element),
    [VIEWS.summary]: ConversionTypes.FINAL_CHECK,
    [VIEWS.bankDetails]: getBankConversionType(element),
    [VIEWS.finalize]: ConversionTypes.SALE,
    [VIEWS.feedback]: getFinalConversionType(appointmentType),
  };

  return conversionTypes[view];
};

const getPersonalDataConversionType = (element: ClickableElements | null) => {
  if (!element) return ConversionTypes.PERSONAL_DATA;

  const isError = element === ClickableElements.EventAddressValidationError;
  const isCorrected =
    element === ClickableElements.EventAddressValidationCorrected;

  if (isError) return ConversionTypes.PERSONAL_DATA_FAILED;
  if (isCorrected) return ConversionTypes.PERSONAL_DATA_CORRECTED;

  return ConversionTypes.PERSONAL_DATA_SUCCESS;
};

const getBankConversionType = (element: ClickableElements | null) => {
  if (!element) return ConversionTypes.BANK_DATA;

  const isStart = element === ClickableElements.EventIBANValidationStart;
  const isSuccess = element === ClickableElements.EventIBANValidationSuccess;

  if (isStart) return ConversionTypes.IBAN_START;
  if (isSuccess) return ConversionTypes.IBAN_SUCCESS;

  return ConversionTypes.BANK_DATA;
};

const getFinalConversionType = (appointmentType: APPOINTMENT_TYPE | null) => {
  switch (appointmentType) {
    case APPOINTMENT_TYPE.email:
      return ConversionTypes.EMAIL;
    case APPOINTMENT_TYPE.pdf:
      return ConversionTypes.PDF;
    case APPOINTMENT_TYPE.post:
      return ConversionTypes.POST;
    case null:
    default:
      return ConversionTypes.SALE;
  }
};
