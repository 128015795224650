import { useEffect, useState, useReducer, useRef, createRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import ControlWithHint from '@eg/elements/ControlWithHint';
import FormSection from '@eg/elements/FormSection';
import RadioGroupRow from '@eg/elements/RadioGroupRow';

import {
  OFFER_ROLES,
  Address,
  Person,
  Identification,
  ContactElement,
  ShippingWay,
} from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/personalData';
import { ActionButtons } from '../../components/Common';
import { useInsuranceUpdate, useInsurance } from '../../context/InsuranceCtx';
import { useLegalUpdate, useLegal } from '../../context/LegalCtx';
import { useLoading, useLoadingUpdate } from '../../context/LoadingCtx';
import { usePerson, usePersonUpdate } from '../../context/PersonCtx';
import { useTariff } from '../../context/TariffCtx';
import {
  useGetAEMConsentDetails,
  useGetAEMOfferStatus,
  useTracking,
} from '../../hooks';
import commonLiterals from '../../language/src/common';
import literals from '../../language/src/personal-data';
import { PageLayout } from '../../layouts';
import { ValidatePersonalDataResponse } from '../../services/personalData';
import {
  Icon,
  SingleButton,
  VIEWS,
  CHECKOUT_TYPE,
  APPOINTMENT_TYPE,
  ClickableElements,
  ErrorTypes,
} from '../../types';
import { scrollToError } from '../../utils';
import { errorHandler } from '../../utils/handlers';
import { getLiteral } from '../../utils/literals';
import { generatePDF, submit } from '../../utils/submit';

import { Consent } from './components/Consent';
import OfferByEmail from './components/OfferByEmail';
import PersonalDataForm from './components/PersonalDataForm';
import { FormType } from './types';
import {
  updateConsentContact,
  consentContactReducer,
  validate,
  ValidateParams,
  UpdateParams,
  update,
  SendShippingAddress,
  sendShippingAddress,
  CheckFormErrors,
  checkFormErrors,
} from './utils';
import { getInsuranceOwnerTitle, getInsuredPersonTitle } from './utils/helpers';

const PersonalData = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { isAppInitialized } = useLoading();
  const { temporalProducts } = useTariff();
  const { setLoading, setProcessing } = useLoadingUpdate();
  const { situation, businessId, checkoutType } = useInsurance();
  const { showConsent } = useLegal();
  const { setCheckoutType, setAppointmentType } = useInsuranceUpdate();
  const { setShowConsent: updateLegalConsent } = useLegalUpdate();
  const {
    spcsPersons,
    persons,
    insuranceOwnerId,
    getConsentInfo,
    errorOnPersonalAddress,
    autoCorrectedAddresses,
  } = usePerson();
  const { setSpcsPersons, setPersons, setPersonById } = usePersonUpdate();
  const {
    setErrorOnPersonalAddress,
    setShowErrorOnEmptyPersonalFields,
    setAutoCorrectedAddresses,
  } = usePersonUpdate();
  const { privacyConsentContent, hideConsentSection } =
    useGetAEMConsentDetails();
  const { fullOfferDisabled } = useGetAEMOfferStatus();
  const { setFormSubmitted } = useInsuranceUpdate();
  const { setTrackingClick, setTrackingError } = useTracking();

  const isMounted = useRef<boolean>(false);
  const currentPath = useRef<CHECKOUT_TYPE>(
    checkoutType || CHECKOUT_TYPE.online,
  );
  const currentAppointment = useRef<APPOINTMENT_TYPE | undefined>();

  const trackingTimeout = useRef<NodeJS.Timeout | null>(null);
  const genderRef = createRef<RadioGroupRow>();
  const nameRef = createRef<ControlWithHint>();
  const lastNameRef = createRef<ControlWithHint>();
  const streetNameRef = createRef<ControlWithHint>();
  const streetNumberRef = createRef<ControlWithHint>();
  const postalCodeRef = createRef<ControlWithHint>();

  const [consentContact, dispatchConsentContact] = useReducer(
    consentContactReducer,
    getConsentInfo(),
  );
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showLegalConsent, setShowLegalConsent] = useState(
    showConsent && !hideConsentSection,
  );

  const [showOfferByEmail, setShowOfferByEmail] = useState(false);
  const [offerByEmail, setOfferByEmail] = useState<ContactElement>(
    consentContact.email!,
  );
  const [isValidEmailOffer, setIsValidEmailOffer] = useState(true);

  const listOfPersons = Array.from(spcsPersons.values()).map(
    (person) => person,
  );
  const insuredPersons = listOfPersons.filter(
    ({ role }) => role === OFFER_ROLES.insuredPerson,
  );

  const emailValue = persons.get(insuranceOwnerId)?.emailOffer ?? '';
  const isOnlineType = CHECKOUT_TYPE.online === currentPath.current;

  useEffect(() => {
    if (!isOnlineType) {
      setShowLegalConsent(true);
    }
    isMounted.current = true;
    currentAppointment.current = undefined;
    return () => {
      isMounted.current = false;
      trackingTimeout.current && clearTimeout(trackingTimeout.current);
      trackingTimeout.current = null;
    };
  }, []);

  useEffect(() => {
    const emailValue = persons.get(insuranceOwnerId)?.emailOffer ?? '';
    setOfferByEmail({
      valid: isValidEmailOffer,
      value: emailValue,
    });
  }, [persons, insuranceOwnerId, isValidEmailOffer]);

  useEffect(() => {
    const { email, phone } = consentContact;

    if (email) setShowEmailError(false);
    if (phone) setShowPhoneError(false);
  }, [consentContact]);

  useEffect(() => {
    if (isAppInitialized) {
      dispatchConsentContact(updateConsentContact(getConsentInfo()));
    }
  }, [isAppInitialized, getConsentInfo]);

  useEffect(() => {
    updateLegalConsent(showLegalConsent);
  }, [showLegalConsent, updateLegalConsent]);

  useEffect(() => {
    if (autoCorrectedAddresses.length > 0) {
      setTrackingClick(ClickableElements.EventAddressValidationCorrected);
    }
  }, [autoCorrectedAddresses]);

  useEffect(() => {
    if (errorOnPersonalAddress.length > 0) {
      const _error = errorOnPersonalAddress.find((error) => error.code !== '');
      setTrackingClick(
        ClickableElements.EventAddressValidationError,
        undefined,
        _error?.code,
      );
    }
  }, [errorOnPersonalAddress]);

  const finalizeOfferActions = () => {
    setProcessing(false);
    setFormSubmitted(true);
    navigate(VIEWS.feedback);
  };

  const infoIcon: Icon = {
    show: true,
    label: getLiteral(literals.tooltipLabel),
    isModal: true,
    bold: true,
    children:
      privacyConsentContent !== '' ? (
        <div dangerouslySetInnerHTML={{ __html: privacyConsentContent }} />
      ) : null,
    onToggledOpen: (isOpen) =>
      isOpen && setTrackingClick(ClickableElements.TooltipPrivacyInfo),
  };

  const onGenericError = () => {
    if (isMounted.current) {
      setProcessing(false);
      setLoading(false);
    }
    navigate(VIEWS.error);
  };

  const onChangeHandler = (
    id: Person['id'],
    data: Identification | Address,
    type: FormType,
  ) => {
    let _person: Partial<Person> = {};

    if (type === 'personal') {
      _person = { ...(data as Identification) };
    } else {
      _person['address'] = data as Address;
    }

    setPersonById(id, _person);
  };

  const handleDiffAddressSelect = (id: Person['id'], checked: boolean) => {
    setPersonById(id, {
      diffAddress: checked,
    });
  };

  const getInsuranceTitle = (singlePerson: Person, personNumber: number) => {
    const { role } = singlePerson;
    const isInsuranceOwner = role === OFFER_ROLES.insuranceOwner;

    if (isInsuranceOwner) {
      return getInsuranceOwnerTitle();
    }

    const order = insuredPersons.length > 1 ? personNumber : undefined;
    return getInsuredPersonTitle(situation, order);
  };

  const updateCheckoutType = (type: CHECKOUT_TYPE) => {
    currentPath.current = type;
    setCheckoutType(type);
  };

  const updateAppointmentType = (type: APPOINTMENT_TYPE) => {
    currentAppointment.current = type;
    setAppointmentType(type);
  };

  const trackingFormErrors = (message: string) => {
    setTrackingError(ErrorTypes.FORMFIELD, { message });
  };

  const checkForm = () => {
    const params: CheckFormErrors = {
      persons,
      path: currentAppointment.current,
      status: {
        consent: showLegalConsent ? consentContact : undefined,
        byOffer: showOfferByEmail ? offerByEmail : undefined,
      },
      callbacks: {
        showEmptyErrors: setShowErrorOnEmptyPersonalFields,
        showEmailError: setShowEmailError,
        showPhoneError: setShowPhoneError,
      },
    };

    const gotErrors = checkFormErrors(params);
    if (gotErrors) {
      const formInputs = [
        genderRef.current,
        nameRef.current,
        lastNameRef.current,
        streetNameRef.current,
        streetNumberRef.current,
        postalCodeRef.current,
      ];

      // This timeout is needed to read error messages after the current render.
      // Without this timeout, the errors are still not readable.
      trackingTimeout.current = setTimeout(() => {
        formInputs.forEach((input) => {
          if (input) {
            const { props } = input;
            const { error } = props;
            error && typeof error === 'string' && trackingFormErrors(error);
          }
        });
      }, 100);

      const { input, index } = gotErrors;
      scrollToError(input, index);
      throw new Error();
    }
  };

  const checkLegalConsent = () => {
    if (!showLegalConsent) {
      dispatchConsentContact(updateConsentContact(getConsentInfo()));
      setShowLegalConsent(true);
      throw new Error();
    }
  };

  const validateFormData = async (): Promise<
    ValidatePersonalDataResponse[]
  > => {
    const params: ValidateParams = {
      id: insuranceOwnerId as string,
      businessId,
      persons,
      consentContact,
      status: {
        showLegalConsent,
        showOfferByEmail,
      },
      callbacks: {
        setShowEmailError,
        setShowPhoneError,
        setErrorOnPersonalAddress,
        setAutoCorrectedAddresses,
        setSpcsPersons,
        setPersons,
      },
    };
    return await validate(params);
  };

  const updateData = async (
    personalData: ValidatePersonalDataResponse[],
  ): Promise<void> => {
    const params: UpdateParams = {
      businessId,
      persons,
      situation,
      personalData,
      callbacks: { setSpcsPersons },
    };
    return await update(params);
  };

  const checkEmail = () => {
    if (showLegalConsent) {
      const { email: consentEmail } = consentContact;

      if (!consentEmail) {
        setShowLegalConsent(false);
        setShowOfferByEmail(true);
        throw new Error();
      } else {
        const { value } = consentEmail;
        if (!value) {
          setShowLegalConsent(false);
          setShowOfferByEmail(true);
          throw new Error();
        }
      }
    } else if (showOfferByEmail) {
      const { value: offerEmail } = offerByEmail;
      if (!offerEmail) {
        const { defaultMessage: message } = commonLiterals.emailErrorMessage;
        setTrackingError(ErrorTypes.FORMFIELD, { message });
        setShowEmailError(true);
        throw new Error();
      }
    } else if (hideConsentSection) {
      setShowOfferByEmail(true);
      throw new Error();
    } else {
      setShowLegalConsent(true);
      throw new Error();
    }
  };

  const setShippingAddress = async (): Promise<void> => {
    const params: SendShippingAddress = {
      id: insuranceOwnerId as string,
      businessId,
      consentContact,
      offerByEmail,
      status: {
        showLegalConsent,
        showOfferByEmail,
      },
      callbacks: { setShowEmailError },
    };
    return await sendShippingAddress(params);
  };

  const onClickNext = async () => {
    try {
      setTrackingClick(ClickableElements.ButtonOnlineAllServices);
      updateCheckoutType(CHECKOUT_TYPE.online);
      checkForm(); // consent contact is not mandatory
      !hideConsentSection && checkLegalConsent(); // display consent contact

      setLoading(true);
      setAutoCorrectedAddresses([]);
      const validatedData = await validateFormData(); // validate form data
      await updateData(validatedData); // save form data
      setTrackingClick(ClickableElements.EventAddressValidationSuccess);
      setErrorOnPersonalAddress([]);
      navigate(VIEWS.summary); // go to Summary page
    } catch (error: unknown) {
      errorHandler(onGenericError, error);
    } finally {
      isMounted.current && setLoading(false);
    }
  };

  const onClickOffer = async () => {
    setTrackingClick(ClickableElements.ButtonRequestOffer);
    const mustShowConsent = !hideConsentSection && !showLegalConsent;

    mustShowConsent && setShowLegalConsent(true); // display consent
    updateCheckoutType(CHECKOUT_TYPE.appointment); // display custom offer buttons
  };

  const onClickEmail = async () => {
    try {
      setTrackingClick(ClickableElements.ButtonRequestOfferEmail);
      updateAppointmentType(APPOINTMENT_TYPE.email);
      checkForm();
      checkEmail();

      setLoading(true);
      setAutoCorrectedAddresses([]);
      const validatedData = await validateFormData();
      await updateData(validatedData);
      setTrackingClick(ClickableElements.EventAddressValidationSuccess);
      await setShippingAddress();
      setLoading(false);

      setProcessing(true);
      await submit(onGenericError, businessId, ShippingWay.EMAIL);
      finalizeOfferActions();
    } catch (error: unknown) {
      errorHandler(onGenericError, error);
    } finally {
      isMounted.current && setLoading(false);
      isMounted.current && setProcessing(false);
    }
  };

  const onClickDownload = async () => {
    try {
      setTrackingClick(ClickableElements.ButtonRequestOfferPDF);
      updateAppointmentType(APPOINTMENT_TYPE.pdf);
      checkForm();

      setLoading(true);
      setAutoCorrectedAddresses([]);
      const validatedData = await validateFormData();
      await updateData(validatedData);
      setTrackingClick(ClickableElements.EventAddressValidationSuccess);
      setLoading(false);

      setProcessing(true);
      const response = await submit(
        onGenericError,
        businessId,
        ShippingWay.SOFORT_DOWNLOAD,
      );
      const { documentId } = response!;
      await generatePDF(
        businessId,
        documentId,
        CHECKOUT_TYPE.appointment,
        temporalProducts,
      );
      finalizeOfferActions();
    } catch (error: unknown) {
      errorHandler(onGenericError, error);
    } finally {
      isMounted.current && setLoading(false);
      isMounted.current && setProcessing(false);
    }
  };

  const onClickPost = async () => {
    try {
      setTrackingClick(ClickableElements.ButtonRequestOfferPost);
      updateAppointmentType(APPOINTMENT_TYPE.post);
      checkForm();

      setLoading(true);
      setAutoCorrectedAddresses([]);
      const validatedData = await validateFormData();
      await updateData(validatedData);
      setTrackingClick(ClickableElements.EventAddressValidationSuccess);
      setLoading(false);

      setProcessing(true);
      await submit(onGenericError, businessId, ShippingWay.POST);
      finalizeOfferActions();
    } catch (error: unknown) {
      errorHandler(onGenericError, error);
    } finally {
      isMounted.current && setLoading(false);
      isMounted.current && setProcessing(false);
    }
  };

  const onClickBack = async () => {
    setTrackingClick(ClickableElements.ButtonBack);
    if (currentPath.current === CHECKOUT_TYPE.online && showLegalConsent) {
      setShowLegalConsent(false);
    }
    navigate(VIEWS.contribution);
  };

  // Button lists
  const onlineButtonList: SingleButton[] = [
    {
      label: getLiteral(literals.contractButton),
      type: 'primary',
      callbackOnClick: onClickNext,
      id: ABTestingIds.onlineButton,
    },
    {
      label: getLiteral(literals.offerButton),
      type: 'secondary',
      hidden: fullOfferDisabled,
      callbackOnClick: onClickOffer,
      id: ABTestingIds.appointmentButton,
    },
  ];

  const appointmentButtonList: SingleButton[] = [
    {
      label: getLiteral(literals.offerByEmailButton),
      type: 'primary',
      callbackOnClick: onClickEmail,
      id: ABTestingIds.emailButton,
    },
    {
      label: getLiteral(literals.downloadOfferButton),
      type: 'secondary',
      callbackOnClick: onClickDownload,
      id: ABTestingIds.pdfButton,
    },
    {
      label: getLiteral(literals.offerByMailButton),
      type: 'secondary',
      callbackOnClick: onClickPost,
      id: ABTestingIds.postButton,
    },
  ];

  const shownButtonList = isOnlineType
    ? onlineButtonList
    : appointmentButtonList;

  const buttonList: SingleButton[] = [
    ...shownButtonList,
    {
      label: getLiteral(commonLiterals.backButton),
      type: 'text-link',
      callbackOnClick: onClickBack,
      id: ABTestingIds.backButton,
    },
  ];

  return (
    <PageLayout
      title={getLiteral(literals.title)}
      id={ABTestingIds.title}
      subtitle={getLiteral(literals.subtitle)}
      infoIcon={infoIcon}
      textAlign="left"
    >
      <FormSection onSubmit={(event) => event.preventDefault()}>
        <div className="abTesting" id={ABTestingIds.sectionsWrapper}>
          {listOfPersons.map((_person, index) => (
            <PersonalDataForm
              id={index}
              key={`data-form-${_person.id}`}
              person={_person}
              label={getInsuranceTitle(_person, index)}
              onDiffAddress={handleDiffAddressSelect}
              onChange={onChangeHandler}
              idDOM={`${ABTestingIds.personInfoPrefix}${index}`}
              forwardGenderRef={genderRef}
              forwardNameRef={nameRef}
              forwardLastNameRef={lastNameRef}
              forwardStreetNameRef={streetNameRef}
              forwardStreetNumberRef={streetNumberRef}
              forwardZipCodeRef={postalCodeRef}
            />
          ))}
          {!hideConsentSection && showLegalConsent && !showOfferByEmail && (
            <Consent
              consentContact={consentContact}
              showEmailError={showEmailError}
              showPhoneError={showPhoneError}
              onChange={(updatedConsent) => {
                const changes: Partial<Person> = {};
                Object.keys(updatedConsent).forEach((key) => {
                  changes[key] = updatedConsent[key].value;
                });
                setPersonById(insuranceOwnerId, changes);
                dispatchConsentContact(updateConsentContact(updatedConsent));
              }}
            />
          )}
          {showOfferByEmail && !showLegalConsent && (
            <OfferByEmail
              showEmailError={showEmailError}
              onBlur={(email) => {
                const changes: Partial<Person> = {};
                changes.emailOffer = email.value;
                setPersonById(insuranceOwnerId, changes);
                setIsValidEmailOffer(email.valid);
              }}
              onChange={() => setShowEmailError(false)}
              value={emailValue}
            />
          )}
        </div>
        <ActionButtons
          buttonsList={buttonList}
          id={ABTestingIds.buttonContainer}
        />
      </FormSection>
    </PageLayout>
  );
};
export default PersonalData;
