import { useCallback } from 'react';

import { ProductIds, InitOfferRequest } from 'ppz-otr-common';

import { useInsuranceUpdate } from '../../context/InsuranceCtx';
import { postInitOffer } from '../../services';

import useInitBankDetails from './useInitBankDetails';
import useInitContribution from './useInitContribution';
import useInitDates from './useInitDates';
import useInitPersons from './useInitPersons';

const useInitOffer = () => {
  const initDates = useInitDates();
  const { initPersons, initMinMaxInsuredPersons } = useInitPersons();
  const initContribution = useInitContribution();
  const initBankDetails = useInitBankDetails();

  const { setBusinessId } = useInsuranceUpdate();

  const initOffer = useCallback(
    async (
      requestData: InitOfferRequest,
      checkedProducts: ProductIds[] | null = null,
    ) => {
      const offerProducts = checkedProducts ?? requestData.products;
      const initOfferData: InitOfferRequest = {
        ...requestData,
        products: offerProducts,
      };

      const response = await postInitOffer(initOfferData, () => {});

      if (!response || !response.data) {
        return null;
      }

      const { data } = response;
      const {
        businessId,
        persons,
        contributionDetails,
        bankDetails,
        submitted,
        insuredPersonsRanges,
        ...updateDateData
      } = data;
      const { min, max } = insuredPersonsRanges;

      setBusinessId(businessId);
      initDates(updateDateData);
      initMinMaxInsuredPersons(min, max);
      initPersons(persons);
      initContribution(contributionDetails);
      initBankDetails(persons, bankDetails);
      return submitted;
    },
    [
      setBusinessId,
      initContribution,
      initDates,
      initPersons,
      initBankDetails,
      initMinMaxInsuredPersons,
    ],
  );

  return initOffer;
};

export default useInitOffer;
