import styled, { StyledComponent } from 'styled-components';

interface SummaryGridProps {
  children: React.ReactNode;
  styledComponent?: StyledComponent<any, any>;
  id?: string;
}

const defaultWrapper = styled.div``;

const SummaryGrid = ({ children, styledComponent, id }: SummaryGridProps) => {
  const Wrapper = styledComponent ?? defaultWrapper;
  return (
    <Wrapper className="esc_grid abTesting" data-testid="esc_grid" id={id}>
      <div className="esc_grid__wrapper" data-testid="esc_grid__wrapper">
        {children}
      </div>
    </Wrapper>
  );
};

export default SummaryGrid;
