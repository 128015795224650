import { defineMessages } from 'react-intl';

export default defineMessages({
  summaryTitle: {
    id: 'sidebar.summary.title',
    defaultMessage: 'Ihr Versicherungsschutz',
  },
  summaryBegin: {
    id: 'sidebar.summary.insurance-begin',
    defaultMessage: 'Versicherungsbeginn',
  },
  summaryContributionPerMonth: {
    id: 'sidebar.summary.total-contribution-per-month',
    defaultMessage: 'Gesamtbeitrag pro Monat',
  },
  tariffSummaryStart: {
    id: 'sidebar.summary.starting-price',
    defaultMessage: 'Sie zahlen vom 1. bis 6. Monat',
  },
  tariffSummaryFinal: {
    id: 'sidebar.summary.tariff-contribution',
    defaultMessage: 'Sie zahlen ab dem 7. Monat',
  },
  tariffSummaryCommon: {
    id: 'sidebar.summary.tariff-common',
    defaultMessage: 'Sie zahlen',
  },
  tariffBreakdownStart: {
    id: 'sidebar.breakdown.starting-price',
    defaultMessage: 'Startbeitrag vom 1. bis 6. Monat',
  },
  tariffBreakdownFinal: {
    id: 'sidebar.breakdown.tariff-contribution',
    defaultMessage: 'Tarifbeitrag ab dem 7. Monat',
  },
  tariffBreakdownCommon: {
    id: 'sidebar.breakdown.tariff-common',
    defaultMessage: 'Sie zahlen',
  },
  breakDownTitle: {
    id: 'sidebar.breakdown.title',
    defaultMessage: 'Ihre Angaben',
  },
});
