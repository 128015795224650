import { FormattedMessage } from 'react-intl';

import Benefit from '@eg/elements/Benefit';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';

import styled from 'styled-components';

import literals from '../../../language/src/bank-details';
import { sizes } from '../../../styles/tokens';
import { BankDetails } from '../types';

interface Props {
  loading: boolean;
  isValid: boolean;
  details: BankDetails;
}

const SuccessIBANCardWrapper = styled.div`
  margin-top: ${sizes.space.PX_32};
`;
const SuccessIbanCard = ({ loading, isValid, details }: Props) => {
  if (loading || !isValid) return null;
  if (!details.bic || !details.bankName) return null;

  const { bankName, bic } = details;

  return (
    <SuccessIBANCardWrapper>
      <Benefit icon={CheckColorIcon}>
        <FormattedMessage {...literals.successNotification} />
        <p>
          <FormattedMessage {...literals.successBicLabel} />: {bic} /{' '}
          <FormattedMessage {...literals.successBankName} />: {bankName}
        </p>
      </Benefit>
    </SuccessIBANCardWrapper>
  );
};
export { SuccessIbanCard };
