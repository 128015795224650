import styled from 'styled-components';

import { sizes } from '../styles/tokens';
import { TypeTextAlign } from '../types';

interface Props {
  textAlign?: TypeTextAlign;
}

const Content = styled.div<Props>`
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  margin: ${sizes.space.PX_16} 0;
`;

export default Content;
