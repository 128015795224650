import { UseValidateParams } from '../hooks/useValidateInput';

/**
 * In addition to native validation, checks that domain has dot.
 * @param email - email address
 * @param nativeValid - native validation
 * @returns true if email is valid and has domain with dot.
 */
export const isValidEmailAddress = (
  email: string,
  nativeValid: boolean,
): boolean => {
  if (!nativeValid) {
    return false;
  }

  const splitEmail = email.split('@');
  const domain = splitEmail[1];

  return domain !== undefined && domain.indexOf('.') > -1;
};

export const validateEmailOnChange: UseValidateParams['validateValueFn'] = (
  value: string,
  event,
  input,
) => {
  const inputTypeMismatch = input ? input.validity.typeMismatch : false;
  const typeMismatch = event
    ? event.target.validity.typeMismatch
    : inputTypeMismatch;
  return validateEmail(value, typeMismatch);
};

export const validateEmail = (value: string, typeMismatch: boolean) => {
  const email = replaceNonASCII(value);
  const isNativeValid = !typeMismatch;
  return isValidEmailAddress(email, isNativeValid);
};

export const replaceNonASCII = (str: string) => {
  return str.replace(/[^\x00-\x7F]/g, ''); // eslint-disable-line no-control-regex
};
