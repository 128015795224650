import { useState } from 'react';

import { BirthdateMap } from '../types';

interface Props {
  dateValues?: BirthdateMap;
  disabled?: boolean;
}

const useBirthdateState = ({
  dateValues: defaultDateValues,
  disabled: defaultDisabled,
}: Props = {}) => {
  const [dateValues, setDateValues] = useState<BirthdateMap>(
    defaultDateValues ?? new Map(),
  );
  const [disabled, setDisabled] = useState<boolean>(defaultDisabled ?? true);

  return {
    dateValues,
    disabled,
    setDateValues,
    setDisabled,
  };
};

export default useBirthdateState;
