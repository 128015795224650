import { IntlProvider } from 'react-intl';

import { Provider as ErgoElementsProvider } from '@eg/elements/Provider';

import DatesProvider from '../../context/DatesCtx';
import { InsuranceProvider } from '../../context/InsuranceCtx';
import IzsIntegrationProvider from '../../context/IzsIntegrationCtx';
import LegalProvider from '../../context/LegalCtx';
import LoadingProvider from '../../context/LoadingCtx';
import PersonProvider from '../../context/PersonCtx';
import TariffProvider from '../../context/TariffCtx';
import { locale } from '../../language';
import deLiterals from '../../language/de.json';
import { Bold } from '../../layouts/Common';

interface AppProviderProps {
  children?: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <IntlProvider
      messages={deLiterals}
      locale={locale}
      defaultLocale={locale}
      defaultRichTextElements={{
        bold: (chunks) => <Bold>{chunks}</Bold>,
        br: () => <br />,
      }}
    >
      <ErgoElementsProvider theme="ergo-one">
        <InsuranceProvider>
          <DatesProvider>
            <LegalProvider>
              <TariffProvider>
                <PersonProvider>
                  <IzsIntegrationProvider>
                    <LoadingProvider>{children}</LoadingProvider>
                  </IzsIntegrationProvider>
                </PersonProvider>
              </TariffProvider>
            </LegalProvider>
          </DatesProvider>
        </InsuranceProvider>
      </ErgoElementsProvider>
    </IntlProvider>
  );
};

export default AppProvider;
