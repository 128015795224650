import { useCallback, useEffect } from 'react';

import { AxiosInstance } from 'axios';

import { useTracking } from '../../hooks';
import { ErrorTypes } from '../../types';

const useHttpResponse = (http: AxiosInstance) => {
  const { setTrackingError } = useTracking();

  const errorHandler = (error: any) => {
    const gotMessage = error.hasOwnProperty('message');
    const gotResponse = error.hasOwnProperty('response');
    const gotRequest = error.hasOwnProperty('request');

    if (gotMessage && gotResponse && gotRequest) {
      const { message } = error;
      const { response, request } = error;

      const gotStatus = response.hasOwnProperty('status');

      if (gotStatus) {
        const { status: code } = response;
        const { responseURL: url } = request;

        setTrackingError(ErrorTypes.GENERIC, { message, code, url });
      }
    }

    Promise.reject(error);
  };

  useEffect(() => {
    http.interceptors.response.use((response) => response, errorHandler);
  }, [http]);
};

export default useHttpResponse;
