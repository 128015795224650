import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import { INSURANCE_TYPE } from 'ppz-otr-common';

import { useInsurance } from '../../context/InsuranceCtx';
import useRouteGuard from '../../hooks/useRouteGuard';
import { VIEWS } from '../../types';
import { getOldPath, pathDivider } from '../../utils';
import BankDetails from '../../views/BankDetails/BankDetails';
import InsuranceOwnerBirthdate from '../../views/BirthDates/InsuranceOwnerBirthdate';
import InsuredPersonBirthdates from '../../views/BirthDates/InsuredPersonBirthdates';
import { Contribution } from '../../views/Contribution';
import Feedback from '../../views/Feedback/Feedback';
import Finalize from '../../views/Finalize/Finalize';
import GeneralError from '../../views/GeneralError/GeneralError';
import InsuranceBeginning from '../../views/InsuranceBeginning/InsuranceBeginning';
import InsuranceSituation from '../../views/InsuranceSituation/InsuranceSituation';
import PersonalData from '../../views/PersonalData/PersonalData';
import Summary from '../../views/Summary/Summary';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const routes: Record<string, JSX.Element> = {
  [VIEWS.initialSelection]: <InsuranceSituation />,
  [VIEWS.myself]: <InsuranceOwnerBirthdate />,
  [VIEWS.myselfAndMore]: (
    <InsuredPersonBirthdates type={INSURANCE_TYPE.myselfAndMore} />
  ),
  [VIEWS.others]: <InsuredPersonBirthdates type={INSURANCE_TYPE.others} />,
  [VIEWS.insuranceBeginning]: <InsuranceBeginning />,
  [VIEWS.contribution]: <Contribution />,
  [VIEWS.personalData]: <PersonalData />,
  [VIEWS.summary]: <Summary />,
  [VIEWS.bankDetails]: <BankDetails />,
  [VIEWS.finalize]: <Finalize />,
  [VIEWS.feedback]: <Feedback />,
  [VIEWS.error]: <GeneralError />,
};

const DynamicRoute = () => {
  const location = useLocation();
  const { defaultView } = useRouteGuard();
  const { formSubmitted } = useInsurance();

  if (formSubmitted && !location.pathname.includes(VIEWS.feedback)) {
    return <Navigate to={VIEWS.feedback} replace={true} />;
  }

  const possiblePath = getOldPath(location.pathname);
  if (!possiblePath) {
    return routes[defaultView];
  }
  return routes[pathDivider + possiblePath];
};

const AppRouter = () => {
  const fixedRoutes = [
    {
      path: '*',
      element: <DynamicRoute />,
    },
  ];
  const routeResult = useRoutes(fixedRoutes);

  return <ScrollToTop>{routeResult}</ScrollToTop>;
};

export default AppRouter;
