import {
  AemWindow,
  ClickableElements,
  ErrorProperties,
  ErrorTypes,
  GeneralClickProps,
  VIEWS,
} from '../../types';

import useTrackingEvents from './useTrackingEvents';

const useTracking = () => {
  const {
    getPageLoadedEventData,
    getGeneralClickEventData,
    getGeneralClickError,
  } = useTrackingEvents();

  // Init carbon data layer array if not already present
  const aemWindow = window as AemWindow;
  aemWindow.appEventData = aemWindow.appEventData || [];

  const setTrackingLoadedPage = (page: VIEWS) => {
    const eventData = getPageLoadedEventData(page);
    aemWindow.appEventData.push(eventData);
  };

  const setTrackingClick = (
    element: ClickableElements,
    properties?: GeneralClickProps,
    errorCode?: string,
  ) => {
    const eventData = getGeneralClickEventData(element, properties, errorCode);
    aemWindow.appEventData.push(eventData);
  };

  const setTrackingError = (type: ErrorTypes, properties: ErrorProperties) => {
    const eventData = getGeneralClickError(type, properties);
    aemWindow.appEventData.push(eventData);
  };

  return {
    setTrackingLoadedPage,
    setTrackingClick,
    setTrackingError,
  };
};

export default useTracking;
