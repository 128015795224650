import styled from 'styled-components';

import { colors, sizes } from '../../../styles/tokens';

const BreakdownList = styled.div`
  & > div:not(:last-child) {
    padding-bottom: ${sizes.space.PX_16};
    border-bottom: 1px solid ${colors.LIGHT_GRAY};
  }
`;

export default BreakdownList;
