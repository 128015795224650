import {
  Person,
  ConsentContact,
  Tariffs,
  isOldTariff,
  OldProductIds,
  INSURANCE_TYPE,
} from 'ppz-otr-common';

import storage, { LocalKeys } from '../../../services/storage';
import { TariffOptions, VIEWS } from '../../../types';

import { getYesNo, generatePreallocationKeyValue } from './common';

export type GeneralClickValue = Tariffs[] | INSURANCE_TYPE;

interface GetTariffOptions {
  birthDatePreFilled: boolean;
  situation: INSURANCE_TYPE;
  consent: ConsentContact;
}

export const getNumberOfPersons = (
  persons: Map<string | number | null, Person>,
): number => {
  const personsArray = Array.from(persons);
  const personIds = personsArray.map((item) => item[1].id);
  return personIds.length;
};

export const getTariffOptions = (
  view: VIEWS,
  options: GetTariffOptions,
  value?: GeneralClickValue,
) => {
  const page = view !== VIEWS.error ? view : storage.get(LocalKeys.history);

  const { birthDatePreFilled, situation, consent } = options;

  const calculator: string = TariffOptions.ZAHN;

  // Result at initial selection
  let result = calculator;

  // Add preallocation info: J = preallocation enabled, N = not enabled
  result += `|${generatePreallocationKeyValue()}`;

  let VPA = '';
  let BDPF = '';

  const _situation = value ?? situation;

  if (_situation) {
    const isMyselfAndMore = _situation === INSURANCE_TYPE.myselfAndMore;
    const isOnlyOthers = _situation === INSURANCE_TYPE.others;

    let option = TariffOptions.MYSELF;
    option = isMyselfAndMore ? TariffOptions.MYSELF_AND_OTHERS : option;
    option = isOnlyOthers ? TariffOptions.ONLY_OTHERS : option;

    VPA = `VPA=${option}`;
  }

  result = VPA ? `${result}|${VPA}` : result;

  if (page === VIEWS.initialSelection) return result;

  BDPF = `BDPF=${getYesNo(birthDatePreFilled)}`;

  result += `|${BDPF}`;

  const isAboutDates =
    page === VIEWS.myself ||
    page === VIEWS.myselfAndMore ||
    page === VIEWS.others ||
    page === VIEWS.insuranceBeginning;

  // Result at birthdates or insurance Beginning
  if (isAboutDates) return result;

  const payment = 'Zahlung=1/12';

  result += `|${payment}`;

  // Result at contribution
  if (page === VIEWS.contribution) return result;

  const hasEmail = !!(consent.email?.valid && consent.email.value !== '');
  const hasPhone = !!(consent.phone?.valid && consent.phone.value !== '');
  const consentEmail = `KeweEmail=${getYesNo(hasEmail)}`;
  const consentPhone = `KeweTel=${getYesNo(hasPhone)}`;

  result += `|${consentEmail}|${consentPhone}`;

  // Result from Pesonal data and so on the remaining pages
  if (page === VIEWS.personalData) return result;

  return result;
};

export const sortProducts = (products: string[]) => {
  const options: Intl.CollatorOptions = {
    numeric: true,
    sensitivity: 'base',
  };

  const sortedProducts = products.sort((a, b) =>
    b.localeCompare(a, undefined, options),
  );
  return sortedProducts;
};

export const sortOldTariffs = (products: string[]) => {
  const { 1: exception } = OldProductIds.ZAHN_ZZP.split('_');
  const filteredProducts = products.filter((item) => item !== exception);
  const sortedProducts = filteredProducts.sort((a, b) => a.localeCompare(b));

  if (products.includes(exception)) {
    return [exception, ...sortedProducts];
  }

  return sortedProducts;
};

export const getProductCombination = (products: Tariffs[]): string => {
  const prefix = 'ZAHN_';
  const _products = products.map((item) => item.substring(prefix.length));

  if (isOldTariff(products)) {
    const sortedProducts = sortOldTariffs(_products);
    return sortedProducts.join('_');
  }

  const prevention = _products.filter((item) => item.includes('DV'));
  const protection = _products.filter((item) => item.includes('DS'));

  const sortedPrev = sortProducts(prevention);
  const sortedProt = sortProducts(protection);

  const combination = [sortedPrev[0], sortedProt[0]].filter((item) => item);

  if (combination.length > 1) {
    return `${combination[0]}_${combination[1]}`;
  }

  return combination[0];
};
