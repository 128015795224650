import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { InitProducts, INSURANCE_TYPE } from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/insuranceBeginning';
import { ActionButtons, RadioButtonCard } from '../../components/Common';
import { useDates, useDatesUpdate } from '../../context/DatesCtx';
import { useInsurance } from '../../context/InsuranceCtx';
import { useLoading, useLoadingUpdate } from '../../context/LoadingCtx';
import { useTariff, useTariffUpdate } from '../../context/TariffCtx';
import { useTracking } from '../../hooks';
import commonLiterals from '../../language/src/common';
import literals from '../../language/src/insurance-beginning';
import { PageLayout, MainWrapper, Section } from '../../layouts';
import { updateBeginningDate } from '../../services/insuranceBeginning';
import storage, { LocalKeys } from '../../services/storage';
import {
  RadioCardOption,
  SingleButton,
  VIEWS,
  ClickableElements,
  GeneralClickProps,
} from '../../types';

const RADIOBUTTON_NAME = 'date-selection';

const filterBeginningDates = (
  availableDates: string[],
  setCallback: (dates: RadioCardOption[]) => void,
) => {
  let filteredDates: RadioCardOption[] = [];
  for (let i = 0; i < 3; i++) {
    filteredDates.push({
      label: availableDates[i],
      value: availableDates[i],
      name: RADIOBUTTON_NAME,
      id: `${ABTestingIds.datesPrefix}${i}`,
    });
  }
  setCallback(filteredDates);
};

const formatSelectedDate = (date: string) => {
  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}`;
};

const InsuranceBeginning = () => {
  const { loading } = useLoading();
  const { businessId, situation } = useInsurance();
  const { beginningDates, beginningDateSelected } = useDates();
  const {
    contributionSelected,
    tariffPriceSelected: currentPrice,
    products,
    premiumProducts,
  } = useTariff();
  const {
    setBasicTariffPrices,
    setTariffPriceSelected,
    setPremiumProducts,
    setPremiumTariffPrices,
  } = useTariffUpdate();
  const { setBeginningDateSelected } = useDatesUpdate();
  const { setLoading } = useLoadingUpdate();
  const navigate = useNavigate();
  const [availableDates, setAvailableDates] = useState<RadioCardOption[]>([]);
  const { setTrackingClick } = useTracking();

  const intl = useIntl();

  useEffect(() => {
    const onUnload = () => {
      if (!!beginningDateSelected) {
        storage.set(LocalKeys['beginningDate'], beginningDateSelected);
      }
    };
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  });

  useEffect(() => {
    filterBeginningDates(beginningDates, setAvailableDates);
    if (beginningDateSelected === '' && beginningDates.length > 0) {
      setBeginningDateSelected(beginningDates[0]);
    }
  }, [beginningDates]);

  const _trackingRadioButtons = (date: string) => {
    const radioBtn1 = ClickableElements.RadioButtonBeginningDate_1;
    const radioBtn2 = ClickableElements.RadioButtonBeginningDate_2;
    const radioBtn3 = ClickableElements.RadioButtonBeginningDate_3;

    let element = radioBtn1;
    element = date === beginningDates[1] ? radioBtn2 : element;
    element = date === beginningDates[2] ? radioBtn3 : element;

    const properties: GeneralClickProps = {
      type: 'BeginningDate',
      value: date,
    };

    setTrackingClick(element, properties);
  };

  const genericError = () => {
    setLoading(false);
    navigate(VIEWS.error);
  };

  const saveBeginningDateSelected = (selected: string) => {
    const beginningDate = formatSelectedDate(selected);
    const initialProducts: InitProducts = {
      basic: products,
      premium: premiumProducts,
    };
    return updateBeginningDate(
      businessId,
      { beginningDate, currentPrice, initialProducts },
      genericError,
    );
  };

  const onContinueClick = async () => {
    setTrackingClick(ClickableElements.ButtonNext);
    navigate(VIEWS.contribution);
  };

  const onBackClick = () => {
    setTrackingClick(ClickableElements.ButtonBack);
    if (situation === INSURANCE_TYPE.myself) {
      navigate(VIEWS.myself);
    } else if (situation === INSURANCE_TYPE.myselfAndMore) {
      navigate(VIEWS.myselfAndMore);
    } else {
      navigate(VIEWS.others);
    }
  };

  const updateSelectedDate = async (selected: string) => {
    _trackingRadioButtons(selected);
    setBeginningDateSelected(selected);
    setLoading(true);
    const saveDateResponse = await saveBeginningDateSelected(selected);

    if (!saveDateResponse || !saveDateResponse.data.success) {
      genericError();
      return;
    }

    if (
      saveDateResponse.data.priceUpdated === true &&
      !!saveDateResponse.data.contributionDetails
    ) {
      const { contributionDetails } = saveDateResponse.data;
      setBasicTariffPrices(contributionDetails.basicPrices);
      setPremiumProducts(contributionDetails.premiumProducts);
      setPremiumTariffPrices(contributionDetails.premiumPrices);
      if (contributionSelected === 'basic' || contributionSelected === '') {
        setTariffPriceSelected(contributionDetails.basicPrices);
      } else {
        setTariffPriceSelected(contributionDetails.premiumPrices);
      }
    }
    setLoading(false);
  };
  const buttonsList: SingleButton[] = [
    {
      label: intl.formatMessage(commonLiterals.nextButton),
      type: 'primary',
      callbackOnClick: onContinueClick,
      disabled: beginningDateSelected === '',
      id: ABTestingIds.nextButton,
    },
    {
      label: intl.formatMessage(commonLiterals.backButton),
      type: 'text-link',
      callbackOnClick: onBackClick,
      id: ABTestingIds.backButton,
    },
  ];

  return (
    <PageLayout
      title={intl.formatMessage(literals.title)}
      id={ABTestingIds.title}
      textAlign="left"
    >
      <MainWrapper>
        <Section className="abTesting" id={ABTestingIds.datesContainer}>
          {!loading &&
            availableDates.map((date, idx) => {
              return (
                <RadioButtonCard
                  key={idx}
                  id={date.id}
                  name={date.name}
                  value={date.value}
                  label={date.label}
                  selected={beginningDateSelected === date.value}
                  onSelectedCard={updateSelectedDate}
                />
              );
            })}
        </Section>
      </MainWrapper>
      <ActionButtons
        buttonsList={buttonsList}
        id={ABTestingIds.buttonContainer}
      />
    </PageLayout>
  );
};
export default InsuranceBeginning;
