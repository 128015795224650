import { ConsentContact } from 'ppz-otr-common';

import {
  ADDRESS_ACTIONS,
  CONSENT_TYPES,
  EMPTY_ERROR,
  ERROR_ACTIONS,
} from '../types';

export const updateConsentContact = (consent: ConsentContact) => ({
  type: CONSENT_TYPES.UPDATE,
  payload: consent,
});

export const updateStreet = (street: string) => ({
  type: ADDRESS_ACTIONS.UPDATE_STREET,
  payload: street,
});

export const updateStreetNumber = (streetNumber: string) => ({
  type: ADDRESS_ACTIONS.UPDATE_STREET_NUMBER,
  payload: streetNumber,
});

export const updatePostalCode = (postalCode: string) => ({
  type: ADDRESS_ACTIONS.UPDATE_POSTAL_CODE,
  payload: postalCode,
});

export const updateCity = (city: string) => ({
  type: ADDRESS_ACTIONS.UPDATE_CITY,
  payload: city,
});

export const setEmptyStreetError = (error: EMPTY_ERROR) => ({
  type: ERROR_ACTIONS.EMPTY_STREET,
  payload: error,
});

export const setInvalidStreetError = (message: string) => ({
  type: ERROR_ACTIONS.INVALID_STREET,
  payload: message,
});

export const setEmptyStreetNumber = (error: EMPTY_ERROR) => ({
  type: ERROR_ACTIONS.EMPTY_STREET_NUMBER,
  payload: error,
});

export const setEmptyPostalCode = (error: EMPTY_ERROR) => ({
  type: ERROR_ACTIONS.EMPTY_POSTAL_CODE,
  payload: error,
});

export const setInvalidPostalCode = (val: boolean) => ({
  type: ERROR_ACTIONS.INVALID_POSTAL_CODE,
  payload: val,
});

export const setAgentRequiredError = (val: boolean) => ({
  type: ERROR_ACTIONS.AGENT_REQUIRED,
  payload: val,
});
