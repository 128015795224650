import Button from '@eg/elements/Button';

import styled from 'styled-components';

import { sizes } from '../../../styles/tokens';

export const ButtonLink = styled(Button)`
  margin-top: ${sizes.space.PX_32};
  margin-bottom: ${sizes.space.PX_16};
  .ee_button__wrapper {
    padding: 0 !important;
  }
`;

export const WrapperActionButtons = styled.div`
  margin-top: ${sizes.space.PX_32};
`;

export const PriceDescription = styled.span`
  margin-left: ${sizes.space.PX_08};
`;
