import React from 'react';
import { useIntl } from 'react-intl';

import Button from '@eg/elements/Button';
import DownloadIcon from '@eg/elements/Icons/DownloadIcon';

import ABTestingIds from '../../../ABTesting/contribution';
import messages from '../../../language/src/contribution';
import { LinkButtonWrapper } from '../layouts';

interface Props {
  onClick: () => void;
}

export const CustomPDFButton = ({ onClick }: Props) => {
  const intl = useIntl();

  return (
    <LinkButtonWrapper id={ABTestingIds.linkButtonPDF} className="abTesting">
      <Button variant="text-link" iconLeft={DownloadIcon} onClick={onClick}>
        {intl.formatMessage(messages.pdfLink)}
      </Button>
    </LinkButtonWrapper>
  );
};
