import { Environments } from 'ppz-otr-common';
import styled from 'styled-components';

import { ENV, VERSION } from '../../config/environment';
import { colors, sizes } from '../../styles/tokens';

const VersionWrapper = styled.div`
  text-align: center;
  font-size: ${sizes.text.XS};
  color: ${colors.DARKER_GRAY};
  margin: ${sizes.space.PX_16} 0 ${sizes.space.PX_20} 0;
`;

const Version = () => {
  if (ENV === Environments.prod) return null;

  return (
    <VersionWrapper>
      <span>v.{VERSION}</span>
    </VersionWrapper>
  );
};

export default Version;
