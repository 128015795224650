import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';

const HeaderContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column; // Mobile first
  row-gap: ${sizes.space.PX_16};

  @media (min-width: ${breakpoints.M}) {
    flex-direction: row; // Desktop view
  }
`;

export default HeaderContent;
