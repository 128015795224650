import { Tariffs } from 'ppz-otr-common';

import storage, { LocalKeys } from '../services/storage';
import { CHECKOUT_TYPE } from '../types';
import { getPDFSuffixProducts } from '../utils';

export enum RequestedPDF {
  AEM = 'AEM',
  Offer = 'Offer',
}
const defaultPdfName = 'ERGO Versicherungsunterlagen.pdf';
const directContractPrefix = 'Vertragsdokumente';
const requestAQuotePrefix = 'Ihr Angebot Krankentarif';

const getPDFName = (
  checkoutType?: CHECKOUT_TYPE,
  products: Tariffs[] = [],
): string => {
  if (!checkoutType) return defaultPdfName;
  const pdfPrefix =
    checkoutType === CHECKOUT_TYPE.online
      ? directContractPrefix
      : requestAQuotePrefix;
  const suffixProducts = getPDFSuffixProducts(products);
  let pdfName = `_${suffixProducts}.pdf`;
  return pdfPrefix + pdfName;
};

export const downloadPdf = (
  pdf: Blob | MediaSource | string,
  checkoutType?: CHECKOUT_TYPE,
  products?: Tariffs[],
) => {
  const defaultName = getPDFName(checkoutType, products);
  const isStream = typeof pdf !== 'string';
  const fileURL = isStream ? URL.createObjectURL(pdf) : pdf;
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = fileURL;
  link.download = defaultName;
  link.click();
  link.remove();
};
