import { NavigateFunction } from 'react-router-dom';

import { DateInputValue } from '@eg/elements/components/DateInput';

import { BirthdateData } from 'ppz-otr-common';

import { VIEWS } from '../../../types';
import { BirthdateMap } from '../types';

import { hasErrors } from './validation';

export const onChangeDate = (
  dateValues: BirthdateMap,

  date: DateInputValue,
  errors: any,
  key: BirthdateData['id'],
) => {
  const newDateValues = new Map(dateValues);
  newDateValues.set(key, {
    ...dateValues.get(key)!,
    ...date,
    errors: { ...errors },
  });
  return newDateValues;
};

export const onBlurDate = (
  dateValues: BirthdateMap,
  setDisabled: React.Dispatch<boolean>,
) => {
  setDisabled(hasErrors(dateValues));
};

export const onGenericError = (
  setLoading: React.Dispatch<boolean>,
  navigate: NavigateFunction,
) => {
  setLoading(false);
  navigate(VIEWS.error);
};
