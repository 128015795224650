import React from 'react';
import { useIntl } from 'react-intl';

import styled, { css } from 'styled-components';

import commonLiterals from '../../../language/src/common';
import literals from '../../../language/src/sidebar';
import { sizes } from '../../../styles/tokens';
import { SidebarText as Text } from '../layouts';
import { DateType } from '../types';

interface Props {
  type: DateType;
  date: string | undefined;
}

const SidebarDateWrapper = styled.div<{ type: DateType }>`
  ${({ type }) =>
    type === 'birthdate' &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `}
  ${({ type }) =>
    type === 'insurance' &&
    css`
      margin-bottom: ${sizes.space.PX_24};
    `}
`;

const SidebarDate = ({ type, date }: Props) => {
  const intl = useIntl();

  const insuranceBeginLabel = intl.formatMessage(literals.summaryBegin);

  const birthdayLabel = intl.formatMessage(commonLiterals.birthday);

  return (
    <SidebarDateWrapper type={type}>
      {type === 'insurance' && (
        <Text
          variant="secondary"
          text={insuranceBeginLabel}
          margin={sizes.space.PX_08}
        />
      )}
      {type === 'birthdate' && <Text text={birthdayLabel} />}
      <Text text={date} />
    </SidebarDateWrapper>
  );
};

export default SidebarDate;
