import {
  ClickableElements,
  ErrorProperties,
  ErrorTypes,
  GeneralClickProps,
  TrackingEvent,
  TrackingObject,
  VIEWS,
} from '../../types';

import useTrackingEventsData from './useTrackingEventsData';

type GetPageLoadedEventData = (page: VIEWS) => TrackingObject;
type GetGeneralClickEventData = (
  element: ClickableElements,
  properties?: GeneralClickProps,
  errorCode?: string,
) => TrackingObject;
type GetGeneralClickError = (
  type: ErrorTypes,
  properties: ErrorProperties,
) => TrackingObject;

interface TrackingData {
  getPageLoadedEventData: GetPageLoadedEventData;
  getGeneralClickEventData: GetGeneralClickEventData;
  getGeneralClickError: GetGeneralClickError;
}

const useTrackingEvents = (): TrackingData => {
  const {
    getPageProperty,
    getEventDetailsProperty,
    getProductProperty,
    getTransactionProperty,
    getUserProperty,
    getMiscellaneousProperty,
  } = useTrackingEventsData();

  const getPageLoadedEventData = (page: VIEWS): TrackingObject => ({
    event: TrackingEvent.LOAD,
    page: getPageProperty(page),
    eventdetails: getEventDetailsProperty(null, false),
    product: getProductProperty(),
    transaction: getTransactionProperty(null, false),
    user: getUserProperty(),
  });

  const getGeneralClickEventData = (
    element: ClickableElements,
    properties?: GeneralClickProps,
    errorCode?: string,
  ): TrackingObject => ({
    event: TrackingEvent.CLICK,
    page: getPageProperty(),
    eventdetails: getEventDetailsProperty(element, false, errorCode),
    product: getProductProperty(properties),
    transaction: getTransactionProperty(element, false),
    user: getUserProperty(properties),
  });

  const getGeneralClickError = (
    type: ErrorTypes,
    properties: ErrorProperties,
  ): TrackingObject => ({
    event: TrackingEvent.CLICK,
    page: getPageProperty(),
    eventdetails: getEventDetailsProperty(null, true),
    product: getProductProperty(),
    transaction: getTransactionProperty(null, true),
    user: getUserProperty(),
    miscellaneous: getMiscellaneousProperty(type, properties),
  });

  return {
    getPageLoadedEventData,
    getGeneralClickEventData,
    getGeneralClickError,
  };
};

export default useTrackingEvents;
