import { Person } from 'ppz-otr-common';

import HeaderABTestingIds from '../ABTesting/header';
import { VIEWS } from '../types';

export * from './products';
export * from './persons';
export * from './validations';
export * from './aem';
export * from './pdf';

export const pathDivider = '/';

function debounce(fn: Function, timeout: number = 300) {
  let timer: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
}

function debounceImmediate(fn: Function, timeout: number = 300) {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return function (this: any, ...args: any[]) {
    if (!timer) {
      fn.apply(this, args);
    }

    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

const getCurrentHeaderHeight = (): number => {
  const navId = HeaderABTestingIds.aemHeader;
  const mainNav = document.getElementById(navId);
  const headerHeightProd = mainNav ? mainNav.clientHeight : 0;

  const devHeaderId = HeaderABTestingIds.container;
  const devHeader = document.getElementById(devHeaderId);
  const devHeaderHeight = devHeader ? devHeader.clientHeight : 0;

  return headerHeightProd + devHeaderHeight;
};

const scrollToError = (inputName: string, inputId: Person['id']) => {
  const MARGIN_OFFSET = 32;
  const currentHeaderHeight = getCurrentHeaderHeight();
  const inputNode = document.getElementById(`${inputName}-${inputId}`);
  const inputNodeTop = inputNode?.getBoundingClientRect().top;
  const inputOnTemplate =
    inputNodeTop! + (window.pageYOffset - currentHeaderHeight) - MARGIN_OFFSET;
  window.scrollTo({ top: inputOnTemplate, behavior: 'smooth' });
};

const getLastPath = (pathname: string) => {
  const pathList = pathname.split(pathDivider);
  const lastPath = pathList.length > 0 ? pathList[pathList.length - 1] : null;
  if (!lastPath) return null;

  return lastPath;
};

const getUrlWithoutLastPath = (path: string) => {
  if (!path) return path;

  const lastPath = getLastPath(path);
  if (!!lastPath && lastPath.includes('.htm')) {
    return path;
  }

  return path.split(pathDivider).slice(0, -1).join(pathDivider);
};

const getOldPath = (pathname: string): string | null => {
  const appPaths = Object.values(VIEWS).map((view) =>
    view.replace(pathDivider, ''),
  );

  const lastPath = getLastPath(pathname);
  const oldPath = !!lastPath
    ? appPaths.find((pathFromList) => lastPath === pathFromList)
    : null;

  if (!oldPath) return null;

  return oldPath;
};

export {
  debounce,
  debounceImmediate,
  scrollToError,
  getUrlWithoutLastPath,
  getLastPath,
  getOldPath,
};
