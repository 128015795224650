import React from 'react';

import TooltipIcon from '@eg/elements/TooltipIcon';

import styled from 'styled-components';

interface IProps {
  label?: string;
  bold?: boolean;
  isModal?: boolean;
  children?: React.ReactNode;
  onToggledOpen?: (isOpen: boolean) => void;
}

interface ITooltipWrapper {
  bold?: boolean;
}

const TooltipWrapper = styled.div<ITooltipWrapper>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

const TooltipInfoModal = ({
  label,
  bold,
  isModal,
  children,
  onToggledOpen,
}: IProps) => {
  return (
    <TooltipWrapper bold={bold}>
      {label && <label>{label}</label>}
      <TooltipIcon onToggledOpen={onToggledOpen} isModal={isModal}>
        {children}
      </TooltipIcon>
    </TooltipWrapper>
  );
};

export default TooltipInfoModal;
