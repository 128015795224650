import { FocusEvent, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';

import { ContactElement } from 'ppz-otr-common';

import { useValidateInput, useTracking } from '../../hooks';
import commonLiterals from '../../language/src/common';
import { ErrorTypes } from '../../types';
import {
  replaceNonASCII as replaceOnChangeFn,
  validateEmailOnChange as validateValueFn,
} from '../../utils';
import { getLiteral } from '../../utils/literals';

import EmailTooltip from './components/EmailTooltip';
import {
  ContactDescription,
  ContactTitle,
  EmailSectionContainer,
} from './layouts';

interface Props {
  id: string;
  value?: string;
  title?: string;
  description?: string;
  tooltip?: string;
  showError?: boolean;
  onBlur: (email: ContactElement) => void;
  onChange?: (email: ContactElement) => void;
  onError?: () => void;
}

const EmailSection = ({
  id,
  value,
  title,
  description,
  tooltip,
  showError,
  onBlur,
  onChange,
  onError,
}: Props) => {
  const containerInput = useRef<HTMLDivElement>(null);

  const {
    onChangeHandler,
    onBlurHandler,
    onKeyPressHandler,
    inputValue,
    setInputValue,
    inputTouched,
    isValidInput,
    validateWithDOMInfo,
  } = useValidateInput({
    defaultValue: value ?? '',
    delayTouched: true,
    reflectTrimOnInput: true,
    required: false,
    replaceOnChangeFn,
    validateValueFn,
    setTouchedWithEnter: true,
  });

  const { setTrackingError } = useTracking();

  const errorMessage = useMemo(() => commonLiterals.emailErrorMessage, []);

  useEffect(() => {
    /**
     * We must query for real input inside Ergo Elements, because Input
     * component from Ergo Elements is using a deprecated API for its ref prop.
     */
    if (containerInput) {
      const $inputs = containerInput.current?.getElementsByTagName('input');
      if ($inputs?.length) {
        const emailValue = value ?? '';

        // Triggers validate manually for input email special case
        validateWithDOMInfo(emailValue, $inputs[0]);
      }
    }

    value && setInputValue(value);
  }, [value]);

  useEffect(() => {
    onChange?.({
      value: inputValue,
      valid: isValidInput,
    });
  }, [inputValue, isValidInput]);

  const onBlurEmail = (event: FocusEvent<HTMLInputElement>) => {
    if (inputValue && !isValidInput) {
      const { defaultMessage: message } = errorMessage;
      setTrackingError(ErrorTypes.FORMFIELD, { message });
    }

    onBlurHandler(event);
    onBlur({
      value: inputValue,
      valid: isValidInput,
    });
  };

  const getErrorMessage = (hasError: boolean) => {
    const emptyMessage = commonLiterals.emailEmptyMessage;
    if (!hasError) {
      return '';
    }
    return inputValue === ''
      ? getLiteral(emptyMessage)
      : getLiteral(errorMessage);
  };

  const gotError = Boolean((!isValidInput && inputTouched) || showError);

  const literalErrorMessage = getErrorMessage(gotError);

  if (gotError && onError) onError();

  return (
    <EmailSectionContainer className="email-section__container">
      {title && (
        <ContactTitle className="esc_teaser__subhead">
          <FormattedMessage id={title} />
        </ContactTitle>
      )}
      {description && (
        <ContactDescription>
          <FormattedMessage id={description} />
        </ContactDescription>
      )}
      <FormRow
        label={getLiteral(commonLiterals.emailLabel)}
        tooltip={tooltip && <EmailTooltip tooltip={tooltip} />}
      >
        <div ref={containerInput}>
          <ControlWithHint error={literalErrorMessage}>
            <Input
              id={id}
              data-testid="email-section__input"
              type="email"
              placeholder={getLiteral(commonLiterals.emailPlaceholder)}
              value={inputValue}
              aria-label={getLiteral(commonLiterals.emailLabel)}
              onChange={onChangeHandler}
              onBlur={onBlurEmail}
              onKeyPress={onKeyPressHandler}
            />
          </ControlWithHint>
        </div>
      </FormRow>
    </EmailSectionContainer>
  );
};
export default EmailSection;
