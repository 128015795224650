import styled from 'styled-components';

import { sizes } from '../../../styles/tokens';

import FormSectionCustom from './FormSectionCustom';

const PersonalDataForm = styled(FormSectionCustom)`
  &&& {
    padding-bottom: ${sizes.space.PX_48};
  }
`;

export default PersonalDataForm;
