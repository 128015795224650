const enum BankDetailsABTestingIds {
  title = 'ppz701',
  IBANfields = 'ppz702',
  IBANdescription = 'ppz703',
  email = 'ppz704',
  buttonContainer = 'ppz705',
  nextButton = 'ppz706',
  appointmentButton = 'ppz707',
  backButton = 'ppz708',
}

export default BankDetailsABTestingIds;
