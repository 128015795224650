import styled, { css } from 'styled-components';

import { sizes } from '../../../styles/tokens';
import { WarningElementWrapper } from '../types';

const WarningDescriptionWrapper = styled.div<WarningElementWrapper>`
  ${({ isSEPA }) => css`
    font-size: ${isSEPA ? sizes.text.SM : sizes.text.M};
  `}
  margin-top: ${sizes.space.PX_12};
`;

export { WarningDescriptionWrapper };
