import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';

import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';

import { Identification, SpcsSalutation } from 'ppz-otr-common';

import { useTracking } from '../../../hooks';
import literals from '../../../language/src/personal-data';
import { ErrorTypes, PERSON_INPUTS } from '../../../types';
import { getLiteral } from '../../../utils/literals';

interface Props {
  id: number;
  salutation: Identification['gender'];
  forwardGenderRef?: RefObject<RadioGroupRow>;
  gotError: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const GenderCheckBox = ({
  id,
  salutation,
  forwardGenderRef,
  gotError,
  onChange,
}: Props) => {
  const { setTrackingError } = useTracking();

  const genderInputId = `${PERSON_INPUTS.gender}-${id}`;
  const femaleLabel = getLiteral(literals.salutationFemaleLabel);
  const maleLabel = getLiteral(literals.salutationMaleLabel);

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (!gotError) {
      setErrorMessage('');
    } else {
      const message = getLiteral(literals.salutationEmptyError);
      setErrorMessage(message);
      setTrackingError(ErrorTypes.FORMFIELD, { message });
    }
  }, [gotError]);

  return (
    <RadioGroupRow
      ref={forwardGenderRef}
      id={genderInputId}
      data-testid="personal-inputs__gender-error"
      error={errorMessage}
      label={getLiteral(literals.salutationLabel)}
      name={`${id} - ${getLiteral(literals.salutationLabel)}`}
      onChange={onChange}
      value={(salutation ?? '') as string}
    >
      {[SpcsSalutation.Ms, SpcsSalutation.Mr].map(
        (gender: SpcsSalutation, key) => (
          <Radio
            key={key}
            value={gender}
            label={gender === SpcsSalutation.Ms ? femaleLabel : maleLabel}
          />
        ),
      )}
    </RadioGroupRow>
  );
};

export default GenderCheckBox;
