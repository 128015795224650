const enum InsuranceSituationABTestingIds {
  title = 'ppz101',
  radiosContainer = 'ppz102',
  myself = 'ppz103',
  myselfAndMore = 'ppz104',
  others = 'ppz105',
  disclaimer = 'ppz106',
  modal = 'ppz107',
  buttonContainer = 'ppz108',
  nextButton = 'ppz109',
}

export default InsuranceSituationABTestingIds;
