import {
  Person,
  SpcsSalutation,
  Address,
  OFFER_ROLES,
  INSURANCE_TYPE,
} from 'ppz-otr-common';

import commonLiterals from '../../../language/src/common';
import literals from '../../../language/src/summary';
import { getLiteral } from '../../../utils/literals';

export interface FormattedPerson {
  id: Person['id'];
  birthDate: string;
  gender: string;
  fullName: string;
  address: {
    line1: string;
    line2: string;
  };
  email: string | undefined;
  phone: string | undefined;
  role: OFFER_ROLES | null;
}

export const formatPerson = (person: Person): FormattedPerson => {
  const birthDate = getBirthDate(person);
  const gender = getGender(person);
  const fullName = getFullName(person);
  const address = getAddress(person.address);

  return {
    id: person.id,
    birthDate,
    gender,
    fullName,
    address,
    email: person.email,
    phone: person.phone,
    role: person.role,
  };
};

const getBirthDate = ({ day, month, year }: Person) => {
  if (day === '' || month === '' || year === '') {
    return '';
  }
  return `${day}.${month}.${year}`;
};

const getGender = ({ gender }: Person) => {
  const description = {
    [SpcsSalutation.Mr]: getLiteral(commonLiterals.mr),
    [SpcsSalutation.Ms]: getLiteral(commonLiterals.ms),
  };
  return gender === null ? '-' : description[gender];
};

const getFullName = ({ name, lastname }: Person) => {
  if (name === null || lastname === null) {
    return '-';
  }
  return `${name} ${lastname}`;
};

const getAddress = ({ streetNumber, street, postalCode, city }: Address) => {
  if (
    streetNumber === null ||
    street === null ||
    postalCode === null ||
    city === null
  ) {
    return {
      line1: '-',
      line2: '',
    };
  }
  return {
    line1: `${street} ${streetNumber}`,
    line2: `${postalCode} ${city}`,
  };
};

export const transformAddress = ({
  line1,
  line2,
}: FormattedPerson['address']) => {
  return (
    <>
      {line1}
      <br />
      {line2}
    </>
  );
};

export const getInsuranceOwnerTitle = (situation: INSURANCE_TYPE): string => {
  let title: string = '';

  const {
    detailsPersonTitleMySelfOwner: myself,
    detailsPersonTitleTitleMySelfAndMoreOwner: myselfAndMore,
    detailsPersonTitleOthersOwner: others,
  } = literals;

  switch (situation) {
    case INSURANCE_TYPE.myself:
      title = getLiteral(myself);
      break;
    case INSURANCE_TYPE.myselfAndMore:
      title = getLiteral(myselfAndMore);
      break;
    default:
      title = getLiteral(others);
      break;
  }

  return title;
};

export const getInsuredPersonTitle = (
  situation: INSURANCE_TYPE,
  index?: number,
): string => {
  const {
    detailsPersonTitleUniquePersonTitle: unique,
    detailsPersonTitlePersonTitle: ordered,
  } = literals;

  if (!index) {
    return getLiteral(unique);
  }

  const isMyselfAndMore = situation === INSURANCE_TYPE.myselfAndMore;
  const order = isMyselfAndMore ? index + 1 : index;

  return `${order}. ${getLiteral(ordered)}`;
};
