import { postHttp } from './config';
import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

export interface ShippingAddressDto {
  businessId: string;
  personId: string;
  email: string;
}

export const SHIPPING_ADDRESS_API = {
  POST: `${ENDPOINTS.shippingAddress}/email`,
};

export const postShippingAddress = async (
  shippingAddress: ShippingAddressDto,
  errorThrown: HttpOptions['errorThrown'],
) => {
  const params = { url: SHIPPING_ADDRESS_API.POST, errorThrown };
  const body = shippingAddress;
  return await postHttp<ShippingAddressDto, boolean>(params, body);
};
