import { useEffect, useState } from 'react';

import { useLoading } from '../../context/LoadingCtx';
import { useTariff } from '../../context/TariffCtx';
import { AemContent } from '../../types';
import { getProductComparisonContentPath, setPDFContent } from '../../utils';
const useGetProductsPDF = () => {
  const { isAppInitialized } = useLoading();
  const { products } = useTariff();
  const [currentPDF, setCurrentPDF] = useState('');

  useEffect(() => {
    if (isAppInitialized) {
      const _pdfPath = getProductComparisonContentPath(
        products,
        AemContent.PDF,
      );
      setPDFContent(_pdfPath, setCurrentPDF);
    }
  }, [isAppInitialized, products]);

  return {
    currentPDF,
  };
};
export default useGetProductsPDF;
