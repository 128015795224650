const enum ContributionABTestingIds {
  title = 'ppz401',
  radioTabContainer = 'ppz402',
  linkButtonPDF = 'ppz403',
  buttonContainer = 'ppz404',
  nextButton = 'ppz405',
  appointmentButton = 'ppz406',
  backButton = 'ppz407',
}

export default ContributionABTestingIds;
