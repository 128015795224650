import { BankDetails } from '../types';

import { BankDetailsAction, BANK_DETAILS_TYPES } from './actions';

export const initialBankDetails: BankDetails = {
  bic: '',
  bankName: '',
  paymentId: '',
  city: '',
};

export const bankDetailsReducer = (
  state: BankDetails,
  action: BankDetailsAction,
): BankDetails => {
  switch (action.type) {
    case BANK_DETAILS_TYPES.UPDATE_BIC:
      return { ...state, bic: action.payload as string };
    case BANK_DETAILS_TYPES.UPDATE_BANK_NAME:
      return { ...state, bankName: action.payload as string };
    case BANK_DETAILS_TYPES.UPDATE_PAYMENT_ID:
      return { ...state, paymentId: action.payload as string };
    case BANK_DETAILS_TYPES.UPDATE_BANK_DETAILS:
      return action.payload as BankDetails;
    default:
      return { ...state };
  }
};
