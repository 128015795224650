import { useCallback } from 'react';

import { InsuranceDatesRanges } from 'ppz-otr-common';

import { useDates, useDatesUpdate } from '../../context/DatesCtx';

export interface InitDateData {
  beginningDates: string[];
  insuranceDates: InsuranceDatesRanges;
}

const useInitDates = () => {
  const { beginningDateSelected } = useDates();
  const { setValidationDates, setBeginningDateSelected, setBeginningDates } =
    useDatesUpdate();

  const initDates = useCallback(
    (dateData: InitDateData) => {
      const { beginningDates, insuranceDates } = dateData;
      setBeginningDateSelected(beginningDateSelected);
      setValidationDates(insuranceDates);
      setBeginningDates(beginningDates);
    },
    [
      beginningDateSelected,
      setBeginningDateSelected,
      setValidationDates,
      setBeginningDates,
    ],
  );

  return initDates;
};

export default useInitDates;
