import React from 'react';
import { useIntl } from 'react-intl';

import literals from '../../../language/src/bank-details';
import { FormRowCustom } from '../layouts';

interface Props {
  name: string | undefined;
}

const AccountOwner = ({ name }: Props) => {
  const intl = useIntl();

  const formHolderLabel = intl.formatMessage(literals.formHolder);

  if (!name) return null;

  return (
    <FormRowCustom label={formHolderLabel}>
      <p>{name}</p>
    </FormRowCustom>
  );
};

export { AccountOwner };
