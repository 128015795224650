import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Price, { Size } from '@eg/elements/Price';

import { TariffPrices } from 'ppz-otr-common';

import { PriceWrapper } from '../../../components/SummaryGrid/layout';
import literals from '../../../language/src/summary';
import { PriceDescription } from '../layouts';

interface Props {
  prices: TariffPrices;
}

const DetailsTariffPrices = ({ prices: { initial, total } }: Props) => {
  const gotInitialPrice = useMemo(() => initial !== total, [initial, total]);

  const prices = useMemo(
    () => (gotInitialPrice ? [initial, total] : [total]),
    [initial, total, gotInitialPrice],
  );

  const getDescriptions = useCallback(
    (index: number) => {
      const {
        detailsTariffCommonPriceDes: common,
        detailsTariffStartPriceDes: start,
        detailsTariffFinalPriceDes: final,
      } = literals;

      if (!gotInitialPrice) return common;

      return index === 0 ? start : final;
    },
    [gotInitialPrice],
  );

  return (
    <>
      {prices.map((item, index) => (
        <PriceWrapper key={index}>
          <Price value={item} size={Size.Small} unstyled />
          <PriceDescription>
            <FormattedMessage {...getDescriptions(index)} />
          </PriceDescription>
        </PriceWrapper>
      ))}
    </>
  );
};

export default DetailsTariffPrices;
