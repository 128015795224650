import { useState, createContext, useContext } from 'react';

import { InsuranceDatesRanges } from 'ppz-otr-common';

import storage, { LocalKeys } from '../services/storage';

export interface DatesContext {
  beginningDates: string[];
  beginningDateSelected: string;
  validationDates: InsuranceDatesRanges;
}

export interface DatesUpdateContext {
  setBeginningDates: React.Dispatch<DatesContext['beginningDates']>;
  setBeginningDateSelected: React.Dispatch<
    DatesContext['beginningDateSelected']
  >;
  setValidationDates: React.Dispatch<DatesContext['validationDates']>;
}

const defaultCtx: DatesContext = {
  beginningDateSelected:
    storage.get(LocalKeys.beginningDate) !== null &&
    storage.get(LocalKeys.beginningDate) !== ''
      ? (storage.get(LocalKeys.beginningDate) as string)
      : '',
  beginningDates: [],
  validationDates: {
    adult: '',
    max: '',
    min: '',
  },
};

export const datesCtx = createContext<DatesContext>(defaultCtx);
export const datesCtxUpdate = createContext<DatesUpdateContext>(
  {} as DatesUpdateContext,
);

export const useDates = () => {
  const dates = useContext(datesCtx);
  if (dates === undefined)
    throw new Error('useDates must be used within a DatesContext');
  return dates;
};

export const useDatesUpdate = () => {
  const setDates = useContext(datesCtxUpdate);
  if (setDates === undefined)
    throw new Error('useDatesUpdate must be used within a DatesUpdateContext');
  return setDates;
};

interface Props extends Partial<DatesContext> {
  children?: React.ReactNode;
}

export const DatesProvider = ({
  children,
  beginningDates: defaultBeginningDates,
  beginningDateSelected: defaultBeginningDateSelected,
  validationDates: defaultValidationDates,
}: Props) => {
  const [beginningDates, setBeginningDates] = useState<
    DatesContext['beginningDates']
  >(defaultBeginningDates ?? defaultCtx.beginningDates);
  const [beginningDateSelected, setBeginningDateSelected] = useState<
    DatesContext['beginningDateSelected']
  >(defaultBeginningDateSelected ?? defaultCtx.beginningDateSelected);
  const [validationDates, setValidationDates] = useState<
    DatesContext['validationDates']
  >(defaultValidationDates ?? defaultCtx.validationDates);

  return (
    <datesCtxUpdate.Provider
      value={{
        setBeginningDates,
        setBeginningDateSelected: (newBeginningDateSelected: string) => {
          if (newBeginningDateSelected !== '') {
            storage.set(LocalKeys.beginningDate, newBeginningDateSelected);
            setBeginningDateSelected(newBeginningDateSelected);
          }
        },
        setValidationDates,
      }}
    >
      <datesCtx.Provider
        value={{
          beginningDates,
          beginningDateSelected,
          validationDates,
        }}
      >
        {children}
      </datesCtx.Provider>
    </datesCtxUpdate.Provider>
  );
};

export default DatesProvider;
