import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import storage, { LocalKeys } from '../services/storage';
import { VIEWS } from '../types';

const useHistory = () => {
  const location = useLocation();

  useEffect(() => {
    const current = location.pathname;
    const isNotErrorView = current !== VIEWS.error;

    isNotErrorView && storage.set(LocalKeys.history, current);
  }, [location.pathname]);
};

export default useHistory;
