import { BirthdateData, Person, OFFER_ROLES } from 'ppz-otr-common';

import { PersonContext } from '../context/PersonCtx';

const getBirthdate = (singlePerson: Person) => {
  return `${singlePerson.day}.${singlePerson.month}.${singlePerson.year}`;
};

const personToBirthdateData = (
  { id, role, day, month, year }: Person,
  errors: BirthdateData['errors'],
): BirthdateData => {
  return {
    id,
    role,
    day,
    month,
    year,
    errors,
  };
};

const personsFromPersonData = (persons: Person[]): PersonContext['persons'] => {
  const newPersons: PersonContext['persons'] = new Map();
  persons.forEach((person) => {
    newPersons.set(person.id, person);
  });
  return newPersons;
};

const getOwnerId = (
  insuranceOwnerId: Person['id'],
  persons: PersonContext['persons'],
  isIzs: boolean,
) => {
  const currentPersons: Person[] = Array.from(persons.values());
  let ownerId: Person['id'] = null;
  if (!!insuranceOwnerId && !isIzs) {
    ownerId = insuranceOwnerId;
  } else if (currentPersons.length > 0) {
    const insuranceOwner = currentPersons.find(
      ({ role }) => role === OFFER_ROLES.insuranceOwner,
    );
    ownerId = insuranceOwner?.id ?? null;
  }

  return ownerId;
};

export {
  getBirthdate,
  personToBirthdateData,
  personsFromPersonData,
  getOwnerId,
};
