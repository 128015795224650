import { ProductIds, OldProductIds } from 'ppz-otr-common';

import { Products } from '../../types';

const deletePrefixAndAddZahnBeginning = (products: string) =>
  `ZAHN_${products.replace(/ZAHN_/g, '')}`;

const aemComparisonProducts = new Map<Products, string>();

aemComparisonProducts.set(
  Products.DS75,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DS90}`,
  ),
);
aemComparisonProducts.set(
  Products.DS90,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DS90}_${ProductIds.ZAHN_DS100}`,
  ),
);
aemComparisonProducts.set(
  Products.DS100,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DS100}_${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS100}`,
  ),
);
aemComparisonProducts.set(
  Products.DVB,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DVE}`,
  ),
);
aemComparisonProducts.set(
  Products.DVE,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS75}`,
  ),
);
aemComparisonProducts.set(
  Products.DVB_DS75,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS90}`,
  ),
);
aemComparisonProducts.set(
  Products.DVE_DS75,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS90}`,
  ),
);
aemComparisonProducts.set(
  Products.DVB_DS90,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS90}_${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS100}`,
  ),
);
aemComparisonProducts.set(
  Products.DVE_DS90,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS90}_${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS100}`,
  ),
);
aemComparisonProducts.set(
  Products.DVB_DS100,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS100}_${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS100}`,
  ),
);
aemComparisonProducts.set(
  Products.DVE_DS100,
  deletePrefixAndAddZahnBeginning(
    `${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS100}`,
  ),
);

// Old tariffs
aemComparisonProducts.set(
  Products.ZEZ,
  deletePrefixAndAddZahnBeginning(OldProductIds.ZAHN_ZEZ),
);
aemComparisonProducts.set(
  Products.ZZP,
  deletePrefixAndAddZahnBeginning(OldProductIds.ZAHN_ZZP),
);
aemComparisonProducts.set(
  Products.ZZP_DVB,
  deletePrefixAndAddZahnBeginning(
    `${OldProductIds.ZAHN_ZZP}_${ProductIds.ZAHN_DVB}`,
  ),
);
aemComparisonProducts.set(
  Products.ZEF,
  deletePrefixAndAddZahnBeginning(OldProductIds.ZAHN_ZEF),
);
aemComparisonProducts.set(
  Products.ZEF_DVB,
  deletePrefixAndAddZahnBeginning(
    `${OldProductIds.ZAHN_ZEF}_${ProductIds.ZAHN_DVB}`,
  ),
);

export { aemComparisonProducts };
