import Card from '@eg/elements/Card';
import { StepButton } from '@eg/elements/StepButton';

import styled, { css } from 'styled-components';

import { sizes, colors, breakpoints } from '../../../styles/tokens';

interface BoldTitlesInterface {
  isMainTitle?: boolean;
}

interface TextWrapperInterface {
  isCheckboxText?: boolean;
}

export const TextWrapper = styled.div<TextWrapperInterface>`
  margin: ${sizes.space.PX_16} ${sizes.space.PX_40};

  @media (max-width: ${breakpoints.M}) {
    ${({ isCheckboxText }) => css`
      margin: ${isCheckboxText ? `0 0 0 ${sizes.space.PX_20}` : '0'};
      align-self: flex-start;
    `}
  }
`;

export const CustomStepButton = styled(StepButton)`
  && {
    margin: ${sizes.space.PX_32} 0;
  }
  @media (max-width: ${breakpoints.M}) {
    align-self: flex-start;
  }
`;

export const CustomCard = styled(Card)`
  && {
    padding: ${sizes.space.PX_24} 0 0 0;
    box-shadow: none;
    border: ${sizes.space.PX_01} solid ${colors.DARK_GRAY};
  }
`;

export const CheckboxSection = styled.div`
  background-color: ${colors.LIGHTER_LAVENDER};
  padding: ${sizes.space.PX_16} ${sizes.space.PX_40};
  margin-top: ${sizes.space.PX_32};
  border-radius: 0px 0px 8px 8px;
`;

export const BoldTitles = styled.h3<BoldTitlesInterface>`
  ${({ isMainTitle }) => css`
    margin-bottom: ${isMainTitle ? sizes.space.PX_32 : 0};
  `}
  font-size: ${sizes.text.L};
  padding: 0;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
