import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8); // like https://www.ergo.de
  display: flex;
  align-items: center;
  justify-content: right;
  z-index: 1000;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  overflow-y: auto;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;
  }

  &.exit {
    opacity: 0;
  }
`;

export default Container;
