const enum FeedbackABTestingIds {
  titleContainer = 'ppz901',
  icon = 'ppz902',
  title = 'ppz903',
  subtitle = 'ppz904',
  message = 'ppz905',
  subheadingContainer = 'ppz906',
  subheadingTitle = 'ppz907',
  summary = 'ppz908',
  downloadButton = 'ppz909',
  aemFragment = 'ppz910',
}

export default FeedbackABTestingIds;
