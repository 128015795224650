import { useLocation } from 'react-router-dom';

import { Tariffs } from 'ppz-otr-common';

import { useDates } from '../../context/DatesCtx';
import { useInsurance } from '../../context/InsuranceCtx';
import { useIzsIntegration } from '../../context/IzsIntegrationCtx';
import { usePerson } from '../../context/PersonCtx';
import { useTariff } from '../../context/TariffCtx';
import {
  ClickableElements,
  ClickTypes,
  ErrorProperties,
  ErrorTypes,
  EventDetails,
  GeneralClickProps,
  Miscellaneous,
  Page,
  ProductAttr,
  ProductCategory,
  ProductCombinations,
  ProductInfo,
  TrackingProduct,
  TrackingUser,
  Transaction,
  TransactionAttrs,
  VIEWS,
} from '../../types';
import useGetTrackingDetails from '../aem/useGetTrackingDetails';
import useGetTrackingPageNames from '../aem/useGetTrackingPageNames';

import {
  eventDetails,
  getCalculatedValue,
  getCalculatedValueReduced,
  getConversionType,
  getErrorMessage,
  getErrorType,
  getErrorUrl,
  getNumberOfPersons,
  getPageAttrs,
  getPageCategory,
  getPageInfo,
  getPersonsFromBirthdates,
  getProductCombination,
  getTariffOptions,
  getTrackingUsers,
  isConversionTypedPage,
  sortPersons,
} from './helpers';

type GetPageProperty = (page?: VIEWS) => Page;
type GetEventDetailsProperty = (
  element: ClickableElements | null,
  isError: boolean,
  errorCode?: string,
) => EventDetails;
type GetProductProperty = (props?: GeneralClickProps) => TrackingProduct[];
type GetTransactionProperty = (
  element: ClickableElements | null,
  isError: boolean,
) => Transaction;
type GetUserProperty = (props?: GeneralClickProps) => TrackingUser[];
type GetMiscellaneousProperty = (
  type: ErrorTypes,
  props: ErrorProperties,
) => Miscellaneous;

export interface TrackingPageLoaded {
  getPageProperty: GetPageProperty;
  getEventDetailsProperty: GetEventDetailsProperty;
  getProductProperty: GetProductProperty;
  getTransactionProperty: GetTransactionProperty;
  getUserProperty: GetUserProperty;
  getMiscellaneousProperty: GetMiscellaneousProperty;
}

const useTrackingEventsData = (): TrackingPageLoaded => {
  const { pathname } = useLocation();
  const viewNames = useGetTrackingPageNames();
  const {
    appointmentType: appointment,
    businessId,
    situation,
  } = useInsurance();
  const { isIzsVnSynced } = useIzsIntegration();
  const { beginningDateSelected } = useDates();
  const { temporalProducts, tariffPriceSelected: tariff } = useTariff();
  const { abbreviation, productGroup, brokerModus } = useGetTrackingDetails();
  const { persons, getConsentInfo } = usePerson();

  const getPageProperty = (_page?: VIEWS): Page => {
    const page = _page || (pathname as VIEWS);
    const pageInfoParams = { page, viewNames, abbreviation, appointment };
    const pageInfo = getPageInfo(pageInfoParams);
    const attributes = getPageAttrs(brokerModus);
    const category = getPageCategory();

    return {
      pageInfo,
      attributes,
      category,
    };
  };

  const getEventDetailsProperty = (
    element: ClickableElements | null = null,
    isError: boolean = false,
    errorCode?: string,
  ): EventDetails => {
    const timedCall = element ? eventDetails.get(element) : true;
    const clickType = ClickTypes.OTHER;

    let result: EventDetails = { timedCall };

    if (element || isError) {
      result = { ...result, clickType };
      if (element) {
        const clickedElement = errorCode ? `${element}_${errorCode}` : element;
        result = { ...result, clickedElement };
      }
    }

    return result;
  };

  const getProductProperty = (props?: GeneralClickProps): TrackingProduct[] => {
    // tracking buttons from birthdates view
    const gotUpdatedPersons = props && props.type === 'BirthdateData';
    const currentPersons = gotUpdatedPersons ? props.value : persons;
    const numberOfPersons = getNumberOfPersons(currentPersons) || 1;

    const productInfo: ProductInfo = {
      productName: abbreviation,
    };

    const options1 = {
      birthDatePreFilled: isIzsVnSynced,
      situation,
      consent: getConsentInfo(),
    };

    // tracking buttons from contribution view
    const gotUpdatedProducts = props && props.type === 'ProductIds';
    const currentProducts = gotUpdatedProducts ? props.value : temporalProducts;

    // tracking buttons from insurance situation view
    const gotUpdatedInsuranceType = props && props.type === 'INSURANCE_TYPE';
    const currentInsuranceType = gotUpdatedInsuranceType
      ? props.value
      : undefined;

    // tracking buttons from insurance beginning view
    const gotUpdatedInsuranceStart = props && props.type === 'BeginningDate';
    const insuranceStart = gotUpdatedInsuranceStart
      ? props.value
      : beginningDateSelected;

    const attributes: ProductAttr = {
      tariffOptions1: getTariffOptions(
        pathname as VIEWS,
        options1,
        currentInsuranceType,
      ),
      productCombination: getProductCombination(
        currentProducts as Tariffs[],
      ) as ProductCombinations,
      insuranceStart,
    };

    const category: ProductCategory = {
      primaryCategory: productGroup,
    };

    const trackingProduct: TrackingProduct = {
      productInfo,
      attributes,
      category,
    };

    const trackingProducts = new Array(numberOfPersons);

    for (let i = 0; i < numberOfPersons; i++) {
      trackingProducts[i] = trackingProduct;
    }

    return trackingProducts;
  };

  const hasExceptionEvent = (element: ClickableElements | null = null) => {
    const eventsElements = [
      ClickableElements.EventIBANValidationStart,
      ClickableElements.EventIBANValidationSuccess,
      ClickableElements.EventAddressValidationSuccess,
      ClickableElements.EventAddressValidationError,
      ClickableElements.EventAddressValidationCorrected,
    ];
    return element !== null && eventsElements.includes(element);
  };

  const getTransactionProperty = (
    element: ClickableElements | null = null,
    isError: boolean = false,
  ): Transaction => {
    const { total, initial } = tariff;
    const transactionID = businessId;

    let attributes: TransactionAttrs = {
      calculatedValue: getCalculatedValue(total),
      calculatedValueReduced: getCalculatedValueReduced(total, initial),
    };

    // ConversionType is not a sticky variable. It must be tracked only on the defined data layer pushes
    const isPageLoadedEvent = !element && !isError;
    const gotConversionType = isConversionTypedPage(pathname as VIEWS);
    const isPageLoadedWithConversion = isPageLoadedEvent && gotConversionType;
    const isEventWithConversion = hasExceptionEvent(element);

    if (isPageLoadedWithConversion || isEventWithConversion) {
      attributes = {
        ...attributes,
        conversionType: getConversionType(
          pathname as VIEWS,
          appointment,
          element,
        ),
      };
    }

    let result: Transaction = {
      transactionID,
      attributes,
    };

    if (pathname === VIEWS.feedback) {
      result = {
        ...result,
        total: {
          transactionTotal: getCalculatedValue(total),
        },
      };
    }

    return result;
  };

  const getUserProperty = (props?: GeneralClickProps): TrackingUser[] => {
    const gotUpdatedPersons = props && props.type === 'BirthdateData';
    const currentPersons = gotUpdatedPersons
      ? getPersonsFromBirthdates(props.value)
      : sortPersons(persons);

    return getTrackingUsers(currentPersons, situation);
  };

  const getMiscellaneousProperty = (
    type: ErrorTypes,
    props: ErrorProperties,
  ): Miscellaneous => ({
    errors: {
      errorFlag: true,
      errorType: getErrorType(type, props),
      errorMessages: getErrorMessage(props),
      errorUrl: getErrorUrl(props),
    },
  });

  return {
    getPageProperty,
    getEventDetailsProperty,
    getProductProperty,
    getTransactionProperty,
    getUserProperty,
    getMiscellaneousProperty,
  };
};

export default useTrackingEventsData;
