import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import Modal from '@eg/elements/Modal';

import styled from 'styled-components';

import literals from '../../language/src/insurance-situation';
import { colors, sizes } from '../../styles/tokens';

interface ModalProps {
  open: boolean;
  onDismiss: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  ) => void;
  id: string;
}

const ListModal = styled.ol`
  list-style: none;
  counter-reset: badge;
  padding-left: 0;
  margin-bottom: ${sizes.space.PX_32};

  & li {
    counter-increment: badge;
    margin-bottom: ${sizes.space.PX_16};
    display: flex;
    align-items: baseline;
  }

  & li:before {
    display: flex;
    align-items: center;
    height: ${sizes.space.PX_14};
    content: counter(badge);
    background-color: ${colors.DARKEST_GRAY};
    color: white;
    padding: 3px ${sizes.space.PX_06};
    margin-right: ${sizes.space.PX_16};
    border-radius: 50%;
    font-size: ${sizes.text.S};
  }
`;

const Paragraph = styled.p`
  font-size: ${sizes.text.M};
  margin-bottom: ${sizes.space.PX_24};
`;

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: ${sizes.space.PX_24};
`;

const NUM_ITEMS_LIST = 8;
const iterableList = Array(NUM_ITEMS_LIST).fill(undefined);

const ModalRelatives = ({ open, onDismiss, id }: ModalProps) => {
  const intl = useIntl();

  return (
    <Modal open={open} onDismiss={onDismiss} dismissible extendedWidth>
      <div className="abTesting" id={id}>
        <Title>{intl.formatMessage(literals.modalTitle)}</Title>
        <ListModal>
          {iterableList.map((_elem, index) => (
            <li key={index}>
              {intl.formatMessage(literals[`modalPoint${index + 1}`])}
            </li>
          ))}
        </ListModal>
        <Paragraph>{intl.formatMessage(literals.modalParagraph1)}</Paragraph>
        <p>{intl.formatMessage(literals.modalParagraph2)}</p>
      </div>
    </Modal>
  );
};

export default ModalRelatives;
