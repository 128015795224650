import {
  SpcsConsent,
  SpcsCountryCode,
  SpcsSalutation,
  SpcsContactType,
  SpcsTelephoneType,
  SpcsTelephoneDetails,
  OFFER_ROLES,
  ValidationErrorPlaces,
} from 'ppz-otr-common';

import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

import { getHttp, postHttp, putHttp } from '.';

interface GenericError {
  status: number;
  data: any;
}

interface InformationUpdatedError {
  status: number;
  data: {
    message?: string;
    emailError?: boolean;
    telephoneError?: boolean;
  };
}
export interface FieldsAddressValidation {
  code: string;
  where: ValidationErrorPlaces;
  needAgent: boolean;
}

export interface ValidatePersonalDataResponse {
  personId: string;
  error: boolean;

  errorUpdatingInformation: boolean;
  dataErrorInformationUpdated?: InformationUpdatedError;
  addresses: SpcsPersonAddressDto[];
  addressesUpdated?: SpcsPersonAddressDto[];
  autoCorrected: boolean;
  emailAddresses?: UpdateInformationEmail[];
  telephoneNumbers?: UpdateInformationTelephone[];
  advertiseConsent: {
    telephonyConsent: SpcsConsent | null;
    emailConsent: SpcsConsent | null;
  };

  errorValidatingAddress?: boolean;
  addressValidationResult?: FieldsAddressValidation | null;
  dataErrorAddressValidationResult?: GenericError;
}

interface SpcsPersonAddressDto {
  id?: string;
  street: string;
  streetNumber: string;
  additionalAddressInformation?: string;
  postalCode: string;
  city: string;
  additionalCityInformation?: string;
  country: SpcsCountryCode;
  parisAddressNumber?: number;
}

interface AddressInfo {
  id: string | null;
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
}

interface EmailInfo {
  id: string | null;
  email: string;
  contactType: SpcsContactType;
}

interface TelephoneInfo {
  id: string | null;
  number: string;
  telephoneType: SpcsTelephoneType;
  contactType: SpcsContactType;
}

interface UpdateInformationEmail {
  id: string;
  email: string;
  contactType: SpcsContactType;
}

interface UpdateInformationTelephone {
  id: string;
  number: string;
  telephoneType: SpcsTelephoneType;
  contactType: SpcsContactType;
  details: SpcsTelephoneDetails;
}

export interface PersonInfo {
  id: string;
  salutation: SpcsSalutation;
  firstName: string;
  familyName: string;
  dateOfBirth: string;
  consentEmail?: boolean;
  consentTelephony?: boolean;
  addresses: AddressInfo[];
  emailAddresses?: EmailInfo[];
  telephoneNumbers?: TelephoneInfo[];
}

export interface ValidateInfoDto {
  persons: PersonInfo[];
}

interface SpcsReferenceDto {
  personId: string;
  addressId: string;
  telephoneId?: string;
  emailId?: string;
  paymentId?: string;
}

export interface SpcsRolleDto {
  reference: SpcsReferenceDto;
  rollentyp: OFFER_ROLES;
}

interface SaveInfoDto {
  persons: SpcsRolleDto[];
}

export interface SaveInfoResponseDto {
  success: boolean;
}

export interface GetCitiesByZipCodeResponseDto {
  result: MatchingAddress[];
}

interface MatchingAddress {
  zipCode: string;
  city: string;
  street: string;
  municipalityKey: string;
}

export const PERSONAL_DATA_API = {
  VALIDATE: `${ENDPOINTS.personalData}/:businessId/validate`,
  SAVE: `${ENDPOINTS.personalData}/:businessId/update`,
  GET_CITIES_BY_ZIP_CODE: `${ENDPOINTS.personalData}/cities/:zipCode`,
};

export const validatePersonalData = async (
  persons: PersonInfo[],
  businessId: string,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await postHttp<ValidateInfoDto, ValidatePersonalDataResponse[]>(
    { url: PERSONAL_DATA_API.VALIDATE, errorThrown, path: { businessId } },
    { persons },
  );
};

export const savePersonalData = async (
  persons: SpcsRolleDto[],
  businessId: string,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await putHttp<SaveInfoDto, SaveInfoResponseDto>(
    { url: PERSONAL_DATA_API.SAVE, errorThrown, path: { businessId } },
    { persons },
  );
};

export const getCitiesByZipCode = async (
  zipCode: string,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await getHttp<GetCitiesByZipCodeResponseDto>({
    url: PERSONAL_DATA_API.GET_CITIES_BY_ZIP_CODE,
    errorThrown,
    path: { zipCode },
  });
};
