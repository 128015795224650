import { rest } from 'msw';

import { CONTRIBUTION_API, UpdateContributionDto } from '../../services';
import { getUrl, resDefault } from '../utils';

import { getUpdateContributionResponse } from './responses/updateDate';

const contributionSetTariffUrl = getUrl(CONTRIBUTION_API.PUT_SELECTED_TARIFF);
const contributionSetTariffHandler = rest.put(
  contributionSetTariffUrl,
  async (req, res, ctx) => {
    const request = await req.json<UpdateContributionDto>();
    return resDefault(
      ctx.status(200),
      ctx.json(getUpdateContributionResponse(request)),
    );
  },
);

export const handlers = [contributionSetTariffHandler];
export default handlers;
