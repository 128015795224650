import { useState, ChangeEvent, RefObject } from 'react';
import { useNavigate } from 'react-router-dom';

import Checkbox from '@eg/elements/Checkbox';
import ControlWithHint from '@eg/elements/ControlWithHint';
import RadioGroupRow from '@eg/elements/RadioGroupRow';

import { OFFER_ROLES, Person, INSURANCE_TYPE } from 'ppz-otr-common';

import { useInsurance } from '../../../context/InsuranceCtx';
import { usePersonUpdate } from '../../../context/PersonCtx';
import { useTracking } from '../../../hooks';
import literals from '../../../language/src/personal-data';
import { VIEWS, ClickableElements } from '../../../types';
import { getLiteral } from '../../../utils/literals';
import {
  PersonalDataForm as PersonalDataFormWrapper,
  Heading,
} from '../layouts';
import { OnChangeForm, OnDiffAddress } from '../types';

import AddressInputs from './AddressInputs';
import Birthdate from './Birthdate';
import PersonalInputs from './PersonalInputs';

interface Props {
  id: number;
  label: string;
  person: Person;
  onDiffAddress: OnDiffAddress;
  onChange: OnChangeForm;
  idDOM: string;
  forwardGenderRef?: RefObject<RadioGroupRow>;
  forwardNameRef?: RefObject<ControlWithHint>;
  forwardLastNameRef?: RefObject<ControlWithHint>;
  forwardStreetNameRef?: RefObject<ControlWithHint>;
  forwardStreetNumberRef?: RefObject<ControlWithHint>;
  forwardZipCodeRef?: RefObject<ControlWithHint>;
}

const PersonalDataForm = ({
  id,
  label,
  person,
  onDiffAddress,
  onChange,
  idDOM,
  forwardGenderRef,
  forwardNameRef,
  forwardLastNameRef,
  forwardStreetNameRef,
  forwardStreetNumberRef,
  forwardZipCodeRef,
}: Props) => {
  const { diffAddress } = person;

  const navigate = useNavigate();

  const { setTrackingClick } = useTracking();
  const { situation } = useInsurance();
  const { setShowErrorOnEmptyPersonalFields } = usePersonUpdate();

  const [isDiffAddress, setIsDiffAddress] = useState<boolean>(diffAddress);

  const isInsuranceOwner = person.role === OFFER_ROLES.insuranceOwner;

  const _trackingCheckBox = (isChecked: boolean) => {
    const clickableElement = isChecked
      ? ClickableElements.CheckBoxDiffAddressActive
      : ClickableElements.CheckBoxDiffAddressInactive;
    setTrackingClick(clickableElement);
  };

  const goToEditBirthDate = () => {
    if (situation === INSURANCE_TYPE.myself || isInsuranceOwner) {
      navigate(VIEWS.myself);
    } else if (situation === INSURANCE_TYPE.myselfAndMore) {
      navigate(VIEWS.myselfAndMore);
    } else {
      navigate(VIEWS.others);
    }
  };

  const onDiffAddressChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    _trackingCheckBox(checked);

    person.address = {
      city: !checked ? null : '',
      postalCode: !checked ? null : '',
      street: !checked ? null : '',
      streetNumber: !checked ? null : '',
    };

    checked && setShowErrorOnEmptyPersonalFields(false);
    onDiffAddress(person.id, checked);
    person.diffAddress = checked;
    setIsDiffAddress(checked);
  };

  return (
    <PersonalDataFormWrapper className="abTesting abTestingBottom" id={idDOM}>
      <Heading className="esc_teaser__subhead">{label}</Heading>
      <Birthdate person={person} onClick={goToEditBirthDate} />
      <PersonalInputs
        id={id}
        person={person}
        onChange={onChange}
        forwardGenderRef={forwardGenderRef}
        forwardNameRef={forwardNameRef}
        forwardLastNameRef={forwardLastNameRef}
      />
      {isInsuranceOwner ? (
        <AddressInputs
          id={id}
          person={person}
          onChange={onChange}
          forwardStreetNameRef={forwardStreetNameRef}
          forwardStreetNumberRef={forwardStreetNumberRef}
          forwardZipCodeRef={forwardZipCodeRef}
        />
      ) : (
        <Checkbox
          label={getLiteral(literals.diffAddress)}
          onChange={onDiffAddressChecked}
          checked={isDiffAddress}
        />
      )}
      {isDiffAddress && (
        <AddressInputs
          id={id}
          person={person}
          onChange={onChange}
          forwardStreetNameRef={forwardStreetNameRef}
          forwardStreetNumberRef={forwardStreetNumberRef}
          forwardZipCodeRef={forwardZipCodeRef}
        />
      )}
    </PersonalDataFormWrapper>
  );
};

export default PersonalDataForm;
