import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLoadingUpdate } from '../../../context/LoadingCtx';
import { getEkomiRating } from '../../../services';
import { VIEWS } from '../../../types';
import { errorHandler } from '../../../utils/handlers';
import { CustomEkomiRatingProps, CustomEkomiRating } from '../layouts';

interface Props extends CustomEkomiRatingProps {
  text: string;
}

const EkomiElement = ({ structure, text }: Props) => {
  const navigate = useNavigate();
  const { setLoading } = useLoadingUpdate();

  const [gotRating, setGotRating] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const [totalRatings, setTotalRatings] = useState<number>(0);

  const onGenericError = () => {
    setLoading(false);
    navigate(VIEWS.error);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getEkomiRating((_error) => {
          return Promise.reject(new Error('generic error'));
        });

        if (data) {
          const { averageRating, totalRatings } = data;
          setGotRating(true);
          setRating(averageRating);
          setTotalRatings(totalRatings);
        }
      } catch (error) {
        errorHandler(onGenericError, error);
        return Promise.reject(new Error('generic error'));
      }
    };

    getData();
  }, []);

  return gotRating ? (
    <CustomEkomiRating
      structure={structure}
      rating={rating}
      totalRatings={totalRatings}
      variant="with-seal"
      align={structure === 'horizontal' ? 'left' : 'center'}
      additionalSubtext={text || ''}
    />
  ) : null;
};

export default EkomiElement;
