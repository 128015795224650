import { rest } from 'msw';

import {
  GetCitiesByZipCodeResponseDto,
  ValidateInfoDto,
  PERSONAL_DATA_API,
} from '../../services';
import { getUrl, resDefault } from '../utils';

import {
  getZipCodeResponse,
  getValidateResponse,
  updateInfoResponse,
} from './responses/';

// Describe the shape of the "req.params"
interface GetCitiesRequestParams {
  zipCode: string;
}

const getCitiesUrl = getUrl(PERSONAL_DATA_API.GET_CITIES_BY_ZIP_CODE);
const getCitiesHandler = rest.get<
  GetCitiesByZipCodeResponseDto,
  GetCitiesRequestParams
>(getCitiesUrl, async (req, res, ctx) => {
  const { zipCode } = req.params;
  return resDefault(ctx.status(200), ctx.json(getZipCodeResponse(zipCode)));
});

const validateUrl = getUrl(PERSONAL_DATA_API.VALIDATE);
const validateHandler = rest.post(validateUrl, async (req, res, ctx) => {
  const request = await req.json<ValidateInfoDto>();
  return resDefault(ctx.status(201), ctx.json(getValidateResponse(request)));
});

const updateUrl = getUrl(PERSONAL_DATA_API.SAVE);
const updateHandler = rest.put(updateUrl, (req, res, ctx) => {
  return resDefault(ctx.status(201), ctx.json(updateInfoResponse));
});

export const handlers = [getCitiesHandler, validateHandler, updateHandler];
export default handlers;
