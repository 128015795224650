import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useRunAfterUpdate } from '@eg/elements/hooks/useRunAfterUpdate';
import {
  beautifyIban,
  isValidIban,
  normalizeIban,
} from '@eg/elements/utils/validation/iban';
import { getFilteredTextAndCursorPositon } from '@eg/elements/utils/validation/inputValidationHelper';

import { ContactElement, SpcsSEPAValidCountries } from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/bankDetails';
import { ActionButtons } from '../../components/Common';
import EmailSection from '../../components/EmailSection/EmailSection';
import { useInsurance, useInsuranceUpdate } from '../../context/InsuranceCtx';
import { useLoading, useLoadingUpdate } from '../../context/LoadingCtx';
import { usePerson, usePersonUpdate } from '../../context/PersonCtx';
import { useGetAEMOfferStatus, useTracking } from '../../hooks';
import literals from '../../language/src/bank-details';
import commonLiterals from '../../language/src/common';
import { PageLayout } from '../../layouts';
import { postBankDetails, saveBankDetails } from '../../services/bankDetails';
import {
  postShippingAddress,
  ShippingAddressDto,
} from '../../services/shippingAddress';
import {
  CHECKOUT_TYPE,
  ClickableElements,
  ErrorTypes,
  Icon,
  PERSON_INPUTS,
  SingleButton,
  VIEWS,
} from '../../types';
import { scrollToError } from '../../utils';

import {
  AccountOwner,
  Description,
  IbanForm,
  SuccessIbanCard,
} from './components';
import { IbanBeautified } from './types';
import { updateBankDetails } from './utils/actions';
import { getFormattedIBAN, getNormalizedIBAN } from './utils/helpers';
import { bankDetailsReducer, initialBankDetails } from './utils/reducers';

const initialIBANFormatted: IbanBeautified = {
  iban: '',
  isValid: false,
  normalized: '',
  beautified: '',
};

const initialEmail: ContactElement = {
  valid: false,
  value: '',
};

const BankDetails = () => {
  const isMounted = useRef<boolean>(false);

  const intl = useIntl();
  const { setCheckoutType, setBankDetails } = useInsuranceUpdate();
  const navigate = useNavigate();
  const runAfterUpdate = useRunAfterUpdate();
  const { fullOfferDisabled } = useGetAEMOfferStatus();
  const { setTrackingClick, setTrackingError } = useTracking();

  const [showEmailError, setShowEmailError] = useState(false);
  const [touchedIBAN, setTouchedIBAN] = useState(false);
  const [submitShowEmailError, setSubmitShowEmailError] = useState(false);

  const {
    businessId,
    checkoutType,
    bankDetails: bankDetailsOffer,
  } = useInsurance();
  const { persons, insuranceOwnerId } = usePerson();
  const { setPersonById } = usePersonUpdate();
  const { loading } = useLoading();
  const { setLoading } = useLoadingUpdate();

  const [email, setEmail] = useState<ContactElement>(initialEmail);
  const [IBANFormatted, setIBANFormatted] =
    useState<IbanBeautified>(initialIBANFormatted);
  const [isValidIBAN, setIsValidIBAN] = useState<boolean>(false);
  const [IBANError, setIBANError] = useState<string>('');

  const [bankDetails, dispatchBankDetails] = useReducer(
    bankDetailsReducer,
    initialBankDetails,
  );

  const insuranceOwner = useMemo(() => {
    const insuranceOwner = persons.get(insuranceOwnerId);
    if (persons.size && insuranceOwnerId && insuranceOwner) {
      return insuranceOwner;
    } else return undefined;
  }, [persons, insuranceOwnerId]);

  const accountHolder = useMemo(() => {
    if (insuranceOwner) {
      const { name, lastname } = insuranceOwner;
      return `${name} ${lastname}`;
    }
  }, [insuranceOwner]);

  const isEmailInputDisplayed = useMemo(() => {
    if (checkoutType !== CHECKOUT_TYPE.online) return false;
    if (insuranceOwner) {
      const { email } = insuranceOwner;

      return email ? false : true;
    } else return false;
  }, [checkoutType, insuranceOwner]);

  const isItSEPA = (_IBAN: string) => {
    const countryCode = _IBAN.slice(0, 2).toUpperCase();
    const allCountries = Object.values(SpcsSEPAValidCountries);
    return allCountries.includes(countryCode as SpcsSEPAValidCountries);
  };

  const getFrontError = useCallback(
    (_IBAN?: string) => {
      if (_IBAN === undefined) {
        return intl.formatMessage(literals.errorIBAN);
      }

      if (_IBAN === '') {
        return intl.formatMessage(literals.emptyIBAN);
      }

      const isSEPA = isItSEPA(_IBAN);
      const errorSepa = !isSEPA && _IBAN.length > 1;
      const error = errorSepa ? literals.errorCountry : literals.errorIBAN;
      return intl.formatMessage(error);
    },
    [intl],
  );

  const onGenericError = useCallback(
    (_error) => {
      navigate(VIEWS.error);
      setLoading(false);
    },
    [navigate, setLoading],
  );

  useEffect(() => {
    isMounted.current = true;

    const refresh = async () => {
      const { bankdata, emailOffer } = persons.get(insuranceOwnerId)!;

      if (bankDetailsOffer && isMounted.current) {
        const iban = getStringValue(bankDetailsOffer.iban);
        const ibanHasSpace = iban.includes(' ');
        const newIban = ibanHasSpace ? iban : getFormattedIBAN(iban);
        const normalizedIban = ibanHasSpace ? getNormalizedIBAN(iban) : iban;
        const isValid = isValidIban(newIban);

        const updatedBankDetails = {
          paymentId: getStringValue(bankDetailsOffer.paymentId),
          bic: getStringValue(bankDetailsOffer.bic),
          bankName: getStringValue(bankDetailsOffer.bankname),
          city: getStringValue(bankDetailsOffer.city),
        };

        dispatchBankDetails(updateBankDetails(updatedBankDetails));
        setIBANFormatted({
          iban: newIban,
          isValid,
          normalized: normalizedIban,
        });

        /**
         * If a person in SPCS has different IBAN than in Offer Engine,
         * we force validating to generate a new paymentId so that SPCS and OE
         * have the same paymentId when clicking Next button.
         */
        let spcsIban = getStringValue(bankdata?.iban);
        const spcsIBanHasSpace = spcsIban.includes(' ');
        spcsIban = spcsIBanHasSpace ? spcsIban : getFormattedIBAN(spcsIban);

        if (bankdata && spcsIban !== newIban) {
          setIsValidIBAN(isValid);
        }
      }
      emailOffer && setEmail({ valid: true, value: emailOffer });

      function getStringValue(str) {
        return str ?? '';
      }
    };

    if (persons && persons.size && insuranceOwnerId) refresh();

    return () => {
      isMounted.current = false;
    };
  }, [insuranceOwnerId, persons.size]);

  useEffect(() => {
    const validate = async () => {
      isMounted.current && setLoading(true);

      const personId = insuranceOwnerId as string;

      // VALIDATE paymentId in Backend
      const { iban: _iban } = IBANFormatted;
      const iban = getNormalizedIBAN(_iban);
      const validateBody = { iban, mrn: '' };

      const response = await postBankDetails(
        businessId,
        personId,
        validateBody,
        onGenericError,
      );

      if (response) {
        const { success, details } = response.data;

        if (success && details && isMounted.current) {
          setTrackingClick(ClickableElements.EventIBANValidationSuccess);
          const { bic, financialInstitutionName: bankName, id, city } = details;

          dispatchBankDetails(
            updateBankDetails({ bic, bankName, paymentId: id, city }),
          );
        } else if (!success) {
          isMounted.current && setIBANError(getFrontError());
        }
      }

      isMounted.current && setLoading(false);
    };

    isValidIBAN && businessId && insuranceOwnerId && validate();
  }, [
    isValidIBAN,
    businessId,
    insuranceOwnerId,
    setLoading,
    IBANFormatted,
    getFrontError,
  ]);

  useEffect(() => {
    IBANError && setTrackingError(ErrorTypes.FORMFIELD, { message: IBANError });
  }, [IBANError]);

  const infoIcon: Icon = {
    show: true,
    isModal: true,
    bold: true,
    children: intl.formatMessage(literals.tooltip),
    onToggledOpen: (isOpen: boolean) =>
      isOpen && setTrackingClick(ClickableElements.TooltipBankDetails),
  };

  const hasErrorBeforeSubmit = () => {
    const hasIBANError = IBANError !== '';
    const hasErrorEmail = !email.valid || email.value === '';
    let hasError = false;

    if (hasIBANError || !IBANFormatted.isValid) {
      if (!hasIBANError) {
        setIBANError(getFrontError(IBANFormatted.iban));
      }
      scrollToError(PERSON_INPUTS.IBAN, 0);
      hasError = true;
    }

    if (isEmailInputDisplayed && hasErrorEmail) {
      setSubmitShowEmailError(true);
      if (!hasError) {
        scrollToError(PERSON_INPUTS.offerEmail, 0);
      }
      hasError = true;
    }

    return hasError;
  };

  const onClickNextButton = async () => {
    setTrackingClick(ClickableElements.ButtonNext);

    if (hasErrorBeforeSubmit()) {
      return;
    }

    setLoading(true);

    let updatedPerson = { ...insuranceOwner };

    const { bankName: bankname, bic, paymentId, city } = bankDetails;
    const saveBody = {
      bankname,
      bic,
      iban: IBANFormatted.iban,
      accountHolder,
      paymentId,
    };

    const bankResponse = await saveBankDetails(
      businessId,
      saveBody,
      onGenericError,
    );

    if (!bankResponse) {
      const error = new Error('Undefined bankResponse');
      onGenericError(error);
      return;
    }

    setBankDetails(saveBody);

    updatedPerson = {
      ...updatedPerson,
      bankdata: {
        bic,
        city,
        financialInstitutionName: bankname,
        iban: IBANFormatted.iban,
        id: paymentId,
        mrn: '',
      },
    };

    if (isEmailInputDisplayed) {
      const emailBody: ShippingAddressDto = {
        businessId,
        personId: insuranceOwner!.id as string,
        email: email.value,
      };
      const emailResponse = await postShippingAddress(
        emailBody,
        onGenericError,
      );

      if (!emailResponse) {
        setLoading(false);
        return;
      }

      const { data } = emailResponse;
      if (!data) {
        setLoading(false);
        setShowEmailError(true);
        scrollToError(PERSON_INPUTS.offerEmail, 0);
        return;
      }

      updatedPerson = {
        ...updatedPerson,
        emailOffer: email.value,
      };
    }

    setPersonById(insuranceOwnerId, updatedPerson);

    setLoading(false);
    navigate(VIEWS.finalize);
  };

  const onClickPersonalDataNavigation = async () => {
    setTrackingClick(ClickableElements.ButtonRequestOffer);
    setCheckoutType(CHECKOUT_TYPE.appointment);
    navigate(VIEWS.personalData);
  };
  const onClickBackButton = async () => {
    setTrackingClick(ClickableElements.ButtonBack);
    navigate(VIEWS.summary);
  };

  const buttonsList: SingleButton[] = [
    {
      label: intl.formatMessage(commonLiterals.nextButtonOnlineContract),
      type: 'primary',
      disabled: loading,
      callbackOnClick: onClickNextButton,
      id: ABTestingIds.nextButton,
    },
    {
      label: intl.formatMessage(literals.buttonOffer),
      type: 'secondary',
      hidden: fullOfferDisabled,
      callbackOnClick: onClickPersonalDataNavigation,
      id: ABTestingIds.appointmentButton,
    },
    {
      label: intl.formatMessage(commonLiterals.backButton),
      type: 'text-link',
      callbackOnClick: onClickBackButton,
      id: ABTestingIds.backButton,
    },
  ];

  const onFocusHandler = () => {
    setTrackingClick(ClickableElements.EventIBANValidationStart);
  };

  const onChangeIBAN = (iban: string, selectionStart: number = 0) => {
    const [newIban] = getFilteredTextAndCursorPositon(
      iban,
      selectionStart,
      beautifyIban,
    );

    const _iban = beautifyIban(newIban as string);
    const isValid = isValidIban(newIban as string);
    const normalized = normalizeIban(newIban as string);

    setIBANFormatted({
      iban: _iban,
      isValid,
      normalized,
    });
  };

  interface ValidateIBANInputErrorParams {
    iban: string;
    selectionStart?: number;
    input: EventTarget & HTMLInputElement;
    touched?: boolean;
  }

  const validateIBANInputError = (params: ValidateIBANInputErrorParams) => {
    const { iban, selectionStart, input, touched } = params;
    const inputTouched = touched ?? touchedIBAN;

    const cursor = selectionStart || 0;

    const [newIban, newCursor] = getFilteredTextAndCursorPositon(
      iban,
      cursor,
      beautifyIban,
    );

    const _iban = beautifyIban(newIban as string);
    const isValid = isValidIban(newIban as string);

    runAfterUpdate(() => {
      input.selectionStart = newCursor as number | null;
      input.selectionEnd = newCursor as number | null;
    });

    let error = '';

    if (!isItSEPA(_iban) && _iban.length > 1) error = getFrontError(_iban);

    if (!isValid && inputTouched && _iban.length) {
      error = getFrontError(_iban);
    }

    setIBANError(error);
    setIsValidIBAN(isValid);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { target: input } = event;
    let { value: iban, selectionStart } = input;
    const cursor = selectionStart || 0;

    onChangeIBAN(iban, cursor);
    validateIBANInputError({ iban, selectionStart: cursor, input });
  };

  const onIBANUpdated = (
    iban: string,
    input: EventTarget & HTMLInputElement,
  ) => {
    setTouchedIBAN(true);
    validateIBANInputError({ iban, selectionStart: 0, input, touched: true });
  };

  const title = intl.formatMessage(literals.title);
  const subTitle = intl.formatMessage(literals.subtitle);
  const emailTooltip = intl.formatMessage(literals.emailTooltip);

  const emailHandler = (email: ContactElement) => {
    if (!email.valid || email.value === '')
      scrollToError(PERSON_INPUTS.offerEmail, 0);
    setEmail(email);
  };

  const handleEmailChange = () => {
    setShowEmailError(false);
    setSubmitShowEmailError(false);
  };

  const handleEmailOnError = () => {
    if (showEmailError) {
      scrollToError(PERSON_INPUTS.offerEmail, 0);
    }
  };

  return (
    <PageLayout
      title={title}
      id={ABTestingIds.title}
      subtitle={subTitle}
      infoIcon={infoIcon}
      textAlign="left"
    >
      <div className="abTesting" id={ABTestingIds.IBANfields}>
        <AccountOwner name={accountHolder} />
        <IbanForm
          id={`${PERSON_INPUTS.IBAN}-0`}
          IBAN={IBANFormatted}
          loading={loading}
          error={IBANError}
          onFocus={onFocusHandler}
          onChangeHandler={onChangeHandler}
          onIBANUpdated={onIBANUpdated}
        />
        <SuccessIbanCard
          loading={loading}
          isValid={IBANFormatted.isValid && IBANError === ''}
          details={bankDetails}
        />
      </div>
      <Description />

      {isEmailInputDisplayed && (
        <div className="abTesting" id={ABTestingIds.email}>
          <EmailSection
            id={`${PERSON_INPUTS.offerEmail}-0`}
            data-testid="bankdetails__offer-email-input"
            value={email.value}
            tooltip={emailTooltip}
            onBlur={emailHandler}
            onChange={handleEmailChange}
            onError={handleEmailOnError}
            showError={showEmailError || submitShowEmailError}
          />
        </div>
      )}

      <ActionButtons
        buttonsList={buttonsList}
        id={ABTestingIds.buttonContainer}
      />
    </PageLayout>
  );
};
export default BankDetails;
