import { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { useLoading } from '../context/LoadingCtx';
import useRouteGuard from '../hooks/useRouteGuard';
import { VIEWS } from '../types';
import { getUrlWithoutLastPath } from '../utils';

const NavWrapper = styled.nav`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem;
  text-align: center;
  > * {
    padding: 0.75rem;
    cursor: pointer;
    text-decoration: none;
    color: gray;
  }
`;

const Nav = () => {
  const { loading } = useLoading();
  const { guardApp, defaultView } = useRouteGuard();
  const location = useLocation();

  const navLinks = useMemo(() => {
    return Object.values(VIEWS).map((view, index) => {
      const isGuarded = guardApp(view);
      const urlWithoutLastPath = getUrlWithoutLastPath(location.pathname);
      const link = isGuarded
        ? urlWithoutLastPath + view
        : urlWithoutLastPath + defaultView;
      return (
        <NavLink
          key={index}
          to={link}
          style={({ isActive }) => ({
            color: isActive && isGuarded ? 'black' : 'gray',
          })}
        >
          {view.replace('/', '')}
        </NavLink>
      );
    });
  }, [defaultView, location.pathname, guardApp]);

  if (loading) return <></>;

  return <NavWrapper>{navLinks}</NavWrapper>;
};

export default Nav;
