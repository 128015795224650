import styled from 'styled-components';

import { colors, sizes } from '../../styles/tokens';

interface Props {
  marginBottom?: number;
}

const Divider = styled.div<Props>`
  border-bottom: ${sizes.space.PX_02} solid ${colors.LIGHTEST_GRAY};
  height: ${sizes.space.PX_01};
  width: 100%;
  margin: 0 0 ${({ marginBottom }) => `${marginBottom}px` ?? sizes.space.PX_48}
    0;
`;

export default Divider;
