import { ProductIds, OldProductIds } from 'ppz-otr-common';

import { Products } from '../../types';

const deleteAllPrefix = (products: string): string => {
  return products.replace(/ZAHN_/g, '');
};

const pdfSuffixProducts = new Map<Products, string>();

pdfSuffixProducts.set(Products.DVB, deleteAllPrefix(`${ProductIds.ZAHN_DVB}`));
pdfSuffixProducts.set(
  Products.DS75,
  deleteAllPrefix(`${ProductIds.ZAHN_DS75}`),
);
pdfSuffixProducts.set(
  Products.DS90,
  deleteAllPrefix(`${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DS90}`),
);
pdfSuffixProducts.set(
  Products.DS100,
  deleteAllPrefix(
    `${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DS90}_${ProductIds.ZAHN_DS100}`,
  ),
);
pdfSuffixProducts.set(
  Products.DVE,
  deleteAllPrefix(`${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DVE}`),
);
pdfSuffixProducts.set(
  Products.DVB_DS75,
  deleteAllPrefix(`${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS75}`),
);
pdfSuffixProducts.set(
  Products.DVB_DS90,
  deleteAllPrefix(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DS90}`,
  ),
);
pdfSuffixProducts.set(
  Products.DVB_DS100,
  deleteAllPrefix(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DS90}_${ProductIds.ZAHN_DS100}`,
  ),
);
pdfSuffixProducts.set(
  Products.DVE_DS75,
  deleteAllPrefix(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS75}`,
  ),
);
pdfSuffixProducts.set(
  Products.DVE_DS90,
  deleteAllPrefix(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DS90}`,
  ),
);
pdfSuffixProducts.set(
  Products.DVE_DS100,
  deleteAllPrefix(
    `${ProductIds.ZAHN_DVB}_${ProductIds.ZAHN_DVE}_${ProductIds.ZAHN_DS75}_${ProductIds.ZAHN_DS90}_${ProductIds.ZAHN_DS100}`,
  ),
);

// Old tariffs
pdfSuffixProducts.set(
  Products.ZEZ,
  deleteAllPrefix(`${OldProductIds.ZAHN_ZEZ}`),
);
pdfSuffixProducts.set(
  Products.ZZP,
  deleteAllPrefix(`${OldProductIds.ZAHN_ZZP}`),
);
pdfSuffixProducts.set(
  Products.ZZP_DVB,
  deleteAllPrefix(`${OldProductIds.ZAHN_ZZP}_${ProductIds.ZAHN_DVB}`),
);
pdfSuffixProducts.set(
  Products.ZEF,
  deleteAllPrefix(`${OldProductIds.ZAHN_ZEF}`),
);
pdfSuffixProducts.set(
  Products.ZEF_DVB,
  deleteAllPrefix(`${OldProductIds.ZAHN_ZEF}_${ProductIds.ZAHN_DVB}`),
);

export { pdfSuffixProducts };
