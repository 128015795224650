import { Tariffs } from 'ppz-otr-common';

import { ComparisonTables, CoverageProductsList } from '.';

interface Props {
  products: Tariffs[];
  gotPremium: boolean;
}

export const CoverageList = ({ products, gotPremium }: Props) => {
  return (
    <>
      <CoverageProductsList products={products} />
      <ComparisonTables gotPremium={gotPremium} />
    </>
  );
};
