import styled from 'styled-components';

import { colors, sizes } from '../../../styles/tokens';

const SidebarHeader = styled.div`
  background-color: ${colors.BRAND_COLOR};
  color: ${colors.WHITE};
  padding: ${sizes.space.PX_16};
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`;

export default SidebarHeader;
