import { FormattedMessage } from 'react-intl';

import { DivCenter } from '../../components/Common';
import commonLiterals from '../../language/src/common';

import { Title, Description, ErrorContainer } from './layouts';

const GeneralError = () => {
  return (
    <ErrorContainer>
      <DivCenter>
        <Title>
          <FormattedMessage {...commonLiterals.generalErrorTitle} />
        </Title>
        <Description>
          <FormattedMessage {...commonLiterals.generalErrorParagraph} />
        </Description>
      </DivCenter>
    </ErrorContainer>
  );
};
export default GeneralError;
