import { createIntl, createIntlCache, MessageDescriptor } from 'react-intl';

import { locale } from '../language';
import deLiterals from '../language/de.json';

const cache = createIntlCache();
const intl = createIntl(
  {
    locale: locale,
    messages: deLiterals,
  },
  cache,
);

export const getLiteral = (input: MessageDescriptor | string) => {
  const id = typeof input === 'string' ? input : input.id;
  return intl.formatMessage({ id });
};

export const getLiteralWithFormattedPerson = (
  input: MessageDescriptor | string,
  person: number,
) => {
  const id = typeof input === 'string' ? input : input.id;
  return intl.formatMessage({ id }, { person });
};
