import { useCallback } from 'react';

import { InitOfferResponse } from 'ppz-otr-common';

import { PersonContext, usePersonUpdate } from '../../context/PersonCtx';
import { personsFromPersonData } from '../../utils';

const useInitPersons = () => {
  const {
    setPersons,
    setSpcsPersons,
    setMinInsuredPersons,
    setMaxInsuredPersons,
  } = usePersonUpdate();

  const initPersons = useCallback(
    (persons: InitOfferResponse['persons']) => {
      const newPersons: PersonContext['persons'] =
        personsFromPersonData(persons);

      if (newPersons.size > 0) {
        setPersons(newPersons);
        setSpcsPersons(newPersons);
      }
    },
    [setPersons, setSpcsPersons],
  );

  const initMinMaxInsuredPersons = (min: number, max: number) => {
    setMinInsuredPersons(min);
    setMaxInsuredPersons(max);
  };

  return { initPersons, initMinMaxInsuredPersons };
};

export default useInitPersons;
