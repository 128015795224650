import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Card from '@eg/elements/Card';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import DownloadIcon from '@eg/elements/Icons/DownloadIcon';
import LayoutBox from '@eg/elements/LayoutBox';

import { FEEDBACK_DOWNLOAD } from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/feedback';
import { ActionButtons } from '../../components/Common';
import SummaryGrid from '../../components/SummaryGrid/SummaryGrid';
import SummaryGridItem from '../../components/SummaryGrid/SummaryGridItem';
import { useDates } from '../../context/DatesCtx';
import { useInsurance } from '../../context/InsuranceCtx';
import { useLoadingUpdate } from '../../context/LoadingCtx';
import { usePerson } from '../../context/PersonCtx';
import { useTariff } from '../../context/TariffCtx';
import { useGetAEMFeedbackContent, useTracking } from '../../hooks';
import literals from '../../language/src/feedback';
import literalsSummary from '../../language/src/summary';
import { Container } from '../../layouts';
import {
  APPOINTMENT_TYPE,
  CHECKOUT_TYPE,
  ClickableElements,
  SingleButton,
  VIEWS,
} from '../../types';
import { getProductName } from '../../utils';
import { errorHandler } from '../../utils/handlers';
import { getLiteral } from '../../utils/literals';
import { generatePDF } from '../../utils/submit';
import DetailsTariffPrices from '../Summary/components/DetailsTariffPrices';

import {
  ContentTextCompletion,
  FullWidth,
  GridDetails,
  MainTitle,
  SubHeading,
  SubTitle,
} from './layout';

const Feedback = () => {
  const navigate = useNavigate();

  const { setTrackingClick } = useTracking();

  const { temporalProducts, tariffPriceSelected } = useTariff();
  const { beginningDateSelected } = useDates();
  const { persons, insuranceOwnerId } = usePerson();
  const { businessId, checkoutType, appointmentType } = useInsurance();
  const { setProcessing } = useLoadingUpdate();

  const product = useMemo(() => {
    return getProductName(temporalProducts);
  }, [temporalProducts]);

  const feedbackConsentContents = useGetAEMFeedbackContent();

  const fullname = `${persons.get(insuranceOwnerId)?.name ?? ''} ${
    persons.get(insuranceOwnerId)?.lastname ?? ''
  }`;

  let subtitle = { id: '', defaultMessage: '' };
  let content = { id: '', defaultMessage: '' };

  if (checkoutType === CHECKOUT_TYPE.online) {
    subtitle = literals.onlineSubtitle;
    content = literals.onlineContent;
  } else {
    switch (appointmentType) {
      case APPOINTMENT_TYPE.email:
        subtitle = literals.emailSubtitle;
        content = literals.emailContent;
        break;
      case APPOINTMENT_TYPE.post:
        subtitle = literals.postSubtitle;
        content = literals.postContent;
        break;
      case APPOINTMENT_TYPE.pdf:
        subtitle = literals.pdfSubtitle;
        content = literals.pdfContent;
        break;
      default:
        subtitle = literals.onlineSubtitle;
        content = literals.onlineContent;
    }
  }

  const onError = () => {
    setProcessing(false);
    navigate(VIEWS.error);
  };

  const onClickDownload = async () => {
    try {
      setTrackingClick(ClickableElements.ButtonFeedbackDownload);
      setProcessing(true);
      await generatePDF(
        businessId,
        FEEDBACK_DOWNLOAD,
        checkoutType!,
        temporalProducts,
      );
      setProcessing(false);
    } catch (error: unknown) {
      errorHandler(onError, error);
    } finally {
      setProcessing(false);
    }
  };

  const buttonList: SingleButton[] = [
    {
      label: getLiteral(literals.downloadPdf),
      type: 'secondary',
      iconLeft: DownloadIcon,
      callbackOnClick: onClickDownload,
      id: ABTestingIds.downloadButton,
    },
  ];

  const shouldButtonAppear = appointmentType === APPOINTMENT_TYPE.pdf;

  return (
    <>
      <FullWidth className="abTesting" id={ABTestingIds.titleContainer}>
        <Container>
          <div className="abTesting" id={ABTestingIds.icon}>
            <CheckColorIcon width={40} height={40} />
          </div>
          <MainTitle className="abTesting" id={ABTestingIds.title}>
            <FormattedMessage {...literals.title} values={{ fullname }} />
          </MainTitle>
          <SubTitle className="abTesting" id={ABTestingIds.subtitle}>
            <FormattedMessage {...subtitle} />
          </SubTitle>
        </Container>
      </FullWidth>

      <ContentTextCompletion
        data-testid="contentMessage"
        className="abTesting"
        id={ABTestingIds.message}
      >
        <FormattedMessage
          {...content}
          values={{
            paragraph: (chunks) => <p>{chunks}</p>,
          }}
        />
      </ContentTextCompletion>

      <div className="abTesting" id={ABTestingIds.subheadingContainer}>
        <SubHeading className="abTesting" id={ABTestingIds.subheadingTitle}>
          <FormattedMessage {...literals.subHeading} />
        </SubHeading>
        <Card>
          <SummaryGrid styledComponent={GridDetails} id={ABTestingIds.summary}>
            <SummaryGridItem type="left">
              <FormattedMessage {...literalsSummary.detailsTariffDes} />
            </SummaryGridItem>
            <SummaryGridItem type="right">{product}</SummaryGridItem>

            <SummaryGridItem type="left">
              <FormattedMessage
                {...literalsSummary.detailsBeginningDateTitle}
              />
            </SummaryGridItem>
            <SummaryGridItem type="right">
              {beginningDateSelected}
            </SummaryGridItem>

            <SummaryGridItem type="left">
              <FormattedMessage {...literalsSummary.detailsTariffPrices} />
            </SummaryGridItem>
            <SummaryGridItem type="right">
              <DetailsTariffPrices prices={tariffPriceSelected} />
            </SummaryGridItem>
          </SummaryGrid>
        </Card>
        {shouldButtonAppear && (
          <ActionButtons specialMargin={true} buttonsList={buttonList} />
        )}
      </div>

      <LayoutBox
        horizontalAlignment="center"
        verticalAlignment="center"
        className="abTesting"
        id={ABTestingIds.aemFragment}
      >
        {feedbackConsentContents}
      </LayoutBox>
    </>
  );
};

export default Feedback;
