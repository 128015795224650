export const enum LocalKeys {
  businessId = 'zahn_ppz_otr_businessId',
  oldBusinessId = 'zahn_ppz_otr_oldBusinessId',
  isPreallocation = 'zahn_ppz_otr_isPreallocation',
  insuranceSituation = 'zahn_ppz_otr_insuranceSituationinsuranceSituation',
  checkedInsuranceSituation = 'zahn_ppz_otr_checkedInsuranceSituation',
  consentIsShown = 'zahn_ppz_otr_consentIsShown',
  beginningDate = 'zahn_ppz_otr_beginningDate',
  contribution = 'zahn_ppz_otr_contribution',
  sessionStarted = 'zahn_ppz_otr_sessionStarted',
  checkoutType = 'zahn_ppz_otr_checkoutType',
  appointmentType = 'zahn_ppz_otr_apointmentType',
  izsProducts = 'zahn_ppz_otr_izsProducts',
  izs = 'zahn_ppz_otr_izs',
  izsBusinessId = 'zahn_ppz_otr_izsBusinessId',
  izsDataSynced = 'zahn_ppz_otr_izsDataSynced',
  izsVnSynced = 'zahn_ppz_otr_izsVnSynced',
  izsFirstNavigated = 'zahn_ppz_otr_izsFirstNavigated',
  formSubmitted = 'zahn_ppz_otr_formSubmitted',
  history = 'zahn_ppz_otr_history',
  tracking = 'zahn_ppz_otr_tracking',
  processing = 'zahn_ppz_otr_processing',
  agentNumber = 'zahn_ppz_otr_agentNumber',
  initialAEMProducts = 'zahn_ppz_otr_initialAEMProducts',
}

const storage = {
  get(key: LocalKeys) {
    return sessionStorage.getItem(key);
  },
  set(key: LocalKeys, value: string) {
    sessionStorage.setItem(key, value);
  },
  remove(key: LocalKeys) {
    sessionStorage.removeItem(key);
  },
  clear() {
    sessionStorage.clear();
  },
};

export default storage;
