import { useState, createContext, useContext, Dispatch } from 'react';

import { InitOfferResponse, INSURANCE_TYPE } from 'ppz-otr-common';

import storage, { LocalKeys } from '../services/storage';
import { APPOINTMENT_TYPE, CHECKOUT_TYPE } from '../types';

export interface InsuranceContext {
  businessId: string;
  situation: INSURANCE_TYPE;
  checkoutType: CHECKOUT_TYPE | null;
  appointmentType: APPOINTMENT_TYPE | null;
  bankDetails: InitOfferResponse['bankDetails'] | null;
  formSubmitted: boolean;
  isPreallocation: boolean;
}

export interface InsuranceUpdateContext {
  setBusinessId: Dispatch<InsuranceContext['businessId']>;
  setSituation: Dispatch<InsuranceContext['situation']>;
  setCheckoutType: Dispatch<InsuranceContext['checkoutType']>;
  setAppointmentType: Dispatch<InsuranceContext['appointmentType']>;
  setBankDetails: React.Dispatch<InsuranceContext['bankDetails']>;
  setFormSubmitted: React.Dispatch<InsuranceContext['formSubmitted']>;
  setIsPreallocation: React.Dispatch<InsuranceContext['isPreallocation']>;
}

const localSituation = storage.get(
  LocalKeys.insuranceSituation,
) as INSURANCE_TYPE;
const localBusinessId = storage.get(LocalKeys.businessId);
const localCheckoutType = storage.get(LocalKeys.checkoutType);
const localAppointmentType = storage.get(LocalKeys.appointmentType);
const localFormSubmitted = storage.get(LocalKeys.formSubmitted);
const localIsPreallocation = storage.get(LocalKeys.isPreallocation);
const defaultCtx = {
  businessId: localBusinessId,
  situation: localSituation,
  checkoutType: localCheckoutType ? parseInt(localCheckoutType) : null,
  bankDetails: null,
  appointmentType: localAppointmentType ? parseInt(localAppointmentType) : null,
  formSubmitted: localFormSubmitted === 'true' ? true : false,
  isPreallocation: localIsPreallocation === 'true' ? true : false,
} as InsuranceContext;

export const insuranceCtx = createContext<InsuranceContext>(defaultCtx);
export const insuranceCtxUpdate = createContext<InsuranceUpdateContext>(
  {} as InsuranceUpdateContext,
);

export const useInsurance = () => {
  const insurance = useContext(insuranceCtx);
  if (insurance === undefined)
    throw new Error('useInsurance must be used within a InsuranceContext');
  return insurance;
};

export const useInsuranceUpdate = () => {
  const setInsurance = useContext(insuranceCtxUpdate);
  if (setInsurance === undefined)
    throw new Error(
      'useInsuranceUpdate must be used within a InsuranceUpdateContext',
    );
  return setInsurance;
};

interface Props extends Partial<InsuranceContext> {
  children?: React.ReactNode;
}

export const InsuranceProvider = ({
  children,
  businessId: defaultBusinessId,
  situation: defaultSituation,
  checkoutType: defaultCheckoutType,
  bankDetails: defaultBankDetails,
  appointmentType: defaultAppointmentType,
  formSubmitted: defaultFormSubmitted,
  isPreallocation: defaultIsPreallocation,
}: Props) => {
  const [businessId, setBusinessId] = useState<InsuranceContext['businessId']>(
    defaultBusinessId ?? defaultCtx.businessId,
  );
  const [situation, setSituation] = useState<InsuranceContext['situation']>(
    defaultSituation ?? defaultCtx.situation,
  );
  const [bankDetails, setBankDetails] = useState<
    InsuranceContext['bankDetails']
  >(defaultBankDetails ?? defaultCtx.bankDetails);
  const [checkoutType, setCheckoutType] = useState<
    InsuranceContext['checkoutType']
  >(defaultCheckoutType ?? defaultCtx.checkoutType);
  const [appointmentType, setAppointmentType] = useState<
    InsuranceContext['appointmentType']
  >(defaultAppointmentType ?? defaultCtx.appointmentType);
  const [formSubmitted, setFormSubmitted] = useState<
    InsuranceContext['formSubmitted']
  >(defaultFormSubmitted ?? defaultCtx.formSubmitted);
  const [isPreallocation, setIsPreallocation] = useState<
    InsuranceContext['isPreallocation']
  >(defaultIsPreallocation ?? defaultCtx.isPreallocation);
  return (
    <insuranceCtxUpdate.Provider
      value={{
        setBusinessId: (newBusinessId: string) => {
          storage.set(LocalKeys.businessId, newBusinessId);
          setBusinessId(newBusinessId);
        },
        setSituation: (newSituation: INSURANCE_TYPE) => {
          storage.set(LocalKeys.insuranceSituation, newSituation);
          setSituation(newSituation);
        },
        setCheckoutType: (newCheckoutType: CHECKOUT_TYPE | null) => {
          if (!!newCheckoutType) {
            storage.set(LocalKeys.checkoutType, String(newCheckoutType));
          }
          setCheckoutType(!!newCheckoutType ? Number(newCheckoutType) : null);
        },
        setBankDetails,
        setAppointmentType: (newAppointmentType: APPOINTMENT_TYPE | null) => {
          if (!!newAppointmentType) {
            storage.set(LocalKeys.appointmentType, String(newAppointmentType));
          }
          setAppointmentType(
            !!newAppointmentType ? Number(newAppointmentType) : null,
          );
        },
        setFormSubmitted: (newFormSubmitted: boolean) => {
          storage.set(LocalKeys.formSubmitted, String(newFormSubmitted));
          setFormSubmitted(newFormSubmitted);
        },
        setIsPreallocation: (_isPreallocation: boolean) => {
          storage.set(LocalKeys.isPreallocation, String(_isPreallocation));
          setIsPreallocation(_isPreallocation);
        },
      }}
    >
      <insuranceCtx.Provider
        value={{
          businessId,
          situation,
          checkoutType,
          bankDetails,
          appointmentType,
          formSubmitted,
          isPreallocation,
        }}
      >
        {children}
      </insuranceCtx.Provider>
    </insuranceCtxUpdate.Provider>
  );
};

export default InsuranceProvider;
