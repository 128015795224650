// 1rem = 16PX, referral value to make conversion

export enum text {
  /** 4px */
  XXS = '0.25rem',
  /** 8px */
  XS = '0.5rem',
  /** 12px */
  S = '0.75rem',
  /** 14px */
  SM = '0.875rem',
  /** 16px */
  M = '1rem',
  /** 18px */
  L = '1.125rem',
  /** 20px */
  LG = '1.25rem',
  /** 24px */
  XL = '1.5rem',
  /** 28px */
  XXL = '1.75rem',
  /** 32px */
  H4 = '2rem',
  /** 36px */
  H3 = '2.25rem',
  /** 40px */
  H2 = '2.5rem',
  /** 48px */
  H1 = '3rem',
}

export enum space {
  /** 1px */
  PX_01 = '0.0625rem',
  /** 2px */
  PX_02 = '0.125rem',
  /** 4px */
  PX_04 = '0.25rem',
  /** 5px */
  PX_05 = '0.313rem',
  /** 6px */
  PX_06 = '0.375rem',
  /** 8px */
  PX_08 = '0.5rem',
  /** 12px */
  PX_12 = '0.75rem',
  /** 13px */
  PX_13 = '0.813rem',
  /** 14px*/
  PX_14 = '0.875rem',
  /** 15px*/
  PX_15 = '0.938rem',
  /** 16px */
  PX_16 = '1rem',
  /** 17px */
  PX_17 = '1.063rem',
  /** 20px */
  PX_20 = '1.25rem',
  /** 24px */
  PX_24 = '1.5rem',
  /** 25px */
  PX_25 = '1.563rem',
  /** 28px */
  PX_28 = '1.75rem',
  /** 32px */
  PX_32 = '2rem',
  /** 40px */
  PX_40 = '2.5rem',
  /** 48px */
  PX_48 = '3rem',
  /** 52px */
  PX_52 = '3.25rem',
  /** 58px */
  PX_58 = '3.625rem',
  /** 60px */
  PX_60 = '3.75rem',
  /** 64px */
  PX_64 = '4rem',
  /** 74px */
  PX_74 = '4.625rem',
  /** 92px */
  PX_92 = '5.75rem',
  /** 95px */
  PX_95 = '5.93rem',
  /** 112px */
  PX_112 = '7rem',
  /** 150px */
  PX_150 = '9.375rem',
}
