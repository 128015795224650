import styled, { css } from 'styled-components';

import { sizes } from '../../../styles/tokens';
import { WarningElementWrapper } from '../types';

const WarningTitleWrapper = styled.div<WarningElementWrapper>`
  ${({ isSEPA }) => css`
    margin-top: ${isSEPA ? sizes.space.PX_24 : 0};
    font-size: ${isSEPA ? sizes.text.SM : sizes.text.M};
  `}
`;

export { WarningTitleWrapper };
