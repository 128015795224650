import { useCallback } from 'react';

import { InitOfferResponse, Person, OFFER_ROLES } from 'ppz-otr-common';

import { useInsuranceUpdate } from '../../context/InsuranceCtx';

const useInitBankDetails = () => {
  const { setBankDetails } = useInsuranceUpdate();

  const initBankDetails = useCallback(
    (persons: Person[], bankDetails: InitOfferResponse['bankDetails']) => {
      const insuranceOwnerRole = OFFER_ROLES.insuranceOwner;
      const insuranceOwner = persons.find(
        ({ role }) => role === insuranceOwnerRole,
      );

      if (!!insuranceOwner) {
        const { bankdata } = insuranceOwner;
        const city = bankdata?.city;
        const paymentId = bankdata?.id;

        if (bankDetails !== null) {
          setBankDetails({
            ...bankDetails,
            paymentId,
            city,
          });
        }
      }
    },
    [setBankDetails],
  );

  return initBankDetails;
};

export default useInitBankDetails;
