import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';

export const FormBirthdates = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 380px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.S}) {
    margin: 0 ${sizes.space.PX_25} 0 auto;
  }

  > div {
    margin: 0.75rem;
    width: 100%;
  }

  > button {
    align-self: center;
    margin-top: 1rem;
  }

  .ee_control-with-hint {
    flex-grow: 1;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
`;

interface ButtonWrapperProps {
  hide?: boolean;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  margin: 0.25rem 0 0 0.75rem;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;

export const TooltipParagraph = styled.p`
  font-size: ${sizes.text.M};
  margin-bottom: ${sizes.space.PX_24};
`;
