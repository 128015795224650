const literals = {
  // TODO: Review
  MR: 'personal-data-form.label-salutation-masculine',
  MS: 'personal-data-form.label-salutation-female',
  tariff: {
    summary: {
      start: 'sidebar.summary.starting-price',
      final: 'sidebar.summary.tariff-contribution',
      common: 'sidebar.summary.tariff-common',
    },
    breakdown: {
      start: 'sidebar.breakdown.starting-price',
      final: 'sidebar.breakdown.tariff-contribution',
      common: 'sidebar.breakdown.tariff-common',
    },
  },
};

export { literals };
