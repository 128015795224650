import { Person, OFFER_ROLES, INSURANCE_TYPE } from 'ppz-otr-common';

import ABTestingIds from '../../../ABTesting/summary';
import { useTracking } from '../../../hooks';
import { ClickableElements } from '../../../types';
import {
  formatPerson,
  FormattedPerson,
  getInsuranceOwnerTitle,
  getInsuredPersonTitle,
  transformAddress,
} from '../utils';

import DetailsPerson from './DetailsPerson';

interface DetailsPersonsListProps {
  persons: Person[];
  insuranceOwnerId: Person['id'];
  situation: INSURANCE_TYPE;
}

const DetailsPersonsList = ({
  persons,
  insuranceOwnerId,
  situation,
}: DetailsPersonsListProps) => {
  const { setTrackingClick } = useTracking();

  const formattedPersons = persons.map((person) => formatPerson(person));
  const insuredPersons = formattedPersons.filter(
    ({ role }) => role === OFFER_ROLES.insuredPerson,
  );

  const _tracking = (
    person: FormattedPerson,
    index: number,
    type: 'link' | 'accordion',
  ) => {
    const { role } = person;

    if (role === OFFER_ROLES.insuranceOwner) {
      const element =
        type === 'link'
          ? ClickableElements.LinkToEditInsuredOwner
          : ClickableElements.AccordionInsuredOwner;
      setTrackingClick(element);
    } else {
      switch (index) {
        case 1:
          type === 'link'
            ? setTrackingClick(ClickableElements.LinkToEditInsuredPerson1)
            : setTrackingClick(ClickableElements.AccordionInsuredPerson1);
          break;
        case 2:
          type === 'link'
            ? setTrackingClick(ClickableElements.LinkToEditInsuredPerson2)
            : setTrackingClick(ClickableElements.AccordionInsuredPerson2);
          break;
        case 3:
          type === 'link'
            ? setTrackingClick(ClickableElements.LinkToEditInsuredPerson3)
            : setTrackingClick(ClickableElements.AccordionInsuredPerson3);
          break;
        case 4:
          type === 'link'
            ? setTrackingClick(ClickableElements.LinkToEditInsuredPerson4)
            : setTrackingClick(ClickableElements.AccordionInsuredPerson4);
          break;
        default:
      }
    }
  };

  const getCurrentTitle = (id: Person['id'], order: number) => {
    const isInsuranceOwner = insuranceOwnerId === id;

    if (isInsuranceOwner) {
      return getInsuranceOwnerTitle(situation);
    }

    const index = insuredPersons.length > 1 ? order : undefined; // If only one insured person, no index required
    return getInsuredPersonTitle(situation, index);
  };

  return (
    <div>
      {formattedPersons.map((person, index) => (
        <DetailsPerson
          title={getCurrentTitle(person.id, index)}
          address={transformAddress(person.address)}
          birthDate={person.birthDate}
          email={person.email}
          phone={person.phone}
          fullName={person.fullName}
          gender={person.gender}
          key={`${person.id}${index}`}
          id={`${ABTestingIds.prefixPersonDetails}${index}`}
          idTop={`${ABTestingIds.prefixPersonDetailsTop}${index}`}
          idButton={`${ABTestingIds.prefixPersonDetailsButton}${index}`}
          onClick={() => _tracking(person, index, 'link')}
          onToggle={() => _tracking(person, index, 'accordion')}
        />
      ))}
    </div>
  );
};

export default DetailsPersonsList;
