import { SpcsCountryCode, SpcsContactType, SpcsConsent } from 'ppz-otr-common';

import {
  PersonInfo,
  ValidateInfoDto,
  ValidatePersonalDataResponse,
} from '../../../services/personalData';

const getPhoneNumbers = (
  person: PersonInfo,
): ValidatePersonalDataResponse['telephoneNumbers'] => {
  const { consentTelephony, telephoneNumbers, id } = person;

  if (!consentTelephony || !telephoneNumbers) {
    return [];
  }

  const [firstPhone] = telephoneNumbers;
  return [
    {
      contactType: firstPhone.contactType,
      details: {
        areaCode: '0365',
        country: SpcsCountryCode.DE,
        dialNumber: '65565',
        internationalAreaCode: '+49',
      },
      number: firstPhone.number,
      telephoneType: firstPhone.telephoneType,
      id: `telephoneId:${id}`,
    },
  ];
};

const getEmailAddresses = (
  person: PersonInfo,
): ValidatePersonalDataResponse['emailAddresses'] => {
  const { consentEmail, emailAddresses, id } = person;

  if (!consentEmail || !emailAddresses) {
    return [];
  }

  const [firstEmail] = emailAddresses;
  return [
    {
      contactType: SpcsContactType.PERSONAL,
      email: firstEmail.email,
      id: `emailId:${id}`,
    },
  ];
};

export const getValidateResponse = (
  bodyRequest: ValidateInfoDto,
): ValidatePersonalDataResponse[] => {
  const { persons } = bodyRequest;

  return persons.map((person) => {
    const { id } = person;
    const { city, postalCode, street, streetNumber } = person.addresses[0];

    const emailAddressesResponse = getEmailAddresses(person);
    const phonesResponse = getPhoneNumbers(person);

    const { YES } = SpcsConsent;
    const emailConsent = emailAddressesResponse?.length ? YES : null;
    const telephonyConsent = phonesResponse?.length ? YES : null;

    const response: ValidatePersonalDataResponse = {
      personId: id,
      error: false,
      errorUpdatingInformation: false,
      advertiseConsent: { telephonyConsent, emailConsent },
      emailAddresses: emailAddressesResponse,
      telephoneNumbers: phonesResponse,
      errorValidatingAddress: false,
      addressValidationResult: null,
      autoCorrected: false,
      addresses: [
        {
          id: `addressId:${id}`,
          street,
          streetNumber,
          postalCode,
          city,
          country: SpcsCountryCode.DE,
        },
      ],
    };
    // Return an validation phone number error
    if (
      phonesResponse?.length &&
      phonesResponse[0].number === '+99999999 36565565'
    ) {
      return {
        ...response,
        error: true,
        errorUpdatingInformation: true,
        addresses: [],
        emailAddresses: [],
        dataErrorInformationUpdated: {
          data: {
            message: 'Could not update existing person.',
            emailError: false,
            telephoneError: true,
          },
          status: 422,
        },
      };
    }

    // Return an autocorrected address response
    if (street === 'Messedamm') {
      return {
        ...response,
        advertiseConsent: {
          telephonyConsent: null,
          emailConsent: null,
          bereich: null,
          erfasser: null,
          erfassungszeitpunkt: null,
        },
        autoCorrected: true,
        addressesUpdated: [
          {
            id: `addressId:${id}`,
            street: 'Messestr.',
            streetNumber,
            postalCode: '06116',
            city,
            country: SpcsCountryCode.DE,
          },
        ],
      };
    }

    // Return an Address error validation response
    if (street === 'Ferdinandstr') {
      return {
        ...response,
        addressValidationResult: {
          code: '6050003',
          where: 3,
          needAgent: true,
        },
        error: true,
        errorValidatingAddress: true,
        autoCorrected: true,
        addressesUpdated: [
          {
            id: `addressId:${id}`,
            street,
            streetNumber,
            postalCode,
            city,
            country: SpcsCountryCode.DE,
          },
        ],
      };
    }

    return response;
  });
};
