import { useState, useEffect, useCallback } from 'react';

import { debounce } from '../utils';

const useResize = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  const onResize = useCallback(
    debounce(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }),
    [],
  );

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      document.body.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return [width, height];
};

export default useResize;
