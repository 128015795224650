import React from 'react';

import styled from 'styled-components';

interface IProps {
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  margin: 0;
`;

const DivCenter = ({ children }: IProps) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default DivCenter;
