import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';

export const CustomProductName = styled.h3`
  font-size: ${sizes.text.L};
  letter-spacing: 0;
  line-height: ${sizes.space.PX_24};
  font-weight: 700;
  margin-bottom: ${sizes.space.PX_16};

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.LG};
    margin-bottom: ${sizes.space.PX_24};
    line-height: ${sizes.space.PX_28};
  }
`;
