const enum PersonalDataABTestingIds {
  title = 'ppz501',
  sectionsWrapper = 'ppz502',
  consent = 'ppz503',
  offerEmail = 'ppz504',
  buttonContainer = 'ppz505',
  onlineButton = 'ppz506',
  appointmentButton = 'ppz507',
  emailButton = 'ppz508',
  pdfButton = 'ppz509',
  postButton = 'ppz510',
  backButton = 'ppz511',
  personInfoPrefix = 'ppz55',
}

export default PersonalDataABTestingIds;
