import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { DateInputValue } from '@eg/elements/components/DateInput';

import {
  BirthdateData,
  ContributionDetails,
  INSURANCE_TYPE,
  OFFER_ROLES,
} from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/birthdates';
import { useDates } from '../../context/DatesCtx';
import { useInsurance } from '../../context/InsuranceCtx';
import { useIzsIntegration } from '../../context/IzsIntegrationCtx';
import { useLoading, useLoadingUpdate } from '../../context/LoadingCtx';
import {
  PersonContext,
  usePerson,
  usePersonUpdate,
} from '../../context/PersonCtx';
import { useTariff, useTariffUpdate } from '../../context/TariffCtx';
import { useResetContributionTariff, useTracking } from '../../hooks';
import literals from '../../language/src/birthdates';
import { Icon, SingleButton, VIEWS, ClickableElements } from '../../types';
import { isZEZ } from '../../utils';

import BirthDatesInput from './components/BirthDatesInput';
import useBirthdateBackend, {
  CurrentValues,
  UpdateCurrentValues,
} from './hooks/useBirthdateBackend';
import useBirthdateState from './hooks/useBirthdateState';
import { TooltipParagraph } from './layouts';
import BirthdatesLayout from './layouts/BirthdatesLayout';
import { BirthdateMap } from './types';
import {
  CommonButtonsIds,
  getCommonButtons,
  getDefaultDateValue,
  getDefaultValues,
  hasErrors,
  onBlurDate,
  ONBLUR_ACTION_TIMEOUT,
  onChangeDate,
  onGenericError,
} from './utils';

const InsuranceOwnerBirthdate = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { setTrackingClick } = useTracking();

  const { isIzs, isIzsVnSynced } = useIzsIntegration();
  const { businessId, situation, isPreallocation } = useInsurance();
  const { dateValues, disabled, setDateValues, setDisabled } =
    useBirthdateState();

  const { validationDates } = useDates();
  const { adult, max, min } = validationDates;
  const dateAdult = new Date(adult);
  const maxDate = new Date(max);
  const minDate = new Date(min);

  const { loading } = useLoading();
  const { setLoading } = useLoadingUpdate();

  const { products, premiumProducts, contributionSelected } = useTariff();
  const {
    setBasicTariffPrices,
    setTariffPriceSelected,
    setPremiumProducts,
    setPremiumTariffPrices,
  } = useTariffUpdate();

  const { persons, spcsPersons, insuranceOwnerId } = usePerson();
  const { setPersons, setSpcsPersons } = usePersonUpdate();

  const requestTimeout = useRef<NodeJS.Timeout | null>(null);

  const updatePersons = (
    newPersons: PersonContext['persons'],
    newDateValues: React.SetStateAction<BirthdateMap>,
  ) => {
    setPersons(newPersons);
    setSpcsPersons(newPersons);
    setDateValues(newDateValues);
    setLoading(false);
  };

  const updateDateValues = (newDateValues: BirthdateMap) => {
    setDateValues(newDateValues);
    setLoading(false);
  };

  const updateTariffs = ({
    basicPrices,
    premiumPrices,
    premiumProducts,
  }: ContributionDetails) => {
    setBasicTariffPrices(basicPrices);
    if (contributionSelected === 'basic' || contributionSelected === '') {
      setTariffPriceSelected(basicPrices);
    } else {
      setTariffPriceSelected(premiumPrices);
    }
    setPremiumProducts(premiumProducts);
    setPremiumTariffPrices(premiumPrices);
  };

  const doNavigation = () => {
    switch (situation) {
      case INSURANCE_TYPE.myselfAndMore:
        navigate(VIEWS.myselfAndMore);
        break;
      case INSURANCE_TYPE.others:
        navigate(VIEWS.others);
        break;
      default:
        navigate(VIEWS.insuranceBeginning);
        break;
    }
  };

  const genericError = () => onGenericError(setLoading, navigate);

  const birthdateBackendData: CurrentValues = {
    businessId,
    dateValues,
    persons,
    spcsPersons,
    situation,
    type: INSURANCE_TYPE.myself,
    initialProducts: {
      basic: products,
      premium: premiumProducts,
    },
    maxDate,
    minDate,
    intl,
  };
  const birthdateBackendUpdater: UpdateCurrentValues = {
    updatePersons,
    updateDateValues,
    updateTariffs,
    setLoading,
    genericError,
  };
  const { saveChanges } = useBirthdateBackend(
    birthdateBackendData,
    birthdateBackendUpdater,
  );

  const clearExistingTimeout = () => {
    if (!!requestTimeout.current) clearTimeout(requestTimeout.current);
    requestTimeout.current = null;
  };

  const onNext = () => {
    clearExistingTimeout();
    setTrackingClick(ClickableElements.ButtonNext);
    saveChanges(doNavigation);
  };

  const tooltipLiteral = isZEZ(products)
    ? literals.tooltipZEZ
    : literals.tooltip;

  const infoIcon: Icon = {
    show: true,
    isModal: true,
    bold: true,
    children: intl.formatMessage(
      { ...tooltipLiteral },
      {
        p: (str: string) => <TooltipParagraph>{str}</TooltipParagraph>,
      },
    ),
    onToggledOpen: (isOpen: boolean) =>
      isOpen && setTrackingClick(ClickableElements.TooltipBirthdates),
  };

  const onBack = () => {
    clearExistingTimeout();
    setTrackingClick(ClickableElements.ButtonBack);
    saveChanges(() => navigate(VIEWS.initialSelection));
  };

  const onChange = (
    date: DateInputValue,
    errors: any,
    key: BirthdateData['id'],
  ) => {
    const newDateValues = onChangeDate(dateValues, date, errors, key);
    const errorInDates = hasErrors(newDateValues);
    setDateValues(newDateValues);
    setDisabled(errorInDates);
  };

  const onBlur = () => {
    onBlurDate(dateValues, setDisabled);

    clearExistingTimeout();
    requestTimeout.current = setTimeout(() => {
      saveChanges();
    }, ONBLUR_ACTION_TIMEOUT);
  };

  const getDescription = (): string => {
    switch (situation) {
      case INSURANCE_TYPE.myself:
      case INSURANCE_TYPE.myselfAndMore:
        return intl.formatMessage(literals.mySelfMySelfAndOthersHint);
      default:
        return intl.formatMessage(literals.mySelfOthersHint);
    }
  };

  const buttonIds: CommonButtonsIds = {
    next: ABTestingIds.nextButton,
    back: ABTestingIds.backButton,
  };

  const commonButtons: SingleButton[] = getCommonButtons(
    disabled,
    onNext,
    onBack,
    intl,
    buttonIds,
  );

  const title = intl.formatMessage(
    (!isIzs || (isIzs && !isIzsVnSynced)) && !isPreallocation
      ? literals.mySelfTitle
      : literals.checkBirthdateIzs,
  );

  const pageSubtitle =
    situation !== INSURANCE_TYPE.others
      ? intl.formatMessage(literals.mySelfSubTitle)
      : intl.formatMessage(literals.mySelfOthersSubtitle);

  const insuranceOwner = dateValues.get(insuranceOwnerId ?? 0);

  useResetContributionTariff(genericError);

  useEffect(() => {
    return () => {
      clearExistingTimeout();
    };
  }, []);

  useEffect(() => {
    const defaultValue: BirthdateData = getDefaultDateValue(
      OFFER_ROLES.insuranceOwner,
    );
    const { newDates, disabled } = getDefaultValues(
      INSURANCE_TYPE.myself,
      persons,
      defaultValue,
      maxDate,
      minDate,
    );
    setDateValues(newDates);
    setDisabled(disabled);
  }, []);

  return (
    <BirthdatesLayout
      title={title}
      titleId={ABTestingIds.ownerTitle}
      subtitle={pageSubtitle}
      infoIcon={infoIcon}
      buttonsList={commonButtons}
      buttonListId={ABTestingIds.buttonContainer}
      loading={loading}
    >
      {validationDates.min !== '' && !!insuranceOwner && (
        <BirthDatesInput
          value={insuranceOwner}
          description={getDescription()}
          onDateChange={onChange}
          onBlur={onBlur}
          minDate={minDate}
          maxDate={dateAdult}
          yesterdayDate={maxDate}
          id={ABTestingIds.ownerDate}
        />
      )}
    </BirthdatesLayout>
  );
};

export default InsuranceOwnerBirthdate;
