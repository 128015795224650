import { BankDetails } from '../types';

export const enum BANK_DETAILS_TYPES {
  UPDATE_BIC = 'UPDATE_BIC',
  UPDATE_BANK_NAME = 'UPDATE_BANK_NAME',
  UPDATE_PAYMENT_ID = 'UPDATE_PAYMENT_ID',
  UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS',
}

export type BankDetailsAction = {
  type: BANK_DETAILS_TYPES;
  payload: string | BankDetails;
};

export const updateBic = (bic: string) => ({
  type: BANK_DETAILS_TYPES.UPDATE_BIC,
  payload: bic,
});

export const updateBankName = (name: string) => ({
  type: BANK_DETAILS_TYPES.UPDATE_BANK_NAME,
  payload: name,
});

export const updatePaymentId = (id: string) => ({
  type: BANK_DETAILS_TYPES.UPDATE_PAYMENT_ID,
  payload: id,
});

export const updateBankDetails = (bankDetails: BankDetails) => ({
  type: BANK_DETAILS_TYPES.UPDATE_BANK_DETAILS,
  payload: bankDetails,
});
