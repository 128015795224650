import { Products } from '../../types';
import { literals as contributionLiterals } from '../../views/Contribution/literals';

const productUspRelationship = new Map<Products, string[]>();

productUspRelationship.set(
  Products.DS75,
  contributionLiterals.usp.protection._75,
);
productUspRelationship.set(
  Products.DS90,
  contributionLiterals.usp.protection._90,
);
productUspRelationship.set(
  Products.DS100,
  contributionLiterals.usp.protection._100,
);
productUspRelationship.set(
  Products.DVB,
  contributionLiterals.usp.prevention.basic,
);
productUspRelationship.set(
  Products.DVE,
  contributionLiterals.usp.prevention.premium,
);
productUspRelationship.set(Products.DVB_DS75, [
  ...contributionLiterals.usp.prevention.basic,
  ...contributionLiterals.usp.protection._75,
]);
productUspRelationship.set(Products.DVB_DS90, [
  ...contributionLiterals.usp.prevention.basic,
  ...contributionLiterals.usp.protection._90,
]);
productUspRelationship.set(Products.DVB_DS100, [
  ...contributionLiterals.usp.prevention.basic,
  ...contributionLiterals.usp.protection._100,
]);
productUspRelationship.set(Products.DVE_DS75, [
  ...contributionLiterals.usp.prevention.premium,
  ...contributionLiterals.usp.protection._75,
]);
productUspRelationship.set(Products.DVE_DS90, [
  ...contributionLiterals.usp.prevention.premium,
  ...contributionLiterals.usp.protection._90,
]);
productUspRelationship.set(Products.DVE_DS100, [
  ...contributionLiterals.usp.prevention.premium,
  ...contributionLiterals.usp.protection._100,
]);

// old tariffs
productUspRelationship.set(Products.ZEZ, contributionLiterals.usp.old.zez);
productUspRelationship.set(Products.ZZP, contributionLiterals.usp.old.zzp);
productUspRelationship.set(Products.ZZP_DVB, [
  ...contributionLiterals.usp.old.zzp,
  ...contributionLiterals.usp.prevention.basic,
]);
productUspRelationship.set(Products.ZEF, contributionLiterals.usp.old.zef);
productUspRelationship.set(Products.ZEF_DVB, [
  ...contributionLiterals.usp.old.zef,
  ...contributionLiterals.usp.prevention.basic,
]);

export { productUspRelationship };
