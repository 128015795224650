import { useState, ChangeEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Button from '@eg/elements/Button';
import Checkbox from '@eg/elements/Checkbox';

import styled from 'styled-components';

import DisclaimerCard from '../../components/DisclaimerCard/DisclaimerCard';
import literals from '../../language/src/insurance-situation';
import { sizes } from '../../styles/tokens';

import ModalRelatives from './ModalRelatives';

export interface DisclaimerProps {
  onChangeAccept: (isAccepted: boolean) => void;
  checked: boolean;
  id: string;
  idModal: string;
  onOpened: () => void;
  onClosed: () => void;
}

interface ParagraphProps {
  bold?: boolean;
}

const Paragraph = styled.p<ParagraphProps>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: ${sizes.text.M};
`;

const Wrapper = styled.div`
  margin-top: ${sizes.space.PX_28};
`;

const ButtonLink = styled(Button)`
  .ee_button__wrapper {
    padding: 0 !important;
    font-size: ${sizes.text.M};
  }
`;

const Disclaimer = ({
  onChangeAccept,
  checked,
  id,
  idModal,
  onClosed,
  onOpened,
}: DisclaimerProps) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedConditions, setCheckedConditions] = useState(checked);
  const closeModalHandler = () => {
    setIsModalOpen(false);
    onClosed();
  };

  const linkModalHandler = () => {
    setIsModalOpen(true);
    onOpened();
  };

  const checkboxChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedConditions(event.target.checked);
    onChangeAccept(event.target.checked);
  };

  useEffect(() => {
    setCheckedConditions(checked);
  }, [checked]);

  return (
    <Wrapper className="abTesting" id={id}>
      <DisclaimerCard>
        <Paragraph bold>
          {intl.formatMessage(literals.disclaimerTitle)}
        </Paragraph>
        <Paragraph>
          {intl.formatMessage(literals.disclaimerParagraph1, {
            link: (str) => (
              <ButtonLink
                variant="text-link"
                onClick={linkModalHandler}
                type="button"
                data-testid="buttonLinkModal"
              >
                {str}
              </ButtonLink>
            ),
          })}
        </Paragraph>
        <Paragraph>
          {intl.formatMessage(literals.disclaimerParagraph2)}
        </Paragraph>
        <Paragraph>
          <Checkbox
            verticalAlign="top"
            label={intl.formatMessage(literals.disclaimerCheckbox)}
            onChange={checkboxChangeHandler}
            checked={checkedConditions}
          />
        </Paragraph>
      </DisclaimerCard>
      <ModalRelatives
        open={isModalOpen}
        onDismiss={closeModalHandler}
        id={idModal}
      ></ModalRelatives>
    </Wrapper>
  );
};

export default Disclaimer;
