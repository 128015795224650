import { CrudBirthdatesRequest, CrudBirthdatesResponse } from 'ppz-otr-common';

import { getContributionDetails } from '../../common/responses';
import { getPersonId } from '../../utils';

export const getCrudResponse = (
  bodyRequest: CrudBirthdatesRequest,
): CrudBirthdatesResponse => {
  const { newDates, updatedDates, deletedDates, initialProducts } = bodyRequest;
  const hasNewDates = newDates.length > 0;
  const hasUpdatedDates = updatedDates.length > 0;
  const hasDeletedDates = deletedDates.length > 0;

  const contributionDetails = getContributionDetails(initialProducts);

  if (!hasNewDates && !hasUpdatedDates && hasDeletedDates) {
    return {
      dates: [],
      contributionDetails,
    };
  }

  if (hasNewDates) {
    const [body] = newDates;
    const { id } = body;
    const mustReturnBackendId = typeof id === 'number';
    const newId = mustReturnBackendId ? getPersonId() : id;
    const newDatesResponse: CrudBirthdatesResponse['dates'] = [
      {
        ...body,
        id: newId,
      },
    ];

    return {
      dates: newDatesResponse,
      contributionDetails,
    };
  }

  // If has updated dates
  const [body] = updatedDates;
  const { id, day, month, year, role } = body;
  const updatedDatesResponse: CrudBirthdatesResponse['dates'] = [
    {
      id,
      day,
      month,
      year,
      role,
      errors: { valid: true },
    },
  ];
  return {
    dates: updatedDatesResponse,
    contributionDetails,
  };
};
