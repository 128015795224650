import { Person, OFFER_ROLES } from 'ppz-otr-common';

import { PERSON_INPUTS } from '../../../types';

import { hasValue } from './index';

export const getEmptyAddress = (person: Person): PERSON_INPUTS | null => {
  const { address, diffAddress, role } = person;

  if (diffAddress || role === OFFER_ROLES.insuranceOwner) {
    if (!address) return PERSON_INPUTS.street;
    const { street, streetNumber, postalCode, city } = address;
    if (!hasValue(street)) return PERSON_INPUTS.street;
    if (!hasValue(streetNumber)) return PERSON_INPUTS.streetNumber;
    if (!hasValue(postalCode) || !hasValue(city))
      return PERSON_INPUTS.postalCode;
  }

  return null;
};
