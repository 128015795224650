import { createGlobalStyle, css } from 'styled-components';

import { breakpoints } from './tokens';

interface Props {
  dev?: boolean;
  debugAB: boolean;
}

const getDevStyles = (dev = false, showABOutline = false) => {
  if (!dev) return null;

  const devStyles = css`
    .ee_input::placeholder {
      opacity: 0.3;
    }
  `;

  const ABTestingStyles = !showABOutline
    ? null
    : css`
        .abTesting {
          outline: 1px solid red !important;
          position: relative;
        }

        .abTesting::before {
          color: red;
          content: attr(id);
          position: absolute;
          right: 0;
          top: 0;
          font-size: 0.75rem;
          z-index: 1;
        }

        .abTesting.abTestingBottom::before {
          top: unset;
          bottom: 0;
        }

        .abTesting.abTestingOut::before {
          right: -60px;
        }

        .abTesting.abTestingOutBottom::before {
          top: unset;
          bottom: -20px;
        }
        .abTesting.abTestingOutLeft::before {
          top: -20px;
          left: 0;
          bottom: unset;
        }
      `;

  return devStyles.concat(ABTestingStyles);
};

export default createGlobalStyle<Props>`
  #comparisonTableModal .ee_modal__content {
    padding: 10px 0;
    height: 500px;
    overflow-y: hidden;

    @media (min-width: ${breakpoints.M}) {
      height: 800px;
    }

    @media (min-width: ${breakpoints.L}) {
      height: 800px;
    }
  }

  #comparisonTableModal .ee_modal__main {
    width: 350px;

    @media (min-width: 650px) {
      width: 600px;
    }

    @media (min-width: ${breakpoints.M}) {
      width: 700px;
    }

    @media (min-width: ${breakpoints.L}) {
      width: 950px;
    }
  }

  #comparisonTableModal iframe {
    border: none;
    width: 100%;
    height: 100%;

    &__hidden {
      visibility: hidden;
    }
  }
  ${({ dev, debugAB: showABOutline }) => getDevStyles(dev, showABOutline)}
`;
