import { getTextInputValue } from '../../utils';

import { pageNames } from './helpers';

export interface TrackingPageNames {
  insuranceSituation: string;
  birthdatesMySelf: string;
  birthdatesMySelfAndOthers: string;
  birthdatesOnlyOthers: string;
  insuranceBeginning: string;
  contribution: string;
  personalData: string;
  summary: string;
  bankDetails: string;
  finalize: string;
  onlineFeedback: string;
  offerFeedback: string;
}

const useGetTrackingPageNames = (): TrackingPageNames => {
  const insuranceSituation =
    getTextInputValue('insuranceSituationPageName') || pageNames.situation;
  const birthdatesMySelf =
    getTextInputValue('birthdateMySelfPageName') || pageNames.birthdatesMySelf;
  const birthdatesMySelfAndOthers =
    getTextInputValue('birthdateMySelfAndOthersPageName') ||
    pageNames.birthdatesMySelfAndOthers;
  const birthdatesOnlyOthers =
    getTextInputValue('birthdateOnlyOthersPageName') ||
    pageNames.birthdatesOnlyOthers;
  const insuranceBeginning =
    getTextInputValue('insuranceBeginningPageName') || pageNames.beginning;
  const contribution =
    getTextInputValue('contributionPageName') || pageNames.contribution;
  const personalData =
    getTextInputValue('personalDataPageName') || pageNames.personalData;
  const summary = getTextInputValue('summaryPageName') || pageNames.summary;
  const bankDetails = getTextInputValue('IBANPageName') || pageNames.bank;
  const finalize = getTextInputValue('finalizePageName') || pageNames.finalize;
  const onlineFeedback =
    getTextInputValue('onlineFeedbackPageName') || pageNames.onlineFeedback;
  const offerFeedback =
    getTextInputValue('offerFeedbackPageName') || pageNames.offerFeedback;

  return {
    bankDetails,
    birthdatesMySelf,
    birthdatesMySelfAndOthers,
    birthdatesOnlyOthers,
    contribution,
    onlineFeedback,
    offerFeedback,
    finalize,
    insuranceBeginning,
    insuranceSituation,
    personalData,
    summary,
  };
};
export default useGetTrackingPageNames;
