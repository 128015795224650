import styled, { css } from 'styled-components';

import { colors, sizes } from '../../styles/tokens';
import { TypeTextAlign } from '../../types';

interface WrapperProps {
  textAlign?: TypeTextAlign;
  noMarginTop?: boolean;
  noPaddingLeft?: boolean;
}
interface Props extends WrapperProps {
  bullet?: string;
  message: string;
}

const SmallPrintWrapper = styled.div<WrapperProps>`
  font-size: ${sizes.text.S};
  color: ${colors.DARKER_GRAY};
  position: relative;
  ${({ textAlign, noMarginTop, noPaddingLeft }) => css`
    text-align: ${textAlign ?? 'left'};
    margin-top: ${noMarginTop ? 0 : sizes.space.PX_16};
    padding-left: ${noPaddingLeft ? 0 : sizes.space.PX_16};
  `}

  &:before {
    position: absolute;
    left: ${`-${sizes.space.PX_08}`};
    content: attr(data-bullet);
    text-align: right;
  }
`;

const SmallPrint = ({ message, bullet = '*', ...props }: Props) => {
  return (
    <SmallPrintWrapper data-bullet={bullet} {...props}>
      {message}
    </SmallPrintWrapper>
  );
};

export default SmallPrint;
