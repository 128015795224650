export interface AemAppConfig {
  // Allgemein TAB
  tariffSelected: string;
  fullOfferDisabled: boolean;
  upsellingDisabled: boolean;
  // Download TAB
  ZAHN_DVB_DVE_pdf?: string;
  ZAHN_DVE_DVE_DS75_pdf?: string;
  ZAHN_DS75_DS90_pdf?: string;
  ZAHN_DS90_DS100_pdf?: string;
  ZAHN_DS100_DVB_DS100_pdf?: string;
  ZAHN_DVB_DS75_DVB_DS90_pdf?: string;
  ZAHN_DVE_DS75_DVE_DS90_pdf?: string;
  ZAHN_DVB_DS90_DVB_DS100_pdf?: string;
  ZAHN_DVE_DS90_DVE_DS100_pdf?: string;
  ZAHN_DVB_DS100_DVE_DS100_pdf?: string;
  ZAHN_DVE_DS100_pdf?: string;
  ZAHN_ZEZ_pdf?: string;
  // Produkttabelle TAB
  ZAHN_DVB_DVE_table?: string;
  ZAHN_DVE_DVE_DS75_table?: string;
  ZAHN_DS75_DS90_table?: string;
  ZAHN_DS90_DS100_table?: string;
  ZAHN_DS100_DVB_DS100_table?: string;
  ZAHN_DVB_DS75_DVB_DS90_table?: string;
  ZAHN_DVE_DS75_DVE_DS90_table?: string;
  ZAHN_DVB_DS90_DVB_DS100_table?: string;
  ZAHN_DVE_DS90_DVE_DS100_table?: string;
  ZAHN_DVB_DS100_DVE_DS100_table?: string;
  ZAHN_DVE_DS100_table?: string;
  ZAHN_ZEZ_table?: string;
  // Trustbanner TAB
  trustBannerVisibility: string;
  showTrustInsuranceSituation: boolean;
  showTrustBirthdates: boolean;
  showTrustInsuranceBeginning: boolean;
  showTrustContribution: boolean;
  showTrustPersonalData: boolean;
  showTrustSummary: boolean;
  showTrustBankDetails: boolean;
  showTrustFinalize: boolean;
  // Trust TAB
  ZAHN_DVB_firstLine?: string;
  ZAHN_DVB_secondLine?: string;
  ZAHN_DVB_tooltip?: string;
  ZAHN_DVB_image_path?: string;
  ZAHN_DVE_firstLine?: string;
  ZAHN_DVE_secondLine?: string;
  ZAHN_DVE_tooltip?: string;
  ZAHN_DVE_image_path?: string;
  ZAHN_DS75_firstLine?: string;
  ZAHN_DS75_secondLine?: string;
  ZAHN_DS75_tooltip?: string;
  ZAHN_DS75_image_path?: string;
  ZAHN_DS90_firstLine?: string;
  ZAHN_DS90_secondLine?: string;
  ZAHN_DS90_tooltip?: string;
  ZAHN_DS90_image_path?: string;
  ZAHN_DS100_firstLine?: string;
  ZAHN_DS100_secondLine?: string;
  ZAHN_DS100_tooltip?: string;
  ZAHN_DS100_image_path?: string;
  ZAHN_DVB_DS75_firstLine?: string;
  ZAHN_DVB_DS75_secondLine?: string;
  ZAHN_DVB_DS75_tooltip?: string;
  ZAHN_DVB_DS75_image_path?: string;
  ZAHN_DVB_DS90_firstLine?: string;
  ZAHN_DVB_DS90_secondLine?: string;
  ZAHN_DVB_DS90_tooltip?: string;
  ZAHN_DVB_DS90_image_path?: string;
  ZAHN_DVB_DS100_firstLine?: string;
  ZAHN_DVB_DS100_secondLine?: string;
  ZAHN_DVB_DS100_tooltip?: string;
  ZAHN_DVB_DS100_image_path?: string;
  ZAHN_DVE_DS75_firstLine?: string;
  ZAHN_DVE_DS75_secondLine?: string;
  ZAHN_DVE_DS75_tooltip?: string;
  ZAHN_DVE_DS75_image_path?: string;
  ZAHN_DVE_DS90_firstLine?: string;
  ZAHN_DVE_DS90_secondLine?: string;
  ZAHN_DVE_DS90_tooltip?: string;
  ZAHN_DVE_DS90_image_path?: string;
  ZAHN_DVE_DS100_firstLine?: string;
  ZAHN_DVE_DS100_secondLine?: string;
  ZAHN_DVE_DS100_tooltip?: string;
  ZAHN_DVE_DS100_image_path?: string;
  ZAHN_ZEZ_firstLine?: string;
  ZAHN_ZEZ_secondLine?: string;
  ZAHN_ZEZ_tooltip?: string;
  ZAHN_ZEZ_image_path?: string;
  // Ekomi TAB
  showEkomi: boolean;
  ekomiText: string;
  // Widerruf TAB
  cancelationElementFirstLine: string;
  cancelationElementSecondLine: string;
  cancelationElementDescription: string;
  cancelationElementImage: string;
  // Datenschutz TAB
  hideConsentSection: boolean;
  privacyConsentContent: string;
  // Feedback TAB
  feedbackContentSubmitOnline?: string;
  feedbackContentSubmitByMail?: string;
  feedbackContentSubmitByEmail?: string;
  feedbackContentSubmitByDownload?: string;
  // Tracking TAB
  brokerModus: boolean;
  abbreviation: string;
  productGroup: string;
  insuranceSituationPageName: string;
  birthdateMySelfPageName: string;
  birthdateMySelfAndOthersPageName: string;
  birthdateOnlyOthersPageName: string;
  insuranceBeginningPageName: string;
  contributionPageName: string;
  personalDataPageName: string;
  summaryPageName: string;
  IBANPageName: string;
  finalizePageName: string;
  onlineFeedbackPageName: string;
  offerFeedbackPageName: string;
}
export interface AppParams {
  [key: string]: AemAppConfig;
}

export type AemWindow = Window &
  typeof globalThis & {
    loadFetchedAemJs: (content: any) => any;
    isEditMode: boolean;
    params: AppParams;
    appEventData?: any;
    sessionStorage?: any;
  };

export enum AemContent {
  PDF = 'pdf',
  TABLE = 'table',
  FIRST_LINE = 'firstLine',
  SECOND_LINE = 'secondLine',
  TOOLTIP = 'tooltip',
  IMAGE = 'image_path',
}

export enum AemAssets {
  PDF = 'pdf',
  HTML = 'html',
  IMAGE = 'image',
}

export enum TrustBannerVisibilityOptions {
  SHOW_EVERYWHERE = 'showEverywhere',
  SHOW_DEFINED_PAGES = 'showOnDefinedPages',
  DONT_SHOW = 'dontShow',
}
export interface TrustBannerVisibility {
  showEverywhere?: boolean;
  hideEverywhere?: boolean;
  insuranceSituation?: boolean;
  birthdates?: boolean;
  insuranceBeginning?: boolean;
  contribution?: boolean;
  personalData?: boolean;
  summary?: boolean;
  bankDetails?: boolean;
  finalize?: boolean;
}
