const getFormattedIBAN = (plainIBAN: string) => {
  const regex = /.{1,4}/g;

  return plainIBAN.match(regex)?.join(' ') || plainIBAN;
};

const getNormalizedIBAN = (_IBAN: string) => {
  const regex = /\s/g;
  return _IBAN.replace(regex, '');
};

export { getFormattedIBAN, getNormalizedIBAN };
