import React, { RefObject } from 'react';

import CloseIcon from '@eg/elements/Icons/CloseIcon';

import styled from 'styled-components';

import { H3 } from '../../../layouts/Common';
import { colors } from '../../../styles/tokens';
import { Void } from '../types';

const ButtonIcon = styled(CloseIcon)`
  fill: ${colors.WHITE};
  cursor: pointer;
`;

const ButtonWrapper = styled.button`
  border: none;
  background-color: transparent;
`;

interface Props {
  title: string;
  forwardRef: RefObject<HTMLButtonElement>;
  onClose: Void;
}

const Header = ({ title, forwardRef, onClose }: Props) => {
  return (
    <>
      <H3>{title}</H3>
      <ButtonWrapper
        data-testid="sidebar__header-close-button"
        ref={forwardRef}
        onClick={onClose}
      >
        <ButtonIcon width={32} height={32} />
      </ButtonWrapper>
    </>
  );
};

export default Header;
