import { Person, INSURANCE_TYPE } from 'ppz-otr-common';

export * from './Product';
export * from './Buttons';
export * from './Errors';
export * from './common';
export * from './AEM';
export * from './InitOffer';
export * from './Agents';
export * from './Tracking';

export type TypeButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'text-link'
  | 'disabled';

export type TypeTextAlign = 'center' | 'left' | 'right';

export const enum CHECKOUT_TYPE {
  online = 1,
  appointment,
}

export const enum APPOINTMENT_TYPE {
  email = 1,
  post,
  pdf,
}

export interface HeaderDetails {
  tariff?: number;
  startTariff?: number;
  insuranceBegin?: string;
}

export interface SidebarDetails extends HeaderDetails {
  personTariffs: Person[];
}

export interface Icon {
  show?: boolean;
  label?: string;
  bold?: boolean;
  isModal?: boolean;
  children?: React.ReactNode;
  onToggledOpen?: (isOpen: boolean) => void;
}

export interface RadioCardOption {
  name: string;
  value: string;
  label: string;
  id: string;
}

export interface InitialSituation {
  name: string;
  value: INSURANCE_TYPE;
  label: string;
  id: string;
}
