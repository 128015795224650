import { useEffect, useState } from 'react';

import { useInsurance } from '../../context/InsuranceCtx';
import { APPOINTMENT_TYPE, CHECKOUT_TYPE } from '../../types';
import { getTextInputValue, setHTMLContent } from '../../utils';
const getCurrentFeedbackContentPath = (
  _appointmentType: APPOINTMENT_TYPE | null,
  _checkoutType: CHECKOUT_TYPE | null,
): string => {
  let _contentPath = '';
  if (_checkoutType == CHECKOUT_TYPE.online) {
    _contentPath = getTextInputValue('feedbackContentSubmitOnline');
  } else {
    switch (_appointmentType) {
      case APPOINTMENT_TYPE.email:
        _contentPath = getTextInputValue('feedbackContentSubmitByEmail');
        break;
      case APPOINTMENT_TYPE.post:
        _contentPath = getTextInputValue('feedbackContentSubmitByMail');
        break;
      case APPOINTMENT_TYPE.pdf:
        _contentPath = getTextInputValue('feedbackContentSubmitByDownload');
        break;
      default:
        _contentPath = getTextInputValue('feedbackContentSubmitOnline');
        console.warn('Appointment type is unknown but it should not');
    }
  }
  return _contentPath;
};
const useGetFeedbackContent = () => {
  const { checkoutType, appointmentType } = useInsurance();
  const [currentContentHTML, setCurrentContentHTML] = useState('');
  useEffect(() => {
    const _contentPath = getCurrentFeedbackContentPath(
      appointmentType,
      checkoutType,
    );

    setHTMLContent(_contentPath, setCurrentContentHTML);
  }, []);

  return currentContentHTML === '' ? (
    <div></div>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: currentContentHTML }} />
  );
};
export default useGetFeedbackContent;
