import React from 'react';
import { FormattedMessage } from 'react-intl';

import TooltipIcon from '@eg/elements/TooltipIcon';

import ABTestingIds from '../../../ABTesting/bankDetails';
import { useTracking } from '../../../hooks';
import literals from '../../../language/src/bank-details';
import { ClickableElements } from '../../../types';
import {
  DescriptionSection,
  WarningDescriptionWrapper,
  WarningTitleWrapper,
} from '../layouts';

const Description = () => {
  const { setTrackingClick } = useTracking();
  return (
    <DescriptionSection
      className="abTesting abTestingBottom"
      id={ABTestingIds.IBANdescription}
    >
      <WarningTitleWrapper>
        <FormattedMessage {...literals.authTitle} />
      </WarningTitleWrapper>
      <WarningDescriptionWrapper>
        <FormattedMessage {...literals.authDescription} />
      </WarningDescriptionWrapper>
      <WarningTitleWrapper isSEPA={true}>
        <FormattedMessage {...literals.authWarning} />
      </WarningTitleWrapper>
      <WarningDescriptionWrapper isSEPA={true}>
        <FormattedMessage {...literals.authWarningDescription} />
      </WarningDescriptionWrapper>
      <WarningTitleWrapper isSEPA={true}>
        <FormattedMessage {...literals.sepaLabel} />
        <TooltipIcon
          isModal={true}
          onToggledOpen={(isOpen) =>
            isOpen && setTrackingClick(ClickableElements.TooltipSEPA)
          }
        >
          <FormattedMessage {...literals.sepaContent} />
        </TooltipIcon>
      </WarningTitleWrapper>
    </DescriptionSection>
  );
};

export { Description };
