import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import ABTestingIds from '../../ABTesting/summary';
import { ActionButtons } from '../../components/Common';
import { useInsurance } from '../../context/InsuranceCtx';
import { usePerson } from '../../context/PersonCtx';
import { useTracking } from '../../hooks';
import commonLiterals from '../../language/src/common';
import literals from '../../language/src/summary';
import { PageLayout } from '../../layouts';
import { ClickableElements, SingleButton, VIEWS } from '../../types';

import DetailsBeginningDate from './components/DetailsBeginningDate';
import DetailsPersonsList from './components/DetailsPersonsList';
import DetailsTariff from './components/DetailsTariff';
import { WrapperActionButtons } from './layouts';

const Summary = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { persons, insuranceOwnerId } = usePerson();
  const { situation } = useInsurance();
  const { setTrackingClick } = useTracking();

  const buttonsList: SingleButton[] = [
    {
      label: intl.formatMessage(commonLiterals.nextButtonOnlineContract),
      type: 'primary',
      callbackOnClick: () => {
        setTrackingClick(ClickableElements.ButtonNext);
        navigate(VIEWS.bankDetails);
      },
      id: ABTestingIds.nextButton,
    },
    {
      label: intl.formatMessage(commonLiterals.backButton),
      type: 'text-link',
      callbackOnClick: () => {
        setTrackingClick(ClickableElements.ButtonBack);
        navigate(VIEWS.personalData);
      },
      id: ABTestingIds.backButton,
    },
  ];

  return (
    <PageLayout
      title={intl.formatMessage(literals.title)}
      id={ABTestingIds.title}
      subtitle={intl.formatMessage(literals.subtitle)}
      textAlign="left"
    >
      <DetailsTariff />
      <DetailsBeginningDate />
      <DetailsPersonsList
        persons={Array.from(persons.values())}
        insuranceOwnerId={insuranceOwnerId}
        situation={situation}
      />
      <WrapperActionButtons>
        <ActionButtons
          buttonsList={buttonsList}
          id={ABTestingIds.buttonContainer}
        />
      </WrapperActionButtons>
    </PageLayout>
  );
};

export default Summary;
