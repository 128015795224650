import { ValidateBankDetailsResponseDto } from 'ppz-otr-common';

import { SaveBankDetailsDto } from '../../../services';

export const getBankDataResponse = (
  bodyRequest: SaveBankDetailsDto,
): ValidateBankDetailsResponseDto => {
  const { iban } = bodyRequest;
  return {
    success: true,
    details: {
      id: 'ab061d41-a031-4f7c-9775-4bc1469702f1',
      iban: iban ?? '',
      bic: 'INGDDEFFXXX',
      financialInstitutionName: 'ING-DiBa Frankfurt am Main',
      city: 'Frankfurt am Main',
      mrn: null,
    },
  };
};
