import { KeyboardEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import InfoIcon from '@eg/elements/Icons/InfoIcon';
import Price, { Size } from '@eg/elements/Price';

import styled from 'styled-components';

import useResize from '../../hooks/useResize';
import commonLiterals from '../../language/src/common';
import { breakpoints, sizes } from '../../styles/tokens';

import { Content } from './layouts';
import Paragraph from './layouts/Paragraph';
import { Void } from './types';

interface Props {
  startTariff: number;
  finalTariff: number;
  onShowSidebar: Void;
}

const TariffFlexRox = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  column-gap: ${sizes.space.PX_06};
`;

const Tariff = styled(TariffFlexRox)`
  cursor: pointer;
  height: ${sizes.space.PX_24};

  & > span > span:nth-child(2) {
    position: relative;
  }

  @media (min-width: ${breakpoints.M}) {
    & .ee_svg-icon {
      margin-bottom: -7px;
    }

    & > span > span:nth-child(2):after {
      top: ${`-${sizes.space.PX_04}`};
      left: ${sizes.space.PX_24};
    }
  }
`;

const InfoText = styled(TariffFlexRox)``;

const FinalTariffWrapper = styled(Paragraph)`
  cursor: pointer;
`;

const FalseIcon = styled.div<{ width: number; height: number }>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
`;

const HeaderTariff = ({ startTariff, finalTariff, onShowSidebar }: Props) => {
  const intl = useIntl();
  const [width] = useResize();

  const breakpoint = parseInt(breakpoints.M.replace('px', ''));
  const gotFinalTariff = finalTariff !== startTariff;
  const onClick = (_event) => {
    onShowSidebar();
  };

  const onKeyDownHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    }
  };

  return (
    <Content type="tariff" className="header__tariff">
      <Tariff onClick={onClick} tabIndex={0} onKeyDown={onKeyDownHandler}>
        <Price
          data-testid="header__tariff-price"
          value={startTariff}
          suffix={`${intl.formatMessage(commonLiterals.monthly)}`}
          size={width < breakpoint ? Size.Small : Size.Medium}
        />
        <InfoIcon
          data-testid="header__tariff-icon"
          width={width < breakpoint ? 16 : 19}
          height={width < breakpoint ? 16 : 19}
        />
      </Tariff>
      {gotFinalTariff && (
        <InfoText>
          <FinalTariffWrapper
            tabIndex={0}
            data-testid="header__tariff-final-price"
            onClick={onClick}
            onKeyDown={onKeyDownHandler}
          >
            <FormattedMessage {...commonLiterals.headerFinalPrice} />{' '}
            <Price value={finalTariff} unstyled />
            {'*'}
          </FinalTariffWrapper>
          {width > breakpoint && (
            <FalseIcon
              width={width < breakpoint ? 16 : 19}
              height={width < breakpoint ? 16 : 19}
            />
          )}
        </InfoText>
      )}
    </Content>
  );
};

export default HeaderTariff;
