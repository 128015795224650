import {
  bodyWithoutSubLine,
  SubLine,
  subLineBodyWrapperClasses,
  subLineWrapperClasses,
} from './layout';

interface Props {
  children: React.ReactNode;
}

interface SummaryGridItemProps {
  children: React.ReactNode;
  type: 'left' | 'right' | 'offsetRight';
}

const SummaryGridItem = ({ children, type }: SummaryGridItemProps) => {
  if (type === 'right') {
    return <SummaryGridItemRight>{children}</SummaryGridItemRight>;
  }
  if (type === 'offsetRight') {
    return <SummaryGridItemNoSubline>{children}</SummaryGridItemNoSubline>;
  }

  return <SummaryGridItemLeft>{children}</SummaryGridItemLeft>;
};

const SummaryGridItemLeft = ({ children }: Props) => {
  return (
    <div className={subLineWrapperClasses} data-testid="gridItem-left">
      <SubLine as="h3">{children}</SubLine>
    </div>
  );
};

const SummaryGridItemRight = ({ children }: Props) => {
  return (
    <div className={subLineBodyWrapperClasses} data-testid="gridItem-right">
      {children}
    </div>
  );
};
const SummaryGridItemNoSubline = ({ children }: Props) => {
  return (
    <div className={bodyWithoutSubLine} data-testid="gridItem-offsetRight">
      {children}
    </div>
  );
};

export default SummaryGridItem;
