import { FunctionComponent } from 'react';

import Radio from '@eg/elements/Radio';

import classNames from 'classnames';
import styled from 'styled-components';

import { colors, sizes, breakpoints } from '../../styles/tokens';

interface RadioCardProps {
  id: string;
  name: string;
  value: string;
  label: string;
  selected: boolean;
  onSelectedCard: (selected: string) => void;
}

const CustomCard = styled.div`
  border: ${sizes.space.PX_01} solid ${colors.MEDIUM_GRAY};
  padding-top: ${sizes.space
    .PX_12}; // 12px (from @eg/elements/Radio label) + 6px = 18px
  padding-bottom: ${sizes.space
    .PX_12}; // 12px (from @eg/elements/Radio label) + 6px = 18px
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: ${sizes.space.PX_16};
  font-weight: bold;
  cursor: pointer;

  @media (min-width: ${breakpoints.M}) {
    margin-bottom: ${sizes.space.PX_24};
    padding-top: ${sizes.space
      .PX_12}; // 12px (from @eg/elements/Radio label) + 8px = 20px
    padding-bottom: ${sizes.space
      .PX_12}; // 12px (from @eg/elements/Radio label) + 8px = 20px
    padding-left: ${sizes.space.PX_24};
    padding-right: ${sizes.space.PX_24};
  }

  /* TODO: Style copied from esc_box-shadow--medium */
  &:hover:not(.selected) {
    box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%),
      0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%);
    border-color: transparent;
  }
  &.selected {
    border-width: 2px;
    background-color: ${colors.LIGHTER_LAVENDER};
    border-color: ${colors.BRAND_COLOR};
  }
`;

const RadioCard: FunctionComponent<RadioCardProps> = ({
  selected,
  ...props
}) => {
  const { name, value, label, onSelectedCard } = props;
  const cardClasses = classNames(
    'abTesting',
    'abTestingBottom',
    'esc_border-radius--large',
    {
      selected,
    },
  );

  return (
    <CustomCard
      className={cardClasses}
      id={props.id}
      data-testid="radio-button-card__container"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onSelectedCard(value);
      }}
    >
      <Radio
        data-testid="radio-button-card__option"
        name={name}
        value={value}
        label={label}
        checked={selected}
        onClick={(event) => {
          event.stopPropagation();
          onSelectedCard(value);
        }}
      />
    </CustomCard>
  );
};

export default RadioCard;
