import styled from 'styled-components';

import { sizes } from '../../../styles/tokens';

const DescriptionSection = styled.div`
  margin-top: ${sizes.space.PX_48};
  margin-bottom: ${sizes.space.PX_24};
`;

export { DescriptionSection };
