import { ProductIds } from 'ppz-otr-common';

function getProductsFromParams(products: string[]): ProductIds[] {
  const productPrefix = 'ZAHN_';
  const NONE = 'None';
  const validProducts = products.filter((p) => !!p && p !== NONE);
  return validProducts.map((p) => `${productPrefix}${p}`) as ProductIds[];
}

export { getProductsFromParams };
