import { getCheckboxValue, getTextInputValue } from '../../utils';

const useGetTrackingDetails = () => {
  const abbreviation = getTextInputValue('abbreviation') || 'izs';
  const productGroup = getTextInputValue('productGroup') || 'ZahnZusatz';
  const brokerModus = getCheckboxValue('brokerModus') || false;

  return { abbreviation, productGroup, brokerModus };
};
export default useGetTrackingDetails;
