import {
  INSURANCE_TYPE,
  UpdateIzsBirthdatesRequest,
  UpdateIzsBirthdatesResponse,
  UpdatePreallocationRolesRequest,
  InitProducts,
} from 'ppz-otr-common';

import { putHttp } from './config';
import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

export const INSURANCE_SITUATION_API = {
  UPDATE_IZS_BIRTHDATES: `${ENDPOINTS.insuranceSituation}/izs-birthdates`,
  UPDATE_PREALLOCATION_ROLES: `${ENDPOINTS.insuranceSituation}/preallocation-roles`,
};

export const updateIzsBirthdates = async (
  businessId: string,
  situation: INSURANCE_TYPE,
  initProducts: InitProducts,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await putHttp<UpdateIzsBirthdatesRequest, UpdateIzsBirthdatesResponse>(
    {
      url: INSURANCE_SITUATION_API.UPDATE_IZS_BIRTHDATES,
      errorThrown,
    },
    { businessId, situation, initProducts },
  );
};

export const updatePreallocationRoles = async (
  businessId: string,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await putHttp<UpdatePreallocationRolesRequest, { success: boolean }>(
    {
      url: INSURANCE_SITUATION_API.UPDATE_PREALLOCATION_ROLES,
      errorThrown,
    },
    { businessId },
  );
};
