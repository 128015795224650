const enum InsuranceBeginningABTestingIds {
  title = 'ppz301',
  datesContainer = 'ppz302',
  buttonContainer = 'ppz303',
  datesPrefix = 'ppz32',
  nextButton = 'ppz304',
  backButton = 'ppz305',
}

export default InsuranceBeginningABTestingIds;
