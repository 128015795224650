import { ContactElement } from 'ppz-otr-common';

export * from './person';
export * from './address';
export * from './actions';
export * from './reducers';
export * from './phone';
export * from './form';
export * from './services';
export * from './error';

export const hasValue = (str: string | undefined | null) => {
  return str !== null && str?.trim() !== '' && str !== undefined;
};

export const inputHasError = (element: ContactElement | undefined) => {
  if (!element) return false;

  const { value, valid } = element;
  return hasValue(value) && !valid;
};
