import { Environments } from 'ppz-otr-common';

import { ENV, BUILD_DATE } from '../../../config/environment';
import {
  APPOINTMENT_TYPE,
  Brands,
  BrokerModus,
  PageAttrs,
  PageCategory,
  PageInfo,
  PagePurposes,
  ProductCategories,
  SYSENV,
  VIEWS,
} from '../../../types';
import { TrackingPageNames } from '../../aem/useGetTrackingPageNames';

export interface GetPageInfo extends GetPageName {}

export interface GetPageName {
  page: VIEWS;
  viewNames: TrackingPageNames;
  abbreviation: string;
  appointment: APPOINTMENT_TYPE | null;
}

export const getPageInfo = (options: GetPageInfo): PageInfo => {
  return {
    pageName: getPageName(options),
    sysEnv: getSysEnv(ENV),
    issueDate: getIssueDate(BUILD_DATE),
  };
};

export const getPageName = (options: GetPageName): string => {
  const { page, viewNames: names, abbreviation, appointment } = options;

  const pageNames = {
    [VIEWS.initialSelection]: names.insuranceSituation,
    [VIEWS.myself]: names.birthdatesMySelf,
    [VIEWS.myselfAndMore]: names.birthdatesMySelfAndOthers,
    [VIEWS.others]: names.birthdatesOnlyOthers,
    [VIEWS.insuranceBeginning]: names.insuranceBeginning,
    [VIEWS.contribution]: names.contribution,
    [VIEWS.personalData]: names.personalData,
    [VIEWS.summary]: names.summary,
    [VIEWS.bankDetails]: names.bankDetails,
    [VIEWS.finalize]: names.finalize,
    [VIEWS.feedback]: appointment ? names.offerFeedback : names.onlineFeedback,
    [VIEWS.error]: 'Generic Error',
  };

  return `${abbreviation}:${pageNames[page]}`;
};

export const getSysEnv = (env: Environments): SYSENV =>
  env === Environments.prod ? 'prod' : 'develop';

export const getIssueDate = (buildDate: string | undefined): string => {
  const appDate = 'OTRzahn2022';
  return `${appDate}|${buildDate}`;
};

export const getPageAttrs = (isBrokerModus: boolean): PageAttrs => {
  const displayedBrand = Brands.ERGO;
  const brokerModus = isBrokerModus ? BrokerModus.ON : BrokerModus.OFF;

  return {
    displayedBrand,
    brokerModus,
  };
};

export const getPageCategory = (): PageCategory => {
  const pagePurpose = PagePurposes.SALE;
  const primaryCategory = ProductCategories.PAGE;

  return {
    pagePurpose,
    primaryCategory,
  };
};
