import { useState, createContext, useContext } from 'react';

import {
  TariffPrices,
  ProductIds,
  OldProductIds,
  Tariffs,
} from 'ppz-otr-common';

import { defaultProducts } from '../config';
import storage, { LocalKeys } from '../services/storage';
import { Selection } from '../types';

export interface TariffContext {
  contributionSelected: Selection;
  products: Tariffs[];
  temporalProducts: Tariffs[];
  basicTariffPrices: TariffPrices;
  premiumTariffPrices: TariffPrices;
  tariffPriceSelected: TariffPrices;
  premiumProducts: Tariffs[];
}

export interface TariffUpdateContext {
  setContributionSelected: React.Dispatch<
    TariffContext['contributionSelected']
  >;
  setProducts: React.Dispatch<TariffContext['products']>;
  setTemporalProducts: React.Dispatch<TariffContext['temporalProducts']>;
  setBasicTariffPrices: React.Dispatch<TariffContext['basicTariffPrices']>;
  setPremiumTariffPrices: React.Dispatch<TariffContext['premiumTariffPrices']>;
  setTariffPriceSelected: React.Dispatch<TariffContext['tariffPriceSelected']>;
  setPremiumProducts: React.Dispatch<TariffContext['premiumProducts']>;
}

let defaultProductsCtx: Tariffs[] = [];

const localProducts = storage.get(LocalKeys.izsProducts);
if (!!localProducts) {
  defaultProductsCtx = [...(localProducts.split(',') as Tariffs[])];
} else {
  defaultProductsCtx = defaultProducts;
}

const defaultCtx: TariffContext = {
  contributionSelected:
    (storage.get(LocalKeys.contribution) as Selection) ?? '',
  products: defaultProductsCtx,
  temporalProducts: defaultProductsCtx,
  basicTariffPrices: {} as TariffPrices,
  premiumTariffPrices: {} as TariffPrices,
  tariffPriceSelected: {} as TariffPrices,
  premiumProducts: [],
};

export const tariffCtx = createContext<TariffContext>(defaultCtx);
export const tariffCtxUpdate = createContext<TariffUpdateContext>(
  {} as TariffUpdateContext,
);

export const useTariff = () => {
  const tariff = useContext(tariffCtx);
  if (tariff === undefined)
    throw new Error('useTariff must be used within a TariffContext');
  return tariff;
};

export const useTariffUpdate = () => {
  const setTariff = useContext(tariffCtxUpdate);
  if (setTariff === undefined)
    throw new Error(
      'useTariffUpdate must be used within a TariffUpdateContext',
    );
  return setTariff;
};

interface Props extends Partial<TariffContext> {
  children?: React.ReactNode;
}

export const TariffProvider = ({
  children,
  contributionSelected: defaultContributionSelected,
  products: defaultProducts,
  temporalProducts: defaultTemporalProducts,

  basicTariffPrices: defaultBasicTariffPrices,
  premiumProducts: defaultPremiumProducts,
  premiumTariffPrices: defaultPremiumTariffPrices,
  tariffPriceSelected: defaultTariffPriceSelected,
}: Props) => {
  const [contributionSelected, setContributionSelected] = useState<
    TariffContext['contributionSelected']
  >(defaultContributionSelected ?? defaultCtx.contributionSelected);
  const [products, setProducts] = useState<TariffContext['products']>(
    defaultProducts ?? defaultCtx.products,
  );
  const [temporalProducts, setTemporalProducts] = useState<
    TariffContext['temporalProducts']
  >(defaultTemporalProducts ?? defaultCtx.temporalProducts);

  const [basicTariffPrices, setBasicTariffPrices] = useState<
    TariffContext['basicTariffPrices']
  >(defaultBasicTariffPrices ?? defaultCtx.basicTariffPrices);
  const [premiumProducts, setPremiumProducts] = useState<
    TariffContext['premiumProducts']
  >(defaultPremiumProducts ?? defaultCtx.premiumProducts);
  const [premiumTariffPrices, setPremiumTariffPrices] = useState<
    TariffContext['premiumTariffPrices']
  >(defaultPremiumTariffPrices ?? defaultCtx.premiumTariffPrices);
  const [tariffPriceSelected, setTariffPriceSelected] = useState<
    TariffContext['tariffPriceSelected']
  >(defaultTariffPriceSelected ?? defaultCtx.tariffPriceSelected);

  return (
    <tariffCtxUpdate.Provider
      value={{
        setContributionSelected: (newContributionSelected: Selection) => {
          storage.set(LocalKeys.contribution, newContributionSelected);
          setContributionSelected(newContributionSelected);
        },
        setProducts,
        setTemporalProducts,
        setBasicTariffPrices,
        setPremiumProducts,
        setPremiumTariffPrices,
        setTariffPriceSelected,
      }}
    >
      <tariffCtx.Provider
        value={{
          contributionSelected,
          products,
          temporalProducts,

          basicTariffPrices,
          premiumProducts,
          premiumTariffPrices,
          tariffPriceSelected,
        }}
      >
        {children}
      </tariffCtx.Provider>
    </tariffCtxUpdate.Provider>
  );
};

export default TariffProvider;
