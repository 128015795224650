import RadioTab from '@eg/elements/components/RadioTab';
import RadioTabs from '@eg/elements/RadioTabs';

import { useTariff } from '../../../context/TariffCtx';
import { Selection } from '../../../types';
import { getProductName } from '../../../utils';

import { CoverageList } from './CoverageList';

interface Props {
  selectedTab: Selection;
  onSelectedTab: (tab: Selection) => void;
  gotPremium: boolean;
}

export const CustomRadioTabs = ({
  selectedTab,
  onSelectedTab,
  gotPremium,
}: Props) => {
  const { products, premiumProducts } = useTariff();

  const radioTabs: Selection[] = ['basic', 'premium'];

  return (
    <RadioTabs
      name="contribution"
      currentValue={selectedTab}
      onSelect={(tab) => onSelectedTab(tab as Selection)}
    >
      {radioTabs.map((selection, index) => {
        const isBasic = selection === 'basic';
        const currentProducts = isBasic ? products : premiumProducts;
        const testIdSuffix = isBasic ? 'current' : 'premium';
        const testId = `contribution__option-${testIdSuffix}`;

        const args = {
          key: `${selection}-${index}`,
          value: selection,
          title: getProductName(currentProducts),
        };

        return (
          <RadioTab data-testid={testId} {...args}>
            <CoverageList products={currentProducts} gotPremium={gotPremium} />
          </RadioTab>
        );
      })}
    </RadioTabs>
  );
};
