import React from 'react';

import {
  useGetAEMCancelationDetails,
  useGetAEMTrustElementContent,
} from '../../../hooks';
import {
  Container,
  Section,
  Image,
  Icon,
  Title,
  SubTitle,
  Visibility,
} from '../layouts';
import { TrustBannerItemStructure } from '../types';
interface Props {
  isCancelation?: boolean;
  structure: TrustBannerItemStructure;
}

const TrustElement = ({ isCancelation, structure }: Props) => {
  const cancelationContent = useGetAEMCancelationDetails();
  const trustElementContent = useGetAEMTrustElementContent();

  const {
    firstLine: subtitle,
    imagePath: imageSrc,
    secondLine: title,
    tooltip: tooltipLegend,
  } = isCancelation ? cancelationContent : trustElementContent;

  const showImageSection = imageSrc ? true : false;
  const showDataSection = subtitle || title || tooltipLegend ? true : false;
  const imageVisibility: Visibility = imageSrc ? 'visible' : 'hidden';
  const subTitleVisibility: Visibility = subtitle ? 'visible' : 'hidden';
  const titleVisibility: Visibility = title ? 'visible' : 'hidden';

  return (
    <Container structure={structure}>
      <Section
        show={structure === 'horizontal' ? true : showImageSection}
        structure={structure}
      >
        <Image
          data-testid="trust-element__image"
          src={imageSrc}
          alt={subtitle}
          structure={structure}
          visibility={imageVisibility}
        />
      </Section>
      <Section show={showDataSection} structure={structure}>
        <SubTitle visibility={subTitleVisibility}>
          {subtitle || 'first line'}
        </SubTitle>
        <Title visibility={titleVisibility}>{title || 'second line'}</Title>
        {tooltipLegend && (
          <Icon isModal={true} structure={structure}>
            {tooltipLegend}
          </Icon>
        )}
      </Section>
    </Container>
  );
};

export default TrustElement;
