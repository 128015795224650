import FormRow, { FormRowProps } from '@eg/elements/FormRow';

import styled from 'styled-components';

import { sizes } from '../../../styles/tokens';

interface Props extends FormRowProps {
  birthdate?: string;
}

const FormRowCustom = styled(FormRow)<Props>`
  &&& {
    margin-bottom: ${({ birthdate }) => (birthdate ? 0 : 'auto')};
  }

  &&& > div {
    margin: ${sizes.space.PX_08} 0;
    padding: 0;
  }

  &&& > div > div:first-child {
    flex-grow: ${({ birthdate }) => (birthdate ? 0 : 'auto')};
  }
`;

export default FormRowCustom;
