import {
  InitProducts,
  ContributionDetails,
  ProductsType,
} from 'ppz-otr-common';

import { ENDPOINTS } from './endpoints';
import { HttpOptions } from './types';

import { putHttp } from '.';

export interface UpdateContributionDto {
  productsType: ProductsType;
  products: InitProducts;
}

export interface UpdateContributionResponse {
  success: boolean;
  contributionDetails?: ContributionDetails;
}

export const CONTRIBUTION_API = {
  PUT_SELECTED_TARIFF: `${ENDPOINTS.contribution}/:businessId`,
};

export const putSelectedTariff = async (
  businessId: string,
  productsType: ProductsType,
  products: InitProducts,
  errorThrown: HttpOptions['errorThrown'],
) => {
  return await putHttp<UpdateContributionDto, UpdateContributionResponse>(
    {
      url: CONTRIBUTION_API.PUT_SELECTED_TARIFF,
      path: {
        businessId,
      },
      errorThrown,
    },
    { productsType, products },
  );
};
