import {
  ContributionDetails,
  ProductIds,
  OldProductIds,
  InitProducts,
} from 'ppz-otr-common';

import {
  basicProducts,
  personIdsMock,
  premiumProducts,
  basicProductsDS100,
  basicProductsZEZ,
  premiumProductsZEZ,
  basicProductsZEF,
  premiumProductsZEF,
  basicProductsZZP,
  premiumProductsZZP,
  premiumProductsDS100,
} from '../../constants';

const contributionDetailsBase: Pick<
  ContributionDetails,
  'basicPrices' | 'premiumPrices'
> = {
  basicPrices: { initial: 0, total: 0, perPerson: [], perContribution: [] },
  premiumPrices: { initial: 0, total: 0, perPerson: [], perContribution: [] },
};

export const contributionDetailsInitial: ContributionDetails = {
  ...contributionDetailsBase,
  basicProducts,
  premiumProducts,
};

export const contributionDetailsInitialDS100: ContributionDetails = {
  ...contributionDetailsBase,
  basicProducts: basicProductsDS100,
  premiumProducts: premiumProductsDS100,
};
export const contributionDetailsInitialZEZ: ContributionDetails = {
  ...contributionDetailsBase,
  basicProducts: basicProductsZEZ,
  premiumProducts: premiumProductsZEZ,
};

export const contributionDetailsInitialZEF: ContributionDetails = {
  ...contributionDetailsBase,
  basicProducts: basicProductsZEF,
  premiumProducts: premiumProductsZEF,
};

export const contributionDetailsInitialZZP: ContributionDetails = {
  ...contributionDetailsBase,
  basicProducts: basicProductsZZP,
  premiumProducts: premiumProductsZZP,
};

const [personId] = personIdsMock.keys();

// Create perPerson array for every possible person IDs to show price in sidebar
const perPerson = Array.from(personIdsMock).map((elem) => {
  const [personId] = elem;
  return {
    personId,
    initial: 33.65,
    final: 51.5,
  };
});

export const contributionDetails: ContributionDetails = {
  basicPrices: {
    initial: 33.65,
    total: 51.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: ProductIds.ZAHN_DS100,
        personId,
        initial: 4.4,
        final: 8.8,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS75,
        personId,
        initial: 10.15,
        final: 20.3,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS90,
        personId,
        initial: 3.3,
        final: 6.6,
      },
      {
        contributionSelected: ProductIds.ZAHN_DVB,
        personId,
        initial: 9.9,
        final: 9.9,
      },
      {
        contributionSelected: ProductIds.ZAHN_DVE,
        personId,
        initial: 5.9,
        final: 5.9,
      },
    ],
  },
  premiumPrices: {
    initial: 43.65,
    total: 61.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: ProductIds.ZAHN_DS100,
        personId,
        initial: 4.4,
        final: 8.8,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS75,
        personId,
        initial: 10.15,
        final: 20.3,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS90,
        personId,
        initial: 3.3,
        final: 6.6,
      },
      {
        contributionSelected: ProductIds.ZAHN_DVB,
        personId,
        initial: 9.9,
        final: 9.9,
      },
      {
        contributionSelected: ProductIds.ZAHN_DVE,
        personId,
        initial: 5.9,
        final: 5.9,
      },
    ],
  },
  basicProducts,
  premiumProducts,
};

export const contributionDetailsDS100: ContributionDetails = {
  basicPrices: {
    initial: 33.65,
    total: 51.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: ProductIds.ZAHN_DS100,
        personId,
        initial: 4.4,
        final: 8.8,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS75,
        personId,
        initial: 10.15,
        final: 20.3,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS90,
        personId,
        initial: 3.3,
        final: 6.6,
      },
    ],
  },
  premiumPrices: {
    initial: 43.65,
    total: 61.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: ProductIds.ZAHN_DS100,
        personId,
        initial: 4.4,
        final: 8.8,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS75,
        personId,
        initial: 10.15,
        final: 20.3,
      },
      {
        contributionSelected: ProductIds.ZAHN_DS90,
        personId,
        initial: 3.3,
        final: 6.6,
      },
      {
        contributionSelected: ProductIds.ZAHN_DVB,
        personId,
        initial: 9.9,
        final: 9.9,
      },
    ],
  },
  basicProducts: basicProductsDS100,
  premiumProducts: premiumProductsDS100,
};

export const contributionDetailsZEZ: ContributionDetails = {
  basicPrices: {
    initial: 33.65,
    total: 51.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: OldProductIds.ZAHN_ZEZ,
        personId,
        initial: 4.4,
        final: 8.8,
      },
    ],
  },
  premiumPrices: {
    initial: 0,
    total: 0,
    perPerson: [],
    perContribution: [],
  },
  basicProducts: basicProductsZEZ,
  premiumProducts: premiumProductsZEZ,
};

export const contributionDetailsZEF: ContributionDetails = {
  basicPrices: {
    initial: 33.65,
    total: 51.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: OldProductIds.ZAHN_ZEF,
        personId,
        initial: 4.4,
        final: 8.8,
      },
    ],
  },
  premiumPrices: {
    initial: 43.65,
    total: 61.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: OldProductIds.ZAHN_ZEF,
        personId,
        initial: 4.4,
        final: 8.8,
      },
      {
        contributionSelected: ProductIds.ZAHN_DVB,
        personId,
        initial: 10.15,
        final: 20.3,
      },
    ],
  },
  basicProducts: basicProductsZEF,
  premiumProducts: premiumProductsZEF,
};

export const contributionDetailsZZP: ContributionDetails = {
  basicPrices: {
    initial: 33.65,
    total: 51.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: OldProductIds.ZAHN_ZZP,
        personId,
        initial: 4.4,
        final: 8.8,
      },
    ],
  },
  premiumPrices: {
    initial: 43.65,
    total: 61.5,
    perPerson,
    perContribution: [
      {
        contributionSelected: OldProductIds.ZAHN_ZZP,
        personId,
        initial: 4.4,
        final: 8.8,
      },
      {
        contributionSelected: ProductIds.ZAHN_DVB,
        personId,
        initial: 10.15,
        final: 20.3,
      },
    ],
  },
  basicProducts: basicProductsZZP,
  premiumProducts: premiumProductsZZP,
};

export const getContributionDetails = (initialProducts: InitProducts) => {
  const { basic } = initialProducts;
  const firstProductId = basic[0];
  const isDS100 = firstProductId === ProductIds.ZAHN_DS100;
  const isZEZ = firstProductId === OldProductIds.ZAHN_ZEZ;
  const isZEF = firstProductId === OldProductIds.ZAHN_ZEF;
  const isZZP = firstProductId === OldProductIds.ZAHN_ZZP;

  if (isDS100) {
    return contributionDetailsDS100;
  }

  if (isZEZ) {
    return contributionDetailsZEZ;
  }

  if (isZEF) {
    return contributionDetailsZEF;
  }

  if (isZZP) {
    return contributionDetailsZZP;
  }

  return contributionDetails;
};
