import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'bank-details.title',
    defaultMessage: 'Geben Sie Ihre Bankverbindung an.',
  },
  subtitle: {
    id: 'bank-details.subtitle',
    defaultMessage:
      'Für einen Online-Abschluss benötigt der Versicherer ein SEPA-Lastschriftmandat.',
  },
  tooltip: {
    id: 'bank-details.tooltip.content',
    defaultMessage:
      'Ihre IBAN finden Sie auf der Vorderseite Ihrer Bankkarte oder auf Ihrem Kontoauszug.',
  },
  formHolder: {
    id: 'bank-details.account-holder-label',
    defaultMessage: 'Kontoinhaber',
  },
  formIBAN: {
    id: 'bank-details.iban-label',
    defaultMessage: 'IBAN',
  },
  formIBANPlaceholder: {
    id: 'bank-details.iban-placeholder',
    defaultMessage: 'DE12 3456 7890 1234 5678 90',
  },
  successNotification: {
    id: 'bank-details.success-notification-iban',
    defaultMessage:
      'Ihre Kontoverbindung wurde erfolgreich geprüft. Die zugehörige BIC wurde automatisch ergänzt.',
  },
  successBicLabel: {
    id: 'bank-details.bic-label',
    defaultMessage: 'BIC',
  },
  successBankName: {
    id: 'bank-details.bank-name',
    defaultMessage: 'Geldinstitut',
  },
  authTitle: {
    id: 'bank-details.authorization-title',
    defaultMessage:
      '<bold>Ich ermächtige die ERGO Krankenversicherung AG (Gläubiger-ID DE25EDK00000041713), Zahlungen von meinem Konto mittels Lastschrift einzuziehen.</bold><br></br>',
  },
  authDescription: {
    id: 'bank-details.authorization-description',
    defaultMessage:
      'Zugleich weise ich mein Kreditinstitut an, die von der ERGO Krankenversicherung AG auf mein Konto gezogenen Lastschriften einzulösen. Der SEPA-Basislastschrift-Einzug wird mir spätestens 5 Kalendertage im Voraus unter Angabe der weiteren Fälligkeitstermine angekündigt.',
  },
  authWarning: {
    id: 'bank-details.authorization-warning',
    defaultMessage: '<bold>Hinweis:</bold>',
  },
  authWarningDescription: {
    id: 'bank-details.authorization-warning-description',
    defaultMessage:
      'Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Dabei gelten die mit meinem Kreditinstitut vereinbarten Bedingungen.',
  },
  errorCountry: {
    id: 'bank-details.error-invalid-iban-country',
    defaultMessage: 'Wir akzeptieren Banken des SEPA-Raums.',
  },
  errorIBAN: {
    id: 'bank-details.error-invalid-iban',
    defaultMessage:
      'Diese IBAN ist nicht korrekt. Bitte korrigieren Sie Ihre Angabe.',
  },
  emptyIBAN: {
    id: 'bank-details.error-empty-iban',
    defaultMessage: 'Bitte geben Sie Ihre Bankverbindung an.',
  },
  sepaLabel: {
    id: 'bank-details.sepa-warning.tooltip-label',
    defaultMessage: '<bold>Hinweise zum SEPA-Lastschriftmandat</bold>',
  },
  sepaContent: {
    id: 'bank-details.sepa-warning.tooltip-content',
    defaultMessage:
      'Sollte bereits ein Mandat für die oben genannte Kontoverbindung bestehen, bin ich damit einverstanden, dass das von mir bereits erteilte SEPA-Lastschriftmandat auch für den Einzug der Beiträge für diesen Versicherungsvertrag genutzt wird.',
  },
  buttonOffer: {
    id: 'bank-details.button.offer',
    defaultMessage: 'Angebot anfordern',
  },
  emailTooltip: {
    id: 'bank-details.email-tooltip',
    defaultMessage:
      '<bold>Warum benötigt ERGO Ihre E-Mail-Adresse?</bold><br></br><br></br>ERGO benötigt bitte Ihre E-Mail-Adresse, um Ihnen die Anforderung per E-Mail zu bestätigen bzw. Ihnen das Angebot per E-Mail zusenden zu können. Diese Angabe unterliegt selbstverständlich unseren strengen Datenschutz-Bestimmungen.',
  },
});
