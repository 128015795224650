import { OldProductIds, Tariffs, TariffPrices } from '../types/products';

export const emptyTariff: TariffPrices = {
  initial: 0,
  total: 0,
  perPerson: [],
  perContribution: [],
};

export const isOldTariff = (products: Tariffs[]): boolean => {
  const oldProductIds = Object.values(OldProductIds);
  return oldProductIds.includes(products[0] as OldProductIds);
};

export const getEmptyPrices = () => {
  return {
    basicPrices: { ...emptyTariff },
    premiumPrices: { ...emptyTariff },
  };
};

export const gotTariffPrices = (basic: TariffPrices, premium: TariffPrices) => {
  const { initial: initialBasic, total: totalBasic } = basic;
  const { initial: initialPremium, total: totalPremium } = premium;

  const gotInitial = initialBasic !== 0 && initialPremium !== 0;
  const gotFinal = totalBasic !== 0 && totalPremium !== 0;
  return gotInitial && gotFinal;
};
