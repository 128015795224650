import {
  Person,
  Address,
  Identification,
  ConsentContact,
} from 'ppz-otr-common';

import { PERSON_INPUTS } from '../../types';

export type FormType = 'personal' | 'address';

export type OnChangeForm = (
  id: Person['id'],
  data: Identification | Address,
  type: FormType,
) => void;

export type OnDiffAddress = (id: Person['id'], checked: boolean) => void;

export const enum CONSENT_TYPES {
  UPDATE = 'UPDATE',
}

export const enum ADDRESS_ACTIONS {
  UPDATE_STREET,
  UPDATE_STREET_NUMBER,
  UPDATE_POSTAL_CODE,
  UPDATE_CITY,
}

export const enum ERROR_ACTIONS {
  EMPTY_STREET,
  INVALID_STREET,
  EMPTY_STREET_NUMBER,
  EMPTY_POSTAL_CODE,
  INVALID_POSTAL_CODE,
  AGENT_REQUIRED,
}

export type ConsentContactAction = {
  type: CONSENT_TYPES;
  payload: ConsentContact;
};

export type ADDRESS_ACTION_TYPE = {
  type: ADDRESS_ACTIONS;
  payload: string;
};

export interface EMPTY_ERROR {
  value: boolean;
  forced: boolean;
}

export type ERROR_ACTION_TYPE = {
  type: ERROR_ACTIONS;
  payload: EMPTY_ERROR | string | boolean;
};

type FormErrorType = 'empty' | 'format';

export interface FormError {
  input: PERSON_INPUTS;
  index: number;
  type?: FormErrorType;
}

export type PersonalInputType = 'name' | 'lastname';

export interface PersonalDataStatus {
  showLegalConsent: boolean;
  showOfferByEmail: boolean;
}

interface ZipCodeParams {
  value: string;
  valid: boolean;
  touched: boolean;
}

interface OnChangeZipCodeParams {
  zipCode: ZipCodeParams;
  city: string;
}

export type OnChangeZipCode = (params: OnChangeZipCodeParams) => void;
