const enum BirthdateABTestingIds {
  ownerTitle = 'ppz201',
  ownerDate = 'ppz202',
  buttonContainer = 'ppz203',
  nextButton = 'ppz204',
  backButton = 'ppz205',

  insuredTitle = 'ppz250',
  insuredButtonContainer = 'ppz251',
  addPersonButton = 'ppz252',
  insuredNextButton = 'ppz253',
  insuredBackButton = 'ppz254',
  insuredPrefixDate = 'ppz27',
}

export default BirthdateABTestingIds;
