import { Dispatch } from 'react';

import { Person, ConsentContact, ContactElement } from 'ppz-otr-common';

import { APPOINTMENT_TYPE, PERSON_INPUTS } from '../../../types';
import { FormError } from '../types';

import { getEmptyAddress } from './address';
import { hasNameFormatError, isLastNameEmpty, isNameEmpty } from './person';

import { hasValue, inputHasError } from '.';

export interface CheckFormErrors {
  persons: Map<string | number | null, Person>;
  path: APPOINTMENT_TYPE | undefined;
  status: {
    consent: ConsentContact | undefined;
    byOffer: ContactElement | undefined;
  };
  callbacks: {
    showEmptyErrors: Dispatch<boolean>;
    showEmailError: Dispatch<boolean>;
    showPhoneError: Dispatch<boolean>;
  };
}

const checkFormErrors = (params: CheckFormErrors): FormError | null => {
  const { persons, status, callbacks } = params;
  const { consent, byOffer } = status;
  const { showEmailError, showEmptyErrors, showPhoneError } = callbacks;

  const errors = getError(
    persons,
    consent,
    byOffer,
    showEmailError,
    showPhoneError,
  );

  if (!errors) return null;

  const { type } = errors;

  if (type && type === 'empty') showEmptyErrors(true);

  return errors;
};

const getError = (
  persons: Map<string | number | null, Person>,
  consent: ConsentContact | undefined = undefined,
  emailOffer: ContactElement | undefined = undefined,
  showEmailError: Dispatch<boolean>,
  showPhoneError: Dispatch<boolean>,
): FormError | null => {
  const formError = getMainFormError(persons);
  if (formError) return formError;

  if (consent) {
    const consentError = getConsentError(
      consent,
      showEmailError,
      showPhoneError,
    );
    if (consentError) {
      return { input: consentError, index: 0 };
    }
  }

  if (emailOffer) {
    const offerByEmailError = getOfferByEmailError(emailOffer);
    if (offerByEmailError) {
      showEmailError(true);
      return { input: offerByEmailError, index: 0 };
    }
  }

  return null;
};

const getMainFormError = (
  persons: Map<string | number | null, Person>,
): FormError | null => {
  const _persons = Array.from(persons);

  for (let index = 0, len = _persons.length; index < len; index++) {
    const [, _person] = _persons[index];

    const _isGenderEmpty = !hasValue(_person.gender);
    if (_isGenderEmpty)
      return { input: PERSON_INPUTS.gender, index, type: 'empty' };

    if (isNameEmpty(_person)) {
      return { input: PERSON_INPUTS.name, index, type: 'empty' };
    } else {
      if (hasNameFormatError(_person.name!)) {
        return { input: PERSON_INPUTS.name, index, type: 'format' };
      }
    }

    if (isLastNameEmpty(_person)) {
      return { input: PERSON_INPUTS.lastname, index, type: 'empty' };
    } else {
      if (hasNameFormatError(_person.lastname!)) {
        return { input: PERSON_INPUTS.lastname, index, type: 'format' };
      }
    }

    const emptyAddress = getEmptyAddress(_person);
    if (emptyAddress) {
      return { input: emptyAddress, index, type: 'empty' };
    }
  }

  return null;
};

const getConsentError = (
  consent: ConsentContact,
  showEmailError: Dispatch<boolean>,
  showPhoneError: Dispatch<boolean>,
): PERSON_INPUTS | null => {
  const { email, phone } = consent;

  if (inputHasError(email)) {
    showEmailError(true);
    return PERSON_INPUTS.consentReceiveEmail;
  }
  if (inputHasError(phone)) {
    showPhoneError(true);
    return PERSON_INPUTS.consentReceivePhone;
  }

  return null;
};

const getOfferByEmailError = (
  emailOffer: ContactElement,
): PERSON_INPUTS | null => {
  if (inputHasError(emailOffer)) return PERSON_INPUTS.offerEmail;
  return null;
};

export { checkFormErrors };
