import { AemAssets } from '../../../types';
import { getAssetURL, getTextInputValue } from '../../../utils';
import { aemInputHasValue } from '../helpers';

const useGetCancelationElementDetails = () => {
  const firstLine = getTextInputValue('cancelationElementFirstLine');
  const secondLine = getTextInputValue('cancelationElementSecondLine');
  const tooltip = getTextInputValue('cancelationElementDescription');
  const imagePath = getAssetURL(
    getTextInputValue('cancelationElementImage'),
    AemAssets.IMAGE,
  );

  const showCancelationElement =
    aemInputHasValue(firstLine) ||
    aemInputHasValue(secondLine) ||
    aemInputHasValue(tooltip) ||
    aemInputHasValue(imagePath)
      ? true
      : false;

  return {
    showCancelationElement,
    firstLine,
    secondLine,
    tooltip,
    imagePath,
  };
};

export default useGetCancelationElementDetails;
