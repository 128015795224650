import { ActionButtons } from '../../../components/Common';
import { PageLayout } from '../../../layouts';
import { Icon, SingleButton } from '../../../types';

import { FormBirthdates } from '.';

interface Props {
  title: string;
  titleId: string;
  subtitle: string;
  buttonsList: SingleButton[];
  buttonListId: string;
  loading: boolean;
  infoIcon?: Icon;
  children: React.ReactNode;
}

const BirthdatesLayout = ({
  title,
  titleId,
  subtitle,
  buttonsList,
  buttonListId,
  loading,
  infoIcon = {},
  children,
}: Props) => {
  return (
    <PageLayout
      title={title}
      id={titleId}
      subtitle={subtitle}
      infoIcon={infoIcon}
      textAlign="left"
      reducedMarginBottom={true}
    >
      {!loading && (
        <>
          <FormBirthdates
            data-testid="FormBirthdates"
            onSubmit={(event) => event.preventDefault()}
          >
            {children}
          </FormBirthdates>
          <ActionButtons buttonsList={buttonsList} id={buttonListId} />
        </>
      )}
    </PageLayout>
  );
};

export default BirthdatesLayout;
