import AppInit from './AppInit';
import AppProviders from './components/App/AppProviders';
import AppRouter from './components/App/AppRouter';
import Nav from './components/Nav';
import useHttpResponse from './hooks/http/useResponse';
import useHistory from './hooks/useHistory';
import AppLayout from './layouts/AppLayout';
import { httpInstance as http } from './services';
import GlobalStyle, { Environment } from './styles/GlobalStyle';
import { isAEM } from './utils';

const hostname = window.location.hostname;
const environment: Environment = {
  aem: isAEM(),
  dev: hostname === 'localhost' || hostname === '127.0.0.1',
};

function App() {
  useHistory(); // Custom router history
  useHttpResponse(http);

  return (
    <AppProviders>
      <AppInit>
        <GlobalStyle environment={environment} />
        {environment.dev ? <Nav /> : <></>}
        <AppLayout>
          <AppRouter />
        </AppLayout>
      </AppInit>
    </AppProviders>
  );
}

export default App;
