import { IntlShape } from 'react-intl';

import {
  BirthdateData,
  Person,
  INSURANCE_TYPE,
  OFFER_ROLES,
  CrudBirthdatesRequest,
  InitProducts,
} from 'ppz-otr-common';

import { defaultPerson, PersonContext } from '../../../context/PersonCtx';
import {
  crudBirthdates,
  validateBirthdate,
} from '../../../services/birthdates';
import { personToBirthdateData } from '../../../utils';
import { BirthdateMap, UpdateTariffs } from '../types';
import {
  formatDateBackend,
  getNewMapFromArr,
  getValidateError,
  hasErrors,
  isValid,
} from '../utils';

export interface CurrentValues {
  businessId: string;
  situation: INSURANCE_TYPE;
  type: INSURANCE_TYPE;
  dateValues: BirthdateMap;
  persons: PersonContext['persons'];
  spcsPersons: PersonContext['spcsPersons'];
  initialProducts: InitProducts;
  maxDate: Date;
  minDate: Date;
  intl: IntlShape;
}

export interface UpdateCurrentValues {
  updatePersons: (
    newPersons: PersonContext['persons'],
    newDateValues: React.SetStateAction<BirthdateMap>,
  ) => void;
  updateDateValues: (newDateValues: BirthdateMap) => void;
  updateTariffs: UpdateTariffs;
  setLoading: React.Dispatch<boolean>;
  genericError: () => void;
}

const useBirthdateBackend = (
  {
    businessId,
    situation,
    type,
    dateValues,
    persons,
    spcsPersons,
    initialProducts,
    maxDate,
    minDate,
    intl,
  }: CurrentValues,
  {
    updatePersons,
    updateDateValues,
    updateTariffs,
    setLoading,
    genericError,
  }: UpdateCurrentValues,
) => {
  const getFilteredDates = () => {
    const birthdatesArr = Array.from(dateValues.values());
    const newDates: BirthdateData[] = birthdatesArr.filter(
      ({ id, errors }) => !isNaN(id as number) && errors.valid,
    );

    const updatedDates: BirthdateData[] = birthdatesArr.filter(
      ({ id, day, month, year, errors }) => {
        const ctxBirthdate = persons.get(id);
        if (!ctxBirthdate) return false;
        const { day: _day, month: _month, year: _year } = ctxBirthdate;
        return (
          isNaN(id as number) &&
          (day !== _day || month !== _month || year !== _year) &&
          errors.valid
        );
      },
    );

    const deletedDates: Person['id'][] =
      type === INSURANCE_TYPE.myself
        ? []
        : Array.from(spcsPersons.values())
            .filter(({ id }) => {
              return isNaN(id as number) && !dateValues.get(id);
            })
            .map(({ id }) => id);

    return { newDates, updatedDates, deletedDates };
  };

  const getNewPersons = (
    originalValues: BirthdateData[],
  ): PersonContext['persons'] => {
    return getNewMapFromArr(
      originalValues.map(({ id, role, day, month, year }) => ({
        ...defaultPerson,
        ...persons.get(id)!,
        id,
        role,
        day,
        month,
        year,
      })),
    ) as PersonContext['persons'];
  };

  const getOrderedDates = (
    birthdateArr: BirthdateData[],
    backendDates: BirthdateData[],
    { newDates, updatedDates, deletedDates },
  ) => {
    const nonUpdatedDates = birthdateArr.filter(({ id }) => {
      return (
        !newDates.some(({ id: newDateId }) => id === newDateId) &&
        !updatedDates.some(({ id: updatedDateId }) => id === updatedDateId) &&
        !deletedDates.some((deletedDateId) => id === deletedDateId)
      );
    });

    const orderedDates: BirthdateData[] = [];
    const allDates = nonUpdatedDates.concat(backendDates);

    // Insurance owner goes first
    const indexInsuranceOwner = allDates.findIndex((newItem) => {
      return newItem.role === OFFER_ROLES.insuranceOwner;
    });

    if (indexInsuranceOwner > -1) {
      orderedDates.push(allDates[indexInsuranceOwner]);
      allDates.splice(indexInsuranceOwner, 1);
    }

    // Insured persons
    if (birthdateArr.length > 1) {
      // Iterate original order
      for (var i = 0; i < birthdateArr.length; i++) {
        const elem = birthdateArr[i];

        if (elem.role === OFFER_ROLES.insuranceOwner) {
          continue;
        }

        // Search for the same date as in original array
        const indexInsured = allDates.findIndex((newItem) => {
          return (
            newItem.day === elem.day &&
            newItem.month === elem.month &&
            newItem.year === elem.year
          );
        });

        if (indexInsured > -1) {
          orderedDates.push(allDates[indexInsured]);
          allDates.splice(indexInsured, 1);
        }
      }
    }

    // Sort dates leaving empty ones at the end
    orderedDates.sort((a, b) => {
      const isEmptyDate = (date: BirthdateData) => {
        return date.day === '' && date.month === '' && date.year === '';
      };
      const isAEmpty = isEmptyDate(a);
      const isBEmpty = isEmptyDate(b);
      if (isAEmpty && isBEmpty) return 0;
      if (isAEmpty) return 1;
      if (isBEmpty) return -1;
      return 0;
    });

    return orderedDates;
  };

  const bulkUpdateDates = async (
    newDates: BirthdateData[],
    updatedDates: Person[],
    deletedDates: Person['id'][],
  ) => {
    const birthdateArr: BirthdateData[] = Array.from(dateValues.values());

    const response = await crudBirthdates(
      businessId as string,
      {
        newDates,
        updatedDates,
        deletedDates,
        situation,
        initialProducts,
      } as CrudBirthdatesRequest,
      genericError,
    );

    if (!response || !response.data) {
      genericError();
      return;
    }

    const { dates: backendDates, contributionDetails } = response.data;

    if (!backendDates) {
      return;
    }

    const newDateValues = getOrderedDates(birthdateArr, backendDates, {
      newDates,
      updatedDates,
      deletedDates,
    });
    if (
      type === INSURANCE_TYPE.myself &&
      Array.from(persons.values()).some(
        ({ role }) => role === OFFER_ROLES.insuredPerson,
      )
    ) {
      const allDates = [
        ...newDateValues,
        ...Array.from(persons.values())
          .filter(({ role }) => role === OFFER_ROLES.insuredPerson)
          .map((person) =>
            personToBirthdateData(person, isValid(person, maxDate, minDate)),
          ),
      ];
      const newPersons: PersonContext['persons'] = getNewPersons(allDates);
      updatePersons(newPersons, getNewMapFromArr(allDates) as BirthdateMap);
    } else {
      const newPersons: PersonContext['persons'] = getNewPersons(newDateValues);
      updatePersons(
        newPersons,
        getNewMapFromArr(newDateValues) as BirthdateMap,
      );
    }
    updateTariffs(contributionDetails);
  };

  const saveChanges = async (onSuccess?: () => void) => {
    const { newDates, updatedDates, deletedDates } = getFilteredDates();
    if (
      newDates.length > 0 ||
      updatedDates.length > 0 ||
      deletedDates.length > 0
    ) {
      setLoading(true);
      const response = await validateBirthdate(
        businessId as string,
        newDates.concat(updatedDates),
        genericError,
      );

      if (!response) {
        genericError();
        return;
      }

      const { data: validatedDates } = response;

      if (!validatedDates) return;

      if (!hasErrors(getNewMapFromArr(validatedDates) as BirthdateMap)) {
        await bulkUpdateDates(
          newDates,
          updatedDates.map(({ id, day, month, year }) => ({
            ...persons.get(id)!,
            day,
            month,
            year,
          })),
          deletedDates,
        );
        onSuccess && onSuccess();
      } else {
        const newDates = validatedDates.map(
          ({
            id,
            role,
            day,
            month,
            year,
            errors: {
              valid,
              rangeOverflow,
              rangeUnderflow,
              valueMissing,
              badInput,
            },
          }) => {
            const error = getValidateError(
              formatDateBackend(day, month, year),
              rangeUnderflow as string,
              rangeOverflow as string,
              maxDate,
              role === OFFER_ROLES.insuranceOwner,
              badInput as string,
              valueMissing as string,
              intl,
            );

            let validatedDate: BirthdateData = {
              id,
              role,
              day,
              month,
              year,
              errors: {
                valid,
              },
            };
            if (!valid) {
              validatedDate = {
                ...validatedDate,
                errors: {
                  ...validatedDate.errors,
                  badInput: error,
                },
              };
            }

            return validatedDate;
          },
        );
        updateDateValues(getNewMapFromArr(newDates) as BirthdateMap);
      }
    } else {
      onSuccess && onSuccess();
    }
  };

  return {
    saveChanges,
  };
};

export default useBirthdateBackend;
