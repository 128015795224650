import { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import AnnotationProvider from '@eg/elements/AnnotationProvider';

import { Person, INSURANCE_TYPE } from 'ppz-otr-common';

import commonLiterals from '../../language/src/common';
import SmallPrint from '../Common/SmallPrint';

import { Summary, Breakdown } from './components';
import { Container, Content } from './layouts';
import { Void } from './types';

interface Props {
  /**
   * @property title
   * @type string
   */
  title: string;
  /**
   * show sidebar
   * @property shown
   * @type boolean | undefined
   * @default false
   */
  shown?: boolean;
  /**
   * @property products
   * @type string
   */
  products?: string; // sidebar
  /**
   * @property personTariffs
   * @type Person[] | undefined
   */
  personTariffs: Person[]; // sidebar
  /**
   * @property tariff
   * @type number | undefined
   */
  tariff?: number; // sidebar
  /**
   * @property finalTariff
   * @type number | undefined
   */
  finalTariff?: number;
  /**
   * @property insuranceBegin
   * @type string | undefined
   */
  insuranceBegin?: string;
  /**
   * @property insuranceSituation
   * @type INSURANCE_TYPE (myself | myself-and-more | other-people-only)
   *
   */
  insuranceSituation: INSURANCE_TYPE | null;
  /**
   * onClose sidebar callback
   * @property onClose
   * @callback Void
   */
  onClose: Void;
}

const Sidebar = (sidebarProps: Props) => {
  const { shown, title, insuranceSituation, onClose, ...props } = sidebarProps;
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const intl = useIntl();
  const closeOnEscapeKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose();
      if (shown && event.key === 'Tab') {
        event.preventDefault();
        closeButtonRef.current?.focus();
      }
    },
    [onClose, shown],
  );

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);

  useEffect(() => {
    if (shown) document.body.classList.add('sidebar-shown');
    else document.body.classList.remove('sidebar-shown');
  }, [shown]);

  return ReactDOM.createPortal(
    <CSSTransition in={shown} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <Container
        data-testid="sidebar__container"
        className={shown ? 'show' : ''}
        onClick={onClose}
      >
        <Content
          data-testid="sidebar__content"
          shown={shown ?? false}
          title={title}
          forwardRef={closeButtonRef}
          onClose={onClose}
        >
          <AnnotationProvider>
            <Summary {...props} />
            <Breakdown
              insuranceType={insuranceSituation ?? INSURANCE_TYPE.others}
              {...props}
            />
            <SmallPrint
              textAlign="left"
              noPaddingLeft
              message={intl.formatMessage(
                commonLiterals.smallPrintPriceAsterisk,
              )}
            />
          </AnnotationProvider>
        </Content>
      </Container>
    </CSSTransition>,
    document.getElementById('root')!,
  );
};

export default Sidebar;
