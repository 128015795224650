import { ContactElement } from 'ppz-otr-common';

import ABTestingIds from '../../../ABTesting/personalData';
import EmailSection from '../../../components/EmailSection/EmailSection';
import literals from '../../../language/src/personal-data';
import { PERSON_INPUTS } from '../../../types';

interface Props {
  showEmailError: boolean;
  onBlur: (email: ContactElement) => void;
  onChange: (email: ContactElement) => void;
  value: string;
}

const OfferByEmail = ({ showEmailError, onBlur, onChange, value }: Props) => {
  const { offerByEmailTitle, offerByEmailDescription } = literals;
  const emailInputId = `${PERSON_INPUTS.offerEmail}-0`;

  return (
    <div className="abTesting" id={ABTestingIds.offerEmail}>
      <EmailSection
        id={emailInputId}
        title={offerByEmailTitle.id}
        description={offerByEmailDescription.id}
        showError={showEmailError}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default OfferByEmail;
