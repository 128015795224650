import { useEffect, useState } from 'react';

import { INSURANCE_TYPE } from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/header';
import { useInsurance } from '../../context/InsuranceCtx';
import { usePerson } from '../../context/PersonCtx';
import { useResize } from '../../hooks';
import { useSteps } from '../../hooks/useSteps';
import Container from '../../layouts/Container';
import { ProgressBar } from '../ProgressBar/ProgressBar';

import { HeaderContainer, HeaderContent } from './layouts';
import { Void } from './types';

import { HeaderTitle, HeaderTariff } from '.';

interface Props {
  /**
   * @property title
   * @type string
   */
  title: string;
  /**
   * @property products
   * @type string
   */
  products?: string;
  /**
   * @property startTariff
   * @type number
   */
  startTariff?: number;
  /**
   * @property finalTariff
   * @type number
   */
  finalTariff?: number;
  /**
   * @property onShowSidebar
   * @type Void
   */
  onShowSidebar: Void;
}

const Header = ({
  finalTariff,
  startTariff,
  onShowSidebar,
  ...props
}: Props) => {
  const { numberOfSteps, currentStep } = useSteps();
  const { situation } = useInsurance();
  const { spcsPersons } = usePerson();
  const [width, height] = useResize();

  const [navHeight, setNavHeight] = useState<number>(0);

  const tariffsExist =
    !!(startTariff && finalTariff) || (startTariff === 0 && !!finalTariff);
  const appHasEnoughData = !!situation && spcsPersons?.size > 0;
  // Edge case when coming from IZS with adult birthdate and choosing Only Others
  const isIzsWithoutInit =
    situation === INSURANCE_TYPE.others && spcsPersons.size === 1;
  const gotTariff = tariffsExist && appHasEnoughData && !isIzsWithoutInit;

  useEffect(() => {
    const navId = ABTestingIds.aemHeader;
    const mainNav = document.getElementById(navId);
    const top = mainNav ? mainNav.offsetHeight : 0;
    setNavHeight(top);
  }, [width, height]);

  return (
    <HeaderContainer
      className="abTesting"
      id={ABTestingIds.container}
      top={navHeight}
    >
      <ProgressBar max={numberOfSteps} value={currentStep} />
      <Container className="header__container">
        <HeaderContent className="header__content">
          <HeaderTitle isCentered={!gotTariff} {...props} />
          {gotTariff && (
            <HeaderTariff
              startTariff={startTariff}
              finalTariff={finalTariff}
              onShowSidebar={onShowSidebar}
            />
          )}
        </HeaderContent>
      </Container>
    </HeaderContainer>
  );
};

export default Header;
