import { rest } from 'msw';

import { SHIPPING_ADDRESS_API } from '../../services';
import { getUrl, resDefault } from '../utils';

import { saveEmail } from './responses/';

const saveEmailUrl = getUrl(SHIPPING_ADDRESS_API.POST);
const saveEmailHandler = rest.post(saveEmailUrl, (req, res, ctx) => {
  return resDefault(ctx.status(201), ctx.json(saveEmail));
});

export const handlers = [saveEmailHandler];
export default handlers;
