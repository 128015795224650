import styled, { css } from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';

type ContentType = 'general' | 'title' | 'tariff';

interface ContentProps {
  type: ContentType;
  gotProducts?: boolean;
}

const getStyleByType = (type: ContentType, gotProducts = false) => {
  if (type === 'title') {
    return css`
      align-items: center;
      justify-content: flex-end;
      height: ${sizes.space.PX_58};

      @media (min-width: ${breakpoints.M}) {
        align-items: flex-start;
        justify-content: center;
        height: ${sizes.space.PX_92};
      }
    `;
  }
  if (type === 'tariff') {
    return css`
      align-items: center;
      justify-content: flex-start;
      height: ${sizes.space.PX_58};

      @media (min-width: ${breakpoints.M}) {
        align-items: flex-end;
        justify-content: center;
        height: ${sizes.space.PX_92};
      }
    `;
  }

  return css`
    align-items: center;
    justify-content: center;
    height: ${gotProducts ? sizes.space.PX_74 : sizes.space.PX_52};

    @media (min-width: ${breakpoints.L}) {
      height: ${gotProducts ? sizes.space.PX_92 : sizes.space.PX_64};
    }
  `;
};

const Content = styled.div<ContentProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${sizes.space.PX_04};
  ${({ type, gotProducts }) => getStyleByType(type, gotProducts)}

  @media (min-width: ${breakpoints.M}) {
    row-gap: ${({ type }) => type !== 'tariff' && sizes.space.PX_08};
  }
`;

export default Content;
