import { SpcsCountryCode } from './country-codes';

export interface Person extends Identification, Consents {
  id: number | string | null;
  role: OFFER_ROLES | null;
  day: string;
  month: string;
  year: string;
  diffAddress: boolean;
  address: Address;
  tariffDetails: TariffDetail | null;
  bankdata?: BankDetails | null;
  emailOffer?: string | null;
}
export interface TariffDetail {
  tariff: number;
  startPrice: number;
}
export interface Address {
  street: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
}
export interface Identification {
  name: string | null;
  lastname: string | null;
  gender: SpcsSalutation | null;
}

export interface Consents {
  email?: string;
  phone?: string;
}

export enum OFFER_ROLES {
  contributionPayer = 'BEITRAGSZAHLER',
  shippingAddress = 'VERSANDEMPFAENGER',
  insuredPerson = 'VERSICHERTE_PERSON',
  insuranceOwner = 'VERSICHERUNGSNEHMER',
}

export enum SpcsSalutation {
  Mr = 'MR',
  Ms = 'MS',
}

export enum SpcsAcademicTitle {
  Doctor = 'DOCTOR',
  Professor = 'PROFESSOR',
  ProfessorDoctor = 'PROFESSOR_DOCTOR',
}

export enum SpcsConsent {
  YES = 'YES',
  YES_IN_WRITING = 'YES_IN_WRITING',
  OBJECTION = 'OBJECTION',
}

export enum SpcsContactType {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export enum SpcsPartnerSystem {
  ERGO = 'ERGO',
  ERGO_DIREKT = 'ERGO_DIREKT',
}

export enum SpcsTelephoneType {
  FIXED_LINE = 'FIXED_LINE',
  MOBILE = 'MOBILE',
}

export enum ValidationErrorPlaces {
  gender = 0,
  name,
  lastName,
  streetName,
  streetNumber,
  zipCode,
  city,
  generic,
}

export interface SpcsTelephoneDetails {
  country: SpcsCountryCode;
  internationalAreaCode: string;
  areaCode: string;
  dialNumber: string;
}

export interface ConsentContact {
  email?: ContactElement;
  phone?: ContactElement;
}

export interface ContactElement {
  value: string;
  valid: boolean;
}

export interface BankDetails {
  id: string;
  iban: string;
  bic: string;
  financialInstitutionName: string;
  city: string;
  mrn: string | null;
}
