import { IntlShape } from 'react-intl';

import {
  Person,
  BirthdateData,
  OFFER_ROLES,
  INSURANCE_TYPE,
} from 'ppz-otr-common';

import { defaultPerson } from '../../../context/PersonCtx';
import literalsCommon from '../../../language/src/common';
import { SingleButton } from '../../../types';
import { personToBirthdateData } from '../../../utils';
import { BirthdateMap } from '../types';

import { hasErrors, isValid } from './validation';

import { getNewMapFromArr } from '.';

export const ONBLUR_ACTION_TIMEOUT = 200;

export const getDefaultDateValue = (role: OFFER_ROLES): BirthdateData => {
  const { id, day, month, year } = defaultPerson;
  return {
    id,
    role,
    day,
    month,
    year,
    errors: {
      valid: false,
    },
  };
};

export interface CommonButtonsIds {
  next: string;
  back: string;
}

export const getCommonButtons = (
  disabled: boolean,
  onNext: () => void,
  onBack: () => void,
  intl: IntlShape,
  ids: CommonButtonsIds,
): SingleButton[] => {
  return [
    {
      label: intl.formatMessage(literalsCommon.nextButton),
      type: 'primary',
      callbackOnClick: onNext,
      disabled,
      id: ids.next,
    },
    {
      label: intl.formatMessage(literalsCommon.backButton),
      type: 'text-link',
      callbackOnClick: onBack,
      id: ids.back,
    },
  ];
};

const _getInsuranceOwnerData = (
  currentPersons: Person[],
  defaultValue: BirthdateData,
  maxDate: Date,
  minDate: Date,
) => {
  const defaultInput = new Map([[defaultValue.id, defaultValue]]);
  const newDateValues: BirthdateMap = getNewMapFromArr([
    ...currentPersons
      .filter((date) => date.role === OFFER_ROLES.insuranceOwner)
      .map((person) =>
        personToBirthdateData(person, isValid(person, maxDate, minDate)),
      ),
  ]) as BirthdateMap;

  if (newDateValues.size === 0)
    return { newDateValues: defaultInput, disabled: true };

  return { newDateValues, disabled: hasErrors(newDateValues) };
};

const _getPersonsData = (
  type: INSURANCE_TYPE,
  persons: Map<string | number | null, Person>,
  defaultValue: BirthdateData,
  maxDate: Date,
  minDate: Date,
) => {
  const currentPersons: Person[] = Array.from(persons.values());
  const currentBirthdateData: BirthdateData[] = currentPersons.map((person) =>
    personToBirthdateData(person, isValid(person, maxDate, minDate)),
  );

  let newDateValues: BirthdateMap = getNewMapFromArr(
    currentBirthdateData,
  ) as BirthdateMap;

  if (type === INSURANCE_TYPE.myself)
    return _getInsuranceOwnerData(
      currentPersons,
      defaultValue,
      maxDate,
      minDate,
    );

  const hasInsuredPersons = currentPersons.some(
    ({ role }) => role === OFFER_ROLES.insuredPerson,
  );
  if (!hasInsuredPersons) {
    newDateValues = getNewMapFromArr([
      ...currentBirthdateData,
      defaultValue,
    ]) as BirthdateMap;
  }

  let disabled: boolean = hasErrors(newDateValues);

  return { newDateValues, disabled };
};

export const getDefaultValues = (
  type: INSURANCE_TYPE,
  persons: Map<string | number | null, Person>,
  defaultValue: BirthdateData,
  maxDate: Date,
  minDate: Date,
) => {
  const hasPersonData = persons.size > 0;
  const defaultInput = new Map([[defaultValue.id, defaultValue]]);

  if (!hasPersonData) return { newDates: defaultInput, disabled: true };

  const { newDateValues, disabled } = _getPersonsData(
    type,
    persons,
    defaultValue,
    maxDate,
    minDate,
  );
  return { newDates: newDateValues, disabled };
};
