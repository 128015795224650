import { useIntl } from 'react-intl';

import { OFFER_ROLES as ROLE, Person, INSURANCE_TYPE } from 'ppz-otr-common';

import ABTestingIds from '../../../ABTesting/sidebar';
import literals from '../../../language/src/sidebar';
import {
  SidebarText as Text,
  SidebarSection as Section,
  BreakdownList,
} from '../layouts';

import { PersonDetails } from '.';

interface Props {
  insuranceType: INSURANCE_TYPE;
  personTariffs: Person[];
}

const Breakdown = ({ insuranceType, personTariffs }: Props) => {
  const intl = useIntl();

  const titleLegend = intl.formatMessage(literals.breakDownTitle);

  const policyHolder = personTariffs.find(
    ({ role }) => role === ROLE.insuranceOwner,
  );
  const insuredPersons = personTariffs.filter(({ id, role }) => {
    const isNotPolicyHolder: boolean = !!policyHolder && id !== policyHolder.id;
    return role === ROLE.insuredPerson && isNotPolicyHolder;
  });

  const persons: Person[] = [...insuredPersons];

  if (!!policyHolder) persons.unshift(policyHolder);

  const sortedPersons = (dates: Person[]) => {
    return dates.filter(({ id }: Person) => typeof id === 'string');
  };

  return (
    <Section
      className="sidebar__breakdown abTesting abTestingOutLeft"
      id={ABTestingIds.breakdown}
    >
      <Text
        variant="primary"
        text={titleLegend}
        id={ABTestingIds.breakdownTitle}
      />
      <BreakdownList data-testid="sidebar__breakdown-list">
        {persons &&
          sortedPersons &&
          sortedPersons(persons).map((person: Person, key) => (
            <PersonDetails
              key={key}
              index={key + 1}
              insuranceType={insuranceType}
              person={person}
              idNameDate={`${ABTestingIds.personNameDatePrefix}${key}`}
              idPrice={`${ABTestingIds.personPricesPrefix}${key}`}
            />
          ))}
      </BreakdownList>
    </Section>
  );
};

export default Breakdown;
