import React from 'react';

import classnames from 'classnames';
import styled, { css } from 'styled-components';

import { breakpoints, colors, fonts, sizes } from '../../../styles/tokens';
import { Variant } from '../types';

interface Props {
  readonly variant?: Variant;
  readonly text: string | undefined;
  margin?: sizes.space;
  id?: string;
  type?: string;
}

const getTextStyle = (variant: Variant = 'paragraph', margin?: sizes.space) => {
  if (variant === 'primary') {
    return css`
      margin: 0 0 ${margin ?? sizes.space.PX_24};
      color: ${colors.BRAND_COLOR};
      font-family: ${fonts.HEADINGS};
      font-size: ${sizes.text.M};
    `;
  } else if (variant === 'secondary') {
    return css`
      margin: 0 0 ${margin ?? 0};
      color: 'inherit';
      font-family: ${fonts.REGULARS};
      font-size: ${sizes.text.S};

      @media (min-width: ${breakpoints.M}) {
        font-size: ${sizes.text.SM};
      }
    `;
  } else {
    return css`
      margin: 0 0 ${margin ?? 0};
      color: 'inherit';
      font-family: ${fonts.REGULARS};
      font-size: ${sizes.text.S};

      @media (min-width: ${breakpoints.M}) {
        font-size: ${sizes.text.SM};
      }
    `;
  }
};

const Tag = styled.span<Props>`
  ${({ variant, margin }) => getTextStyle(variant, margin)}
`;

const SidebarText = ({ ...props }: Props) => {
  const { text, variant, id } = props;

  const level = variant === 'primary' ? 2 : 4;
  const classNames = classnames(id && 'abTesting');
  return (
    <Tag
      as={!variant || variant === 'paragraph' ? 'p' : `h${level}`}
      {...props}
      className={classNames}
    >
      {text ?? ''}
    </Tag>
  );
};

export default SidebarText;
