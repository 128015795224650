const enum SidebarABTestingIds {
  sidebarContainer = 'ppz002',
  headerContainer = 'ppz003',
  sidebarBodyContainer = 'ppz004',
  summary = 'ppz005',
  breakdown = 'ppz006',
  title = 'ppz007',
  productNameAndBeginDate = 'ppz008',
  pricesWrapper = 'ppz009',
  breakdownTitle = 'ppz010',
  personNameDatePrefix = 'ppz011',
  personPricesPrefix = 'ppz012',
}

export default SidebarABTestingIds;
