export const enum ENDPOINTS {
  initOffer = 'init-offer',
  insuranceSituation = 'insurance-situation',
  birthdates = 'birthdates',
  contribution = 'contribution',
  personalData = 'personal-data',
  bankDetails = 'bank-details',
  insuranceBeginning = 'insurance-beginning',
  shippingAddress = 'shipping-address',
  finalize = 'finalize',
  ekomi = 'ekomi',
}
