import { FunctionComponent } from 'react';

import LoadingSpinner from '@eg/elements/LoadingSpinner';

import { FocusScope } from '@react-aria/focus';

import { VisuallyHidden } from '../../layouts/Common';

interface SpinnerProps {
  show: boolean;
  messageToAnnounce?: string;
  loadingMessage: string;
}

const Spinner: FunctionComponent<SpinnerProps> = ({
  show,
  messageToAnnounce,
  loadingMessage,
}) => {
  return (
    <>
      {show && (
        <FocusScope contain restoreFocus autoFocus>
          <VisuallyHidden
            aria-live="assertive"
            aria-atomic="true"
            tabIndex={0}
            data-testid="spinner__live-region"
          >
            {messageToAnnounce ?? loadingMessage}
          </VisuallyHidden>
        </FocusScope>
      )}
      <LoadingSpinner loadingMessage={loadingMessage} show={show} />
    </>
  );
};

export default Spinner;
