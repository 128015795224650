import { useEffect, useState } from 'react';

import {
  getTextInputValue,
  getCheckboxValue,
  setHTMLContent,
} from '../../utils';

const useGetConsentDetails = () => {
  const [hideConsentSection, setHideConsentSection] = useState<
    boolean | undefined
  >(false);
  const [privacyConsentContent, setPrivacyConsentContent] =
    useState<string>('');

  useEffect(() => {
    const _hideStatus = getCheckboxValue('hideConsentSection');
    const _privacyContent = getTextInputValue('privacyConsentContent');

    setHideConsentSection(_hideStatus);
    if (_privacyContent) {
      setHTMLContent(_privacyContent, setPrivacyConsentContent);
    } else setPrivacyConsentContent('');
  }, []);

  return {
    hideConsentSection,
    privacyConsentContent,
  };
};

export default useGetConsentDetails;
