import React from 'react';
import { FormattedMessage } from 'react-intl';

import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';

import { ConsentContact, ContactElement } from 'ppz-otr-common';

import ABTestingIds from '../../../ABTesting/personalData';
import EmailSection from '../../../components/EmailSection/EmailSection';
import {
  ContactDescription,
  ContactSection,
} from '../../../components/EmailSection/layouts';
import { useValidateInput, useTracking } from '../../../hooks';
import literals from '../../../language/src/personal-data';
import { ErrorTypes, PERSON_INPUTS } from '../../../types';
import { getLiteral } from '../../../utils/literals';
import { validatePhoneNumber } from '../utils';

interface Props {
  consentContact: ConsentContact;
  showEmailError: boolean;
  showPhoneError: boolean;
  onChange: (consent: ConsentContact) => void;
}

const Consent = ({
  consentContact,
  showEmailError,
  showPhoneError,
  onChange,
}: Props) => {
  const {
    inputValue: phoneNumber,
    inputTouched: touchedPhone,
    isValidInput,
    onChangeHandler: onChangePhoneHandler,
    onBlurHandler: onBlurPhoneHandler,
  } = useValidateInput({
    validateValueFn: validatePhoneNumber,
    required: false,
    defaultValue: consentContact.phone?.value,
  });

  const { setTrackingError } = useTracking();

  const {
    consentTitle,
    consentEmailDescription,
    consentPhoneDescription,
    consentPhonePlaceholder,
    consentPhoneLabel,
    consentPhoneFormatError,
  } = literals;

  const gotError = (!isValidInput && touchedPhone) || showPhoneError;

  const emailInputId = `${PERSON_INPUTS.consentReceiveEmail}-0`;
  const phoneInputId = `${PERSON_INPUTS.consentReceivePhone}-0`;

  const _onChangeHandler = (params: {
    email?: ContactElement;
    phone?: ContactElement;
  }) => {
    const { email, phone } = params;
    const _params: ConsentContact = {
      email: email ?? consentContact.email,
      phone: phone ?? consentContact.phone,
    };
    onChange(_params);
  };

  return (
    <ContactSection className="abTesting" id={ABTestingIds.consent}>
      <EmailSection
        id={emailInputId}
        title={consentTitle.id}
        description={consentEmailDescription.id}
        showError={showEmailError}
        onBlur={(email) => _onChangeHandler({ email })}
        value={consentContact.email?.value}
      />
      <ContactDescription>
        <FormattedMessage {...consentPhoneDescription} />
      </ContactDescription>
      <FormRow label={getLiteral(consentPhoneLabel)}>
        <ControlWithHint
          error={gotError ? getLiteral(consentPhoneFormatError) : ''}
        >
          <Input
            id={phoneInputId}
            value={phoneNumber}
            aria-label={getLiteral(consentPhoneLabel)}
            placeholder={getLiteral(consentPhonePlaceholder)}
            onChange={(event) => {
              _onChangeHandler({
                phone: { value: event.target.value, valid: isValidInput },
              });
              onChangePhoneHandler(event);
            }}
            onBlur={(event) => {
              if (touchedPhone && phoneNumber && !isValidInput) {
                const { defaultMessage: message } =
                  literals.consentPhoneFormatError;
                setTrackingError(ErrorTypes.FORMFIELD, { message });
              }
              onBlurPhoneHandler(event);
            }}
          />
        </ControlWithHint>
      </FormRow>
    </ContactSection>
  );
};

export { Consent };
