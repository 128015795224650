import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { InitProducts, ProductsType, Tariffs } from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/contribution';
import { ActionButtons } from '../../components/Common';
import { useInsurance, useInsuranceUpdate } from '../../context/InsuranceCtx';
import { useLoading, useLoadingUpdate } from '../../context/LoadingCtx';
import { usePersonUpdate } from '../../context/PersonCtx';
import { useTariff, useTariffUpdate } from '../../context/TariffCtx';
import {
  useGetAEMOfferStatus,
  useGetAEMProductsPDF,
  useResetContributionTariff,
  useTracking,
} from '../../hooks';
import commonLiterals from '../../language/src/common';
import messages from '../../language/src/contribution';
import { PageLayout } from '../../layouts';
import { putSelectedTariff } from '../../services/contribution';
import {
  VIEWS,
  Selection,
  CHECKOUT_TYPE,
  SingleButton,
  ClickableElements,
  GeneralClickProps,
} from '../../types';
import { getProductName, downloadPdf } from '../../utils';

import { CustomCard, CustomPDFButton, CustomRadioTabs } from './components';
import { ContributionContainer } from './layouts';
import { OnProcess } from './types';

const Contribution = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const productsPDF = useGetAEMProductsPDF();
  const { fullOfferDisabled } = useGetAEMOfferStatus();
  const { setErrorOnPersonalAddress, setShowErrorOnEmptyPersonalFields } =
    usePersonUpdate();
  const { businessId } = useInsurance();
  const { setCheckoutType } = useInsuranceUpdate();
  const {
    products,
    premiumProducts,
    contributionSelected,
    basicTariffPrices,
    premiumTariffPrices,
  } = useTariff();

  const {
    setTemporalProducts,
    setContributionSelected,
    setTariffPriceSelected,
  } = useTariffUpdate();
  const { loading } = useLoading();
  const { setTrackingClick } = useTracking();
  const { setLoading } = useLoadingUpdate();

  const [selectedTab, setSelectedTab] = useState<Selection>(
    contributionSelected === '' ? 'basic' : contributionSelected,
  );

  const basicProductName = useMemo(() => getProductName(products), [products]);
  const premiumProductName = useMemo(() => {
    return getProductName(premiumProducts);
  }, [premiumProducts]);

  useEffect(() => {
    setContributionSelected(selectedTab);
  }, []);

  const gotProducts = premiumProducts.length > 0 && products.length > 0;
  const gotPremium = gotProducts && premiumProductName !== basicProductName;
  const showPDFButton = productsPDF.currentPDF !== '';

  const trackingButtonsHandler = (isNext: boolean) => {
    const clickableElement = isNext
      ? ClickableElements.ButtonNext
      : ClickableElements.ButtonRequestOffer;
    setTrackingClick(clickableElement);
  };

  const trackingTabsHandler = (tab: Selection, _productIds: Tariffs[]) => {
    const isBasic = tab === 'basic';
    const basicTab = ClickableElements.RadioButtonBasicTariff;
    const premiumTab = ClickableElements.RadioButtonPremiumTariff;
    const clickableElement = isBasic ? basicTab : premiumTab;
    const trackingProperties: GeneralClickProps = {
      type: 'ProductIds',
      value: _productIds,
    };

    setTrackingClick(clickableElement, trackingProperties);
  };

  const onSelectTabHandler = (tab: Selection) => {
    const isBasic = tab === 'basic';
    const currentProducts = isBasic ? products : premiumProducts;
    const currentPrices = isBasic ? basicTariffPrices : premiumTariffPrices;

    trackingTabsHandler(tab, currentProducts);
    setSelectedTab(tab);
    setTemporalProducts(currentProducts); // context
    setTariffPriceSelected(currentPrices);
    setContributionSelected(tab);
  };

  const onProcess: OnProcess = async (type: 'primary' | 'secondary') => {
    const isPrimary = type === 'primary';
    trackingButtonsHandler(isPrimary);

    setLoading(true);
    setCheckoutType(
      isPrimary ? CHECKOUT_TYPE.online : CHECKOUT_TYPE.appointment,
    );

    const isBasic = selectedTab === 'basic';
    const selectedProductsType: ProductsType = isBasic
      ? ProductsType.basic
      : ProductsType.premium;
    const initProducts: InitProducts = {
      basic: products,
      premium: premiumProducts,
    };
    const response = await putSelectedTariff(
      businessId!,
      selectedProductsType,
      initProducts,
      genericError,
    );
    if (!response || !response.data.success) return genericError();

    setContributionSelected(selectedTab);
    setLoading(false);
    setErrorOnPersonalAddress([]);
    setShowErrorOnEmptyPersonalFields(false);
    navigate(VIEWS.personalData);
  };

  const onGoBack = () => {
    setTrackingClick(ClickableElements.ButtonBack);
    navigate(VIEWS.insuranceBeginning);
  };

  const onClickPDFButtonHandler = () => {
    setTrackingClick(ClickableElements.LinkDownloadPDF);
    downloadPdf(productsPDF.currentPDF);
  };

  const genericError = () => {
    navigate(VIEWS.error);
    setLoading(false);
  };

  useResetContributionTariff(genericError);

  const buttonList: SingleButton[] = [
    {
      label: intl.formatMessage(messages.proceed),
      type: 'primary',
      disabled: loading,
      callbackOnClick: () => onProcess('primary'),
      id: ABTestingIds.nextButton,
    },
    {
      label: intl.formatMessage(messages.request),
      type: 'secondary',
      disabled: loading,
      hidden: fullOfferDisabled,
      callbackOnClick: () => onProcess('secondary'),
      id: ABTestingIds.appointmentButton,
    },
    {
      label: intl.formatMessage(commonLiterals.backButton),
      type: 'text-link',
      disabled: loading,
      callbackOnClick: onGoBack,
      id: ABTestingIds.backButton,
    },
  ];

  const contributionContent: JSX.Element = gotPremium ? (
    <CustomRadioTabs
      selectedTab={selectedTab}
      onSelectedTab={onSelectTabHandler}
      gotPremium={gotPremium}
    />
  ) : (
    <CustomCard gotPremium={gotPremium} />
  );

  const mainTitle: string = gotPremium
    ? intl.formatMessage(messages.premiumTariffTitle)
    : intl.formatMessage(messages.singleTariffTitle);

  return (
    <PageLayout title={mainTitle} id={ABTestingIds.title}>
      <ContributionContainer>
        <div id={ABTestingIds.radioTabContainer} className="abTesting">
          {!loading && contributionContent}
        </div>
        {showPDFButton && <CustomPDFButton onClick={onClickPDFButtonHandler} />}
        <ActionButtons
          buttonsList={buttonList}
          id={ABTestingIds.buttonContainer}
        />
      </ContributionContainer>
    </PageLayout>
  );
};

export default Contribution;
