import React from 'react';

import styled from 'styled-components';

import { colors, sizes, fonts, breakpoints } from '../../styles/tokens';

import { Content } from './layouts';
import Paragraph from './layouts/Paragraph';

interface Props {
  /**
   * @property title
   * @default ''
   * @type string
   */
  title: string;
  /**
   * @property products
   * @default undefined
   * @type GotProduct { string | undefined}
   *
   */
  products?: string;
  /**
   * @property isCentered
   * @default false
   * @type boolean
   */
  isCentered?: boolean;
}

// TODO: This style comes from CMS
const Title = styled.h2`
  color: ${colors.DARKEST_GRAY};
  font-family: ${fonts.REGULARS};
  font-size: ${sizes.text.SM};
  font-weight: bold;
  letter-spacing: 0;
  line-height: ${sizes.text.LG};

  && {
    margin: 0;
  }

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.M};
  }
`;

const HeaderTitle = ({ title, products, isCentered = false }: Props) => {
  const gotProducts = !!products;

  return (
    <Content
      className="header__title"
      type={isCentered ? 'general' : 'title'}
      gotProducts={gotProducts}
    >
      <Title data-testid="header__main-title">{title}</Title>
      {gotProducts && (
        <Paragraph data-testid="header__product-name">{products}</Paragraph>
      )}
    </Content>
  );
};

export default HeaderTitle;
